import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import Modal from "components/Modal";
import ButtonForm from "components/Button/ButtonForm";
import CloseWithBorderIcon from "components/constant/icons/CloseWithBorderIcon";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import TextField from "components/Form/TextField/TextField";
import Label from "components/Form/Label";
import SearchByAddress from "modules/Contacts/ContactEditPage/SearchByAddress";
import DatePickerField from "components/Form/DatePicker/DatePickerField";
import { ProjectRequest } from "data/api/project/type";
import { AddressDetail } from "modules/Contacts/ContactEditPage/ContactEditForm";
import ImageUploadIcon from "components/constant/icons/ImageUploadIcon";
import ImageSelectModal from "modules/Apartment/ApartmentCardPage/ImageSelectModal";
import {
  useProjectCreateMutation,
  useProjectUpdateMutation,
} from "data/api/project";
import showToast from "utils/showToast";
import { ApiException, ResponseStatusType } from "data/api/exception";
import { useGetCitiesQuery } from "data/api/city";
import { RootState } from "store";
import { useDispatch, useSelector } from "react-redux";
import { handleOpenProjectCreateModal } from "store/ProjectReducer";
import { Project } from "modules/Deal/type";
import { useWindowSize } from "hooks/useWindowSize";
import { Editor } from "components/Form/Editor";
import { formatUrlComingFromServer } from "utils/putHttpsToLink";

interface IProps {
  projectSelected: Project | null;
  setProjectSelected: Dispatch<SetStateAction<Project | null>>;
  refetch: () => void;
}

const CreateEditProjectModal = ({
  projectSelected,
  setProjectSelected,
  refetch,
}: IProps) => {
  const isOpen = useSelector(
    (state: RootState) => state.projects.isOpenProjectCreateModal
  );

  const defaultCityId = useSelector((state: RootState) => state.header.city_id);

  const [inputAddress, setInputAddress] = useState<string>("");

  const { isMd } = useWindowSize();

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<ProjectRequest>();

  const dispatch = useDispatch();

  const { data: cities } = useGetCitiesQuery();

  const [loading, setLoading] = useState(false);

  const [images, setImage] = useState<string[]>([]);

  const [photoSelectModal, setPhotoSelectModal] = useState(false);

  const [selectedImgId, setSelectedImgId] = useState<string>("");

  const [validationStart, setIsValiditonStart] = useState<boolean>(false);

  const [selectedCity, setSelectedCity] = useState<AddressDetail | null>(null);

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "buildings",
  });

  const [projectMutation] = useProjectCreateMutation();

  const [projectUpdateMutation] = useProjectUpdateMutation();

  useEffect(() => {
    if (projectSelected) {
      const project: ProjectRequest = {
        photos: projectSelected?.photos || [],
        photo: projectSelected?.photo,
        name: projectSelected?.name,
        description: projectSelected?.description,
        location: projectSelected?.location,
        long: projectSelected?.long,
        lat: projectSelected?.lat,
        end_date: projectSelected?.end_date,
        company_link: projectSelected?.company_link,
        building_link: projectSelected?.building_link,
        project_link: projectSelected?.project_link,
        buildings: projectSelected?.buildings,
        city_id: projectSelected?.city?.id,
      };
      reset(project);
      setSelectedImgId(projectSelected?.photo);
      setImage(projectSelected?.photos || []);
      setInputAddress(projectSelected?.location);
      setSelectedCity({
        title: "г Екатеринбург",
        lat: Number(projectSelected.lat),
        long: Number(60.605514),
        city: projectSelected.city.name,
      });
    }
  }, [projectSelected]);

  const buildings = watch("buildings") || [];

  useEffect(() => {
    if (!fields.length)
      update(0, {
        name: "",
        object_id: null,
      });
  }, [fields]);

  const handleChangeAddress = (e: ChangeEvent<HTMLInputElement>) => {
    if (inputAddress.length === 0) {
      setSelectedCity(null);
    }

    setInputAddress(e.target.value);
  };

  const handleAddressSelect = (data: AddressDetail) => {
    setInputAddress(data?.title);
    setValue("location", data?.title);
    setSelectedCity(data);
    const city = cities?.data.find((item) => item.name === data?.city);
    if (city) {
      setValue("city_id", city?.id);
    } else {
      setValue("city_id", defaultCityId);
    }
  };

  const handleSelectImage = () => {
    // if (selectedImgId) {
    //   setSelectedMainImage(selectedImgId);
    // }
    // setSelectedMainImage(images[0]);
    setPhotoSelectModal(false);
  };

  const onSubmit: SubmitHandler<ProjectRequest> = async (data) => {
    setLoading(true);
    const formData: ProjectRequest = {
      ...data,
      photos: images,
      photo: selectedImgId,
      location: inputAddress,
      lat: selectedCity?.lat?.toString() || undefined,
      long: selectedCity?.long?.toString() || undefined,
    };

    if (projectSelected) {
      updateProject(formData);
    } else {
      try {
        const { data: res } = await projectMutation(formData);
        if (res?.meta?.type === ResponseStatusType.SUCCESS) {
          showToast("Проект успешно создан!", "success", 2000);
          handleCloseModal();
          refetch();
        }
        setLoading(false);
      } catch (error) {
        const err = error as ApiException;
        showToast(err.message, "error", 2000);
        setLoading(false);
      }
    }
  };

  // const isImageArrayDirt = (currentImage: string[]) => {
  //   if (currentImage.length !== projectSelected?.photos.length) {
  //     return true;
  //   }
  //   if (currentImage.length === 0 && projectSelected?.photos.length === 0) {
  //     return false;
  //   }
  //   if (currentImage.length === projectSelected?.photos.length) {
  //     for (let i = 0; i == currentImage.length; i++) {
  //       return currentImage[i] !== projectSelected?.photos[i];
  //     }
  //   }
  //   return false;
  // };

  async function updateProject(formData: ProjectRequest) {
    try {
      const { data: res } = await projectUpdateMutation({
        data: formData,
        id: projectSelected?.id,
      });
      if (res?.meta?.type === ResponseStatusType.SUCCESS) {
        showToast("Проект успешно обновлен!", "success", 2000);
        handleCloseModal();
        refetch();
      }
      setLoading(false);
    } catch (error) {
      const err = error as ApiException;
      showToast(err.message, "error", 2000);
      setLoading(false);
    }
  }

  function resetForm() {
    reset({
      photos: [],
      photo: "",
      name: "",
      description: "",
      location: "",
      long: "",
      lat: "",
      end_date: "",
      company_link: "",
      building_link: "",
      project_link: "",
      buildings: [{ name: "", object_id: null }],
      city_id: 0,
    });
    setSelectedImgId("");
    setSelectedCity(null);
    setImage([]);
    setInputAddress("");
    setProjectSelected(null);
  }

  const handleCloseModal = () => {
    dispatch(handleOpenProjectCreateModal(false));
    resetForm();
    setIsValiditonStart(false);
  };

  const canAddBuilding =
    buildings.length === 0 ||
    (buildings[buildings.length - 1]?.name &&
      buildings[buildings.length - 1]?.object_id);

  return (
    <Modal
      isOpen={isOpen}
      className="mx-auto w-full sm:w-[672px] rounded-t-[30px] rounded-b-0 sm:rounded-lg"
      onClose={handleCloseModal}
    >
      <div className={"flex justify-between mb-[20px]"}>
        <h1 className={"font-extrabold text-[20px] font-dewi"}>
          {projectSelected ? "Редактировать" : "Создать"}{" "}
        </h1>
        <ButtonForm text={<CloseWithBorderIcon />} onClick={handleCloseModal} />
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-[20px] overflow-y-scroll toolbar-scroll max-h-[70vh] pr-3"
      >
        <div
          className="cursor-pointer"
          onClick={() => setPhotoSelectModal(true)}
        >
          <div className="flex gap-[20px] items-center">
            <div className="flex flex-col justify-center border-2 border-dashed border-[#D7DCE4] rounded-md w-[120px] h-[120px] cursor-pointer">
              {selectedImgId ? (
                <div className="w-[120px] h-[120px] overflow-hidden rounded">
                  <img
                    src={`${process.env.REACT_APP_FILES_URL}/${selectedImgId}`}
                    crossOrigin="anonymous"
                    alt="Uploaded"
                    className="w-fit h-auto"
                    width={30}
                  />
                </div>
              ) : (
                <div className="flex flex-col items-center">
                  <ImageUploadIcon width="34" height="33" color="#D7DCE4" />
                </div>
              )}
            </div>
            <div className="text-accent-default flex flex-col gap-[10px]">
              <p className="text-[14px] font-semibold leading-4">Фон</p>
              <p className="text-[12px] font-normal leading-[14px]">
                Выберите изображение
              </p>
            </div>
          </div>
          {!selectedImgId && validationStart && (
            <p className="text-red-default mb-[5px] text-[12px] leading-[14.06px] mt-2">
              Пожалуйста, предоставьте изображение проекта.
            </p>
          )}
        </div>

        <Controller
          name="name"
          control={control}
          rules={{ required: "Пожалуйста, заполните название проекта." }}
          render={({ field: { onChange, value } }) => (
            <TextField
              name="name"
              value={value}
              onChange={onChange}
              placeholder="Укажите название проекта"
              label="Заголовок"
              errors={errors}
            />
          )}
        />
        <Label label="Описание" className={"w-full"}>
          <Controller
            name="description"
            control={control}
            rules={{
              required: "Пожалуйста, заполните описание проекта",
            }}
            render={({ field: { onChange, value } }) => (
              // <QuillEditor value={value} onChangeValue={onChange} />
              <>
                <Editor value={value} onChangeValue={onChange} />
                <p className="text-red-default mb-[5px] text-[12px] leading-[14.06px]">
                  {errors?.description?.message}
                </p>
              </>
            )}
          />
        </Label>

        <div>
          <SearchByAddress
            inputsValue={inputAddress}
            handleChange={handleChangeAddress}
            handleAddressSelect={handleAddressSelect}
          />
          {validationStart
            ? (!selectedCity || !inputAddress) && (
                <p className="text-red-default mt-2 text-[12px] leading-[14.06px]">
                  Пожалуйста, укажите адрес проекта
                </p>
              )
            : null}
        </div>
        <Label label="Срок сдачи">
          <Controller
            name="end_date"
            control={control}
            rules={{
              required: "Пожалуйста, заполните срок сдачи проекта.",
            }}
            render={({ field: { onChange, value } }) => (
              <DatePickerField
                className={`w-full ${!isMd && "calendar-block"}`}
                startDate={value ? new Date(value) : null}
                placeholder={"Дата добавления"}
                isIcon
                name="end_date"
                isRightIcon
                isClearable
                isDisableRead
                onChangeDate={(date) => onChange(date?.toISOString())}
                errors={errors}
              />
            )}
          />
        </Label>

        <div className="flex flex-col gap-5">
          <h3 className="text-accent-default text-[16px] font-semibold">
            Объекты
          </h3>

          {fields.map((field, index) => (
            <div
              key={field.id}
              className="flex flex-col gap-3 border border-blue-default p-3 rounded-lg"
            >
              <Controller
                name={`buildings.${index}.name`}
                control={control}
                rules={{ required: "Пожалуйста, заполните название объекта" }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <TextField
                      name={`buildings.${index}.name`}
                      value={value}
                      onChange={onChange}
                      placeholder="Название объекта"
                      label="Название"
                      errors={errors}
                    />
                    {errors?.buildings?.[index]?.name && (
                      <p className="text-red-default mb-[5px] text-[12px] leading-[14.06px]">
                        {errors.buildings?.[index]?.name?.message}
                      </p>
                    )}
                  </>
                )}
              />
              <Controller
                name={`buildings.${index}.object_id`}
                control={control}
                rules={{
                  required: "Пожалуйста, заполните ID объекта",

                  // validate: (value) => {
                  //   if (value && !/^\d+$/.test(value.toString())) {
                  //     return "ID объекта должен быть числом";
                  //   }
                  //   return true;
                  // },
                }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <TextField
                      name={`buildings.${index}.object_id`}
                      value={value}
                      onChange={onChange}
                      placeholder="ID объекта"
                      label="ID объекта"
                      errors={errors}
                    />
                    {errors?.buildings?.[index]?.object_id && (
                      <p className="text-red-default mb-[5px] text-[12px] leading-[14.06px]">
                        {errors.buildings?.[index]?.object_id?.message}
                      </p>
                    )}
                  </>
                )}
              />

              <ButtonForm
                text="Удалить"
                onClick={() => remove(index)}
                className="py-3 px-6 bg-light-red-10 flex justify-center text-red-default w-full hover:bg-red-default hover:text-white-default"
              />
            </div>
          ))}

          <ButtonForm
            text="Добавить объект"
            disabled={!canAddBuilding}
            onClick={() => append({ name: "", object_id: null })}
            className="py-3 px-6 bg-light-blue-10 flex justify-center text-blue-default w-full hover:bg-blue-default hover:text-white-default"
          />
        </div>
        <Controller
          name="company_link"
          control={control}
          rules={{
            required: "Пожалуйста, укажите ссылку на сайт",
          }}
          render={({ field: { onChange, value } }) => (
            <TextField
              name="company_link"
              value={formatUrlComingFromServer(value)}
              onChange={(e) => {
                const formattedUrl = formatUrlComingFromServer(e.target.value);
                onChange(formattedUrl);
              }}
              type="link"
              placeholder="Напишите ссылка на сайт Брусники"
              label="Ссылка на сайт Брусники"
              errors={errors}
            />
          )}
        />
        <Controller
          name="building_link"
          control={control}
          rules={{
            required:
              "Пожалуйста, укажите вид ссылки для перехода по квартирам",
          }}
          render={({ field: { onChange, value } }) => (
            <TextField
              name="building_link"
              value={formatUrlComingFromServer(value)}
              onChange={(e) => {
                const formattedUrl = formatUrlComingFromServer(e.target.value);
                onChange(formattedUrl);
              }}
              placeholder="Напишите вид ссылки для перехода по квартирам"
              label="Вид ссылки для перехода по квартирам"
              errors={errors}
            />
          )}
        />
        <Controller
          name="project_link"
          control={control}
          rules={{
            required:
              "Пожалуйста, укажите вид ссылки для перехода по иным помещениям",
          }}
          render={({ field: { onChange, value } }) => (
            <TextField
              name="project_link"
              value={formatUrlComingFromServer(value)}
              onChange={(e) => {
                const formattedUrl = formatUrlComingFromServer(e.target.value);
                onChange(formattedUrl);
              }}
              placeholder="Напишите вид ссылки для перехода по иным помещениям"
              label="Вид ссылки для перехода по иным помещениям"
              errors={errors}
            />
          )}
        />

        <div className="">
          <ButtonForm
            disabled={loading}
            type="submit"
            onClick={() => setIsValiditonStart(true)}
            text={projectSelected ? "Редактировать" : "Добавить"}
            className="py-3 px-6 border bg-accent-default flex justify-center text-white-default w-full hover:bg-accent-disabled hover:text-bg-default"
          />
        </div>
      </form>
      <ImageSelectModal
        images={images}
        setImage={setImage}
        photoSelectModal={photoSelectModal}
        setPhotoSelectModal={setPhotoSelectModal}
        handleSelectImage={handleSelectImage}
        selectedImgId={selectedImgId}
        setSelectedImgId={setSelectedImgId}
      />
    </Modal>
  );
};

export default CreateEditProjectModal;
