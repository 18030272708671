import React from "react";
import Modal from "components/Modal";
import ButtonForm from "components/Button/ButtonForm";
import CloseWithBorderIcon from "components/constant/icons/CloseWithBorderIcon";
import { formatPhoneNumber } from "hooks/useFormatInput";
import { handleFormatPrice } from "hooks/handleFormatPrice";
import { Deal } from "./type";
import { useRoles } from "hooks/useRoles";
import useFormattedDate from "hooks/useFormatDate";
import { Primise } from "enums/primisesType";
import { useNavigate } from "react-router";

interface Props {
  isModalOpen: boolean;
  handleClose: () => void;
  data: Deal;
}

const DealsInfoModal = ({ isModalOpen, handleClose, data }: Props) => {
  const navigate = useNavigate();
  const formatedate = useFormattedDate();
  const roles = useRoles(null);

  function toUpperCasefunc(str: string) {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  }

  const handleClick = () => {
    navigate(`/apartments/card/${data.premise_id}`);
    handleClose();
  };

  const primisesType: Record<Primise, string> = {
    [Primise.APARTMENT]: "Квартира",
    [Primise.PARKING]: "Паркинг",
    [Primise.STOREROOM]: "Кладовая",
    [Primise.COMMERCIAL]: "Kоммерческое",
  };

  return (
    <Modal
      isOpen={isModalOpen}
      className="mx-auto w-full md:w-[450px] lg:w-[500px] rounded-t-[30px] rounded-b-0 sm:rounded-[20px]"
      onClose={() => handleClose()}
    >
      <div className={"flex justify-between mb-[20px]"}>
        <h1 className={"font-extrabold text-[25px] font-dewi"}>
          Данные сделки
        </h1>

        <ButtonForm
          text={<CloseWithBorderIcon />}
          onClick={() => handleClose()}
        />
      </div>
      <div className="max-h-[70vh] overflow-y-auto toolbar-scroll pr-3 ">
        <div className="flex flex-col gap-[15px] text-accent-default text-[14px] leading-[18px]">
          <p className="flex gap-[10px] font-semibold">
            Проект:<span className=" font-normal">{data?.project?.name}</span>
          </p>
          <p className="flex gap-[10px] font-semibold">
            Помещение:
            <span className=" font-normal">
              {primisesType[data?.premise?.type as Primise]}
            </span>
          </p>
        </div>
        <hr className=" text-boder-default my-[10px] " />
        <div className="flex flex-col gap-[15px] text-accent-default text-[14px] leading-[18px]">
          <p className="flex gap-[10px] font-semibold">
            Клиент:
            <span className=" font-normal">{data?.client?.fullname}</span>
          </p>
          <p className="flex gap-[10px] font-semibold">
            Телефон:
            <span className=" font-normal">
              {data?.client?.phone_number
                ? "+7 " + formatPhoneNumber(data?.client?.phone_number)
                : null}
            </span>
          </p>
        </div>
        <hr className=" text-boder-default my-[10px] " />
        <div className="flex flex-col gap-[15px] text-accent-default text-[14px] leading-[18px]">
          <p className="flex gap-[10px] font-semibold">
            Номер сделки:
            <span className=" font-normal">#{data?.lead_number}</span>
          </p>
          <p className="flex gap-[10px] font-semibold">
            Статус сделки:
            <span className=" font-normal">{data?.state}</span>
          </p>
          <p className="flex gap-[10px] font-semibold">
            Этап сделки:
            <span className="font-normal">
              {toUpperCasefunc(data?.current_status)}
            </span>
          </p>
        </div>
        <hr className=" text-boder-default my-[10px] " />
        <div className="flex flex-col gap-[15px] text-accent-default text-[14px] leading-[18px]">
          <p className="flex gap-[10px] font-semibold">
            Дата заведения сделки:
            {data?.created_at && (
              <span className="font-normal">
                {formatedate(data?.created_at, true)}
              </span>
            )}
          </p>
          {/*<p className="flex gap-[10px] font-semibold">*/}
          {/*  Дата последнего изменения:*/}
          {/*  <span className="font-normal">*/}
          {/*    {formatedate(data?.updated_at)}*/}
          {/*  </span>*/}
          {/*</p>*/}
        </div>
        <hr className=" text-boder-default my-[10px] " />
        <div className="flex flex-col gap-[15px] text-accent-default text-[14px] leading-[18px]">
          <p className="flex gap-[10px] font-semibold">
            Данные помещения:
            <span className="font-normal">{data?.premise?.floor}</span>
            <span className="font-normal">этаж</span>
            <span className="font-normal">{data?.premise?.rooms}</span>
            <span className="font-normal">квартира</span>
          </p>
          <p className="flex gap-[10px] font-semibold">
            Стоимость помещения:
            <span className="font-normal">
              {handleFormatPrice(data?.premise?.price + "")}
            </span>
          </p>
        </div>
        <hr className=" text-boder-default my-[10px] " />
        <div className="flex flex-col gap-[15px] text-accent-default text-[14px] leading-[18px]">
          <p className="flex gap-[10px] font-semibold">
            ФИО агента:
            <span className="font-normal">{data?.agent?.fullName}</span>
          </p>
          <p className="flex gap-[10px] font-semibold">
            ФИО мененджера:
            <span className="font-normal">{data?.manager?.fullName}</span>
          </p>
        </div>

        <div className="p-[10px] rounded bg-bg-default flex items-center mt-[20px] text-accent-default justify-center flex-col gap-[5px]">
          <p className="text-[14px] text-center font-normal">
            Предварительный расчет размера вознаграждения:
          </p>
          <h3 className=" font-semibold">
            {handleFormatPrice(data?.fee + "")}
          </h3>
        </div>
        <div className="pt-[30px] gap-[10px] flex flex-col">
          <ButtonForm
            onClick={handleClick}
            text="Открыть помещение"
            className=" hover:bg-accent-disabled py-3 px-6
                     bg-accent-default xl:flex-1 flex justify-center
                      text-white-default hover:text-bg-default w-full"
          />

          {(roles.isOzk_manager || roles.isManager) && (
            <ButtonForm
              onClick={() => navigate("/tasks")}
              text="Задачи"
              className=" hover:bg-accent-disabled py-3 px-6
                     bg-bg-default xl:flex-1 flex justify-center
                      text-accent-default hover:text-bg-default w-full"
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default DealsInfoModal;
