import React, { useEffect, useState } from "react";
import ButtonForm from "../../components/Button/ButtonForm";
import { useNavigate, useParams } from "react-router-dom";
import ArrowDropdown from "../../components/constant/icons/ArrowDropdown";
import EyeIcon from "../../components/constant/icons/EyeIcon";
import LikeIcon from "../../components/constant/icons/LikeIcon";
import NewsPageCard from "../../components/Cards/NewsPageCard";
import PencilIcon from "../../components/constant/icons/PencilIcon";
import {
  useGetNewsQuery,
  useGetOneNewsMutation,
  useToggleLikeNewsMutation,
} from "data/api/news";
import useFormattedDate from "hooks/useFormatDate";
import moment from "moment";
import LikedIcon from "components/constant/icons/LikedIcon";
import { News } from "data/api/news/type";
import { useRoles } from "hooks/useRoles";
import BreadCrump from "../../components/BreadCrump";
import AnimationFrame from "components/AnimationFrame.tsx";
import Loader from "components/Loader";

const NewsViewPage = () => {
  const navigate = useNavigate();

  const route = useParams();

  const [newsOne, setNewsOne] = useState<News | null>(null);

  const { data: news } = useGetNewsQuery();

  const [mutateToggleLike] = useToggleLikeNewsMutation();

  const [hashtags, setHashtags] = useState<any[]>([]);

  const [getOneMutation, { isLoading }] = useGetOneNewsMutation();

  const formatedDate = useFormattedDate();

  const roles = useRoles(null);

  useEffect(() => {
    init();
  }, [route.id]);

  async function init() {
    try {
      const { data } = await getOneMutation(Number(route.id));
      setHashtags([
        data?.data?.primary_category || "",
        data?.data?.secondary_category || "",
      ]);
      setNewsOne(data?.data || null);
    } catch (error) {
      console.log("error", error);
    }
  }

  const handleToggleLike = async () => {
    await mutateToggleLike(Number(route.id));
    init();
  };

  if (isLoading) {
    return (
      <div className=" relative min-h-[50vh]">
        <Loader />
      </div>
    );
  }

  return (
    <section className=" bg-bg-default relative">
      <AnimationFrame>
        <div className="md:py-[30px] py-[10px] br-container flex gap-[20px] flex-col lg:flex-row">
          <div className="xl:w-[76%] lg:w-[70%] w-full">
            <div className={"p-[20px] relative bg-white-default rounded-lg"}>
              <div className="relative">
                <div
                  className={"hidden md:flex absolute left-[30px] top-[30px]"}
                >
                  <ButtonForm
                    leftIcon={
                      <div className={"rotate-90"}>
                        <ArrowDropdown color={"#37465B"} />
                      </div>
                    }
                    className="bg-white-default p-2 pr-[20px] text-accent-default flex gap-[0!important] rounded-full"
                    text="Назад"
                    onClick={() => navigate(-1)}
                  />
                </div>

                <div>
                  <img
                    crossOrigin="anonymous"
                    src={
                      process.env.REACT_APP_FILES_URL +
                      "/" +
                      newsOne?.cover_image
                    }
                    width={988}
                    height={463}
                    className={"rounded-sm w-[988px]"}
                  />
                </div>
                {/* {newsOne?.is_like_enabled && (
                <div
                  onClick={handleToggleLike}
                  className={`absolute  cursor-pointer w-[44px] h-[44px] rounded-full flex items-center justify-center bg-white-default bottom-[15px] right-[15px]`}
                >
                  {newsOne?.is_liked ? <LikedIcon /> : <LikeIcon />}
                </div>
              )} */}
              </div>

              <div className={"mt-[30px]"}>
                <div className="flex gap-[7px] sm:gap-[10px] flex-wrap text-[12px] sm:text-[14px] leading-[18px] font-normal text-blue-default">
                  {hashtags.map((item, i) => {
                    if (item) {
                      return (
                        <div
                          key={`hashtag-item-${i}`}
                          className="px-[20px] py-[6px] rounded-[100px] bg-light-blue"
                        >
                          {item.name}
                        </div>
                      );
                    }
                  })}
                </div>

                <div className={"flex justify-between md:pt-[15px] pt-[10px]"}>
                  <h3 className="text-[24px] leading-[28px] text-accent-default font-semibold">
                    {newsOne?.title}
                  </h3>
                  <p
                    className={
                      "md:block hidden text-sm text-accent-default whitespace-nowrap"
                    }
                  >
                    {newsOne?.created_at &&
                      formatedDate(newsOne.created_at, false)}
                  </p>
                </div>
                {newsOne?.content ? (
                  <div
                    className={
                      "news-show-editor my-[30px] text-accent-default text-sm"
                    }
                    dangerouslySetInnerHTML={{
                      __html: newsOne.content,
                    }}
                  />
                ) : null}

                {/*{(roles.isAdmin || roles.isAffiliate_manager) && (*/}
                {/*	<div className={"mb-2"}>*/}
                {/*		<ButtonForm*/}
                {/*			className=" bg-white-default border-[1px] border-accent-default py-[12px] px-[24px]*/}
                {/*text-accent-default text-center flex justify-center hover:bg-bg-focus"*/}
                {/*			text="Отправить пуш уведомление"*/}
                {/*			onClick={() => {}}*/}
                {/*		/>*/}
                {/*	</div>*/}
                {/*)}*/}

                <div className={"flex justify-between"}>
                  <div className="flex gap-[15px] items-center text-[12px] md:ml-auto text-accent-disabled font-medium leading-[16px]">
                    {(newsOne?.views_count || newsOne?.views_count === 0) && (
                      <div className="flex items-center justify-center gap-[8px]">
                        <EyeIcon color="#8E95A1" width="18" />
                        <span>{newsOne?.views_count}</span>
                      </div>
                    )}
                    {newsOne?.is_like_enabled &&
                      (newsOne?.likes_count || newsOne?.likes_count === 0) && (
                        <div
                          onClick={handleToggleLike}
                          className="flex items-center justify-center gap-[8px] cursor-pointer"
                        >
                          {newsOne?.is_liked ? <LikedIcon /> : <LikeIcon />}
                          <span>{newsOne?.likes_count}</span>
                        </div>
                      )}
                  </div>

                  <div className={"md:hidden flex items-center"}>
                    <p className={"text-sm text-accent-disabled"}>
                      {newsOne?.created_at &&
                        formatedDate(newsOne.created_at, false)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="xl:w-[24%] lg:w-[30%] w-full flex flex-col gap-[10px] self-start mt-2 sm:mt-0">
            {news?.data.length ? (
              <div className="block sm:hidden">
                <BreadCrump title="Рекомендованные новости" link="/news" />
              </div>
            ) : null}

            <div>
              {news?.data
                ?.slice(0, 3)
                .filter((item) => item.id !== Number(route.id))
                .map((item, idx) => (
                  <NewsPageCard
                    category={[
                      item?.primary_category?.name || "",
                      item?.secondary_category?.name || "",
                    ]}
                    key={`news-block-item-${item.id}`}
                    imageSrc={
                      process.env.REACT_APP_FILES_URL +
                        "/" +
                        item?.cover_image || ""
                    }
                    title={item?.title || ""}
                    watched={item?.views_count}
                    likes={item?.likes_count}
                    isLikeEnabled={item.is_like_enabled}
                    date={`${
                      item.published_at
                        ? moment(item.published_at).format("DD.MM.YYYY")
                        : ""
                    }`}
                    onClick={() => navigate(`/news/view/${item.id}`)}
                  />
                ))}
            </div>
          </div>
        </div>
      </AnimationFrame>

      {(roles.isAdmin || roles.isAffiliate_manager) && (
        <div className="hidden lg:fixed right-[30px] bottom-[30px] lg:flex items-center justify-center">
          <div
            className={
              "rounded-full bg-accent-default flex items-center shadow-md border border-bg-default justify-center p-3 cursor-pointer"
            }
            onClick={() => navigate("/news/edit")}
          >
            <PencilIcon color={"#FFFFFF"} />
          </div>
        </div>
      )}
    </section>
  );
};

export default NewsViewPage;
