import React, { useMemo } from "react";
import Logo from "../../constant/icons/Logo";
import TelegramIcon from "../../constant/icons/TelegramIcon";
import YoutubeIcon from "../../constant/icons/YoutubeIcon";
import VKIcon from "../../constant/icons/VKIcon";
import { Link } from "react-router-dom";
import { UserRole } from "enums/users";
import { NavLinkType } from "../Header";
import { useRoles } from "hooks/useRoles";
import { handleDownload } from "data/api/auth";
import { navLinksFlat } from "utils/navUtils";

const Footer = () => {
  const roles = useRoles(null);

  function filterNavLinksByRole(
    role: UserRole,
    links: NavLinkType[]
  ): NavLinkType[] {
    return links.filter((link) => !(link.notAllowedRoles || []).includes(role));
  }
  const filteredFooterLinks = useMemo(
    () =>
      roles?.currentRole &&
      filterNavLinksByRole(roles.currentRole, navLinksFlat),
    [roles?.currentRole]
  );
  const downloadContractFile = async () => {
    const CONTRACT_OFFER =
      "https://cdn.brusnika.ru/media/core/agent_page/contract_offer/%D0%94%D0%BE%D0%B3%D0%BE%D0%B2%D0%BE%D1%80_%D0%BF%D1%80%D0%B8%D1%81%D0%BE%D0%B5%D0%B4%D0%B8%D0%BD%D0%B5%D0%BD%D0%B8%D1%8F_%D0%95%D0%9A%D0%91_01_10.pdf?_gl=1*f3w0q5*_gcl_au*MTk0NjU5NjE1NS4xNzE5ODE2OTk2*_ga*NDEyODc3NjExLjE3MTk4MTY5OTY.*_ga_RM6GT3MWMR*MTcyMzE4Nzg5Ny44LjEuMTcyMzE4NzkwOS40OC4wLjE1NjQ3Nzg1MDA";

    await handleDownload(CONTRACT_OFFER, "contract_offer.pdf");
  };

  const downloadRulesFile = async () => {
    const RULES =
      "https://cdn.brusnika.ru/media/core/agent_page/rules_document/%D0%9F%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0_%D0%91%D1%80%D1%83%D1%81%D0%BD%D0%B8%D0%BA%D0%B0.%D0%90%D0%B3%D0%B5%D0%BD%D1%82_2_Dq0kIbx.pdf?_gl=1*1iieyo2*_gcl_au*MTk0NjU5NjE1NS4xNzE5ODE2OTk2*_ga*NDEyODc3NjExLjE3MTk4MTY5OTY.*_ga_RM6GT3MWMR*MTcyMzE4Nzg5Ny44LjEuMTcyMzE4NzkwOS40OC4wLjE1NjQ3Nzg1MDA";
    await handleDownload(RULES, "rules_document.pdf");
  };

  return (
    <footer className=" bg-accent-default text-white-default ">
      <div className="br-container pt-[60px] pb-[100px] lg:py-[60px] mx-auto">
        <div className="flex flex-col md:flex-row justify-between gap-[30px]  ">
          <div className=" md:w-[45%] lg:w-[50%] xl:w-[55%] flex items-center justify-center  md:items-start md:justify-normal flex-col">
            <Logo />
            <p className="text-[12px] leading-[16px] text-center md:text-left font-normal mt-[20px] mb-[30px] opacity-50">
              ООО «Брусника» ОГРН 1116671018958 <br /> Юридический адрес:
              620075, г. Екатеринбург, ул. Малышева, д.51, офис 37/05 <br /> ООО
              «Брусника. Строительство и девелопмент» раскрывает информацию в
              сети Интернет на странице <br /> ООО «Интерфакс-ЦРКИ»-
              информационного агентства, аккредитованного ЦБ РФ на раскрытие
              информации.
              <br /> Вся информация, представленная на данном сайте, носит
              исключительно информационный характер, <br /> не является офертой
              или публичной офертой согласно ст. 435, п. 2 ст. 437 ГК РФ.
            </p>
            <div className="w-full block md:hidden h-[1px] bg-white-default opacity-50  mb-[20px]"></div>
            <div className="md:mb-[30px] flex items-center gap-[10px] ">
              <a
                title="telegram"
                rel="noreferrer"
                target="_blank"
                href="https://t.me/brusnikacompany"
                className="w-[36px] h-[36px] hover:bg-bg-focus rounded-full bg-white-default cursor-pointer  flex items-center justify-center"
              >
                <TelegramIcon />
              </a>
              <a
                title="youtube"
                rel="noreferrer"
                target="_blank"
                href="https://www.youtube.com/channel/UCbd7gxDtiUoyHOeWJ97BOhQ"
                className="w-[36px] h-[36px] hover:bg-bg-focus rounded-full bg-white-default cursor-pointer flex items-center justify-center"
              >
                <YoutubeIcon />
              </a>
              <a
                title="vk"
                rel="noreferrer"
                target="_blank"
                href="https://vk.com/brusnika.company"
                className="w-[36px] h-[36px] hover:bg-bg-focus rounded-full bg-white-default cursor-pointer flex items-center justify-center"
              >
                <VKIcon />
              </a>
            </div>
            <div className="w-full block md:hidden h-[1px] bg-white-default opacity-50 mt-[20px]"></div>
            {/*<ButtonForm*/}
            {/*  text="Связаться"*/}
            {/*  className=" bg-white-default hidden py-[12px] px-[24px] w-[205px] hover:bg-bg-focus*/}
            {/* text-accent-focus text-[14px] font-medium md:flex items-center justify-center"*/}
            {/*/>*/}
          </div>
          <div className=" xl:w-[45%] flex flex-col gap-3 sm:gap-0  sm:flex-row ">
            <div className="flex  justify-center items-center md:justify-normal md:items-start gap-[15px] flex-1 flex-col">
              {filteredFooterLinks?.slice(0, 9).map((item, i) => (
                <Link key={i} to={item.path || ""}>
                  <li className="hover:underline list-none text-[16px] leading-[20px] font-normal cursor-pointer">
                    {item.label}
                  </li>
                </Link>
              ))}
            </div>
            <div className="flex gap-[15px] justify-center items-center md:justify-normal md:items-start flex-1 flex-col">
              {filteredFooterLinks
                ?.slice(9, navLinksFlat.length)
                .map((item, i) => (
                  <Link key={i} to={item.path || ""}>
                    <li className=" hover:underline list-none text-[16px] leading-[20px] font-normal cursor-pointer">
                      {item.label}
                    </li>
                  </Link>
                ))}
            </div>
          </div>
        </div>
        <div className="w-full h-[1px] bg-white-default opacity-50 my-[40px]"></div>
        <div className="text-[16px] font-normal leading-[20px] flex flex-col-reverse md:flex-row md:justify-between">
          <p className="xl:w-[55%] text-center md:text-left">
            &copy; 2024 Компания Брусника
          </p>
          <div className="xl:w-[45%] flex flex-col md:flex-row items-center gap-[30px] xl:justify-end">
            <a
              href="https://ekaterinburg.brusnika.ru/personal_data"
              className=" cursor-pointer"
              target="_blank"
            >
              Политика персональных данных
            </a>
            <p onClick={downloadContractFile} className=" cursor-pointer">
              Договор-оферта
            </p>
            <p className=" cursor-pointer" onClick={downloadRulesFile}>
              Правила работы
            </p>
            <div className="w-full block md:hidden h-[1px] bg-white-default opacity-50  mb-[20px]"></div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
