import { Project } from "modules/Deal/type";
import { apiService } from "..";
import { ClientSearch } from "../base-clients/type";
import { LinksOfGlobalSearch, MetaData } from "../exception";
import { SearchType } from "./type";

export const addTagTypes = [""] as const;

export const searchApiHooks = apiService
  .enhanceEndpoints({ addTagTypes })
  .injectEndpoints({
    endpoints: (build) => ({
      globalSearch: build.query<
        MetaData<SearchType<any>[], unknown, LinksOfGlobalSearch[]>,
        string
      >({
        query: (query) => ({
          path: `/search?text=${query}`,
        }),
      }),
      searchClientsList: build.mutation<MetaData<ClientSearch[]>, string>({
        query: (query) => ({
          path: `/client/search?${query}`,
        }),
      }),
      searchProjectList: build.mutation<MetaData<Project[]>, string>({
        query: (query) => ({
          path: `/projects/search?${query}`,
        }),
      }),
      searchNewsList: build.mutation<MetaData<Project[]>, string>({
        query: (query) => ({
          path: `/news/search?${query}`,
        }),
      }),
      searchEventsList: build.mutation<MetaData<Project[]>, string>({
        query: (query) => ({
          path: `/events/search?${query}`,
        }),
      }),
    }),
  });

export const {
  useGlobalSearchQuery,
  useSearchEventsListMutation,
  useSearchNewsListMutation,
  useSearchProjectListMutation,
  useSearchClientsListMutation,
} = searchApiHooks;
