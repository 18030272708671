import React from "react";
import loaderImg from "../../assets/images/Loader.png";

const LoaderSm = () => {
  return (
    <div
      className="flex justify-center items-center h-[30px] z-20"
    >
      <img src={loaderImg} alt="loading img" className="animate-spin-slow h-[30px]" />
    </div>
  );
};

export default LoaderSm;
