import React, { useEffect, useRef, useState } from "react";
import { AddressDetail } from "./ContactEditForm";
import ArrowDropdown from "components/constant/icons/ArrowDropdown";
import Label from "components/Form/Label";
import { getCityByDaData } from "data/api/city";
import { withDebounce } from "hooks/useDebounce";
import { images } from "utils/resource";

interface IProps {
  inputsValue: string;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    index?: number
  ) => void;
  index?: number;
  handleAddressSelect: (data: AddressDetail) => void;
}

const SearchByAddress = ({
  inputsValue,
  handleChange,
  index,
  handleAddressSelect,
}: IProps) => {
  const [isResultsVisible, setIsResultsVisible] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [addressDetails, setAddressDetails] = useState<AddressDetail[]>([]);

  const dropdownRef = useRef<HTMLDivElement>(null);

  async function handleSearchAddress(query: string) {
    setIsLoading(true);
    const res = await getCityByDaData(query);
    const formattedData: AddressDetail[] = res.map((item) => ({
      title: item.value,
      lat: item?.data?.geo_lat,
      long: item?.data?.geo_lon,
      city: item?.data?.city,
    }));

    setAddressDetails(formattedData);
    setIsLoading(false);
  }

  const handleChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e, index);
    withDebounce(() => handleSearchAddress(e.target.value));
  };

  const handleOutsideClick = (e: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target as Node)
    ) {
      setIsResultsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <Label label="Адрес">
        <div className="relative">
          <input
            value={inputsValue}
            autoComplete="off"
            placeholder="Введите адрес"
            onFocus={() => setIsResultsVisible(true)}
            className="flex-1 px-[16px] py-[12px] placeholder:text-accent-disabled  w-full border-[1px] focus:border-boder-focus border-boder-default outline-none placeholder:font-normal font-normal text-[14px] rounded-[4px]"
            name="address"
            onChange={handleChangeSearchInput}
          />
          <div
            onClick={() => setIsResultsVisible(!isResultsVisible)}
            className={` absolute cursor-pointer top-[50%] -translate-y-[50%] bg-white-default right-[2px] w-[50px] flex items-center justify-center ${
              isResultsVisible ? "rotate-180" : ""
            }`}
          >
            <ArrowDropdown />
          </div>
        </div>
      </Label>
      {isResultsVisible && inputsValue.length ? (
        <div className="absolute w-full flex flex-col bg-white-default max-h-[200px] overflow-auto toolbar-scroll z-20 border border-boder-default shadow-md rounded-sm">
          {isLoading ? (
            <div className="flex py-4 justify-center">
              <img
                src={images.loaderImg}
                alt="loading img"
                className="animate-spin-slow w-8"
              />
            </div>
          ) : addressDetails.length ? (
            addressDetails.map((item) => (
              <div
                key={item.title}
                onClick={() => {
                  handleAddressSelect(item);
                  setIsResultsVisible(false);
                }}
                className="w-full hover:bg-bg-default cursor-pointer text-sm text-accent-default py-3 px-4"
              >
                {item.title}
              </div>
            ))
          ) : (
            !isLoading && (
              <div className="w-full text-sm text-center text-accent-default py-3 px-4">
                Пусто
              </div>
            )
          )}
        </div>
      ) : null}
    </div>
  );
};

export default SearchByAddress;
