import React, { Dispatch, SetStateAction, useState } from "react";
import Modal from "components/Modal";
import CircleXIcon from "components/constant/icons/CircleXIcon";
import TickInCircleIcon from "components/constant/icons/TickInCircleIcon";
import ButtonForm from "components/Button/ButtonForm";
import { useUploadFileMutation } from "data/api/file";
import CancelIcon from "components/constant/icons/CancelIcon";
import ImgUploadWithCroping from "components/Form/ImgUpload/ImageUploadWithCroping";
import { useWindowSize } from "hooks/useWindowSize";
import {
  AdvertisementImage,
  useDeleteApartmentImagesMutation,
  useUploadApartmentImagesMutation,
} from "data/api/premises";

interface IProps {
  photoSelectModal: boolean;
  setPhotoSelectModal: Dispatch<SetStateAction<boolean>>;
  setSelectedImgId: (img: string) => void;
  selectedImgId?: string;
  handleSelectImage: () => void;
  images: string[];
  setImage: Dispatch<SetStateAction<string[]>>;
  isImageUploadExist?: boolean;
  aspect?: number[];
  page?: string;
  imagesData?: AdvertisementImage[];
  sizeInfo?: string;
}

const ImageSelectModal = ({
  photoSelectModal,
  setPhotoSelectModal,
  setSelectedImgId,
  selectedImgId,
  handleSelectImage,
  images,
  setImage,
  isImageUploadExist = true,
  aspect = [1, 1],
  page,
  imagesData,
  sizeInfo = "600x600",
}: IProps) => {
  const [fileMutate] = useUploadFileMutation();

  const [uploadApartmentImageMutation] = useUploadApartmentImagesMutation();
  const [deleteApartmentImageMutation] = useDeleteApartmentImagesMutation();

  const { isMd } = useWindowSize();

  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const handleImageUpload = async (file: File) => {
    const formData = new FormData();
    formData.append("image", file);
    try {
      const response = await fileMutate(formData).unwrap();
      const fileName = response.data.filename;
      setImage((prev) => [...prev, fileName]);
      if (page === "apartment") {
        await uploadApartmentImageMutation(fileName);
      }
    } catch (error) {
      console.error("File upload failed", error);
    }
  };

  const handleDeleteImg = async (image: string) => {
    setImage((prevImages) => prevImages.filter((img) => img !== image));
    if (selectedImgId === image) {
      setSelectedImgId("");
    }
    if (page === "apartment") {
      const deletedImage = imagesData?.find((item) => item.image === image);
      if (deletedImage?.id) {
        await deleteApartmentImageMutation(deletedImage?.id);
      }
    }
  };

  return (
    <Modal
      className="w-full md:w-[500px] rounded-[8px] max-h-[60vh] overflow-auto toolbar-scroll"
      isOpen={photoSelectModal}
      onClose={() => setPhotoSelectModal(false)}
    >
      <div className=" text-accent-default mb-[20px] flex items-center justify-between">
        <h1 className="font-dewi text-[25px] font-extrabold leading-[30px]">
          Выбор фона
        </h1>
        <button onClick={() => setPhotoSelectModal(false)}>
          <CircleXIcon />
        </button>
      </div>

      <div className="flex gap-[10px] flex-wrap items-center">
        {isImageUploadExist && (
          <ImgUploadWithCroping
            aspect={aspect}
            title={"Обложка"}
            value=""
            requirementText={`Добавьте изображение с разрешением минимум ${sizeInfo} px`}
            handleImageUpload={handleImageUpload}
          />
        )}

        {images?.map((img, i) => (
          <div
            onClick={(e) => {
              setSelectedImgId(img);
              // e.stopPropagation();
            }}
            onMouseEnter={() => setHoveredIndex(i)}
            onMouseLeave={() => setHoveredIndex(null)}
            key={i}
            className={`rounded-lg overflow-hidden cursor-pointer relative ${
              page === "apartment" && isMd ? "w-full" : "w-[30.8%]"
            }`}
          >
            <img
              src={`${process.env.REACT_APP_FILES_URL}/${img}`}
              crossOrigin="anonymous"
              width={140}
              height={90}
              className={`h-full ${
                page === "apartment" && isMd ? "w-full" : ""
              }`}
              alt="apartment maket img"
            />
            {selectedImgId === img && (
              <div className="absolute top-0 left-0 bottom-0 right-0 bg-accent-default opacity-50 flex items-center justify-center"></div>
            )}
            {selectedImgId === img && (
              <div className="absolute top-0 left-0 bottom-0 right-0 flex items-center justify-center">
                <TickInCircleIcon />
              </div>
            )}
            {hoveredIndex === i && isImageUploadExist && (
              <div
                onClick={(e) => {
                  handleDeleteImg(img);
                  e.stopPropagation();
                }}
                className="absolute top-[4px] right-[4px] bg-red-default w-[20px] h-[20px] rounded-full flex items-center justify-center"
              >
                <CancelIcon color="#ffff" />
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="flex gap-[10px] mt-[20px]">
        <ButtonForm
          onClick={() => {
            setPhotoSelectModal(false);
            setSelectedImgId("");
          }}
          text="Отмена"
          type="button"
          className="border border-accent-default py-3 px-6 flex-1
        hover:bg-accent-default flex justify-center
         hover:text-white-default"
        />
        <ButtonForm
          text="Сохранить"
          onClick={handleSelectImage}
          className=" py-3 px-6 
        bg-accent-default flex-1
        text-white-default flex justify-center
        hover:bg-accent-disabled
        hover:
        "
        />
      </div>
    </Modal>
  );
};

export default ImageSelectModal;
