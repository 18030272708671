export const objectToQueryString = <T extends Record<string, any>>(obj: T) => {
  const query = Object.entries(obj)
    .filter(
      ([key, value]) =>
        value !== undefined && value !== null && value !== "" && value !== 0,
    )
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
  return query;
};
