import React, { useEffect, useState } from "react";
import { Range, getTrackBackground } from "react-range";
import "./style.css";

interface RangeValueType {
  min: number;
  max: number;
}

interface MultiRangeSliderProps {
  min: number;
  max: number;
  step: number;
  onChange: ({ min, max }: RangeValueType) => void;
  value?: string[] | null; 
}

const MultiRangeSlider: React.FC<MultiRangeSliderProps> = ({
  min,
  max,
  step,
  onChange,
  value,
}) => {
  const [inputValues, setInputValues] = useState<number[]>([
    value ? parseFloat(value[0]) : min,
    value ? parseFloat(value[1]) : max,
  ]);

  const [tempValues, setTempValues] = useState<string[]>(
    value ? value : [min.toString(), max.toString()]
  );

  useEffect(() => {
    if (value) {
      const parsedValues = value.map((v) => parseFloat(v));
      setInputValues(parsedValues);
      setTempValues(value); 
    }
  }, [value]);

  const validateAndSetValues = (index: number) => {
    const newValue = parseFloat(tempValues[index]);

    setInputValues((prevValues) => {
      let newValues = [...prevValues];

      if (index === 0 && newValue < newValues[1] && newValue >= min) {
        newValues[index] = newValue;
      } else if (index === 1 && newValue > newValues[0] && newValue <= max) {
        newValues[index] = newValue;
      }

      if (newValues[0] >= newValues[1]) {
        newValues[index === 0 ? 1 : 0] = newValue + (index === 0 ? 1 : -1);
      }

      onChange({ min: newValues[0], max: newValues[1] });

      return newValues;
    });
  };

  const handleInputChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const unformattedNum = unformatNumber(event.target.value);
      setTempValues((prevValues) => {
        const newValues = [...prevValues];
        newValues[index] = unformattedNum + "";
        return newValues;
      });
    };

  const handleBlur = (index: number) => () => {
    validateAndSetValues(index);
  };

  const handleKeyDown =
    (index: number) => (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        validateAndSetValues(index);
      }
    };

  const handleRangeChange = (newValues: number[]) => {
    setInputValues(newValues);
    setTempValues(newValues.map((val) => val.toString()));
    onChange({ min: newValues[0], max: newValues[1] }); 
  };

  function formatNumber(num: number): string {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  function unformatNumber(formatted: string): number {
    return parseFloat(formatted.replace(/\s/g, "")) || 0;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
      }}
    >
      <div className="px-[16px] py-[14px] relative flex items-center w-full justify-between opacity-60 border-[2px] border-boder-default rounded-[4px]">
        <input
          type="text"
          className="outline-none absolute left-4 text-left w-[40%]"
          onChange={handleInputChange(0)}
          onBlur={handleBlur(0)}
          onKeyDown={handleKeyDown(0)}
          value={formatNumber(+tempValues[0])}
        />
        <div className="h-[17px] w-[2px] bg-boder-default m-auto"></div>
        <input
          type="text"
          className="outline-none absolute right-4 text-right w-[40%]"
          onChange={handleInputChange(1)}
          onBlur={handleBlur(1)}
          onKeyDown={handleKeyDown(1)}
          value={formatNumber(+tempValues[1])}
        />
      </div>
      <Range
        values={inputValues}
        step={step}
        min={min}
        max={max}
        onChange={handleRangeChange} 
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              display: "flex",
              width: "100%",
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: "3px",
                width: "100%",
                borderRadius: "4px",
                background: getTrackBackground({
                  values: inputValues,
                  colors: ["#dadada", "#3B4658", "#dadada"],
                  min: min,
                  max: max,
                }),
                alignSelf: "center",
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            key={props.key}
            style={{
              ...props.style,
              height: "14px",
              width: "14px",
              borderRadius: "100%",
              backgroundColor: "#3B4658",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 2px 6px #AAA",
              outline: "none",
            }}
          />
        )}
      />
    </div>
  );
};

export default MultiRangeSlider;
