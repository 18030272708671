import { apiService } from "..";
import { MetaData } from "../exception";
import { ISection } from "./type";

export const addTagTypes = ["sections"] as const;

export const sectionsApiHooks = apiService
  .enhanceEndpoints({ addTagTypes })
  .injectEndpoints({
    endpoints: (build) => ({
      getSections: build.query<
        MetaData<ISection[]>,
        { buildingId: number | null }
      >({
        query: (param) => ({
          path: param.buildingId
            ? `/sections?building_id=${param.buildingId}`
            : "/sections",
        }),
        providesTags: ["sections"],
      }),
      getSectionsByBuildingId: build.mutation<MetaData<ISection[]>, number>({
        query: (id) => ({
          path: `/sections?building_id=${id}`,
        }),
      }),
    }),
  });

export const { useGetSectionsQuery, useGetSectionsByBuildingIdMutation } =
  sectionsApiHooks;
