import React from "react";
import NotFound from "components/NotFound";
import { ManagerInfo, TopInfo } from "data/api/analytics/type";
import { useRoles } from "hooks/useRoles";
import Loader from "components/Loader";

type dataType = TopInfo[] | ManagerInfo[] | null;

interface Props {
  title: string;
  data: dataType;
  isLoading?: boolean;
  label: string;
  paginationInfo?: { currPage: number; totalPage: number };
}

const EventsAnaliticsCart = ({
  title,
  data,
  isLoading,
  label,
  paginationInfo,
}: Props) => {
  const role = useRoles(null);

  function imgByRole(item: TopInfo | ManagerInfo) {
    if (role.isAdmin) {
      return (item as TopInfo).news_cover_image;
    } else {
      return (item as ManagerInfo).photo;
    }
  }

  function titleByRole(item: TopInfo | ManagerInfo) {
    if (role.isAdmin) {
      return (item as TopInfo).news_title;
    } else {
      return (item as ManagerInfo).first_name;
    }
  }

  function readableNumber(num: number): string {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }


  return (
    <div className="bg-white-default rounded-lg relative">
      <div className="py-[15px] px-5">
        <h2 className="font-dewi text-[18px] sm:text-[20px] text-accent-default">
          {title}
        </h2>
      </div>
      <hr className=" border-boder-default opacity-60" />
      <div className="py-[15px]  flex flex-col">
        {isLoading && (
          <div className="h-[100px]">
            <Loader />
          </div>
        )}
        {data?.length
          ? data?.map((item, i) => (
              <div key={item.news_id}>
                <div className="flex justify-between px-[15px] sm:px-5">
                  <div className="flex gap-3">
                    <div className="img relative w-[48px] h-[48px]">
                      <img
                        src={
                          process.env.REACT_APP_FILES_URL +
                          "/" +
                          imgByRole(item)
                        }
                        alt="analitcs img"
                        crossOrigin="anonymous"
                        className="w-[48px] h-[48px] rounded-lg"
                      />
                      {i <= 2 && (
                        <div
                          className={`w-[20px] absolute h-[20px] rounded-full flex items-center justify-center text-[12px] text-accent-default font-medium  -bottom-[5px] -right-[5px] ${
                            i + 1 === 1
                              ? "bg-first-place"
                              : i + 1 === 2
                              ? "bg-second-place"
                              : "bg-third-place"
                          }`}
                        >
                          {i + 1}
                        </div>
                      )}
                    </div>
                    <div className="text-accent-default leading-[18px] flex flex-col sm:gap-[5px]">
                      <p className=" text-[13px] sm:text-[16px] font-medium ">
                        {titleByRole(item)}
                      </p>
                      <p className="text-[12px]">#брусника #горячее</p>
                    </div>
                  </div>
                  <div className="text-accent-default leading-[18px] flex flex-col gap-[5px]">
                    <p className="text-[12px] opacity-50">{label}</p>
                    <p className=" text-sm sm:text-[16px] font-medium text-center">
                      {readableNumber(Number(item.total))}
                    </p>
                  </div>
                </div>
                {i + 1 !== data?.length && (
                  <hr className=" border-boder-default my-[10px] opacity-60" />
                )}
              </div>
            ))
          : !isLoading && <NotFound title={title + " " + "не найдено"} />}
      </div>
      {data?.length &&
      paginationInfo?.currPage !== paginationInfo?.totalPage ? (
        <>
          <hr className=" border-boder-default opacity-60" />
          <button className=" outline-none text-center cursor-pointer text-[#0085FF] text-[16px] font-medium py-3 w-full">
            Посмотреть все
          </button>
        </>
      ) : null}
    </div>
  );
};

export default EventsAnaliticsCart;
