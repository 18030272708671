import { apiService } from "..";
import { MetaData } from "../exception";
import { Building } from "./type";

export const addTagTypes = ["buildings"] as const;

export const buildingsApiHooks = apiService
  .enhanceEndpoints({ addTagTypes })
  .injectEndpoints({
    endpoints: (build) => ({
      getBuildings: build.query<MetaData<Building[]>, string | null>({
        query: (query) => ({
          path: query ? `/buildings?${query}` : "/buildings",
        }),
        providesTags: ["buildings"],
      }),
      getBuildingsByProjectId: build.mutation<MetaData<Building[]>, number>({
        query: (id) => ({
          path: `/buildings?project_id=${id}`,
        }),
      }),
    }),
  });

export const { useGetBuildingsQuery, useGetBuildingsByProjectIdMutation } =
  buildingsApiHooks;
