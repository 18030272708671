import { StatusLeads } from "data/api/analytics/type";
import React from "react";
import { Pie, PieChart, Cell } from "recharts";
import { setColorByStatus } from "../data";

interface Props {
  data?: StatusLeads[];
}

const PieChartComponent = ({ data: leadsStatus }: Props) => {
  const data = leadsStatus?.map((item) => {
    return { name: item.status, value: item.total };
  });
  const COLORS = [
    "#36C979",
    "#1400FF",
    "#FF005C",
    "#3B4A60",
    "#52FF01",
    "#7723FF",
    "#DEBE4E",
  ];

  return (
    <PieChart width={140} height={140}>
      <Pie
        data={data}
        cx={65}
        cy={70}
        innerRadius={45}
        outerRadius={60}
        fill="#8884d8"
        dataKey="value"
      >
        {data?.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={setColorByStatus(entry.name)} />
        ))}
      </Pie>
    </PieChart>
  );
};

export default PieChartComponent;
