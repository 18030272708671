import React from "react";

const IconSearchPeopleIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.3999 21.5999L2.40031 17.9995C2.40053 16.0114 4.01224 14.3999 6.00031 14.3999H10.7999M14.3999 5.9999C14.3999 7.98813 12.7881 9.5999 10.7999 9.5999C8.81167 9.5999 7.1999 7.98813 7.1999 5.9999C7.1999 4.01168 8.81167 2.3999 10.7999 2.3999C12.7881 2.3999 14.3999 4.01168 14.3999 5.9999Z"
        stroke="#37465B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.4568 20.5201L22.4001 22.4001M21.7734 17.3867C21.7734 19.8094 19.8094 21.7734 17.3867 21.7734C14.964 21.7734 13 19.8094 13 17.3867C13 14.964 14.964 13 17.3867 13C19.8094 13 21.7734 14.964 21.7734 17.3867Z"
        stroke="#37465B"
        strokeWidth="1.3"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default IconSearchPeopleIcon;
