import React, { ReactNode, useState } from "react";
import ButtonForm from "../../components/Button/ButtonForm";
import CloseWithBorderIcon from "../../components/constant/icons/CloseWithBorderIcon";
import Label from "../../components/Form/Label";
import Dropdown from "../../components/Form/Dropdown/Dropdown";
import Modal from "../../components/Modal";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import TextField from "../../components/Form/TextField/TextField";
import RublIcon from "../../components/constant/icons/RublIcon";
import CheckBox from "../../components/Form/CheckBox";
import LinkAngledIcon from "../../components/constant/icons/LinkAngledIcon";
import { useWindowSize } from "../../hooks/useWindowSize";

interface FormSubmit {
	client: any;
	city: string;
	street: string;
	house: string;
	apartment: string;
	room: any;
	year_house: string;
	choose_stay: any;
	floor: string;
	floor_house: string;
	rooms: string;
	area_total: string;
	area_live: string;
	mortgage: boolean;
	illegal_alter: boolean;
	condition_room: any;
	price_client: string;
	price_agent: string;
	url: string;
}

interface formData {
	key: any;
	label?: string;
	required: boolean;
	type: string;
	placeholder?: string;
	text1?: string;
	text2?: string;
	options?: any[];
	className?: string;
	classNameLabel?: string;
	groupList?: formData[];
	leftIcon?: ReactNode;
	rightIcon?: ReactNode;
}

interface ExchangeModalApplicationProps {
	isOpenModal: boolean;
	setIsOpenModal: (value: boolean) => void;
	setIsMyApplication: () => void;
}

const ExchangeModalApplication = (
	{
		isOpenModal,
		setIsOpenModal,
		setIsMyApplication
	}: ExchangeModalApplicationProps
) => {
	const { isMd } = useWindowSize();

	const [isStatusComplete, setIsStatusComplete] = useState(false);

	const formList: formData[] = [
		{
			key: "client",
			label: "Клиент",
			required: true,
			type: "select",
			placeholder: "Выберите клиента",
			options: []
		},
		{
			key: "city",
			label: "Адрес помещения:",
			required: true,
			type: "text",
			placeholder: "Город",
			classNameLabel: "flex-row-important flex-wrap gap-3",
			className: "w-full",
			groupList: [
				{
					key: "street",
					required: true,
					type: "text",
					placeholder: "Улица",
					className: "w-full"
				},
				{
					key: "house",
					required: true,
					type: "text",
					placeholder: "Дом/корпус/строение",
					className: "w-[49.2%]",
				},
				{
					key: "client",
					required: true,
					type: "text",
					placeholder: "Номер квартиры",
					className: "w-[49.2%]",
				}
			]
		},
		{
			key: "room",
			label: "Вид помещения",
			required: true,
			type: "select",
			placeholder: "Выберите вид помещения",
			options: []
		},
		{
			key: "year_house",
			label: "Год постройки дома",
			required: true,
			type: "text",
			placeholder: "Введите год",
			className: "w-full"
		},
		{
			key: "choose_stay",
			label: "Нужно ли проживание в квартире до переезда?",
			required: true,
			type: "select",
			placeholder: "Выберите проживание в квартире",
			options: []
		},
		{
			key: "floor",
			label: "Этаж",
			required: true,
			type: "text",
			placeholder: "Введите этаж",
			className: "w-full"
		},
		{
			key: "floor_house",
			label: "Этажность дома",
			required: true,
			type: "text",
			placeholder: "Введите этажность дома",
			className: "w-full"
		},
		{
			key: "rooms",
			label: "Кол-во комнат",
			required: true,
			type: "text",
			placeholder: "Введите кол-во комнат",
			className: "w-full"
		},
		{
			key: "area_total",
			label: "Площадь (общая)",
			required: true,
			type: "text",
			placeholder: "Введите площадь",
			className: "w-full"
		},
		{
			key: "area_live",
			label: "Площадь (жилая)",
			required: true,
			type: "text",
			placeholder: "Введите площадь",
			className: "w-full"
		},
		{
			key: "mortgage",
			label: "Наличие ипотеки",
			required: false,
			type: "checkbox",
			text1: "Да",
			text2: "Нет",
			className: "w-full"
		},
		{
			key: "illegal_alter",
			label: "Наличие неузаконенных перепланировок",
			required: false,
			type: "checkbox",
			text1: "Да",
			text2: "Нет",
			className: "w-full"
		},
		{
			key: "condition_room",
			label: "Состояние помещения",
			required: true,
			type: "select",
			placeholder: "Выберите состояние",
			options: []
		},
		{
			key: "price_client",
			label: "Ожидание по цене клиента",
			required: true,
			type: "text",
			placeholder: "10 000 000",
			className: "w-full",
			rightIcon: <RublIcon/>
		},
		{
			key: "price_agent",
			label: "Ожидание по цене агента",
			required: true,
			type: "text",
			placeholder: "10 000 000",
			className: "w-full",
			rightIcon: <RublIcon/>
		},
		{
			key: "url",
			label: "Ссылка на объявление по продаже (необязательно)",
			required: false,
			type: "text",
			placeholder: "www.brusnika.com",
			className: "w-full",
			leftIcon: <LinkAngledIcon/>
		},
	]

	const {
		handleSubmit,
		control,
		reset,
		formState: { errors },
	} = useForm<FormSubmit>();

	const onSubmit: SubmitHandler<FormSubmit> = async (data) => {
		console.log(data)

		reset();
		setIsStatusComplete(true);
	};

	return (
		<Modal
			isOpen={isOpenModal}
			onClose={() => {
				setIsOpenModal(false)
				setIsStatusComplete(false)
			}}
			className="mx-auto w-full md:w-[450px] lg:w-[500px] rounded-t-[30px] rounded-b-0 sm:rounded-[20px]"
		>
			{isStatusComplete ? (
				<>
					<h3 className="text-[25px] font-dewi font-extrabold text-center mb-[10px]">
						Заявка отправлена
					</h3>

					<p className="text-sm font-normal text-center mb-5">Заявка на обмен отправлена</p>

					<ButtonForm
						text={"Перейти к заявкам"}
						onClick={() => {
							setIsStatusComplete(false);
							setIsOpenModal(false);
							setIsMyApplication();
						}}
						className="bg-accent-default py-[16px] w-full
             text-white-default text-center flex justify-center hover:bg-accent-focus "
					/>
				</>
			) : (
				<>
					<div className={`flex ${isMd ? "justify-center" : "justify-between"} mb-[20px]`}>
						<h1 className={"font-extrabold text-[25px] font-dewi"}>
							Заявка на обмен
						</h1>

						{!isMd && (
							<ButtonForm
								text={<CloseWithBorderIcon/>}
								onClick={() => {
									setIsOpenModal(false)
									setIsStatusComplete(false)
								}}
							/>
						)}
					</div>
					<div className="flex flex-col gap-5">
						<form
							onSubmit={handleSubmit(onSubmit)}
							className=" pt-[20px] flex flex-col gap-5 max-h-[80vh] overflow-y-auto pr-3 toolbar-scroll"
						>
							{formList.map((item, index) => (
								<Label
									label={item.label}
									key={`form-${item.key}-index`}
									className={item.classNameLabel}
								>
									<Controller
										name={item.key}
										control={control}
										rules={{
											required: {
												value: item.required,
												message: "",
											},
										}}
										render={({ field: { onChange, value } }) => {
											if (item.type === "select") return (
												<Dropdown
													onChange={onChange}
													options={item.options || []}
													value={value}
													name={item.key}
													placeholder={item.placeholder}
													errors={errors}
													className={item.className}
												/>
											)

											if (item.type === "checkbox") return (
												<div className={"flex justify-between gap-3"}>
													<div className="w-full">
														<CheckBox
															label={item.text1}
															onChange={() => onChange(true)}
															value={value}
															name={item.key}
														/>
													</div>

													<div className="w-full">
														<CheckBox
															label={item.text2}
															onChange={() => onChange(false)}
															value={!value}
															name={item.key}
														/>
													</div>
												</div>
											)

											return (
												<TextField
													value={value}
													onChange={onChange}
													placeholder={item.placeholder}
													errors={errors}
													className={item.className}
													imgLeft={item.leftIcon}
													imgRight={item.rightIcon}
												/>
											)
										}}
									/>

									{item.groupList && item.groupList.map((i: any, idx: number) => (
										<Controller
											key={`group-${i.key}-${idx}`}
											name={i.key}
											control={control}
											rules={{
												required: {
													value: i.required,
													message: "",
												},
											}}
											render={({ field: { onChange, value } }) => (
												<TextField
													value={value}
													onChange={onChange}
													placeholder={i.placeholder}
													errors={errors}
													className={i.className}
												/>
											)}
										/>
									))}
								</Label>
							))}

							<ButtonForm
								type={"submit"}
								className={
									" px-6 py-3 w-full mt-3 justify-center bg-accent-default text-white-default hover:bg-accent-disabled  hover:text-bg-default  hover:bg-bg-defau text-ellipsis whitespace-nowrap"
								}
								text="Отправить заявку на обмен"
							/>
						</form>
					</div>
				</>
				)}
		</Modal>
	)
}

export default ExchangeModalApplication;