import { FC, useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import MyCustomUploadAdapterPlugin from "../../../utils/UploadImageByEditor";

import "@ckeditor/ckeditor5-build-classic/build/translations/ru";

interface EditorProps {
  value?: any;
  onChangeValue?: (value: string) => void;
}

export const Editor: FC<EditorProps> = ({ value, onChangeValue }) => {
  const [valueLocal, setValue] = useState<string>(value);

  const onChangeLocalValue = (value: string) => {
    setValue(value);

    if (onChangeValue) onChangeValue(value);
  };

  useEffect(() => {
    if (value !== valueLocal) setValue(value);
  }, [value]);

  return (
    <div className="App prose">
      <CKEditor
        editor={ClassicEditor}
        data={valueLocal}
        // onReady={(editor: any) => {
        //   editor.model.document.on("change:data", () => {
        //     const viewFragment = editor.data.processor.toView(editor.getData());

        //     const viewImages = Array.from(viewFragment.getChildren()).filter(
        //       (child: any) => child.is("element") && child.name === "img"
        //     );

        //     viewImages.forEach((viewImage: any) => {
        //       viewImage.setAttribute("crossOrigin", "anonymous");
        //     });

        //     const updatedHtml = editor.data.processor.toData(viewFragment);
        //     editor.setData(updatedHtml);
        //   });
        // }}
        onChange={(event: any, editor) => {
          const data = editor.getData();
          onChangeLocalValue(data);
        }}
        config={{
          extraPlugins: [MyCustomUploadAdapterPlugin],
          toolbar: [
            "alignment",
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "imageUpload",
            "blockQuote",
            "insertTable",
            "mediaEmbed",
          ],
          placeholder: "Начните писать текст здесь...",
          language: "ru",
          mediaEmbed: {
            previewsInData: false,
          },
        }}
      />
    </div>
  );
};
