import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { useSearchAgenciesQuery } from "data/api/agency";
import { Agency } from "data/api/agency/type";
import TextField from "components/Form/TextField/TextField";
import SearchIcon from "components/constant/icons/SearchIcon";
import CancelIcon from "components/constant/icons/CancelIcon";
import { withDebounce } from "hooks/useDebounce";
import Loader from "components/Loader";
import LoaderSm from "components/Loader/LoaderSm";

interface Props {
  searchField: string;
  setSearchField: Dispatch<SetStateAction<string>>;
  setDebouncedTerm: Dispatch<SetStateAction<string>>;
  searchResult?: Agency[];
  setSelectedAgent?: Dispatch<SetStateAction<number | null>>;
  loading?: boolean;
}

const SearchAgency = ({
  searchField,
  setSearchField,
  setDebouncedTerm,
  searchResult,
  setSelectedAgent,
  loading,
}: Props) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleItemClick = (agencyId: number) => {
    setSearchField(
      searchResult?.find((agency) => agency.id === agencyId)?.title || ""
    );
    setSelectedAgent?.(agencyId);
    setIsFocused(false);
  };

  const handleFocus = (val: boolean) => {
    setIsFocused(val);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (searchField.length === 0) {
      setSelectedAgent?.(null);
    }
    setSearchField(e.target.value);
    withDebounce(() => setDebouncedTerm(e.target.value));
  };

  return (
    <div className="relative">
      <TextField
        value={searchField}
        className=""
        placeholder="Введите агентство"
        label="Название агентства"
        type="text"
        onChange={handleChange}
        name="agency_id"
        imgLeft={<SearchIcon />}
        imgRight={
          searchField.length ? (
            <div
              onClick={() => {
                setSearchField("");
                setSelectedAgent?.(null);
              }}
            >
              <CancelIcon color="#C5CAD1" />
            </div>
          ) : null
        }
        setFocused={handleFocus}
      />
      {isFocused && (
        <ul className="bg-white-default border border-boder-default rounded-b w-full absolute min-h-[50px] max-h-[200px] overflow-y-auto toolbar-scroll z-10 shadow-md top-[70px] left-0 right-0 flex flex-col">
          {loading && <LoaderSm />}
          {searchResult?.length
            ? searchResult?.map((agency: Agency) => (
                <li
                  onMouseDown={() => handleItemClick(agency.id)}
                  className="cursor-pointer hover:bg-bg-default py-[14px] px-[15px] text-accent-default"
                  key={agency.id}
                >
                  {agency.title}
                </li>
              ))
            : !loading && (
                <li className="text-accent-default py-[14px] px-[15px] text-[14px]">
                  Агентство не найдено
                </li>
              )}
        </ul>
      )}
    </div>
  );
};

export default SearchAgency;
