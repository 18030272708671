import React from "react";
import { images } from "utils/resource";
import LogoutIcon from "components/constant/icons/LogoutIcon";
import { useWindowSize } from "hooks/useWindowSize";
import { mamberLocalName } from "enums/users";
import { useNavigate } from "react-router";
import { useAppSelector } from "store";
import { selectUser } from "store/UserReducer/selectors";

const LogOutAsUser = () => {
  const data = useAppSelector(selectUser);
  const navigate = useNavigate();
  const windowSize = useWindowSize();

  function logOut() {
    const oldToken = localStorage.getItem("old_token") as string;
    localStorage.setItem("token", oldToken);
    localStorage.removeItem("old_token");
    navigate("/administration");
    window.location.reload();
  }

  const imgUrl = process.env.REACT_APP_FILES_URL;

  return (
    <>
      {windowSize.isLg ? (
        <div className="flex gap-3 items-center py-[15px] pl-[15px] pr-[25px] fixed bottom-0 left-[50%] -translate-x-[50%] z-50  bg-white-default border-[3px] border-red-default rounded-t-[20px] w-[300px]">
          <img
            src={
              data?.data?.avatar
                ? `${imgUrl}/${data?.data?.avatar}`
                : images.defaultImg
            }
            alt="user image"
            width={50}
            height={50}
            className="rounded-full"
            crossOrigin="anonymous"
          />
          <div className="flex flex-col ">
            <p className="text-sm text-accent-default font-semibold">
              {data?.data?.fullName}
            </p>
            <p className="text-[12px] text-accent-disabled font-medium">
              {/* @ts-ignore */}
              <p>{mamberLocalName[data?.data?.role]}</p>
            </p>
          </div>
          <div onClick={logOut} className=" ml-auto cursor-pointer">
            <LogoutIcon />
          </div>
        </div>
      ) : (
        <div
          onClick={logOut}
          className="w-[48px] h-[48px] bg-white-default shadow-md rounded-full flex items-center justify-center cursor-pointer fixed bottom-[80px] right-[10px]  z-50 "
        >
          <LogoutIcon />
        </div>
      )}
    </>
  );
};

export default LogOutAsUser;
