export enum FixingType {
  ALL = "",
  LEAD_VERIFICATION = "Проверка лида",
  CENCEL_FIXING = "Отказ в закреплении",
  WEAK_FIXING = "Слабое закрепление",
  STRONG_FIXING = "Сильное закрепление",
}

export enum FixingTypeWithSmall {
  ALL = "",
  LEAD_VERIFICATION = "проверка лида",
  CENCEL_FIXING = "отказ в закреплении",
  WEAK_FIXING = "слабое закрепление",
  STRONG_FIXING = "сильное закрепление",
}
