import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../../index.css";
import { useLocation } from "react-router-dom";
import { useWindowSize } from "../../hooks/useWindowSize";
import LogOutAsUser from "./LogOutAsUser";
import { BottomNavigationBar } from "./Header/BottomNavbar";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  const { pathname } = useLocation();
  const { isMd, isLg } = useWindowSize();

  const isShowMobileCap = ["/base-clients"].includes(pathname) && isMd;

  const old_token = localStorage.getItem("old_token");

  return (
    <React.Fragment>
      {isShowMobileCap ? (
        <div className=" relative">
          <Header />
          <main className="min-h-[50vh] bg-bg-default">{children}</main>
          <Footer />
          {!isLg ? <BottomNavigationBar /> : null}
        </div>
      ) : (
        <div className=" relative">
          <Header />
          <main className="min-h-[50vh] bg-bg-default">{children}</main>
          <Footer />
          {!isLg ? <BottomNavigationBar /> : null}
        </div>
      )}
      {old_token ? <LogOutAsUser /> : null}
    </React.Fragment>
  );
};

export default Layout;
