import React, { useState } from "react";
import { Deal } from "./type";
import { DealStatus } from "enums/deals";
import DealCard from "./DealCard";
import { useLazyGetLeads2Query } from "data/api/lead";
import { objectToQueryString } from "utils/objectToQuery";
import { iFilterDeals } from ".";
import { Links } from "data/api/exception";
import { dropDownOptionsType } from "modules/Apartment/Filter/type";
import AnimationFrame from "components/AnimationFrame.tsx";

interface iProps {
  data: Deal[];
  status: DealStatus;
  filter: iFilterDeals;
  initialLinks: Links;
  dealsSteps: dropDownOptionsType[];
}

const RenderByTypeComp = ({
  data,
  status,
  filter,
  initialLinks,
  dealsSteps,
}: iProps) => {
  const [deals, setDeals] = useState(data);

  const [page, setPage] = useState(1);

  const [hasMore, setHasMore] = useState(initialLinks.totalPage > 1);

  const [getLeadsMutate] = useLazyGetLeads2Query();

  const handleLoadMore = async () => {
    try {
      const newPage = page + 1;

      const query = objectToQueryString({
        ...filter,
        page: newPage,
        state: status,
        limit: 15,
      });

      const res = await getLeadsMutate(query);

      setDeals((prev) => [...prev, ...(res.data?.data || [])]);

      setPage(newPage);

      setHasMore(newPage <= (res.data?.meta?.links?.totalPage ?? 0));
    } catch (error) {
      console.log("error", error);
    }
  };

  if (data.length === 0) {
    return null;
  }

  return (
    <div className=" mb-[20px] md:mb-[30px]">
      <h2 className=" text-[20px] md:text-2xl font-semibold mb-[10px] text-accent-default">
        {status}
      </h2>
      <div className="grid  sm:grid-cols-2 lg:grid-cols-1 gap-[10px]">
        {deals?.map((item, index) => (
          <AnimationFrame key={index}>
            <DealCard data={item} dealsSteps={dealsSteps} />
          </AnimationFrame>
        ))}
      </div>
      {hasMore && (
        <button
          type={"button"}
          className={
            "py-3 px-6 mx-auto text-blue-default text-sm font-medium flex mt-[15px]"
          }
          onClick={handleLoadMore}
        >
          Загрузить еще
        </button>
      )}
    </div>
  );
};

export default RenderByTypeComp;
