import React from "react";

const ThreeDotLoader: React.FC<{
  className?: string;
}> = ({ className = "h-[80vh]" }) => {
  return (
    <div
      className={`flex justify-center absolute items-center space-x-4 w-full ${className}`}
    >
      <div className="w-[10px] h-[10px] bg-accent-default rounded-full animate-scale-up"></div>
      <div className="w-[10px] h-[10px] bg-accent-default rounded-full animate-scale-up animation-delay-200"></div>
      <div className="w-[10px] h-[10px] bg-accent-default rounded-full animate-scale-up animation-delay-400"></div>
    </div>
  );
};

export default ThreeDotLoader;
