import React, { useState } from "react";

import ButtonForm from "components/Button/ButtonForm";
import PhoneIcon from "components/constant/icons/PhoneIcon";
import TextField from "components/Form/TextField/TextField";
import Modal from "components/Modal";
import SelectionToggle from "components/SelectionToggle";
import CloseWithBorderIcon from "components/constant/icons/CloseWithBorderIcon";
import Label from "components/Form/Label";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import showToast from "utils/showToast";
import { useCreateClientMutation } from "data/api/base-clients";
import { ResponseStatusType } from "data/api/exception";
import MaskComponent from "components/MaskInput/MaskComponent";
import { toggleNotificationFormModal2 } from "store/BaseClientsReducer";
import { useDispatch } from "react-redux";

interface PropsComponent {
  isOpen: boolean;
  handleToggle: () => void;
  client?: { name: string; phone: string };
  refetch?: () => void;
}

enum NotificationForm {
  PHONE = "звонок",
  SMS = "смс",
}

export interface NotificationFormSubmit {
  fullname?: string;
  phone_number?: string;
  confirmation_type?: NotificationForm;
  comment?: string;
}

const BaseClientFormNotification = ({
  handleToggle,
  isOpen,
  client,
  refetch,
}: PropsComponent) => {
  const [notificationType, setNotificationType] = useState(
    NotificationForm.PHONE
  );

  const dispatch = useDispatch();

  const [createClientMutation] = useCreateClientMutation();

  const {
    handleSubmit,
    control,
    reset,
    formState: { isValid },
    setValue,
    getValues,
  } = useForm<NotificationFormSubmit>({
    values: { fullname: client?.name, phone_number: client?.phone },
  });

  const handleNotificationTypeChange = (type: NotificationForm) => {
    setNotificationType(type);
    setValue("confirmation_type", notificationType);
  };

  const onSubmit: SubmitHandler<NotificationFormSubmit> = async (data) => {
    const { data: res, error } = await createClientMutation({
      ...data,
      phone_number: data?.phone_number || "",
      confirmation_type: notificationType,
    });

    if (res?.meta?.type === ResponseStatusType.SUCCESS) {
      handleToggle();
      resetForm();
      dispatch(toggleNotificationFormModal2(null));
      showToast("Успешно отправлено", "success", 2000);
      refetch?.();
      setNotificationType(NotificationForm.PHONE);
      return;
    }
    if (error?.message) {
      showToast(error.message, "error", 2000);
      return;
    }
  };

  function resetForm() {
    reset({ phone_number: "", fullname: "", comment: "" });
  }

  return (
    <Modal
      isOpen={isOpen}
      className="md:w-[500px] w-full rounded-t-[30px] sm:rounded-[20px]"
    >
      <div className={"flex justify-between"}>
        <h1 className={"font-extrabold text-[25px] leading-[30px] font-dewi"}>
          Форма <br /> уведомления
        </h1>
        <ButtonForm
          text={<CloseWithBorderIcon />}
          onClick={() => {
            resetForm();
            handleToggle();
            dispatch(toggleNotificationFormModal2(null));
          }}
        />
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className=" pt-[20px] flex flex-col gap-5 max-h-[70vh] overflow-y-auto pr-3 toolbar-scroll"
      >
        <Controller
          name="fullname"
          control={control}
          rules={{
            required: {
              value: true,
              message: "",
            },
          }}
          render={({ field: { onChange, value } }) => (
            <TextField
              value={client?.name || value}
              className=""
              placeholder="Введите ФИО"
              label="ФИО"
              type="text"
              name="firstName"
              onChange={onChange}
            />
          )}
        />
        <Label className={""} label="Номер телефона">
          <Controller
            name="phone_number"
            control={control}
            rules={{
              required: {
                value: true,
                message: "",
              },
              minLength: {
                value: 11,
                message: "Неверный номер телефона",
              },
              maxLength: {
                value: 11,
                message: "Неверный номер телефона",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <MaskComponent
                name="phone_number"
                imgLeft={<PhoneIcon />}
                value={
                  client?.phone
                    ? client?.phone
                    : getValues("phone_number") || ""
                }
                onChange={(value) => onChange(value)}
              />
            )}
          />
        </Label>
        <Label label="Метод связи с агентом">
          <SelectionToggle
            hasButton={false}
            leftLabel="Звонок"
            rightLabel="СМС"
            options={{
              LEFT: NotificationForm.PHONE,
              RIGHT: NotificationForm.SMS,
            }}
            selectedOption={notificationType}
            onSubmit={setNotificationType}
            handleChange={handleNotificationTypeChange}
          />
        </Label>
        <Controller
          name="comment"
          control={control}
          rules={{
            required: {
              value: false,
              message: "",
            },
          }}
          render={({ field: { onChange, value } }) => (
            <Label label="Комментарий">
              <textarea
                id="comment"
                name="comment"
                onChange={onChange}
                placeholder="Комментарий"
                value={value}
                rows={4}
                className="mt-1 block w-full resize-none px-3 py-2 border border-boder-default rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              ></textarea>
            </Label>
          )}
        />

        <div className={"pt-[20px]"}>
          <ButtonForm
            type="submit"
            disabled={!isValid}
            text="Отправить клиента на закрепление"
            className="hover:bg-bg-default py-3 px-6
                 bg-accent-default xl:flex-1 flex justify-center
                  text-white-default hover:text-accent-default w-full"
          />
        </div>
      </form>
    </Modal>
  );
};

export default BaseClientFormNotification;
