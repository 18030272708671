import { RootState } from "../index";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SelectionState {
  isOpenCreateModal: boolean;
  isOpenMortgageTaskModal: boolean;
}
const initialState: SelectionState = {
  isOpenCreateModal: false,
  isOpenMortgageTaskModal: false,
};

const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    handleOpenCreateTasks: (state, action: PayloadAction<boolean>) => {
      state.isOpenCreateModal = action.payload;
    },
    handleOpenMortgageTask: (state, action: PayloadAction<boolean>) => {
      state.isOpenMortgageTaskModal = action.payload;
    },
  },
});

export const { handleOpenCreateTasks, handleOpenMortgageTask } =
  tasksSlice.actions;

export default tasksSlice.reducer;
