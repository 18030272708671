import React, { useEffect, useState } from "react";
import Carousel from "../../components/Carousel";
import BreadCrump from "../../components/BreadCrump";
import TableContainer from "../../components/Form/Table";

import HomeCalendar from "../../components/Calendar/HomeCalendar";
import Notifications from "components/Notifications";
import HomePageCard from "../../components/Cards/HomePageCard";
import { useWindowSize } from "../../hooks/useWindowSize";
import HomePageMobileCard from "../../components/Cards/HomePageMobileCard";
import { TrainingSider } from "../../components/Carousel/TrainingSider";
import { useRoles } from "hooks/useRoles";
import { useGetEventsQuery } from "data/api/event";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import moment from "moment";
import { useNavigate } from "react-router";
import { useGetNewsQuery } from "data/api/news";
import ToggleSwitcher from "components/ToggleSwitcher";
import { handleEditModeInHomePage } from "store/HomPageReducer";
import PencilIcon from "components/constant/icons/PencilIcon";
import { CloseIcon } from "yet-another-react-lightbox";
import BannerEditModals from "./BannerEditModals";
import { useGetBannersQuery } from "data/api/banner";
import { objectToQueryString } from "utils/objectToQuery";
import { useGetTasksMutation } from "data/api/tasks";
import useFormattedDate from "hooks/useFormatDate";
import ButtonForm from "components/Button/ButtonForm";
import { PaginationType } from "modules/BaseClients";
import AnimationFrame from "components/AnimationFrame.tsx";

interface SortData {
  limit: number;
  sort_by?: string;
  order_by?: string;
  page: number;
}

const HomePage = () => {
  const { isMd } = useWindowSize();

  const roles = useRoles(null);

  const selection = useSelector((state: RootState) => state);

  const navigate = useNavigate();

  const [newsLimit, setNewsLimit] = useState(6);

  const [eventsLimit, setEventsLimit] = useState(6);

  const [paginationInfo, setPaginationInfo] = useState<
    PaginationType | undefined
  >({
    currPage: 0,
    limit: 0,
    total: 0,
    totalPage: 0,
  });

  const { data: events } = useGetEventsQuery(
    `city_id=${selection.header.city_id}&limit=${eventsLimit}`
  );
  const { data: news } = useGetNewsQuery(
    `city_id=${selection.header.city_id}&limit=${newsLimit}`
  );

  const [tasksGetMutation] = useGetTasksMutation();

  const [isBannerEditOpen, setIsBannerEditOpen] = useState(false);

  const [contentTable, setContentTable] = useState<any[]>([]);

  const [sort, setSort] = useState<SortData>({ limit: 5, page: 1 });

  const [updatePage, setUpdatePage] = useState<boolean>(false);

  const formatDate = useFormattedDate();

  const [headerTable, setHeaderTable] = useState([
    {
      key: "task_type",
      title: "Тип задачи",
      sortable: true,
    },
    {
      key: "client_fullname",
      title: "Клиент",
      sortable: true,
    },
    {
      key: "end_date",
      title: "Дедлайн",
      sortable: true,
    },
    {
      key: "project_name",
      title: "Проект",
      sortable: true,
    },
  ]);

  const { data: bannerData } = useGetBannersQuery(
    objectToQueryString({ city_id: selection.header.city_id })
  );

  const dispatch = useDispatch();

  const isEditMode = selection.home.isEdit;

  const handleEdit = () => {
    dispatch(handleEditModeInHomePage(!isEditMode));
  };

  useEffect(() => {
    loadList();
  }, [sort]);

  async function loadList() {
    const queryString = objectToQueryString({
      ...sort,
    });
    const { data: res } = await tasksGetMutation(queryString);

    if (!res || !res.data) return;

    setPaginationInfo(res.meta?.links);

    const updateList = res.data.map((item) => onChangeTableList(item));

    if (updatePage) {
      setUpdatePage(false);
      setContentTable((list) => [...list, ...updateList]);

      return;
    }

    setContentTable(updateList);
  }

  const onChangeTableList = (item: any): any => {
    return {
      ...item,
      task_type: {
        key: item.task_type,
        text: (
          <div className="flex flex-col gap-[5px]">
            <span className=" text-blue-default text-[16px]">
              {toUpperCasefunc(item.task_type)}
            </span>
            <span className="text-sm">{item.comment}</span>
          </div>
        ),
      },
      client_fullname: item.client?.fullname,
      end_date: formatDate(item?.end_date, true),
      project_name: item.project?.name,
    };
  };

  function handleSortBy(sort_by: string, order_by: string) {
    setSort({
      ...sort,
      sort_by,
      order_by,
      page: 1,
    });
  }

  async function handleUpdateList() {
    setUpdatePage(true);
    const filterUpdate = {
      ...sort,
      page: sort.page + 1,
    };

    setSort(filterUpdate);
  }
  const handleChangeCountNews = () => {
    setNewsLimit((count) => count + 6);
  };

  const handleChangeCountEvents = () => {
    setEventsLimit((count) => count + 6);
  };

  const handleJump = (link: string) => {
    window.location.href = link;
  };

  function toUpperCasefunc(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <section id="homepage" className=" bg-bg-default relative">
      <AnimationFrame>
        <div className="md:py-[30px] py-[10px] br-container flex gap-[20px] flex-col lg:flex-row">
          <div className="xl:w-[76%] lg:w-[70%] w-full">
            <div className="md:mb-[20px] mb-[15px] 2xl:mb-[30px]  w-full relative">
              {isEditMode ? (
                <>
                  {/* <div className="absolute z-30 top-[10px] left-[10px]">
                  <ToggleSwitcher />
                </div> */}
                  <div
                    onClick={() => setIsBannerEditOpen(true)}
                    className="absolute z-30 top-[10px] right-[15px] w-[34px] h-[34px] border cursor-pointer bg-white-default border-boder-default rounded-xl flex items-center justify-center"
                  >
                    <PencilIcon color={"#37465B"} />
                  </div>
                </>
              ) : null}
              {bannerData?.data.length ? (
                <Carousel>
                  {bannerData?.data.map((item) => (
                    <div
                      onClick={() => handleJump(item.link)}
                      className="rounded-[8px] cursor-pointer h-auto md:h-[439px] px-2"
                      key={item.id}
                    >
                      <img
                        src={
                          process.env.REACT_APP_FILES_URL +
                            "/" +
                            item?.cover_image || ""
                        }
                        crossOrigin="anonymous"
                        alt="img of building"
                        className="w-full h-full rounded-xl"
                      />
                    </div>
                  ))}
                </Carousel>
              ) : isEditMode ? (
                <div className=" h-[173.03px]  md:h-[350px] px-2 w-full bg-bg-default rounded-lg border border-boder-default"></div>
              ) : null}
            </div>

            {isMd && <HomeCalendar />}

            {roles.isOzk_manager ||
            roles.isManager ||
            roles.isAffiliate_manager ? (
              <div className="md:mt-[20px] mt-[15px] xl:mt-[30px]">
                {contentTable.length ? (
                  <>
                    <div className="flex justify-start items-center gap-4">
                      {isEditMode ? (
                        <div className=" mb-[6px]">
                          <ToggleSwitcher />
                        </div>
                      ) : null}

                      <BreadCrump link="/tasks" title="Задачи" />
                    </div>

                    {isMd ? (
                      <div className={"bg-white-default rounded-lg"}>
                        {contentTable.map((item: any, index) => (
                          <div
                            key={`table-content-item-${index}`}
                            onClick={() => {}}
                            className={`cursor-pointer w-full p-1 ${
                              index ? "border-t" : ""
                            } border-boder-default px-[15px] py-[12px]`}
                          >
                            <div className={"flex items-center mb-[5px]"}>
                              <p
                                className={
                                  "text-sm font-medium mr-[12px] text-blue-default"
                                }
                              >
                                {toUpperCasefunc(item?.task_type?.key)}
                              </p>

                              <p
                                className={
                                  "text-xs text-accent-default opacity-50"
                                }
                              >
                                {item?.end_date}
                              </p>
                            </div>

                            <div className={"mb-[15px]"}>
                              <p className={"text-xs"}>{item?.comment}</p>
                            </div>

                            <div
                              className={
                                "flex justify-between text-sm font-medium"
                              }
                            >
                              <p>{item?.project?.name}</p>

                              <p>{item?.client_fullname}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <TableContainer
                        orderBy="ASC"
                        header={headerTable}
                        content={contentTable}
                        setData={setContentTable}
                        handleSortBy={handleSortBy}
                      />
                    )}
                    {paginationInfo
                      ? !(
                          paginationInfo?.currPage >= paginationInfo?.totalPage
                        ) && (
                          <button
                            type={"button"}
                            className={
                              "py-3 px-6 mx-auto text-blue-default text-sm font-medium flex mt-[15px]"
                            }
                            onClick={() => handleUpdateList()}
                          >
                            Загрузить еще
                          </button>
                        )
                      : null}
                  </>
                ) : null}
              </div>
            ) : null}

            {news?.data.length ? (
              <div className="md:mt-[20px] mt-[15px] xl:mt-[30px]">
                <div className="flex justify-start items-center gap-4">
                  {/* {isEditMode ? (
                  <div className=" mb-[6px]">
                    <ToggleSwitcher />
                  </div>
                ) : null} */}
                  <BreadCrump link="/news" title="Новости" />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-[15px]">
                  {isMd ? (
                    <HomePageMobileCard type="news" list={news?.data} />
                  ) : (
                    news?.data.map((item, i) => (
                      <HomePageCard
                        type="news"
                        imageSrc={
                          process.env.REACT_APP_FILES_URL +
                            "/" +
                            item?.cover_image || ""
                        }
                        key={i}
                        title={item?.title || ""}
                        watched={item.views_count}
                        likes={item.likes_count}
                        date={`${
                          item.created_at
                            ? moment(item.created_at).format("DD.MM.YYYY")
                            : ""
                        }`}
                        hashtag={
                          item.primary_category?.name
                            ? [item.primary_category?.name]
                            : []
                        }
                        is_like_enabled={item?.is_like_enabled}
                        onClick={() => navigate(`news/view/${item.id}`)}
                      />
                    ))
                  )}
                </div>

                {(news?.meta?.links?.total || news?.data.length) > newsLimit &&
                  !isMd && (
                    <div
                      className={
                        "w-full px-[24px] pt-[22px] pb-[10px] flex items-center justify-center"
                      }
                    >
                      <ButtonForm
                        text={"Загрузить еще"}
                        className={"text-blue-default"}
                        onClick={handleChangeCountNews}
                      />
                    </div>
                  )}
              </div>
            ) : null}

            {isMd && <TrainingSider className={isMd ? "mt-[15px]" : ""} />}
            {events?.data.length ? (
              <div className="md:mt-[20px] mt-[15px] xl:mt-[30px]">
                <div className="flex justify-start items-center gap-4">
                  {/* {isEditMode ? (
                  <div className=" mb-[6px]">
                    <ToggleSwitcher />
                  </div>
                ) : null} */}
                  <BreadCrump link="/events" title="Мероприятия" />
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-[15px]">
                  {isMd ? (
                    <HomePageMobileCard type="events" list={events?.data} />
                  ) : (
                    events?.data
                      .slice(0, 6)
                      .map((item, i) => (
                        <HomePageCard
                          imageSrc={
                            process.env.REACT_APP_FILES_URL +
                              "/" +
                              item?.photo || ""
                          }
                          key={i}
                          title={item.title}
                          hashtag={item.tags}
                          watched={item.views_count}
                          likes={item.likes_count}
                          date={`${
                            item.date
                              ? moment(item.date).format("DD.MM.YYYY")
                              : ""
                          }`}
                          onClick={() => navigate(`events/view/${item.id}`)}
                          type="events"
                        />
                      ))
                  )}
                </div>
                {(events?.meta?.links?.total || events?.data.length) >=
                  eventsLimit &&
                  !isMd && (
                    <div
                      className={
                        "w-full px-[24px] pt-[22px] pb-[10px] flex items-center justify-center"
                      }
                    >
                      <ButtonForm
                        text={"Загрузить еще"}
                        className={"text-blue-default"}
                        onClick={handleChangeCountEvents}
                      />
                    </div>
                  )}
              </div>
            ) : null}
          </div>
          <div className="xl:w-[24%] lg:w-[30%] w-full flex flex-col gap-[10px] self-start">
            {!isMd && (
              <>
                <TrainingSider />
                <HomeCalendar />
              </>
            )}
            <Notifications />
          </div>
        </div>
      </AnimationFrame>

      <div>
        {(roles.isAdmin || roles.isAffiliate_manager) && (
          <div className="fixed bottom-[70px] right-[10px] sm:right-[90px]  lg:bottom-[28px] lg:flex items-center justify-center">
            <div
              className={`rounded-full ${
                isEditMode ? "bg-red-default" : "bg-accent-default"
              }  flex items-center shadow-md border border-bg-default justify-center p-3 cursor-pointer`}
              onClick={handleEdit}
            >
              {isEditMode ? (
                <CloseIcon color={"#FFFFFF"} />
              ) : (
                <PencilIcon color={"#FFFFFF"} />
              )}
            </div>
          </div>
        )}
      </div>
      {/*{!isMd && (*/}
      {/*  <button*/}
      {/*    className="w-[48px] h-[48px] flex items-center justify-center*/}
      {/* bg-accent-default fixed bottom-[30px] right-[30px] rounded-full outline-none border border-white-default"*/}
      {/*  >*/}
      {/*    <ChatIcon />*/}
      {/*  </button>*/}
      {/*)}*/}
      <BannerEditModals
        isBannerEditOpen={isBannerEditOpen}
        setIsBannerEditOpen={setIsBannerEditOpen}
      />
    </section>
  );
};

export default HomePage;
