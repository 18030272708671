import React, { useEffect, useState } from "react";
import ButtonForm from "../../../components/Button/ButtonForm";
import { Link, useNavigate } from "react-router-dom";
import { SearchField, UserData } from "../../../components/Form/SearchField";
import Label from "../../../components/Form/Label";
import ArrowDropdown from "../../../components/constant/icons/ArrowDropdown";

import showToast from "utils/showToast";
import {
  useRecordForShowMutation,
  useRecordForShowTimeSlotMutation,
} from "data/api/calendar";
import { useDispatch, useSelector } from "react-redux";
import { toggleBookingModal } from "store/BaseClientsReducer";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Dropdown from "../../../components/Form/Dropdown/Dropdown";
import { ApiException, ResponseStatusType } from "../../../data/api/exception";
import TooltipTags from "../../../components/Tooltip/TooltipTags";
import { Primise } from "enums/primisesType";
import moment from "moment";
import ToggleSwitcher from "components/ToggleSwitcher";
import { toggleItemInStore } from "store/ApartmentReducer";
import { RootState } from "store";
import { useWindowSize } from "hooks/useWindowSize";
import { images } from "utils/resource";
import { TimeSlotsResponse } from "data/api/calendar/type";
import { dropDownOptionsType } from "../Filter/type";

interface RecordForShow {
  project: number;
  date: string;
  time: string;
  client: UserData | null;
}

interface ApartmentCardRightBlockProps {
  selection: any;
  setIsOpenAdvertisingModal: (value: boolean) => void;
}

const ApartmentCardRightBlock = ({
  selection,
  setIsOpenAdvertisingModal,
}: ApartmentCardRightBlockProps) => {
  const [mutate] = useRecordForShowMutation();

  const dispatch = useDispatch();

  const [hoveredIconId, setHoveredIconId] = useState<number | null>(null);

  const navigate = useNavigate();

  const selector = useSelector((state: RootState) => state.apartment);
  const city_id = useSelector((state: RootState) => state.header.city_id);

  const [getTimeSlotsMutation] = useRecordForShowTimeSlotMutation();

  const [slotData, setSlotData] = useState<TimeSlotsResponse[] | null>(null);

  const [dateOptions, setDateOptions] = useState<dropDownOptionsType[]>([]);

  const [timeOptionsSlot, setTimeOptions] = useState<dropDownOptionsType[]>([]);

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid },
    watch,
    setValue,
  } = useForm<RecordForShow>();

  const handleMouseEnter = (id: number) => {
    setHoveredIconId(id);
  };

  const handleMouseLeave = () => {
    setHoveredIconId(null);
  };

  const handleToggleBookingModal = () => {
    dispatch(toggleBookingModal());
  };

  const onSubmit: SubmitHandler<RecordForShow> = async (data) => {
    try {
      const { data: res, error } = await mutate({
        project_id: selection?.project?.id,
        date: moment(data.date).toISOString(),
        time: data.time,
        client_id: data?.client?.id,
      });
      if (res?.meta?.type === ResponseStatusType.SUCCESS) {
        reset();
        showToast("Подтверждение заявки на показ!", "success", 1000);
        return;
      }

      if (error?.message) {
        showToast(error.message, "error");
        return;
      }
    } catch (error) {
      const err = error as ApiException;
      console.log(err);
    }
  };

  const handleSelectParking = (id: number) => {
    if (city_id === selection.project?.city_id) {
      navigate(`/apartments?project_id=${id}&type=${Primise.PARKING}`);
    } else {
      navigate(`/apartments?type=${Primise.PARKING}`);
    }
  };

  const handleSelectStoreRoom = (id: number) => {
    if (city_id === selection.project?.city_id) {
      navigate(`/apartments?project_id=${id}&type=${Primise.STOREROOM}`);
    } else {
      navigate(`/apartments?type=${Primise.STOREROOM}`);
    }
  };

  const handleSelectItem = (id: number) => {
    dispatch(toggleItemInStore({ id, type: selection?.type }));
  };

  const itemExists = (id: number) => {
    return selector.storedData.includes(id);
  };

  useEffect(() => {
    if (selection?.project?.id) {
      initTimeSlots(selection?.project?.id);
    }
  }, [selection?.project?.id]);

  async function initTimeSlots(id: number) {
    try {
      const { data: res } = await getTimeSlotsMutation(id);

      if (res && res?.data?.length) {
        console.log("res", res);
        setSlotData(res.data);
        const dates = res?.data?.flatMap((item) =>
          Object.keys(item).map((date) => ({ value: date, label: date }))
        );

        setDateOptions(dates as dropDownOptionsType[]);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const handleDateChange = (date: string) => {
    setValue("time", "");
    const selectedData = slotData?.find((item) => item[date]);
    if (selectedData) {
      const timeSlots = selectedData[date].map((slot) => ({
        value: slot.start,
        label: `${slot.start} - ${slot.end}`,
      }));
      setTimeOptions(timeSlots);
    } else {
      setTimeOptions([]);
    }
  };

  const getValue = (
    opts: { label: string; value: string | number | null }[],
    val: number | string | undefined
  ) => {
    if (val) {
      return opts.filter((o) => o.value == val);
    }
    return null;
  };

  return (
    <div className="w-[100%] lg:w-[31%]">
      {selector.generateSelection && (
        <div className={"bg-white-default rounded-lg p-[20px] mb-[10px]"}>
          <ToggleSwitcher
            label="Добавить в подборку"
            isToggle={itemExists(selection?.id)}
            setIsToggle={() => {
              handleSelectItem(selection?.id);
            }}
          />
        </div>
      )}
      <div
        className={"bg-white-default rounded-lg p-[20px] gap-[30px] mb-[10px]"}
      >
        <div className={"gap-[10px]"}>
          <div className={"flex gap-[10px] flex-wrap mb-[10px]"}>
            {selection.types.map((item: any, i: number) => (
              <a
                key={`selection-type-item-${i}`}
                className={
                  "border border-[#D7DCE4] rounded-3xl p-[5px] flex items-center"
                }
                href={item.url}
              >
                <img
                  crossOrigin="anonymous"
                  src={
                    item.image
                      ? process.env.REACT_APP_FILES_URL + "/" + item.image
                      : images.defaultProjectImg
                  }
                  className={"w-8 h-8 rounded-full"}
                />
                <p key={`tag-item-${item}`} className={"mx-[8px] text-xs"}>
                  {item.text}
                </p>
                <span className={"flex -rotate-90"}>
                  <ArrowDropdown color={"#37465B"} />
                </span>
              </a>
            ))}
          </div>

          <div className={"flex gap-[10px] flex-wrap mb-[30px]"}>
            {Object.keys(selection.info).map((item: any, idx: number) => (
              <p className={"text-sm"} key={`text-info-item-${idx}`}>
                <span className={"text-[#0000004d]"}>{item}:</span>{" "}
                {(selection.info as any)[item]}
              </p>
            ))}
          </div>

          <div className={"flex flex-col sm:flex-row gap-[10px]"}>
            <ButtonForm
              onClick={() => handleSelectStoreRoom(selection?.project?.id)}
              text="Выбрать кладовую"
              className="border border-red-default py-3 2xl:px-5 text-red-default
                     hover:bg-red-default xl:flex-1 flex justify-center
                     hover:text-white-default w-full"
            />
            <ButtonForm
              onClick={() => handleSelectParking(selection?.project?.id)}
              text="Выбрать паркинг"
              className="border border-red-default py-3 2xl:px-6 text-red-default
                     hover:bg-red-default xl:flex-1 flex justify-center
                     hover:text-white-default w-full"
            />
          </div>
        </div>
      </div>
      {selection?.feature_new && (
        <div
          className={
            "bg-white-default rounded-lg p-[20px] mb-[10px] flex flex-wrap gap-[10px] relative"
          }
        >
          {/* {selection?.feature_new?.map((item: any, idx: number) => ( */}
          <p
            // key={`tag-item-${idx}`}
            className={
              "border border-bg-focus py-[5px] px-[14px] text-xs rounded-3xl text-accent-default"
            }
          >
            {selection?.feature_new}
          </p>
          {/* ))} */}
        </div>
      )}

      <div
        className={
          "bg-white-default rounded-lg p-[20px] mb-[10px] flex flex-wrap gap-[15px]"
        }
      >
        <p>
          Вариант покупки
          <Link
            to={`/mortgage-calculator/${selection.id}`}
            className={"text-sm text-blue-default underline ml-[15px]"}
          >
            Ипотечный калькулятор
          </Link>
        </p>

        <div className={"flex flex-wrap gap-[10px] relative"}>
          {selection.buys.map((item: any, idx: number) => (
            <p
              key={`tag-buys-item-${idx}`}
              className={
                "border border-bg-focus py-[5px] px-[14px] text-xs rounded-3xl text-accent-default cursor-pointer"
              }
              onMouseEnter={() => handleMouseEnter(item?.label)}
              onMouseLeave={handleMouseLeave}
            >
              {item.label}

              {hoveredIconId === item.label && <TooltipTags data={item} />}
            </p>
          ))}
        </div>
      </div>
      <div
        className={"bg-white-default rounded-lg p-[20px] gap-[30px] mb-[10px]"}
      >
        {selection.type !== Primise.COMMERCIAL && (
          <form className={"gap-[10px]"} onSubmit={handleSubmit(onSubmit)}>
            <p className={"mb-[20px]"}>Запись на показ</p>

            <div className={"mb-[30px]"}>
              <Label label="Клиент">
                <Controller
                  name="client"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Проект обязателен для заполнения",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <SearchField
                      id="records-for-show"
                      placeholder={"ФИО клиента"}
                      onChange={(value) => onChange(value)}
                      className="rounded-[4px!important]"
                      isShowBtnDelete={true}
                      selectItem={value || null}
                    />
                  )}
                />
              </Label>

              <div className={"flex flex-col xl:flex-row gap-[10px] mt-[20px]"}>
                <Label label="Дата" className={"flex-1"}>
                  <Controller
                    name="date"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Дата обязателен для заполнения",
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Dropdown
                        menuPlacement="bottom"
                        name="date"
                        options={dateOptions}
                        errors={errors}
                        value={getValue(dateOptions, value)}
                        onChange={(data) => {
                          onChange(data.value);
                          handleDateChange(data.value);
                        }}
                        placeholder="Дата добавления"
                      />
                    )}
                  />
                </Label>

                <Label label="Время" className="flex-1">
                  <Controller
                    name="time"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Время обязателен для заполнения",
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Dropdown
                        disabled={!watch("date")}
                        menuPlacement="bottom"
                        name="time"
                        options={timeOptionsSlot}
                        errors={errors}
                        value={getValue(timeOptionsSlot, value)}
                        onChange={(data) => onChange(data.value)}
                        placeholder="Время не выбран"
                      />
                    )}
                  />
                </Label>
              </div>
            </div>

            <div className={"flex flex-col sm:flex-row gap-[10px]"}>
              <ButtonForm
                disabled={!isValid}
                type={"submit"}
                text="Запись на показ"
                className="bg-bg-default py-3 px-6
                     hover:bg-accent-default xl:flex-1 flex justify-center
                      hover:text-white-default w-full"
              />

              {!selection?.is_booked &&
                selection.type === Primise.APARTMENT && (
                  <ButtonForm
                    onClick={handleToggleBookingModal}
                    text="Забронировать"
                    className="bg-bg-default py-3 px-6 text-accent-default
                     hover:bg-accent-default xl:flex-1 flex justify-center
                     hover:text-white-default w-full"
                  />
                )}
            </div>
            {selection?.type === Primise.APARTMENT && (
              <ButtonForm
                onClick={() => setIsOpenAdvertisingModal(true)}
                text="Сформировать рекламный макет"
                className="bg-bg-default py-3 px-6 mt-[10px] text-accent-default
                     hover:bg-accent-default xl:flex-1 flex justify-center
                     hover:text-white-default w-full"
              />
            )}
          </form>
        )}
        {selection?.type === Primise.COMMERCIAL && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              // handleClickAppLicationPhone(item);
            }}
            className=" bg-bg-default flex-1 mt-[1px] py-3 
            hover:bg-accent-default flex justify-center text-[12px]
             hover:text-white-default w-full rounded transition-all 
              duration-400
              "
          >
            Заказать звонок
          </button>
        )}
      </div>
    </div>
  );
};

export default ApartmentCardRightBlock;
