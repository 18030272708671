import React from "react";
import loaderImg from "../../assets/images/Loader.png";

const Loader = () => {
  return (
    <div
      className="flex justify-center items-center h-[150px] absolute left-[50%] 
    top-[50%] -translate-x-[50%] -translate-y-[50%] z-20"
    >
      <div className="w-full">
        <div className={`flex justify-center items-center space-x-4 w-full`}>
          <div className="w-[10px] h-[10px] bg-accent-default rounded-full animate-scale-up"></div>
          <div className="w-[10px] h-[10px] bg-accent-default rounded-full animate-scale-up animation-delay-200"></div>
          <div className="w-[10px] h-[10px] bg-accent-default rounded-full animate-scale-up animation-delay-400"></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
