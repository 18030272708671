import { useEffect, useState } from "react";
import { useGetBuildingsByProjectIdMutation } from "data/api/building";
import { Control, useWatch } from "react-hook-form";
import { BookingForm } from "components/BookingAndVisits/BookingFrom";
import { Building } from "data/api/building/type";

interface UseGetBuildingByProjectIdProps {
  projectID?: number;
}

const useGetBuildingByProjectId = ({
  projectID,
}: UseGetBuildingByProjectIdProps) => {
  const [building, setBuilding] = useState<Building[] | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [getBuildingByProjectId] = useGetBuildingsByProjectIdMutation();

  const fetchBuilding = async (projectID: number) => {
    setIsLoading(true);
    try {
      const { data: res } = await getBuildingByProjectId(projectID).unwrap();
      setBuilding(res);
    } catch (err) {
      console.log("Failed to fetch building data.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (projectID) {
      fetchBuilding(projectID);
    }
  }, [projectID, getBuildingByProjectId]);

  const refetch = () => {
    if (projectID) {
      fetchBuilding(projectID);
    }
  };

  return { building, isLoading, refetch };
};

export default useGetBuildingByProjectId;
