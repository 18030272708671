import {
  CommentsType,
  ProfileRequestType,
  SettingRequestType,
  User,
  UsersMeta,
} from "./type";
import { apiService } from "..";
import { MetaData } from "../exception";

export const addTagTypes = ["user"] as const;

export const profileApiHooks = apiService
  .enhanceEndpoints({ addTagTypes })
  .injectEndpoints({
    endpoints: (build) => ({
      updateUser: build.mutation<MetaData<User>, MetaData<ProfileRequestType>>({
        query: ({ data }) => ({
          path: "/users/update",
          body: { meta: {}, data },
          method: "POST",
        }),
      }),
      verifyUser: build.mutation<
        MetaData<User>,
        MetaData<{ user_id: number; is_verified: boolean }>
      >({
        query: ({ data }) => ({
          path: "/users/verify",
          body: { meta: {}, data },
          method: "POST",
        }),
      }),
      getAllUsers: build.query<MetaData<User[], UsersMeta>, string | void>({
        query: (query) => ({
          path: query ? `/users?${query}` : "/users",
          method: "GET",
        }),
      }),
      getSingleUser: build.mutation<MetaData<User>, number>({
        query: (id) => ({
          path: `/users/${id}`,
          method: "GET",
        }),
      }),
      updatePhone: build.mutation<
        MetaData<{ user_id: number }>,
        MetaData<{ phone: string }>
      >({
        query: ({ data }) => ({
          path: "/users/phone",
          body: { meta: {}, data },
          method: "POST",
        }),
      }),
      verifyUserPhone: build.mutation<
        MetaData,
        MetaData<{ code: number | null }>
      >({
        query: ({ data }) => ({
          path: "/users/phone/verify",
          body: { meta: {}, data },
          method: "POST",
        }),
      }),
      resendSMSCode: build.mutation<
        MetaData<{ user_id: number }>,
        MetaData<{ phone: string }>
      >({
        query: ({ data }) => ({
          path: "/users/phone/resend-code",
          body: { meta: {}, data },
          method: "POST",
        }),
      }),
      getSetting: build.query<MetaData<SettingRequestType>, void>({
        query: () => ({
          path: "/settings",
          method: "GET",
        }),
      }),
      modifySetting: build.mutation<MetaData, MetaData<SettingRequestType>>({
        query: ({ data }) => ({
          path: "/settings",
          body: { meta: {}, data },
          method: "PUT",
        }),
      }),
      loginAsUser: build.mutation<
        MetaData<{ accessToken: string }>,
        MetaData<{ user_id?: number }>
      >({
        query: ({ data }) => ({
          path: "/users/login",
          body: { meta: {}, data: data },
          method: "POST",
        }),
      }),
      getComments: build.query<MetaData<CommentsType[]>, void>({
        query: () => ({
          path: "/comments",
          method: "GET",
        }),
      }),
      postComment: build.mutation<MetaData, MetaData<{ text?: string }>>({
        query: ({ data }) => ({
          path: "/comments",
          body: { meta: {}, data: data },
          method: "POST",
        }),
      }),
      updateComment: build.mutation<
        MetaData,
        MetaData<{ text?: string; id: number }>
      >({
        query: ({ data }) => ({
          path: `/comments/${data.id}`,
          body: { meta: {}, data: { text: data.text } },
          method: "PUT",
        }),
      }),
      deleteComment: build.mutation<MetaData, number>({
        query: (id) => ({
          path: `/comments/${id}`,
          method: "DELETE",
        }),
      }),
    }),
  });

export const {
  useUpdatePhoneMutation,
  useVerifyUserPhoneMutation,
  useVerifyUserMutation,
  useResendSMSCodeMutation,
  useGetAllUsersQuery,
  useUpdateUserMutation,
  useGetSettingQuery,
  useModifySettingMutation,
  useLoginAsUserMutation,
  usePostCommentMutation,
  useGetCommentsQuery,
  useUpdateCommentMutation,
  useDeleteCommentMutation,
  useGetSingleUserMutation,
} = profileApiHooks;
