import React from "react";

const UserOutIcon = ({ color = "#37465B" }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.39844 20.6004L1.39884 17C1.39907 15.0119 3.01077 13.4004 4.99884 13.4004H10.9984M18.027 12.5004L20.5984 14.9004M20.5984 14.9004L18.027 17.3004M20.5984 14.9004H13.9984M13.3984 5.00039C13.3984 6.98862 11.7867 8.60039 9.79844 8.60039C7.81021 8.60039 6.19844 6.98862 6.19844 5.00039C6.19844 3.01217 7.81021 1.40039 9.79844 1.40039C11.7867 1.40039 13.3984 3.01217 13.3984 5.00039Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UserOutIcon;
