import { useWindowSize } from "hooks/useWindowSize";
import React from "react";
interface RedRadioButtonProps {
  label?: string;
  name?: string;
  value?: string;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioButton: React.FC<RedRadioButtonProps> = ({
  checked = false,
  onChange,
}) => {
  const { isMd, isLg } = useWindowSize();

  return (
    <label
      className="inline-flex items-center cursor-pointer"
      onClick={(e) => e.stopPropagation()}
    >
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="hidden peer"
      />
      <div
        className={`w-5 h-5 relative rounded-full border  ${
          checked ? "border-red-default" : " border-boder-focus"
        } ${isMd && "flex items-center justify-center"} `}
      >
        <div
          className={`w-[10px] h-[10px]   rounded-full bg-red-default ${
            checked ? "block" : "hidden"
          } ${
            isLg &&
            "absolute top-[50%] -translate-y-[50%] left-[50%] -translate-x-[50%]"
          } `}
          style={{ willChange: "transform" }}
        ></div>
      </div>
    </label>
  );
};

export default RadioButton;
