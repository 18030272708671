import React, { ReactNode, useEffect } from "react";
import { FieldErrors } from "react-hook-form";
import { useIMask } from "react-imask";

type phone = {
  phone: string;
};

interface MaskComponentProps {
  value: string;
  onChange: (value: string) => void;
  errors?: FieldErrors<phone>;
  imgLeft?: ReactNode | null;
  imgRight?: ReactNode | null;
  name: string;
  disabled?: boolean;
  className?: string;
}

const MaskComponent: React.FC<MaskComponentProps> = ({
  value: inputVal,
  onChange,
  errors,
  name,
  imgLeft,
  imgRight,
  className,
  disabled,
}) => {
  const opts = {
    mask: "+{7} (000) 000-00-00",
    lazy: false,
    overwrite: true,
    placeholderChar: "_",
  };

  const { value, ref, setUnmaskedValue, unmaskedValue } = useIMask(opts, {
    defaultUnmaskedValue: inputVal,
  });

  console.log("inputVal", inputVal);

  useEffect(() => {
    onChange(unmaskedValue);
  }, [unmaskedValue]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value;
    handleValueChange(inputValue);
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData("Text");
    handleValueChange(pastedText);
  };

  const handleValueChange = (inputValue: string) => {
    let cleanedValue = inputValue.replace(/\D/g, "");

    if (cleanedValue.startsWith("7") && cleanedValue.length > 10) {
      cleanedValue = cleanedValue.slice(1);
    } else if (cleanedValue.startsWith("8") && cleanedValue.length > 9) {
      cleanedValue = cleanedValue.slice(1);
    }

    const normalizedValue = cleanedValue.slice(0, 10);
    setUnmaskedValue(normalizedValue);
  };

  return (
    <div className={`${className}`}>
      <div className="relative">
        {imgLeft && (
          <div
            className={`form-icon-left absolute top-0 bottom-0 flex items-center justify-center left-[16px]`}
          >
            {imgLeft}
          </div>
        )}
        {imgRight && (
          <div
            className={`form-icon-right absolute top-0 bottom-0 flex items-center justify-center right-[16px]`}
          >
            {imgRight}
          </div>
        )}
        <input
          ref={ref as React.LegacyRef<HTMLInputElement>}
          value={value}
          defaultValue={unmaskedValue}
          type="text"
          name="phone"
          className="outline-none border border-boder-default py-3 pl-[45px] w-full text-sm rounded text-accent-default placeholder:text-accent-default"
          onChange={handleInputChange}
          onPaste={handlePaste}
          placeholder="+7 (909)"
          disabled={disabled}
        />
      </div>

      {errors?.[name as keyof phone] && (
        <div className="text-[12px] leading-[14.06px] flex flex-col">
          <p className="text-red-default mb-[5px]">
            {errors?.[name as keyof phone]?.message}
          </p>
        </div>
      )}
    </div>
  );
};

export default MaskComponent;
