import { apiService } from "..";
import { MetaData } from "../exception";
import { Notification } from "./type";

export const addTagTypes = ["notification"] as const;

export const notificationApiHooks = apiService
  .enhanceEndpoints({ addTagTypes })
  .injectEndpoints({
    endpoints: (build) => ({
      getAllNotifications: build.query<MetaData<Notification[]>, void | string>(
        {
          query: (query) => ({
            path: query ? `/notification?${query}` : "/notification",
          }),
          providesTags: ["notification"],
        }
      ),
      getOneNotification: build.mutation<MetaData<Notification[]>, number>({
        query: (id) => ({
          path: `/notification/${id}`,
        }),
        invalidatesTags: ["notification"],
      }),
    }),
  });

export const { useGetAllNotificationsQuery, useGetOneNotificationMutation } =
  notificationApiHooks;
