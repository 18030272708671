import React from "react";
import { AnimatePresence, motion } from "framer-motion";

interface Props {
  children: React.ReactNode;
  duration?: number;
}

const AnimationFrame = ({ children, duration = 0.3 }: Props) => {
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        transition={{ duration: duration }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

export default AnimationFrame;
