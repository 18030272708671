const ExchangeAdvantages2 = () => {
	return (
		<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M4.54013 23.8191L4.53881 23.8185C4.26737 23.7082 4.05502 23.4745 3.94806 23.1922C3.94798 23.1919 3.94815 23.1924 3.94806 23.1922L1.20767 15.6126L1.20408 15.604C1.17997 15.5529 1.16195 15.5138 1.15322 15.477L1.15146 15.4705C0.969877 14.8827 1.28186 14.2435 1.82783 14.0547C2.3707 13.867 2.97389 14.1859 3.15491 14.7719L3.1562 14.7758L4.86882 19.6064L4.96823 19.8868L5.05837 19.6033C8.39544 9.10822 17.6335 2.09998 28.248 2.09998C39.3795 2.09998 49.113 9.98468 51.9208 21.2591C52.0706 21.8586 51.7267 22.4741 51.1709 22.6294C50.6176 22.7842 50.035 22.4316 49.8858 21.8339C47.3229 11.5393 38.4261 4.34402 28.2481 4.34402C18.5346 4.34402 10.1008 10.7538 7.07384 20.3372L7.00498 20.5552L7.21182 20.4577L11.6514 18.3664C12.1621 18.1238 12.7629 18.3632 13.0076 18.9038C13.2639 19.4686 13.0378 20.1422 12.5118 20.4102L5.37079 23.7964L5.36836 23.7976C5.10781 23.9299 4.80386 23.9307 4.54013 23.8191Z"
				fill="#3B4658"/>
			<path
				d="M51.5154 32.1809L51.5167 32.1815C51.7881 32.2918 52.0005 32.5255 52.1074 32.8078C52.1073 32.8076 52.1075 32.8081 52.1074 32.8078L54.8478 40.3874L54.8514 40.396C54.8755 40.4471 54.8935 40.4862 54.9023 40.523L54.904 40.5295C55.0856 41.1173 54.7736 41.7565 54.2277 41.9453C53.6848 42.133 53.0816 41.8141 52.9006 41.2281L52.8993 41.2242L51.1867 36.3936L51.0873 36.1132L50.9971 36.3967C47.6601 46.8917 38.4221 53.9 27.8076 53.9C16.676 53.9 6.94261 46.0153 4.13475 34.7409C3.98501 34.1414 4.32891 33.5259 4.88464 33.3706C5.43793 33.2158 6.02058 33.5684 6.16978 34.1661C8.73269 44.4607 17.6294 51.656 27.8075 51.656C37.521 51.656 45.9547 45.2462 48.9817 35.6628L49.0505 35.4448L48.8437 35.5423L44.4042 37.6336C43.8934 37.8762 43.2927 37.6368 43.0479 37.0962C42.7916 36.5314 43.0177 35.8578 43.5437 35.5898L50.6847 32.2036L50.6871 32.2024C50.9477 32.0701 51.2516 32.0693 51.5154 32.1809Z"
				fill="#3B4658"/>
			<path
				d="M19.8182 35.1805V23.0812C19.8181 22.8944 19.8755 22.7122 19.9825 22.5592C20.0895 22.4062 20.241 22.2899 20.4164 22.226L29.2082 19.0274C29.2769 19.0024 29.3506 18.9943 29.423 19.0039C29.4955 19.0134 29.5646 19.0403 29.6244 19.0823C29.6843 19.1243 29.7331 19.18 29.7669 19.2449C29.8006 19.3098 29.8182 19.3819 29.8182 19.455V23.9609L35.56 25.8759C35.7411 25.9362 35.8986 26.0521 36.0102 26.207C36.1218 26.362 36.1819 26.5482 36.1818 26.7392V35.1805H38V37H18V35.1805H19.8182ZM21.6364 35.1805H28V21.4027L21.6364 23.7189V35.1805ZM34.3636 35.1805V27.3951L29.8182 25.8786V35.1805H34.3636Z"
				fill="#3B4658"/>
		</svg>
	)
}

export default ExchangeAdvantages2;