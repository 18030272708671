import { useRef } from "react";
import Slider from "react-slick";

import NewsBannerVertical from "./NewsBannerVertical";
import EyeIcon from "../constant/icons/EyeIcon";
import LikeIcon from "../constant/icons/LikeIcon";
import ArrowDropdown from "../constant/icons/ArrowDropdown";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import "moment/locale/ru";
import ClockIcon from "../constant/icons/ClockIcon";
import { News } from "data/api/news/type";
import { Event } from "data/api/event/type";
interface PropsType {
  list?: any[];
}

const NewsBanner = ({ list }: PropsType) => {
  const navigate = useNavigate();

  const location = useLocation();

  let sliderRef = useRef<any>(null);

  const next = () => {
    sliderRef.current.slickNext();
  };
  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const settings = {
    dots: false,
    infinite: true,
    centerMode: true,
    speed: 500,
    initialSlide: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    adaptiveHeight: true,
    centerPadding: "269px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          infinite: true,
          dots: false,
          centerPadding: "25px",
        },
      },
    ],
  };

  function getDataByPathName(item: Event & News) {
    if (location.pathname === "/news") {
      return {
        photo: item?.cover_image,
        date: item?.published_at,
      };
    } else {
      return {
        photo: item?.photo,
        date: item?.date,
      };
    }
  }

  if (!list || !list.length) return null;

  return (
    <div
      className="slider-container relative mb-[18px] md:mx-auto mx-[-16px]
    lg:before:content-[''] before:content-none before:absolute
    before:bg-[linear-gradient(270deg,_#F4F4F400_0%,_#F4F4F4_100%)]
    before:top-0 before:left-0 before:bottom-0 before:w-[70px] sm:before:w-[269px] before:z-10 before:pointer-events-none
    lg:after:content-[''] after:content-none after:absolute
    after:bg-[linear-gradient(270deg,_#F4F4F4_8.33%,_#F4F4F400_100%)]
    after:top-0 after:right-0 after:bottom-0 after:w-[70px] sm:after:w-[269px] after:z-1 after:pointer-events-none"
    >
      <Slider ref={sliderRef} className="center" {...settings}>
        {list?.map((item, index) => {
          return (
            <div key={`slider-item-${index}`}>
              <div
                className={
                  "mx-[10px] overflow-hidden rounded-lg relative cursor-pointer"
                }
                onClick={() => navigate(`view/${item.id}`)}
              >
                <NewsBannerVertical
                  list={[getDataByPathName(item).photo ?? ""] || []}
                  index={index}
                />

                <div
                  className={"absolute bottom-0 p-[10px] sm:p-[20px] w-full"}
                  style={{
                    background:
                      "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%)",
                  }}
                >
                  <div className={"flex gap-[5px] sm:gap-[10px] flex-wrap"}>
                    {item.tags &&
                      location.pathname === "/events" &&
                      item.tags.map((i: string, idx: number) => (
                        <div
                          key={`tag-item-${idx}`}
                          className={
                            "rounded-full bg-blue-default py-[2px] sm:py-1 px-2 sm:px-3 text-white-default text-[11px] font-medium sm:mb-[15px] sm:text-[12px]"
                          }
                        >
                          #{i}
                        </div>
                      ))}
                    {location.pathname === "/news" &&
                      [
                        item?.primary_category?.name || "",
                        item?.secondary_category?.name || "",
                      ].map((i: string, idx: number) => {
                        if (!i) return null;

                        return (
                          <div
                            key={`tag-item-${idx}`}
                            className={
                              "rounded-full bg-blue-default py-[2px] sm:py-1 px-2 sm:px-3 text-white-default text-[11px] font-medium sm:mb-[15px] sm:text-[12px]"
                            }
                          >
                            #{i}
                          </div>
                        );
                      })}
                  </div>

                  <h1
                    className={
                      "sm:text-[20px] font-dewi font-extrabold sm:mb-[15px] mb-0 text-white-default text-[16px] mt-1 leading-[19.2px]"
                    }
                  >
                    {item.title}
                  </h1>
                  <div
                    className={
                      "hidden sm:flex justify-between text-white-default"
                    }
                  >
                    <p
                      className={
                        "flex items-center gap-[8px] text-xs font-medium"
                      }
                    >
                      <ClockIcon color={"white"} />

                      {getDataByPathName(item) && (
                        <span className="ml-auto">
                          {moment(getDataByPathName(item).date).format(
                            "DD MMMM, YYYY "
                          ) + (item.start_time || "")}
                        </span>
                      )}
                    </p>

                    <div className="flex gap-[15px] items-center  text-[12px] font-medium leading-[16px]">
                      { (
                        <div className="flex items-center justify-center gap-[8px] ">
                          <EyeIcon color="white" width="18" />
                          <span>{item?.views_count}</span>
                        </div>
                      )}
                      {
                      location.pathname === "/news" ? (
                        item?.is_like_enabled && (
                          <div className="flex items-center justify-center gap-[8px] ">
                            <LikeIcon color={"white"} />
                            <span>{item?.likes_count}</span>
                          </div>
                        )
                      ) : (
                        <div className="flex items-center justify-center gap-[8px] ">
                          <LikeIcon color={"white"} />
                          <span>{item?.likes_count}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>

      <div
        style={{
          textAlign: "center",
          top: "50%",
          transform: "translateY(-50%)",
        }}
        className={`absolute left-0 hidden sm:block z-10`}
      >
        <button
          className={`button rotate-[90deg] w-[36px] flex items-center justify-center h-[36px] bg-accent-default rounded-full`}
          onClick={previous}
        >
          <ArrowDropdown color="#FFFFFF" />
        </button>
      </div>
      <div
        style={{
          textAlign: "center",
          top: "50%",
          transform: "translateY(-50%)",
        }}
        className={`absolute right-0 hidden sm:block z-10`}
      >
        <button
          className={`button -rotate-[90deg] w-[36px] flex items-center justify-center h-[36px] bg-accent-default rounded-full`}
          onClick={next}
        >
          <ArrowDropdown color="#FFFFFF" />
        </button>
      </div>
    </div>
  );
};

export default NewsBanner;
