import React, { useState } from "react";
import { AnalyticsFilter } from "./FilterAnalytics";
import AnalyticsMainInfoList from "./AnalyticsMainInfoList";

import NewsEventsTrainingComponent from "./NewsEventsTrainingApi";
import moment from "moment";
import BarchartList from "./BarchartList";
import { TimeFilter } from "./data";



export interface IFiltersAnalytics {
  city_id: number;
  fromDate: string;
  toDate: string;
}

const Analitics = () => {
  const [selectedTab, setSelectedTab] = useState<TimeFilter>(TimeFilter.MONTH);

  const [filter, setFilter] = useState<IFiltersAnalytics>({
    city_id: 0,
    fromDate: moment().startOf("day").toISOString(),
    toDate: moment().endOf("day").toISOString(),
  });

  return (
    <section id="analytics" className="min-h-[50vh]">
      <div className="br-container py-6 flex flex-col gap-6">
        <AnalyticsFilter
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          setFilter={setFilter}
          filter={filter}
        />
        <BarchartList
          selectedTab={selectedTab}
          setFilter={setFilter}
          filter={filter}
        />
        <AnalyticsMainInfoList filter={filter} />
        <NewsEventsTrainingComponent filter={filter} />
      </div>
    </section>
  );
};

export default Analitics;
