import React, { useEffect, useState } from "react";
import EventsList from "./EventsList";
import { useNavigate } from "react-router";
import PencilIcon from "components/constant/icons/PencilIcon";
import CalendarList from "./EventCalendarList";
import { useGetEventsBannerQuery, useGetEventsQuery } from "data/api/event";
import NewsBanner from "components/Carousel/NewsBanner";
import { useRoles } from "hooks/useRoles";
import { EventsCategory } from "enums/Events";
import EventsCategories from "./EventsCategory";
import { useWindowSize } from "hooks/useWindowSize";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { objectToQueryString } from "utils/objectToQuery";
import Dropdown from "../../components/Form/Dropdown/Dropdown";
import { useGetCitiesQuery } from "../../data/api/city";
import { useFormatForDropDownOptions } from "../../hooks/useDropDownOption";
import { cityItem } from "../News";
import AnimationFrame from "components/AnimationFrame.tsx";
import moment from "moment";

const Events = () => {
  const { data: cities } = useGetCitiesQuery();

  const cityOptions = useFormatForDropDownOptions(cities?.data ?? []);

  const [selectCity, setSelectCity] = useState<cityItem>();

  const [category, setCategory] = useState<EventsCategory>(EventsCategory.ALL);

  const [fullDate, setFullDate] = useState<string | null>(
    moment().format("YYYY-MM-DD")
  );

  const selection = useSelector((state: RootState) => state.header);

  const navigate = useNavigate();

  const size = useWindowSize();

  const roles = useRoles(null);

  // console.log("fullDate", fullDate);

  const { data, isLoading } = useGetEventsQuery(
    objectToQueryString({
      city_id: selectCity?.value || selection.city_id,
      date: fullDate,
      type: category === EventsCategory.ALL ? "" : category,
    })
  );

  const { data: dataAll } = useGetEventsQuery(
    objectToQueryString({
      city_id: selectCity?.value || selection.city_id,
      type: "",
    })
  );

  const { data: bannerData } = useGetEventsBannerQuery(
    objectToQueryString({
      city_id: selectCity?.value || selection.city_id,
    })
  );

  useEffect(() => {
    const city = cityOptions.find((item) => item.value === selection?.city_id);
    setSelectCity(city as cityItem);
  }, [selection?.city_id]);

  function init() {
    setCategory(EventsCategory.ALL);
  }

  useEffect(() => init(), []);

  return (
    <section className={"bg-bg-default relative min-h-[50vh]"}>
      {/* {isLoading && (
        <div className=" h-[200px]">
          <Loader />
        </div>
      )} */}
      <AnimationFrame>
        <div className={`br-container py-5 ${size.isLg && "pt-5"}`}>
          <div className="flex items-center justify-between pb-3">
            <h1
              className={
                "text-[25px] font-dewi hidden lg:block font-extrabold mb-[15px] text-[#3B4658]"
              }
            >
              Мероприятия
            </h1>
            {size.isLg && (
              <EventsCategories setCategory={setCategory} category={category} />
            )}
          </div>

          {bannerData?.data.length ? (
            <NewsBanner list={bannerData?.data} />
          ) : null}

          <div className={"relative"}>
            {!isLoading && (
              <CalendarList
                fullDate={fullDate}
                setFullDate={setFullDate}
                events={dataAll?.data}
              />
            )}

            <div className={"md:hidden flex absolute right-0 top-0"}>
              <Dropdown
                placeholder={"Город"}
                value={selectCity}
                options={[{ value: "", label: "Все" }, ...cityOptions]}
                className={"select-bg-transparent is__right min-w-[90px]"}
                onChange={setSelectCity}
              />
            </div>
          </div>

          {!size.isLg && (
            <div className={"pb-1 pt-2"}>
              <EventsCategories setCategory={setCategory} category={category} />
            </div>
          )}
          {/*{!size.isLg && (*/}
          {/*	<div className="flex flex-col gap-4 pt-5">*/}
          {/*		<EventsCategories setCategory={setCategory} category={category}/>*/}
          {/*		<h1*/}
          {/*			className={"text-[18px] sm:text-[25px] font-dewi font-extrabold"}*/}
          {/*		>*/}
          {/*			События Брусники*/}
          {/*		</h1>*/}
          {/*	</div>*/}
          {/*)}*/}
          {data?.data ? <EventsList data={data?.data} /> : null}
        </div>
      </AnimationFrame>

      {(roles.isAdmin || roles.isAffiliate_manager) && (
        <div className=" hidden lg:fixed right-[30px]  bottom-[30px] lg:flex items-center justify-center">
          <div
            className={
              "rounded-full bg-accent-default flex items-center shadow-md border border-bg-default justify-center p-3 cursor-pointer"
            }
            onClick={() => navigate("edit")}
          >
            <PencilIcon color={"#FFFFFF"} />
          </div>
        </div>
      )}
    </section>
  );
};

export default Events;
