import React from "react";

import RadioButton from "components/RadioSelectionButton";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useNavigate } from "react-router";
import { handleFormatPrice } from "hooks/handleFormatPrice";
import { Apartment } from "./dataTransformer";
import moment from "moment";
import { Primise } from "enums/primisesType";
import { renderImage } from "utils/renderImage";
import { images } from "utils/resource";

interface propsType {
  isButton?: boolean;
  handleSelectItem?: (id: number) => void;
  item: Apartment;
  handleClickBooking: (item: Apartment) => void;
  handleClickRecord: (item: Apartment) => void;
  handleClickAppLicationPhone: (item: Apartment) => void;
}
const ApartmentCard = ({
  handleSelectItem,
  item,
  handleClickBooking,
  handleClickRecord,
  handleClickAppLicationPhone,
}: propsType) => {
  const {
    end_date,
    name,
    number,
    price,
    is_booked,
    photo,
    type,
    feature,
    feature_new,
    similiarApartmentCount,
  } = item;

  const navigate = useNavigate();

  const selection = useSelector((state: RootState) => state.apartment);

  const itemExists = (id: number) => {
    return selection.storedData.includes(id);
  };

  const handleNavigateToDeatails = () => {
    navigate(`/apartments/card/${item.id}`);
  };

  const hanldeSelectItem = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    handleSelectItem?.(item.id);
  };

  return (
    <div className="bg-white-default rounded-[16px] sm:rounded-lg text-accent-default min-w-[295px] 2xl:max-w-[320px] flex-1 cursor-pointer hover:shadow-lg overflow-hidden h-full flex flex-col justify-between">
      <div
        onClick={handleNavigateToDeatails}
        className="p-[20px] flex flex-col gap-[18px]"
      >
        <div className="flex justify-between items-start">
          <div>
            <h3 className="text-[16px] font-medium leading-[18.65px] mb-[5px]">
              {name}
            </h3>
            <p className="text-[12px] font-medium leading-[16px]">
              {handleFormatPrice(price)}
            </p>
          </div>
          {selection.generateSelection && (
            <RadioButton
              onChange={hanldeSelectItem}
              checked={itemExists(item.id)}
            />
          )}
        </div>
        <div className="flex justify-center">
          <img
            crossOrigin="anonymous"
            src={renderImage(photo) || images.apartmentDefaultImg}
            height={177}
            className="h-[200px]"
            alt=""
          />
        </div>
        <div className="text-[12px] leading-[16px] font-medium">
          <p className="opacity-50">{name}</p>
          <p className="opacity-50">Квартира {number}</p>
          {item?.quarter && item?.year && (
            <p className="opacity-50">
              Срок сдачи {item?.quarter} квартал {item?.year} года
            </p>
          )}
          {selection.generateSelection && (
            <p className="text-[12px] cursor-pointer mt-[10px] text-blue-default hover:text-blue-focus leading-[16px] font-medium ">
              Посмотреть
            </p>
          )}
        </div>

        {feature_new ? (
          <div className="flex gap-[10px] flex-wrap">
            {/* {feature.map((item, i) => ( */}
            <div
              // key={i}
              className="text-[12px] w-fit px-3 h-[28px] font-normal 
             rounded-[20px] border border-light-blue-10 flex items-center justify-center"
            >
              {feature_new}
            </div>
            {/* ))} */}
          </div>
        ) : null}
      </div>
      <div className="flex flex-col px-[20px]">
        <div className="flex justify-between flex-col sm:flex-row gap-[10px] mt-[10px] mb-[18px]">
          {type === Primise.COMMERCIAL ? (
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleClickAppLicationPhone(item);
              }}
              className="border border-accent-default mt-[1px] py-3 
            hover:bg-accent-default flex justify-center text-[12px]
             hover:text-white-default w-full rounded transition-all 
              duration-400
              "
            >
              Заказать звонок
            </button>
          ) : (
            <>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleClickRecord(item);
                }}
                className="border border-accent-default mt-[1px] py-3 
            hover:bg-accent-default flex-1 flex justify-center text-[12px]
             hover:text-white-default rounded transition-all 
              duration-400
              "
              >
                Запись на показ
              </button>
              {!is_booked && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClickBooking(item);
                  }}
                  className="border border-accent-default mt-[1px] py-3
            hover:bg-accent-default text-[12px] flex-1 flex justify-center
             hover:text-white-default rounded transition-all
                duration-400
                "
                >
                  Забронировать
                </button>
              )}
            </>
          )}
        </div>
        {similiarApartmentCount !== 0 && (
          <>
            <hr className="opacity-10" />
            <span className="text-[12px] font-medium inline-block py-[8px] ">
              {similiarApartmentCount !== 0 &&
                `Таких квартир: ${similiarApartmentCount}`}{" "}
            </span>
          </>
        )}
      </div>
    </div>
  );
};

export default ApartmentCard;
