import React from "react";

const WarningIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 8.99996V13M20.543 21H4.45695C2.91895 21 1.95695 19.336 2.72295 18.003L10.766 4.01496C11.536 2.67796 13.465 2.67796 14.234 4.01496L22.277 18.003C23.043 19.336 22.08 21 20.543 21Z"
        stroke="#DAAA00"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M12.5 17.0122L12.512 16.999"
        stroke="#DAAA00"
        strokeWidth="1.375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WarningIcon;
