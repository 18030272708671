import React, { Dispatch, SetStateAction } from "react";
import PageTitle from "components/PageTitle";
import { ActiveClientToggler } from "modules/BaseClients/BaseClientsFilters";

interface Props {
  activeClient: ActiveClientToggler;
  setActiveClientFilter: Dispatch<SetStateAction<ActiveClientToggler>>;
  hasTitle: boolean;
}

export const ActiveClientTogglerComp = ({
  activeClient,
  setActiveClientFilter,
  hasTitle,
}: Props) => {
  return (
    <div className="px-[15px] md:px-0 flex justify-between items-center lg:pb-[10px] z-0 w-full">
      {hasTitle ? <PageTitle text="Клиенты" /> : null}

      <div className="w-full sm:w-[406px]">
        <div className="p-[1.5px] sm:p-[3.5px] bg-white-default flex rounded-[32px]  relative">
          <div
            onClick={() => setActiveClientFilter(ActiveClientToggler.ACTIVE)}
            className={`flex-1 text-[14px] py-[8px] sm:py-[12px] z-10 transition ease-in-out delay-100 cursor-pointer ${
              activeClient === ActiveClientToggler.ACTIVE &&
              "bg-accent-default text-white-default"
            } rounded-[32px] text-center `}
          >
            Активные
          </div>
          <div
            onClick={() => setActiveClientFilter(ActiveClientToggler.ALL)}
            className={`flex-1 text-[14px] z-10 py-[8px] sm:py-[12px] transition ease-in-out delay-100 cursor-pointer ${
              activeClient === ActiveClientToggler.ALL &&
              "bg-accent-default text-white-default"
            } rounded-[32px] text-center `}
          >
            Все
          </div>
          <div
            className={`absolute bottom-1 z-0 left-1 rounded-[32px] bg-white-default h-[34px] sm:h-[45px] w-1/2 transition-transform duration-150 transform ${
              activeClient === ActiveClientToggler.ACTIVE
                ? "translate-x-0"
                : "translate-x-[90%]"
            }`}
          ></div>
        </div>
      </div>
    </div>
  );
};
