interface Props {
  className?: string;
  data: any;
}

const TooltipTags = ({ data, className }: Props) => {
  return (
    <div
      className={`absolute py-3 px-4 min-w-[60px] 
      w-full z-10 top-[38px] left-0 bg-white-default 
      text-accent-default shadow-lg border 
      border-boder-default rounded ${className}`}
			style={{
				maxWidth: "calc(100% - 20px)"
			}}
    >
			<h4 className={"mb-[5px] text-sm font-medium"}>{data?.label}</h4>

			<p className={"text-xs"}>{data?.desc || "Нету описания"}</p>
    </div>
  );
};

export default TooltipTags;
