import { Project } from "modules/Deal/type";
import { apiService } from "..";
import { MetaData } from "../exception";
import { ProjectRequest } from "./type";

export const addTagTypes = ["projects"] as const;

export const projectsApiHooks = apiService
  .enhanceEndpoints({ addTagTypes })
  .injectEndpoints({
    endpoints: (build) => ({
      getProjects: build.query<MetaData<Project[]>, number | null>({
        query: (cityId) => {
          return {
            path: cityId ? `/projects?city_id=${cityId}` : "/projects",
          };
        },
        providesTags: ["projects"],
      }),

      getProjectsByCityId: build.mutation<MetaData<Project[]>, number>({
        query: (id) => {
          return {
            path: `/projects?city_id=${id}`,
          };
        },
      }),

      projectCreate: build.mutation<MetaData<Project>, ProjectRequest>({
        query: (data) => {
          return {
            path: `/projects`,
            method: "POST",
            body: { meta: {}, data },
          };
        },
        invalidatesTags: ["projects"],
      }),
      projectDelete: build.mutation<MetaData<Project>, number>({
        query: (id) => {
          return {
            path: `/projects/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["projects"],
      }),
      projectUpdate: build.mutation<
        MetaData<Project>,
        { data: ProjectRequest; id?: number }
      >({
        query: (data) => {
          return {
            path: `/projects/${data?.id}`,
            method: "PUT",
            body: { meta: {}, data: data.data },
          };
        },
        invalidatesTags: ["projects"],
      }),
    }),
  });

export const {
  useGetProjectsQuery,
  useGetProjectsByCityIdMutation,
  useProjectUpdateMutation,
  useProjectCreateMutation,
  useProjectDeleteMutation,
} = projectsApiHooks;
