import moment from "moment";

export type ExpirationStatus = "gonna_expire" | "expired" | "success";

export const checkExpirationStatus = (
  expirationDate: string
): ExpirationStatus => {
  const currentDate = moment();

  const expirationMoment = moment(expirationDate);

  const diffInDays = expirationMoment.diff(currentDate, "days");

  if (diffInDays <= 0) {
    return "expired";
  } else if (diffInDays <= 3) {
    return "gonna_expire";
  } else {
    return "success";
  }
};
