import { useEffect, useState } from "react";

import Modal from "../../components/Modal";
import ButtonForm from "../../components/Button/ButtonForm";
import Close from "../../components/constant/icons/Close";
import { useCancelVisitsRecordMutation } from "data/api/calendar";
import { VisitStatus } from "enums/visitStatus";
import { useGetCommentsQuery } from "../../data/api/profile";

interface ContentModalData {
  key: string | number;
  text: string;
}

interface CloseModalsData {
  isOpen: boolean;
  close: () => void;
  eventId?: string;
  isEvent?: boolean;
}

const CloseModals = ({ isOpen, close, eventId, isEvent }: CloseModalsData) => {
  const [mutate] = useCancelVisitsRecordMutation();
	const {
		data: comments,
		isLoading,
	} = useGetCommentsQuery();

	const [contentModalList, setContentModalList] = useState([
		{
			key: 1,
			text: "Клиент не выходит на связь",
		},
		{
			key: 2,
			text: "Клиент передумал",
		},
		{
			key: 3,
			text: "Клиент выбрал другую квартиру",
		},
		{
			key: 4,
			text: "Другое",
		},
	])

  const [selectKeyItem, setSelectKeyItem] = useState<ContentModalData | null>(
    null,
  );
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);

  const cancel = async () => {
    if (!eventId) return;

    await mutate({
      data: {
        id: Number(eventId),
        params: {
          data: {
            note: selectKeyItem?.text ?? "test",
            status: VisitStatus.FAIL,
          },
        },
      },
    });
    close();
    setIsOpenConfirmModal(true);
  };

	useEffect(() => {
		init();
	}, [isLoading]);

	function init() {
		if (comments?.data) {
			const updateList = [...comments.data].map(item => ({
				...item,
				key: item.id
			}))

			setContentModalList(updateList)
		}
	}

  return (
    <>
      <Modal isOpen={isOpen} className="md:w-[500px] rounded-lg">
        <div className={"flex justify-center relative"}>
          <h1
            className={
              "font-extrabold text-[25px] font-dewi max-w-[80%] text-center"
            }
          >
            Укажите причину отмены {isEvent ? "мероприятия" : "показа"}
          </h1>

          <div className={"absolute right-0"}>
            <ButtonForm
              text={<Close color={"black"} />}
              className={"p-0 px-0"}
              onClick={close}
            />
          </div>
        </div>

        <div className={"my-[20px]"}>
          {contentModalList &&
            contentModalList.map((item, idx) => (
              <div className={"mb-[10px]"} key={`modal-content-item-${idx}`}>
                <ButtonForm
                  text={item.text}
                  className={`p-3 justify-center font-medium rounded-3xl w-full 
								${
                  selectKeyItem?.key === item.key
                    ? "bg-red-default text-white-default"
                    : "bg-bg-default"
                }`}
                  onClick={() => setSelectKeyItem(item)}
                />
              </div>
            ))}
        </div>

        <div className={"flex flex-col"}>
          <ButtonForm
            text={"Подтвердить отмену"}
            className={
              "p-3 bg-red-default text-white-default justify-center font-medium"
            }
            onClick={cancel}
            disabled={!selectKeyItem}
          />
        </div>
      </Modal>

      <Modal isOpen={isOpenConfirmModal} className="md:w-[500px] rounded-lg">
        <div className={"flex justify-center relative"}>
          <h1 className={"font-extrabold text-[25px] font-dewi text-center"}>
            {isEvent ? "Мероприятие" : "Показ"} отменен
          </h1>
        </div>

        <div className={"mt-[10px] mb-[20px]"}>
          <p className={"text-center"}>
            Вы всегда сможете записаться на {isEvent ? "мероприятие" : "показ"}{" "}
            в удобное для вас время
          </p>
        </div>

        <div className={"flex flex-col"}>
          <ButtonForm
            text={"Ок"}
            className={
              "p-3 bg-accent-default text-white-default justify-center font-medium"
            }
            onClick={() => setIsOpenConfirmModal(false)}
          />
        </div>
      </Modal>
    </>
  );
};

export default CloseModals;
