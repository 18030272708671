import ButtonForm from "../../components/Button/ButtonForm";
import CloseWithBorderIcon from "../../components/constant/icons/CloseWithBorderIcon";
import Label from "../../components/Form/Label";
import Dropdown from "../../components/Form/Dropdown/Dropdown";
import Modal from "../../components/Modal";
import React, { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useApplicationIpotekaMutation } from "../../data/api/calculate";
import { SearchField } from "../../components/Form/SearchField";
import { useFormatForDropDownOptions } from "../../hooks/useDropDownOption";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { optionsType } from "../Apartment/Filter/type";
import { useGetPremisesByBuildingIdMutation } from "../../data/api/premises";
import RublIcon from "../../components/constant/icons/RublIcon";
import TextField from "../../components/Form/TextField/TextField";
import { useWindowSize } from "../../hooks/useWindowSize";
import showToast from "../../utils/showToast";
import useGetProjectByCityId from "../../hooks/commonApiCalls/useGetProjectByCityId";
import useGetBuildingByProjectId from "../../hooks/commonApiCalls/useGetBuildingsbyProjectId";

export interface formMortgageData {
  client_id: number;
  project?: number;
  building_id: any;
  premise_id: number;
  cost: number;
  first_payment: number;
  comment: string;
}

interface MortgageModalProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

const MortgageModal = ({ isOpen, setIsOpen }: MortgageModalProps) => {
  const { isSm } = useWindowSize();

  const selection = useSelector((state: RootState) => state.header);
  const [mutate] = useApplicationIpotekaMutation();
  const [premisesGetMutation] = useGetPremisesByBuildingIdMutation();

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    watch,
    reset,
    setValue,
  } = useForm<formMortgageData>();

  const { building } = useGetBuildingByProjectId({
    projectID: useWatch({
      control,
      name: "project",
    }),
  });

  const { projects } = useGetProjectByCityId({
    cityId: selection.city_id,
  });

  const optionsProject = useFormatForDropDownOptions(projects ?? []);

  const optionsBuilding = useFormatForDropDownOptions(building ?? []);

  const [premisesOptions, setPremisesOptions] = useState<optionsType[] | null>(
    null
  );

  const [isLoading, setIsLoading] = useState(false);

  const getValue = (
    opts: { label: string; value: string | number }[],
    val: number | string | undefined
  ) => {
    if (val) {
      return opts.filter((o) => o.value == val);
    }
    return null;
  };

  useEffect(() => {
    if (watch("building_id")) init(watch("building_id"));
  }, [watch("building_id")]);

  useEffect(() => {
    if (watch("premise_id")) {
      changeCost(watch("premise_id"));
    }
  }, [watch("premise_id")]);

  async function changeCost(value: string) {
    const { data: res, error } = await premisesGetMutation(
      +watch("building_id")
    );

    if (error?.message) {
      console.log(error?.message);
      return;
    }

    if (res?.data) {
      const findSelect = res?.data.find((item: any) => item.id === value);

      if (findSelect) {
        setValue("cost", findSelect.price);
        setValue("first_payment", ((findSelect.price / 100) * 30).toFixed(2));
      }
    }
  }

  async function init(value: string) {
    if (value) {
      const { data: res, error } = await premisesGetMutation(+value);

      if (error?.message) {
        console.log(error?.message);
        return;
      }

      const options = res?.data.map((i) => {
        return { value: i?.id, label: i?.name };
      });

      if (options?.length) setPremisesOptions(options);
    }
  }

  async function handleApply(data: formMortgageData) {
    if (isLoading) return;

    setIsLoading(true);
    const requestObj = {
      ...data,
      cost: +data.cost,
      first_payment: +data.first_payment,
    };

    const { data: res } = await mutate(requestObj);

    if (res) {
      setTimeout(() => {
        reset();
        setIsOpen(false);
        setIsLoading(false);
        showToast("Заявку на ипотеку успешно отправлена", "success", 2000);
      }, 30000);
    } else {
      setTimeout(() => {
        setIsLoading(false);
        showToast(
          "Заявка на ипотеку не отправлена, попробуйте позднее",
          "error",
          2000
        );
      }, 30000);
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        if (isLoading) return;

        reset();
        setIsOpen(false);
      }}
      className="mx-auto sm:w-[450px] lg:w-[500px] w-full rounded-t-[30px] rounded-b-0 sm:rounded-[20px]"
    >
      <div
        className={"flex md:justify-between justify-center relative mb-[20px]"}
      >
        <h1 className={"font-extrabold text-[25px] font-dewi"}>
          Заявка на ипотеку
        </h1>

        {!isSm && (
          <ButtonForm
            text={<CloseWithBorderIcon />}
            onClick={() => {
              reset();
              setIsOpen(false);
            }}
          />
        )}
      </div>
      <form
        className="flex flex-col gap-5 max-h-[70vh] overflow-auto toolbar-scroll"
        onSubmit={handleSubmit(handleApply)}
      >
        <Label label="Клиент">
          <Controller
            name="client_id"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Клиент обязателен для заполнения",
              },
            }}
            render={({ field: { onChange } }) => (
              <SearchField
                onChange={(value) => onChange(value?.id)}
                className="rounded-[4px!important]"
                disabled={isLoading}
              />
            )}
          />
        </Label>
        <Label
          label="Проект"
          // labelLink={{ label: "Открыть проекты", to: "/projects" }}
        >
          <Controller
            name="project"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Проект обязателен для заполнения",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <Dropdown
                name="project"
                value={getValue(optionsProject, value)}
                options={optionsProject}
                onChange={(value) => onChange(value.value)}
                placeholder="Проект не выбран"
                errors={errors}
                disabled={isLoading}
                isSearchable
              />
            )}
          />
        </Label>
        <Label label="Объект">
          <Controller
            name="building_id"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Объект обязателен для заполнения",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <Dropdown
                name="building"
                value={getValue(optionsBuilding, value)}
                options={watch("project") ? optionsBuilding : []}
                onChange={(value) => onChange(value.value)}
                placeholder="Объект не выбран"
                errors={errors}
                disabled={isLoading}
                isSearchable
              />
            )}
          />
        </Label>

        <Label label="Помещение">
          <Controller
            name="premise_id"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Помещение обязательно для заполнения",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <Dropdown
                name="premise_id"
                value={getValue(premisesOptions, value)}
                options={premisesOptions || []}
                onChange={(value) => onChange(value.value)}
                placeholder="Выберите помещение"
                errors={errors}
                disabled={isLoading}
                isSearchable
              />
            )}
          />
        </Label>

        {watch("client_id") && watch("building_id") && watch("premise_id") && (
          <>
            <Label label="Стоимость">
              <Controller
                name="cost"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Стоимость обязательна для заполнения",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    value={value}
                    placeholder="Введите сумму"
                    onChange={onChange}
                    imgRight={<RublIcon />}
                    disabled={isLoading}
                  />
                )}
              />
            </Label>

            <Label label="Первый взнос">
              <Controller
                name="first_payment"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Взнос обязателен для заполнения",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    value={value}
                    placeholder="Введите сумму"
                    onChange={onChange}
                    disabled={isLoading}
                  />
                )}
              />
            </Label>

            <Label label="Комментарий">
              <Controller
                name="comment"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Комментарий обязателен для заполнения",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <textarea
                    value={value}
                    onChange={onChange}
                    name="comment"
                    rows={3}
                    placeholder="Введите свой комментарий"
                    cols={5}
                    disabled={isLoading}
                    className="border border-boder-default py-3 px-4 w-full rounded text-sm font-normal outline-none resize-none"
                  ></textarea>
                )}
              />
            </Label>
          </>
        )}

        <ButtonForm
          type={"submit"}
          className={
            "px-6 py-3 w-full mt-3 justify-center bg-accent-default text-white-default hover:bg-accent-disabled  hover:text-bg-default  hover:bg-bg-defau text-ellipsis whitespace-nowrap"
          }
          text="Отправить заявку"
          disabled={!isValid || isLoading}
          loading={isLoading}
        />
      </form>
    </Modal>
  );
};

export default MortgageModal;
