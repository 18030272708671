import * as yup from "yup";

export const firstName = yup
  .string()
  .required("Имя обязательно")
  .min(2, "Имя должно содержать не менее 2 символов")
  .max(50, "Имя должно содержать не более 50 символов");

export const lastName = yup
  .string()
  .required("Фамилия обязательна")
  .min(2, "Фамилия должна содержать не менее 2 символов")
  .max(50, "Фамилия должна содержать не более 50 символов");

export const city_id = yup.object().required("Город обязателен");

export const birthDate = yup.date().required("Дата рождения обязательна");

export const email = yup
  .string()
  .email("Email должен быть действительным адресом электронной почты")
  .required("Email обязателен");

export const phone = yup
  .string()
  .min(11, "Телефон должен содержать не менее 11 символов")
  .required("Пожалуйста, напишите свой номер телефона");

export const password = yup
  .string()
  .required("Пожалуйста, напишите новый пароль");

export const passwordConfirm = yup
  .string()
  .oneOf([yup.ref("password")], "Пароли должны совпадать")
  .required("Пожалуйста, подтвердите новый пароль");

export const code = yup
  .string()
  .min(6, "Код должен быть не менее 6 символов")
  .required("Пожалуйста, напишите свой пароль");

export const legalName = yup
  .string()
  .required("Название компании обязательно для заполнения")
  .min(2, "Название компании должно содержать не менее 2 символов");

export const title = yup
  .string()
  .required("Название агентства обязательно для заполнения")
  .min(2, "Название агентства должно содержать не менее 2 символов");

export const inn = yup
  .string()
  .min(12, "ИНН должен быть длиной 12 символов.")
  .required("ИНН обязателен для заполнения");

export const entry_doc = yup
  .mixed()
  .required("Сертификат ОГРН обязателен для загрузки");

export const company_card_doc = yup
  .mixed()
  .required("Визитная карточка обязательна для загрузки");

export const tax_registration_doc = yup
  .mixed()
  .required(
    "Сертификат о постановке на учет в налоговом органе обязателен для загрузки"
  );

export const authority_signatory_doc = yup
  .mixed()
  .required(
    "Документ, подтверждающий полномочия подписанта, обязателен для загрузки"
  );

// export const isOwner = yup
//   .boolean()
//   // .oneOf([true], "Необходимо согласие на обработку персональных данных");

export const ownerFullName = yup
  .string()
  .required("Имя обязательно")
  .min(2, "Имя должно содержать не менее 2 символов")
  .max(50, "Имя должно содержать не более 50 символов");

export const ownerPhone = yup
  .string()
  .required("Номер телефона обязателен для заполнения")
  .min(11, "Телефон должен содержать не менее 11 символов");

export const startWorkDate = yup
  .date()
  .required("Начало работы в АН обязательно");

export const agency_id = yup.string();
