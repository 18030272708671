import { UserData } from "components/Form/SearchField";
import { useCheckingThefasteningMutation } from "data/api/base-clients";
import { unformatPhoneNumber } from "hooks/useFormatInput";
import { useEffect, useState } from "react";
import showToast from "utils/showToast";
import ButtonForm from "../../components/Button/ButtonForm";
import Modal from "../../components/Modal";

interface PropsComponent {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

const BaseClientsReClipModal = ({ isOpen, setIsOpen }: PropsComponent) => {

  const [nameValue, setNameValue] = useState<UserData | null>(null);
  const [phoneValue, setPhoneValue] = useState("");
  const [notFount, setNotFound] = useState(false);

  const [fasteningMutate] = useCheckingThefasteningMutation();

  useEffect(() => {
    init();
  }, []);

  function init() {}

  async function handleCheckClient() {
    const query = `fullname=${
      nameValue?.name
    }&phone_number=${unformatPhoneNumber(phoneValue)}`;

    const { data: res, error } = await fasteningMutate(query);
    if (error?.message) {
      showToast(error?.message, "error", 2000);
      return;
    }
    if (res?.data?.id) {
      showToast("Проверка закрепления завершена", "success", 2000);
      setIsOpen(false);
      clearfields();
      setNotFound(false);
    } else {
      setNotFound(true);
    }
  }

  function clearfields() {
    setNameValue(null);
    setPhoneValue("");
  }

  function handleModalClose() {
    clearfields();
    setIsOpen(false);
    setNotFound(false);
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        className="md:w-[440px] w-full rounded-t-[30px] sm:rounded-lg"
        onClose={handleModalClose}
      >
        <div className={"flex justify-center mb-[10px]"}>
          <h1 className={"font-extrabold text-[25px] font-dewi text-center"}>
            Перезакрепить клиента?
          </h1>
        </div>

        <div className={"flex gap-[10px] pt-[20px]"}>
          <ButtonForm
            text="Отмена"
            className="hover:bg-bg-default py-3 px-6
                     bg-white-default xl:flex-1 flex justify-center
                     border border-accent-default
                      text-accent-default hover:text-accent-default w-full"
            onClick={handleCheckClient}
          />
          <ButtonForm
            text="Перезакрепить"
            className="hover:bg-bg-default py-3 px-6
                     bg-accent-default xl:flex-1 flex justify-center
                      text-white-default hover:text-accent-default w-full"
            onClick={handleCheckClient}
          />
        </div>
      </Modal>
    </>
  );
};

export default BaseClientsReClipModal;
