import React from "react";
import ButtonForm from "../Button/ButtonForm";

import "./toggler.css";

interface SelectionToggleProps<T> {
  handleChange: (option: T) => void;
  options: { LEFT: T; RIGHT: T };
  selectedOption: T;
  leftLabel?: string;
  rightLabel?: string;
  onSubmit?: (selectedOption: T) => void;
  hasButton?: boolean;
  buttonText?: string;
  title?: string;
}

const SelectionToggle = <T,>({
  handleChange,
  options,
  selectedOption,
  leftLabel,
  rightLabel,
  onSubmit,
  hasButton = false,
  buttonText = "Submit",
  title,
}: SelectionToggleProps<T>) => {
  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit(selectedOption);
    }
  };

  return (
    <React.Fragment>
      {title && (
        <div className="text-center text-[14px] text-accent-default font-medium leading-[16.41px] mb-[10px]">
          {title}
        </div>
      )}

      <div className={`toggle-container`}>
        <button
          type="button"
          onClick={() => handleChange(options.LEFT)}
          className="toggle-button"
        >
          {leftLabel}
        </button>
        <button
          type="button"
          onClick={() => handleChange(options.RIGHT)}
          className="toggle-button"
        >
          {rightLabel}
        </button>
        <div
          className={`toggle-bg transition-all  ${
            selectedOption === options.LEFT
              ? "translate-x-0"
              : "translate-x-[calc(100%-6px)]"
          }`}
        />
      </div>
      {hasButton && (
        <ButtonForm
          onClick={handleSubmit}
          className="bg-accent-default py-[16px] w-full mt-[30px] text-white-default text-center flex justify-center hover:bg-accent-focus"
          text={buttonText}
        />
      )}
    </React.Fragment>
  );
};

export default SelectionToggle;
