import { apiService } from "..";
import { MetaData } from "../exception";
import { Banner, RequestType } from "./type";

export const addTagTypes = ["banner"] as const;

export const bannerApiHooks = apiService
  .enhanceEndpoints({ addTagTypes })
  .injectEndpoints({
    endpoints: (build) => ({
      getBanners: build.query<MetaData<Banner[]>, string | void | null>({
        query: (query) => ({
          path: query ? `/banner?${query}` : "/banner",
        }),
        providesTags: ["banner"],
      }),
      bannerBulk: build.mutation<MetaData, RequestType>({
        query: (data) => ({
          path: "/banner/bulk",
          body: { meta: {}, data: data },
          method: "POST",
        }),
        invalidatesTags: ["banner"],
      }),
    }),
  });
export const { useGetBannersQuery, useBannerBulkMutation } = bannerApiHooks;
