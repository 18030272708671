import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Modal from "components/Modal";
import Dropdown from "components/Form/Dropdown/Dropdown";
import { cityItem } from "modules/News";
import { useFormatForDropDownOptions } from "hooks/useDropDownOption";
import { useGetCitiesQuery } from "data/api/city";
import { useBannerBulkMutation, useGetBannersQuery } from "data/api/banner";
import PencilIcon from "components/constant/icons/PencilIcon";
import TrashIcon from "components/constant/icons/TrashIcon";
import { Banner, RequestType } from "data/api/banner/type";
import ButtonForm from "components/Button/ButtonForm";
import PlusIcon from "components/constant/icons/PlusIcon";
import NotFound from "components/NotFound";
import { useUploadFileMutation } from "data/api/file";
import TextField from "components/Form/TextField/TextField";
import Label from "components/Form/Label";
import LinkIcon from "components/constant/icons/homePage/LinkIcon";
import { ResponseStatusType } from "data/api/exception";
import showToast from "utils/showToast";
import { RootState } from "store";
import { useSelector } from "react-redux";
import ImgUploadWithCroping from "components/Form/ImgUpload/ImageUploadWithCroping";
import { objectToQueryString } from "utils/objectToQuery";
import { formatUrlComingFromServer } from "utils/putHttpsToLink";
import CloseWithBorderIcon from "components/constant/icons/CloseWithBorderIcon";

interface Props {
  isBannerEditOpen: boolean;
  setIsBannerEditOpen: Dispatch<SetStateAction<boolean>>;
}

type bannerSubmitType = "created" | "deleted" | "updated" | null;

const BannerEditModals = ({ isBannerEditOpen, setIsBannerEditOpen }: Props) => {
  const [isBannerCreateAndEditOpen, setIsBannerCreateAndEditOpen] =
    useState(false);

  const selection = useSelector((state: RootState) => state);

  const [banner, setBanner] = useState<Banner[] | null>(null);

  const [titleValue, setTitleValue] = useState("");

  const [url, setUrl] = useState("");

  const [selectedImg, setSelectedImg] = useState<string>("");

  const [selectCity, setSelectCity] = useState<cityItem | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [selectCityValue, setSelectCityValue] = useState<cityItem | null>(null);

  const [successModal, setSuccessModal] = useState<{
    isOpen: boolean;
    type: bannerSubmitType;
  }>({
    isOpen: false,
    type: "created",
  });

  const [selected, setSelected] = useState<Banner | null>(null);

  const { data: bannerData } = useGetBannersQuery(
    objectToQueryString({ city_id: selectCity?.value })
  );

  const { data: cities } = useGetCitiesQuery();

  const [bannerBulkMutate] = useBannerBulkMutation();

  const [fileMutate] = useUploadFileMutation();

  const [deletedItems, setDeletedItems] = useState<number[]>([]);

  const cityOptions = useFormatForDropDownOptions(cities?.data ?? []);

  useEffect(() => {
    init();
  }, [bannerData]);

  useEffect(() => {
    const selectedCity = cityOptions.find(
      (item) => item.value == selection.header.city_id
    );

    setSelectCity({
      label: selectedCity?.label ?? "",
      value: selectedCity?.value + "" ?? "",
    });
  }, [isBannerEditOpen]);

  useEffect(() => {
    if (selected) {
      setSelectedImg(selected?.cover_image ?? "");
      setTitleValue(selected?.title ?? "");
      setUrl(selected?.link ?? "");
      const selectedCity = cityOptions.find(
        (item) => item.value == selected?.city_id
      );
      setSelectCityValue({
        label: selectedCity?.label ?? "",
        value: selectedCity?.value + "" ?? "",
      });
    }
  }, [selected]);

  useEffect(() => {
    const city = cityOptions.find(
      (item) => item.value === selection.header.city_id
    );
    setSelectCity(city as cityItem);
  }, [selection.header.city_id]);

  const init = () => {
    if (bannerData?.data) {
      setBanner(bannerData?.data);
    }
  };

  const handleImageUpload = async (file: File) => {
    const formData = new FormData();
    formData.append("image", file);
    try {
      const response = await fileMutate(formData).unwrap();
      const fileName = response.data.filename;
      setSelectedImg(fileName);
    } catch (error) {
      console.error("File upload failed", error);
    }
  };

  const handleAddNewBlock = () => {
    setIsBannerEditOpen(false);
    setIsBannerCreateAndEditOpen(true);
  };

  function isDisabledNext() {
    return !(titleValue && selectedImg && selectCityValue && url);
  }

  function handleCloseCreateBanner() {
    setIsBannerCreateAndEditOpen(false);
    setIsBannerEditOpen(true);
  }

  function handleEditItem(item: Banner) {
    setSelected(item);
    setIsBannerEditOpen(false);
    setIsBannerCreateAndEditOpen(true);
  }

  function handleDeleteBanner(
    id: number | undefined,
    ref_id: string | undefined
  ) {
    if (!ref_id?.toString().startsWith("new")) {
      setDeletedItems((prev) => [...prev, Number(id)]);
    }
    setBanner(
      (prevBanner) => prevBanner?.filter((item) => item.id !== id) || []
    );
  }

  async function handleSubmit() {
    const data: RequestType = {
      create: banner?.filter((item) => item.ref_id?.startsWith("new")) || [],
      update: banner?.filter((item) => item.ref_id?.startsWith("old")) || [],
      delete: deletedItems,
    };
    if (data.create.length || data.update.length || data.delete.length) {
      setIsLoading(true);
      const { data: res, error } = await bannerBulkMutate(data);
      if (res?.meta?.type === ResponseStatusType.SUCCESS) {
        if (
          data.create?.length &&
          !data.delete?.length &&
          !data.update?.length
        ) {
          showToast("Баннер успешно создан", "success", 2000);
          setSuccessModal({ isOpen: true, type: "created" });
        } else if (
          !data.create?.length &&
          data.delete?.length &&
          !data.update?.length
        ) {
          showToast("Баннер успешно удален", "success", 2000);
          setSuccessModal({ isOpen: true, type: "deleted" });
        } else {
          showToast("Баннер успешно обновлен", "success", 2000);
          setSuccessModal({ isOpen: true, type: "updated" });
        }
        setIsBannerEditOpen(false);
        setIsLoading(false);
        return;
      }
      if (error?.message) {
        setIsLoading(false);
        showToast(error.message, "error", 2000);
      }
    } else {
      setIsBannerEditOpen(false);
    }
  }

  function handleSave() {
    const newBanner = {
      id: Date.now(),
      ref_id: `new-${Date.now()}`,
      is_active: false,
      cover_image: selectedImg,
      title: titleValue,
      link: url,
      open_in_tab: false,
      city_id: Number(selectCityValue?.value),
    };

    if (selected) {
      const isNewBanner =
        selected?.ref_id && selected?.ref_id.toString().startsWith("new");

      setBanner(
        (prev) =>
          prev &&
          prev?.map((item) =>
            item.id === selected.id
              ? {
                  ...newBanner,
                  id: selected.id,
                  ref_id: isNewBanner
                    ? newBanner.ref_id
                    : `old-${newBanner.id}`,
                }
              : item
          )
      );
    } else {
      setBanner((prev) => prev && [...prev, newBanner]);
    }

    setIsBannerCreateAndEditOpen(false);
    setIsBannerEditOpen(true);
    setDeletedItems([]);
    reset();
  }

  function reset() {
    setSelectedImg("");
    setTitleValue("");
    setUrl("");
    setSelectCityValue(null);
    setSelected(null);
  }

  return (
    <>
      <Modal
        isOpen={isBannerEditOpen}
        className="mx-auto w-full sm:w-[440px] rounded-t-[30px]  rounded-b-0 sm:rounded-lg"
        onClose={() => {
          setIsBannerEditOpen(false);
          init();
          setDeletedItems([]);
        }}
      >
        <div className={"flex justify-between items-center mb-[20px]"}>
          <h1 className={"font-extrabold text-[20px] font-dewi"}>Баннеры</h1>
          <div>
            <Dropdown
              placeholder={"Город"}
              value={selectCity}
              options={[...cityOptions]}
              className={" select-bg-transparent min-w-[100px]"}
              onChange={setSelectCity}
            />
          </div>
        </div>
        <div className=" flex flex-col gap-[10px] max-h-[60vh] overflow-y-auto toolbar-scroll pr-3 ">
          {banner?.length ? (
            banner.map((item) => (
              <div
                key={item.id}
                className=" border border-boder-default rounded-lg relative"
              >
                <img
                  src={
                    process.env.REACT_APP_FILES_URL + "/" + item?.cover_image ||
                    ""
                  }
                  crossOrigin="anonymous"
                  alt="banner_image"
                  className="w-full object-cover h-[154px] rounded-lg"
                />
                <div
                  onClick={() => handleDeleteBanner(item?.id, item?.ref_id)}
                  className="absolute z-10 top-[10px] bg-white-default  right-[10px] w-[34px] h-[34px] border cursor-pointer border-boder-default rounded-xl flex items-center justify-center"
                >
                  <TrashIcon />
                </div>
                <div
                  onClick={() => handleEditItem(item)}
                  className="absolute z-10 top-[10px] bg-white-default right-[50px] w-[34px] h-[34px] border cursor-pointer border-boder-default rounded-xl flex items-center justify-center"
                >
                  <PencilIcon color={"#37465B"} />
                </div>
              </div>
            ))
          ) : (
            <>
              <NotFound title="Данные баннера не найдены" />
            </>
          )}
        </div>
        <div className="mt-2 flex flex-col gap-[10px]">
          <ButtonForm
            text={<PlusIcon color={"#3583FF"} width={37} height={37} />}
            className={
              "bg-white-default border border-blue-default border-dashed rounded justify-center min-w-[48px] w-full p-[4px]"
            }
            onClick={() => handleAddNewBlock()}
          />

          <ButtonForm
            text={"Готово"}
            disabled={isLoading}
            className={
              "p-3 bg-accent-default text-white-default justify-center font-medium w-full"
            }
            onClick={handleSubmit}
          />
        </div>
      </Modal>
      <Modal
        isOpen={isBannerCreateAndEditOpen}
        className="mx-auto w-full sm:w-[440px] rounded-t-[30px] rounded-b-0 sm:rounded-lg "
        onClose={() => {
          handleCloseCreateBanner();
          reset();
        }}
      >
        <div className="max-h-[70vh] overflow-y-auto toolbar-scroll pr-3 pl-[1px]">
          <div className={"flex justify-between items-center mb-[20px]"}>
            <h1 className={"font-extrabold text-[20px] font-dewi"}>
              {selected ? "Редактирование баннера" : "Добавление баннера"}
            </h1>
          </div>
          <div className="flex flex-col gap-[15px]">
            <ImgUploadWithCroping
              title={"Обложка"}
              requirementText={
                "Добавьте изображение с разрешением минимум 600x1800 px"
              }
              value={
                selectedImg
                  ? `${process.env.REACT_APP_FILES_URL}/${selectedImg}`
                  : ""
              }
              handleImageUpload={handleImageUpload}
            />
            <TextField
              value={titleValue}
              onChangeValue={setTitleValue}
              label={"Заголовок"}
              placeholder="Укажите заголовок баннера"
              type="text"
              name="teg"
            />
            <Label label="Город" className={"w-full"}>
              <Dropdown
                value={selectCityValue}
                onChange={setSelectCityValue}
                options={cityOptions}
                placeholder="Выберите город показа"
                className="cursor-pointer"
              />
            </Label>
            <TextField
              value={formatUrlComingFromServer(url)}
              className="mb-[20px]"
              onChangeValue={(value) =>
                setUrl(formatUrlComingFromServer(value))
              }
              label={"Ссылка"}
              imgLeft={<LinkIcon />}
              placeholder="Введите ссылку"
              type="text"
              name="teg"
            />

            <ButtonForm
              text={selected ? "Сохранить" : "Создать"}
              className={
                "p-3 bg-accent-default text-white-default justify-center font-medium w-full"
              }
              onClick={handleSave}
              disabled={isDisabledNext()}
            />
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={successModal.isOpen}
        className="mx-auto w-full sm:w-[440px] rounded-t-[30px] rounded-b-0 sm:rounded-lg relative"
        onClose={() => setSuccessModal({ isOpen: false, type: null })}
      >
        <div className="absolute top-5 right-4">
          <ButtonForm
            text={<CloseWithBorderIcon />}
            onClick={() => setSuccessModal({ isOpen: false, type: null })}
          />
        </div>
        <div className={"flex  items-center justify-center"}>
          <div className="text-center text-accent-default">
            <h1 className={"font-extrabold text-[20px] font-dewi mb-3"}>
              Готово
            </h1>
            <p className="text-sm font-normal mt-[10px]">
              {successModal.type === "created"
                ? " Баннер был добавлен"
                : successModal.type === "deleted"
                ? "Баннер был удален"
                : "Баннер был обновлен"}
            </p>
          </div>
        </div>

        {/* <ButtonForm
          text="Отмена"
          onClick={() => setSuccessModal(false)}
          className="py-3 px-6 bg-red-default flex items-center justify-center text-white-default hover:bg-red-focus  w-full"
        /> */}
      </Modal>
    </>
  );
};

export default BannerEditModals;
