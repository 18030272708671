import React, { useEffect, useState } from "react";
import ContactEditForm from "./ContactEditForm";
import { useNavigate } from "react-router";
import { CloseIcon } from "yet-another-react-lightbox";
import ContactsMap from "../Map";
import { Office } from "..";
import PlusIcon from "components/constant/icons/PlusIcon";
import { IContact } from "data/api/contact/type";
import { useContactsBulkMutation, useGetContactsQuery } from "data/api/contact";
import ButtonForm from "components/Button/ButtonForm";
import showToast from "utils/showToast";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { handleBackToInitialContactsState } from "store/ContactReducer";
import { useWindowSize } from "hooks/useWindowSize";
import { useGetCitiesQuery } from "data/api/city";
import AnimationFrame from "components/AnimationFrame.tsx";

const ContactsEditPage = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { isMd } = useWindowSize();

  const [mapLoaction, setMapLocations] = useState<Office[]>();

  const initialWorkSchedule = [
    {
      weekday: 0,
      start_time: undefined,
      end_time: undefined,
      contact_id: null,
    },
    {
      weekday: 1,
      start_time: undefined,
      end_time: undefined,
      contact_id: null,
    },
    {
      weekday: 2,
      start_time: undefined,
      end_time: undefined,
      contact_id: null,
    },
    {
      weekday: 3,
      start_time: undefined,
      end_time: undefined,
      contact_id: null,
    },
    {
      weekday: 4,
      start_time: undefined,
      end_time: undefined,
      contact_id: null,
    },
    {
      weekday: 5,
      start_time: undefined,
      end_time: undefined,
      contact_id: null,
    },
    {
      weekday: 6,
      start_time: undefined,
      end_time: undefined,
      contact_id: null,
    },
  ];
  const [inputsValue, setInputValue] = useState<IContact[]>([]);

  const selector = useSelector((state: RootState) => state);

  const { data: city } = useGetCitiesQuery();

  const [contactsMutate] = useContactsBulkMutation();

  const {
    data: contacts,
    refetch,
    isLoading,
  } = useGetContactsQuery(selector.header.city_id);

  useEffect(() => init(), [contacts, selector.header.city_id, city]);

  function init() {
    setInputValue([]);
    if (contacts?.data?.length && isLoading === false) {
      setInputValue(contacts?.data);
    } else {
      addNewForm();
    }
    if (contacts?.data.length) {
      const cityInfo = city?.data.filter(
        (item) => item.id === contacts.data[0].city_id
      );
      const tronformData = contacts.data.map((item) => ({
        city: {
          id: 8,
          name: cityInfo?.[0]?.name,
          lat: cityInfo?.[0]?.lat,
          long: cityInfo?.[0]?.long,
        },
        id: 8,
        lat: item.address?.lat,
        link: item?.address_link,
        location: item?.address?.title,
        long: item.address?.long,
        name: item?.title,
        photo: "project_default_image.jpg",
      }));

      setMapLocations(tronformData);
    }
  }

  const addNewForm = () => {
    setInputValue((prev) => [
      ...prev,
      {
        id: Date.now(),
        ref_id: `new-${Date.now()}`,
        title: "",
        address: { title: "" },
        address_link: "",
        phone_number: [""],
        work_schedule: initialWorkSchedule,
        city_id: selector.header.city_id,
      },
    ]);
  };

  const isDisabled = () => {
    const lastItem = inputsValue[inputsValue.length - 1];
    return !lastItem?.title || !lastItem?.address?.title;
  };

  const handleSubmitBulk = async () => {
    const data = {
      create: inputsValue.filter((item) => item.ref_id?.startsWith("new-")),
      update: inputsValue.filter((item) => item.ref_id?.startsWith("old-")),
      delete: selector.contact.delete,
    };
    const { data: res, error } = await contactsMutate({ data: data });
    if (error?.message) {
      showToast(error.message, "error", 2000);
      return;
    }
    showToast("succesfully created", "success", 2000);
    dispatch(handleBackToInitialContactsState());
    navigate("/contacts");
    refetch();
  };

  return (
    <div className="bg-white-default">
      <AnimationFrame>
        <div className="br-container min-h-[50vh] py-7 flex flex-wrap gap-6 ">
          {inputsValue.map((inputsValue, index) => (
            <ContactEditForm
              key={index}
              contacts={contacts?.data}
              inputsValue={inputsValue}
              setInputValue={setInputValue}
              index={index}
            />
          ))}
        </div>
      </AnimationFrame>
      <div className="br-container mt-[55px] ">
        <div className="flex flex-col md:flex-row w-full md:w-[70%] gap-5 mr-auto ">
          {isMd && (
            <button
              className="h-[48px] w-full rounded-lg flex items-center justify-center border border-dashed border-blue-default outline-none"
              onClick={addNewForm}
            >
              <PlusIcon width={28} height={28} color="#3583FF" />
            </button>
          )}

          <ButtonForm
            text="Отменить"
            onClick={() => navigate("/contacts")}
            className=" py-3 px-6   bg-white-default text-accent-default border border-accent-default hover:bg-accent-default  flex-1  hover:text-white-default flex justify-center"
          />
          <ButtonForm
            onClick={handleSubmitBulk}
            disabled={isDisabled()}
            text="Сохранить"
            className=" py-3 px-6  bg-accent-default text-white-default   hover:bg-accent-focus  flex-1  hover:text-white-default flex justify-center"
          />
        </div>
      </div>

      <div className="mt-9">
        <ContactsMap contactsList={mapLoaction} />
      </div>
      <div className=" hidden fixed right-[90px] bottom-[30px] lg:flex items-center justify-center">
        <button
          disabled={isDisabled()}
          className={
            "rounded-full bg-accent-default flex items-center justify-center p-3 cursor-pointer border disabled:bg-accent-disabled border-white-default"
          }
          onClick={addNewForm}
        >
          <PlusIcon color={"#FFFFFF"} />
        </button>
      </div>
      <div className=" hidden fixed right-[30px] bottom-[30px] lg:flex items-center justify-center">
        <div
          className={
            "rounded-full bg-red-default flex items-center justify-center p-3 cursor-pointer"
          }
          onClick={() => navigate("/contacts")}
        >
          <CloseIcon color={"#FFFFFF"} />
        </div>
      </div>
    </div>
  );
};

export default ContactsEditPage;
