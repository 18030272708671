import React, { MutableRefObject, useRef, useState } from "react";
import Slider, { Settings } from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowDropdown from "../constant/icons/ArrowDropdown";
interface PropsType {
  children: React.ReactNode;
  dots?: boolean;
  arrowWraperStyles?: string;
  arrowStyle?: string;
  slidesToShow?: number;
}

const testSettings = {
  backgroundColor: "#3583FF",
  outline: "0",
  scale: "1.4",
};

const Carousel = ({
  children,
  dots = true,
  arrowWraperStyles = "bottom-[20px] right-[20px]",
  arrowStyle,
  slidesToShow = 1,
}: PropsType) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  let sliderRef = useRef<any>(null);
  const next = () => {
    sliderRef.current.slickNext();
  };
  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const settings: Settings = {
    dots: dots,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,

    slidesToScroll: 1,
    arrows: false,
    adaptiveHeight: true,
    appendDots: (dots: React.ReactNode) => (
      <div className="custom-dots">
        <ul
          style={{ margin: "0px" }}
          className="lg:flex flex-nowrap items-center justify-center overflow-hidden w-[82px] hidden"
        >
          {dots}
        </ul>
      </div>
    ),
    customPaging: (i: number) => (
      <div
        style={i === currentIndex ? testSettings : {}}
        className={`h-[8px] slick w-[8px] rounded-full bg-white-default custom-dot `}
      />
    ),
  };

  return (
    <div className="slider-container relative">
      <Slider
        afterChange={(currentIndex) => {
          setCurrentIndex(currentIndex);
        }}
        ref={sliderRef}
        className="w-full"
        {...settings}
      >
        {children}
      </Slider>
      <div
        style={{ textAlign: "center" }}
        className={`absolute flex gap-[10px] ${arrowWraperStyles}`}
      >
        <button
          className={`button rotate-[90deg] w-[36px] flex items-center justify-center h-[36px] shadow-sm bg-white-default border border-boder-default hover:bg-bg-focus rounded-full ${arrowStyle} `}
          onClick={previous}
        >
          <ArrowDropdown color="#37465B" />
        </button>
        <button
          className={`button -rotate-[90deg] w-[36px] flex items-center justify-center h-[36px] shadow-sm bg-white-default border border-boder-default hover:bg-bg-focus rounded-full ${arrowStyle}`}
          onClick={next}
        >
          <ArrowDropdown color="#37465B" />
        </button>
      </div>
    </div>
  );
};

export default Carousel;
