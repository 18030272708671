import { apiService } from "..";
import { MetaData } from "../exception";
import { ITrainingBulk, TrainingCategoryType, TrainingType } from "./type";

export const addTagTypes = [
  "training",
  "trainingCategories",
  "newbieTrainings",
] as const;

export const trainingApiHooks = apiService
  .enhanceEndpoints({ addTagTypes })
  .injectEndpoints({
    endpoints: (build) => ({
      getTrainings: build.query<MetaData<TrainingType[]>, void>({
        query: () => ({
          path: "/trainings",
        }),
        providesTags: ["training"],
      }),
      getAllTrainings: build.query<MetaData<TrainingType[]>, void>({
        query: () => ({
          path: "/trainings?include_non_actives=true",
        }),
        providesTags: [{ type: "training", id: "all" }],
      }),
      getTrainingsById: build.query<MetaData<TrainingType[]>, number>({
        query: (id) => ({
          path: `/trainings?category_id=${id}`,
        }),
        providesTags: (_, __, arg) => [{ type: "training", id: arg }],
      }),
      getTrainingCategories: build.query<
        MetaData<TrainingCategoryType[]>,
        void
      >({
        query: () => ({
          path: "/trainings/categories",
        }),
        providesTags: ["trainingCategories"],
      }),
      getAllTrainingCategories: build.query<
        MetaData<TrainingCategoryType[]>,
        void
      >({
        query: () => ({
          path: "/trainings/categories?include_non_actives=true",
        }),
        providesTags: ["trainingCategories"],
      }),
      handleCreateTraining: build.mutation<
        MetaData<TrainingCategoryType[]>,
        MetaData<{ data: ITrainingBulk }>
      >({
        query: ({ data }) => ({
          path: "/trainings/bulk",
          body: { meta: {}, data: data.data },
          method: "POST",
        }),
        invalidatesTags: ["training", "trainingCategories", "newbieTrainings"],
      }),

      getNewbieTrainings: build.query<MetaData<TrainingType[]>, void>({
        query: () => ({
          path: "/trainings?include_non_actives=false&is_show=true&limit=10",
        }),
        providesTags: ["newbieTrainings"],
      }),
    }),
  });

export const {
  useGetTrainingsQuery,
  useGetAllTrainingsQuery,
  useGetTrainingCategoriesQuery,
  useHandleCreateTrainingMutation,
  useGetNewbieTrainingsQuery,
  useGetAllTrainingCategoriesQuery,
  useGetTrainingsByIdQuery,
} = trainingApiHooks;
