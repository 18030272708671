import { useEffect, useMemo, useState } from "react";

import ButtonForm from "components/Button/ButtonForm";
import BackIcon from "components/constant/icons/BackIcon";
import CheckBox from "components/Form/CheckBox";
import Dropdown from "components/Form/Dropdown/Dropdown";
import { SearchField, UserData } from "components/Form/SearchField";
import Loader from "components/Loader";
import Modal from "components/Modal";
import NotFound from "components/NotFound";
import { Links } from "data/api/exception";
import { useGetLeads2InitialQuery } from "data/api/lead";
import { useGetProjectsQuery } from "data/api/project";
import { useFormatForDropDownOptions } from "hooks/useDropDownOption";
import { capitalizeFirstLetter } from "hooks/useRoles";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsOpenFilters,
  getIsOpenSearch,
  handleFiltersSearch,
  handleFiltersSelect,
  handleOpenFilters,
  handleOpenSearch,
} from "store/BaseClientsReducer";
import { objectToQueryString } from "utils/objectToQuery";
import { Option } from "./dealUtils";
import RenderByTypeComp from "./RenderByTypeComp";
import { primisesTypeDataInDeals } from "./type";
import { DealStatus } from "enums/deals";

export interface iFilterDeals {
  project_id: string;
  premise_type: string;
  status: string;
  client_id: number;
  createdAt: string;
  is_finished: boolean;
  page?: number;
  limit?: number;
  // price?: string;
}

const Deals = () => {
  const [checked, setChecket] = useState(false);

  const [mobileChecked, setMobileChecked] = useState(checked);

  const [isASC, setIsASC] = useState(true);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { data: projects } = useGetProjectsQuery(null);

  const [filter, setFilter] = useState<iFilterDeals>({
    project_id: "",
    premise_type: "",
    status: "",
    client_id: 0,
    createdAt: "ASC",
    is_finished: false,
    limit: 15,
  });

  const [mobileFilter, setMobileFilter] = useState(filter);

  const options = useFormatForDropDownOptions(projects?.data ?? []);

  const { data, isLoading, isFetching } = useGetLeads2InitialQuery(
    objectToQueryString(filter)
  );

  const [searchedClient, setSearchedClient] = useState<UserData>();

  const storeIsOpenFilters = useSelector(getIsOpenFilters);

  const storeIsOpenSearch = useSelector(getIsOpenSearch);

  const dispatch = useDispatch();

  const handleOrderData = () => {
    setIsASC(!isASC);
    setFilter((prev) => ({ ...prev, createdAt: !isASC ? "ASC" : "DESC" }));
  };

  const dealsSteps = useMemo(() => {
    const statuses = data?.meta?.data?.statuses ?? [];

    return statuses.map((status) => ({
      label: capitalizeFirstLetter(status),
      value: status,
    }));
  }, [data?.meta?.data]);

  const getValue = useMemo(
    () =>
      (opts: Option[], val: string | undefined): Option | Option[] | null => {
        if (val) {
          return opts.filter((o) => o.value === val);
        }
        return null;
      },
    []
  );

  const handleFilter = () => {
    setFilter(mobileFilter);
    setChecket(mobileChecked);
    dispatch(handleOpenFilters(false));
    dispatch(
      handleFiltersSelect(
        !!mobileFilter.project_id ||
          !!mobileFilter.premise_type ||
          !!mobileFilter.status ||
          mobileFilter.is_finished
      )
    );
  };

  const resetFilter = () => {
    setMobileFilter({
      project_id: "",
      premise_type: "",
      status: "",
      client_id: 0,
      createdAt: "ASC",
      is_finished: false,
      limit: 15,
    });
    setChecket(false);

    dispatch(handleFiltersSelect(false));
  };

  const handleMenuOpen = () => {
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  const onChangeSearchField = (value: UserData) => {
    if (value) {
      dispatch(handleOpenSearch(false));
    }

    dispatch(handleFiltersSearch(!!value));

    setSearchedClient(value);

    setFilter((prev) => ({
      ...prev,
      client_id: value?.id,
    }));
  };

  const sorteredData = useMemo(() => {
    const list = [...(data?.data ?? [])];

    const order = [
      DealStatus.ACTIVE,
      DealStatus.PAUSE,
      DealStatus.WIN,
      DealStatus.LOST,
    ];

    list.sort(({ state: a }, { state: b }) => {
      return order.indexOf(a) - order.indexOf(b);
    });

    return list;
  }, [data?.data]);

  const loading = isLoading || isFetching;

  // @ts-ignore
  const links: Links[] = data?.meta?.links ?? [];

  useEffect(() => {
    return () => {
      dispatch(handleFiltersSelect(false));
      dispatch(handleFiltersSearch(false));
    };
  }, []);

  return (
    <div className="bg-bg-default">
      <div className="br-container py-[20px] min-h-[50vh] relative">
        <div className="flex  justify-between lg:mb-[30px] relative">
          <div
            className=" hidden
           lg:flex gap-[10px]"
          >
            <Dropdown
              onChange={(e) =>
                setFilter((prev) => ({ ...prev, project_id: e.value }))
              }
              options={[{ value: "", label: "Все" }, , ...options]}
              name="type"
              placeholder="Проект"
              className=" w-[130px] xl:w-[226px]"
            />
            <Dropdown
              onChange={(e) =>
                setFilter((prev) => ({ ...prev, premise_type: e.value }))
              }
              options={[
                { value: "", label: "Все" },
                ,
                ...primisesTypeDataInDeals,
              ]}
              name="type"
              placeholder="Помещение"
              className=" w-[130px] xl:w-[226px]"
            />
            <Dropdown
              onChange={(e) =>
                setFilter((prev) => ({ ...prev, status: e.value }))
              }
              options={[{ value: "", label: "Все" }, , ...(dealsSteps || [])]}
              name="type"
              placeholder="Этап сделки"
              className=" w-[130px] xl:w-[226px]"
            />
            <div className="ml-[10px] flex items-center">
              <CheckBox
                value={checked}
                onChange={(e) => {
                  setFilter((prev) => ({
                    ...prev,
                    is_finished: e,
                  }));
                  setChecket(e);
                }}
                label={"Показать завершенные сделки"}
              />
            </div>
          </div>
          <SearchField
            shouldClearOnBlur={false}
            className="w-[250px] hidden lg:block xl:w-full ml-auto md:rounded-[4px!important]"
            onChange={(value) =>
              setFilter((prev) => ({
                ...prev,
                client_id: value?.id,
              }))
            }
            isShowBtnDelete
          />
          <button
            className="w-[100px] border-none outline-none h-[34px] rounded-[4px] flex items-center justify-center gap-1 bg-white-default absolute right-0 -bottom-[35px] lg:-bottom-[63px] text-sm
          "
            onClick={handleOrderData}
          >
            По дате
            <div className={` ${isASC ? "-rotate-90" : "rotate-90"} `}>
              <BackIcon />
            </div>
          </button>
        </div>

        {loading ? (
          <Loader />
        ) : sorteredData.every(({ data }) => data.length === 0) ? (
          <h1 className="text-[22px] text-accent-default text-center mt-[100px]">
            <NotFound title="Сделок не найдено" />
          </h1>
        ) : (
          sorteredData.map(({ data, state }, idx) => (
            <RenderByTypeComp
              key={idx}
              status={state}
              dealsSteps={dealsSteps}
              data={data}
              filter={filter}
              // @ts-ignore
              initialLinks={links.find((el) => el.state === state)}
            />
          ))
        )}
      </div>
      {/* Responsive part of filter */}
      <Modal
        isOpen={storeIsOpenFilters}
        className={`mx-auto w-full sm:w-[450px] lg:w-[500px] rounded-t-[30px] rounded-b-0 sm:rounded-[20px] md:rounded-[20px] 
         `}
        onClose={() => {
          dispatch(
            handleFiltersSelect(
              !!mobileFilter.project_id ||
                !!mobileFilter.premise_type ||
                !!mobileFilter.status ||
                mobileFilter.is_finished
            )
          );
          dispatch(handleOpenFilters(false));
        }}
      >
        <h2 className="text-[20px] font-dewi font-extrabold text-center mb-[30px]">
          Фильтр
        </h2>
        <div className="flex flex-col gap-[10px]">
          <Dropdown
            onChange={(e) =>
              setMobileFilter((prev) => ({ ...prev, project_id: e?.value }))
            }
            options={[{ value: "", label: "Все" }, ...options]}
            name="type"
            placeholder="Проект"
            className="w-full"
            isClearable={true}
            value={getValue(
              [{ label: "Проект", value: "" }, ...options],
              mobileFilter.project_id
            )}
          />
          <Dropdown
            onChange={(e) =>
              setMobileFilter((prev) => ({ ...prev, premise_type: e?.value }))
            }
            options={[{ value: "", label: "Все" }, ...primisesTypeDataInDeals]}
            name="type"
            placeholder="Помещение"
            className="w-full"
            isClearable={true}
            menuPosition="fixed"
            value={getValue(
              [
                { value: "", label: "Помещение" },
                ...[{ value: "", label: "Все" }, ...primisesTypeDataInDeals],
              ],
              mobileFilter.premise_type
            )}
          />
          <Dropdown
            onChange={(e) =>
              setMobileFilter((prev) => ({ ...prev, status: e?.value }))
            }
            options={[{ value: "", label: "Все" }, , ...(dealsSteps || [])]}
            name="type"
            placeholder="Этап сделки"
            className="w-full"
            isClearable={true}
            value={getValue(
              [{ value: "", label: "Этап сделки" }, ...(dealsSteps || [])],
              mobileFilter.status
            )}
            onMenuOpen={handleMenuOpen}
            onMenuClose={handleMenuClose}
            menuPosition="fixed"
            // menuPlacement="auto"
          />
          <div className="mt-[10px]">
            <CheckBox
              value={mobileFilter.is_finished}
              onChange={(e) => {
                setMobileFilter((prev) => ({
                  ...prev,
                  is_finished: e,
                }));
                setChecket(e);
              }}
              label={"Показать завершенные сделки"}
            />
          </div>
        </div>
        <div className="pt-[30px] flex flex-col gap-3">
          <ButtonForm
            text="Применить"
            onClick={handleFilter}
            className="hover:bg-accent-disabled py-3 px-6 bg-accent-default
            xl:flex-1 flex justify-center text-white-default
            hover:text-bg-default w-full rounded-lg"
          />

          <ButtonForm
            text="Сбросить"
            onClick={resetFilter}
            className="border border-accent-default
            py-3 px-6 bg-white-default xl:flex-1 flex justify-center
            text-accent-default w-full rounded-lg"
          />
        </div>
      </Modal>

      <Modal
        isOpen={storeIsOpenSearch}
        onClose={() => {
          dispatch(handleOpenSearch(false));
        }}
        className="rounded-t-[30px] w-full"
      >
        <SearchField
          className="w-full xl:w-full ml-auto md:rounded-[4px!important]"
          selectItem={searchedClient}
          onChange={onChangeSearchField}
          isShowBtnDelete={true}
          isOpenSearch
        />
      </Modal>
    </div>
  );
};

export default Deals;
