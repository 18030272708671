import React, { FC, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { ru } from "date-fns/locale/ru";
import Calendar from "../../constant/icons/Calendar";

import "react-datepicker/dist/react-datepicker.css";
import { FieldErrors } from "react-hook-form";
import { dataPickerType } from "modules/Auth/types";

type DateInterface = Date | null;

interface DatePickerProps {
  startDate?: DateInterface;
  onChangeDate: (date: Date | null) => void;
  isIcon?: boolean;
  placeholder?: string;
  isRightIcon?: boolean;
  isTimeSelect?: boolean;
  isMonthSelect?: boolean;
  className?: string;
  disabled?: boolean;
  minDate?: Date | null;
  maxDate?: Date | null;
  name?: string;
  errors?: FieldErrors<dataPickerType>;
  value?: string;
  isClearable?: boolean;
  setIsOpen?: (val: boolean) => void;
  isMobile?: boolean;
  isDisableRead?: boolean;
  iconZindex?: string;
}

const DatePickerField: FC<DatePickerProps> = ({
  startDate,
  onChangeDate,
  isIcon = false,
  placeholder = "",
  isRightIcon = false,
  isTimeSelect = false,
  isMonthSelect = false,
  className,
  disabled,
  minDate,
  maxDate,
  errors,
  name,
  value,
  isClearable,
  setIsOpen,
  isDisableRead,
  isMobile,
  iconZindex
}) => {
  const dateStart = (date: any): DateInterface => {
    if (typeof date === "string") return new Date(date);

    return startDate || null;
  };

  const [startDateLocal, setStartDate] = useState<DateInterface>(
    dateStart(startDate)
  );
  const [endDate, setEndDate] = useState<DateInterface>(null);

  useEffect(() => {
    // if (startDateLocal === null && startDate) setStartDate(dateStart(startDate))
    if (dateStart(startDate) !== startDateLocal)
      setStartDate(dateStart(startDate));
  }, [startDate]);

  const onDateRange = (value: DateInterface) => {
    onChangeDate(value);
  };

  const getDateFormatText = (): string => {
    if (isTimeSelect) return "dd.MM.yyyy HH:mm";

    if (isMonthSelect) return "MM/yyyy";

    return "dd.MM.yyyy";
  };

  const renderMonthContent = (
    monthIndex: number,
    shortMonthText: string,
    fullMonthText: string
  ) => {
    const tooltipText = `Подсказка: ${fullMonthText}`;

    return <span title={tooltipText}>{shortMonthText}</span>;
  };

  const handleFocus = (e: ReactClickEvent<HTMLInputElement>) => {
    const { target } = e;

    if (target) {
      target.readOnly = true; // -------> this for all others
      target.blur(); //  ------> this for ios iphone, TV Browsers, Ipad, Safari
    }
  };

  return (
    <React.Fragment>
      <div className={`relative ${className}`}>
        {isIcon && (
          <div
            className={`icon-date-picker absolute bottom-0 h-full flex items-center justify-center  ${
              isRightIcon ? "right-4" : "left-4"
            } ${isClearable && isMobile && "right-[25px]"} ${iconZindex} `}
          >
            <Calendar color={"#3583FF"} />
          </div>
        )}

        <div
          className={`calendar-block__date-picker ${
            isRightIcon ? "right-icon" : ""
          }`}
        >
          <DatePicker
            locale={ru}
            selected={startDateLocal}
            value={value}
            dateFormat={getDateFormatText()}
            wrapperClassName="w-full"
            onChange={(date: DateInterface) => {
              onDateRange(date);

              setStartDate(date);
            }}
            selectsStart
            startDate={startDateLocal}
            endDate={endDate}
            placeholderText={placeholder}
            minDate={minDate}
            maxDate={maxDate}
            showTimeSelect={isTimeSelect}
            timeCaption="Время"
            timeFormat="HH:mm"
            className={`
                    py-[12px] 
                    px-[12px] 
                    placeholder:text-accent-disabled 
                    w-full
                    border-[1px] 
                    border-boder-default 
                    outline-none 
                    placeholder:font-normal
                    text-[14px] 
                    rounded-[4px]
                    z-[2]
                    whitespace-nowrap
                    text-ellipsis
                    ${isIcon ? (isRightIcon ? "pr-[55px]" : "pl-[55px]") : ""}
                    `}
            renderMonthContent={renderMonthContent}
            showMonthYearPicker={isMonthSelect}
            disabled={disabled}
            isClearable={isClearable}
            showYearDropdown
            showMonthDropdown
            yearDropdownItemNumber={50}
            scrollableYearDropdown
            onFocus={(e: ReactClickEvent<HTMLInputElement>) => {
              isDisableRead && handleFocus(e);
              setIsOpen && setIsOpen(true);
            }}
            onBlur={() => setIsOpen && setIsOpen(false)}
            disabledKeyboardNavigation={false}
          />
        </div>
      </div>
      {errors?.[name as keyof dataPickerType] && (
        <div className="text-[12px] leading-[14.06px] flex flex-col">
          <p className="text-red-default mb-[5px]">
            {errors?.[name as keyof dataPickerType]?.message}
          </p>
        </div>
      )}
    </React.Fragment>
  );
};

export default DatePickerField;
