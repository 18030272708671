import React, { useEffect, useState } from "react";
import ButtonForm from "../../components/Button/ButtonForm";
import Modal from "../../components/Modal";
import CloseWithBorderIcon from "../../components/constant/icons/CloseWithBorderIcon";
import PlusIcon from "../../components/constant/icons/PlusIcon";
import NewsCategoryItem from "./NewsCategoryItem";
import { useWindowSize } from "../../hooks/useWindowSize";
import { NewsCategories } from "data/api/news/type";
import {
  useDeleteCategoryMutation,
} from "data/api/news";
import { ResponseStatusType } from "data/api/exception";
import showToast from "utils/showToast";

interface NewsCategoryModalData {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  propsList?: NewsCategories[];
}

const NewsCategoryModal = ({
  isOpen,
  setIsOpen,
  propsList,
}: NewsCategoryModalData) => {
  const { isSm } = useWindowSize();

  const [deleteMutation] = useDeleteCategoryMutation();

  const [categoryList, setCategoryList] = useState<NewsCategories[]>(
    propsList || []
  );

  const onChangeCategory = (value: string, id: number) => {
    const updateList = categoryList.map((key) => {
      if (id === key.id) return { name: value, id };

      return key;
    });
    setCategoryList(updateList);
  };

  const handleDeleteCategoryBlock = async (id: number) => {
    const { data, error } = await deleteMutation(id);
    if (data?.meta?.type === ResponseStatusType.ERROR) {
      showToast(error?.message || "Что-то пошло не так!", "error", 2000);
      return;
    }

  };

  useEffect(() => {
    if (propsList) setCategoryList(propsList);
  }, [propsList]);

  function handleAddNewBlock() {
    setCategoryList((list) => {
      return [...list, { name: "", id: Date.now() }];
    });
  }

  function handleSave() {
    setIsOpen(true);
  }

  return (
    <Modal
      isOpen={isOpen}
      className="md:w-[440px] w-full rounded-lg overflow-auto max-h-[90vh]"
      onClose={() => setIsOpen(true)}
    >
      <div className={"flex md:justify-start justify-center relative"}>
        <h1 className={"font-extrabold text-[20px] font-dewi md:max-w-[80%]"}>
          Категории
        </h1>

        {!isSm && (
          <div className={"absolute right-0"}>
            <ButtonForm
              text={<CloseWithBorderIcon color={"black"} />}
              className={"p-0 px-[0px!important]"}
              onClick={() => setIsOpen(true)}
            />
          </div>
        )}
      </div>

      <section className="my-[15px]">
        {categoryList.map((key, idx) => (
          <div key={`category-edit-item-${key.id}`}>
            <NewsCategoryItem
              text={key.name}
              id={key.id}
              isActive={key.is_active}
              onChangeValue={onChangeCategory}
              handleDeleteBlock={() => handleDeleteCategoryBlock(key.id)}
            />

            {idx !== categoryList.length - 1 && (
              <div className={"border-t border-[#D7DCE4]"}></div>
            )}
          </div>
        ))}

        <div>
          <ButtonForm
            text={<PlusIcon color={"#3583FF"} width={37} height={37} />}
            className={
              "bg-white-default border border-blue-default border-dashed rounded justify-center min-w-[48px] w-full p-[4px]"
            }
            onClick={() => handleAddNewBlock()}
          />
        </div>
      </section>

      <div className={"flex py-[15px]"}>
        <ButtonForm
          text={"Готово"}
          className={
            "p-3 bg-accent-default text-white-default justify-center font-medium w-full"
          }
          onClick={handleSave}
        />
      </div>
    </Modal>
  );
};

export default NewsCategoryModal;
