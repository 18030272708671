import { useEffect, useState } from "react";
import { Project } from "modules/Deal/type";
import { useGetProjectsByCityIdMutation } from "data/api/project";

interface UseGetProjectByCityIdProps {
  cityId?: number;
}

const useGetProjectByCityId = ({ cityId }: UseGetProjectByCityIdProps) => {
  const [projects, setProjects] = useState<Project[] | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [getProjectByCityId] = useGetProjectsByCityIdMutation();

  const fetchProject = async (id: number) => {
    setIsLoading(true);
    try {
      const { data: res } = await getProjectByCityId(id).unwrap();
      setProjects(res);
    } catch (err) {
      console.log("Failed to fetch project data.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (cityId && cityId !== 0) {
      fetchProject(cityId);
    }
  }, [cityId, getProjectByCityId]);

  const refetch = () => {
    if (cityId) {
      fetchProject(cityId);
    }
  };

  return { projects, isLoading, refetch };
};

export default useGetProjectByCityId;
