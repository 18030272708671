import React from "react";
import { Link } from "react-router-dom";

import ButtonForm from "../../../../components/Button/ButtonForm";
import { RegisterFormState, Status } from "../../types";
import { RegisterType, Step } from "enums/authEnums";

interface Props {
  setFormState: React.Dispatch<React.SetStateAction<RegisterFormState>>;
}

const SelectRegisterType = ({ setFormState }: Props) => {
  function handleSelectRegisterType(type: RegisterType) {
    let step;
    const status = localStorage.getItem("register_status");
    console.log("status", status);

    if (!status) {
      step = Step.PHONE_NUMBER_FORM;
    } else {
      const stepMapping: Record<Status, Step> = {
        fill_data: Step.USER_INFO_FORM,
        created: Step.USER_INFO_FORM,
        attachment:
          type === RegisterType.NEW_AGENCY
            ? Step.NEW_AGENCY_AGREEMENT_FORM
            : Step.ATTACH_TO_AGENCY_FORM,
      };

      step = stepMapping[status as Status];
    }
    setFormState({
      step,
      registerType: type,
    });

    localStorage.setItem("registerType", type);
  }
  return (
    <div className="flex flex-col gap-[10px]">
      <h3 className="text-[25px] font-extrabold font-dewi text-accent-default text-center leading-[30px] mb-[20px]">
        Регистрация
      </h3>
      <ButtonForm
        onClick={() => handleSelectRegisterType(RegisterType.AGENCY)}
        className="bg-accent-default py-[16px] px-[14px]  w-full
              text-white-default text-center flex justify-center hover:bg-accent-focus "
        text="Выбрать агентство недвижимости"
      />
      <ButtonForm
        onClick={() => handleSelectRegisterType(RegisterType.NEW_AGENCY)}
        className="bg-accent-default py-[16px] px-[14px] w-full
              text-white-default text-center flex justify-center hover:bg-accent-focus "
        text="Зарегистрировать новое агентство"
      />

      <Link to="/sign-in">
        <ButtonForm
          className=" bg-white-default border-[1px] border-accent-default py-[12px] px-[14px] 
          w-full
              text-accent-default text-center flex justify-center hover:bg-accent-default
              hover:text-white-default "
          text="Назад"
        />
      </Link>
    </div>
  );
};

export default SelectRegisterType;
