/* eslint-disable jsx-a11y/iframe-has-title */
import ProjectPageCard from "components/Cards/ProjectPageCard";
import PageTitle from "components/PageTitle";
import { useEffect, useMemo, useState } from "react";
import ButtonForm from "../../components/Button/ButtonForm";
import ListIcon from "../../components/constant/icons/ListIcon";
import MarkerIcon from "../../components/constant/icons/MarkerIcon";
import { useWindowSize } from "../../hooks/useWindowSize";
import YMap from "./YMap";
import Loader from "components/Loader";
import { Project } from "modules/Deal/type";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import AnimationFrame from "components/AnimationFrame.tsx";
import { Link, useNavigate } from "react-router-dom";
import { useRoles } from "hooks/useRoles";
import PencilIcon from "components/constant/icons/PencilIcon";
import { cityItem } from "modules/News";
import { useFormatForDropDownOptions } from "hooks/useDropDownOption";
import { useGetCitiesQuery } from "data/api/city";
import Dropdown from "components/Form/Dropdown/Dropdown";
import useGetProjectByCityId from "hooks/commonApiCalls/useGetProjectByCityId";
import { toggleRecordForShowModal } from "store/ApartmentReducer";
import RecordForShow from "components/BookingAndVisits/RecordForShowForm";
import NotFound from "components/NotFound";

const Projects = () => {
  const selection = useSelector((state: RootState) => state.header);

  const recordForShowModalById = useSelector(
    (state: RootState) => state.apartment.recordForShowModalById
  );

  const [projectId, setProjectId] = useState<number>();

  const [selectCity, setSelectCity] = useState<cityItem | null>(null);

  const { projects: data, isLoading } = useGetProjectByCityId({
    cityId: selectCity ? Number(selectCity?.value) : selection.city_id,
  });

  const dispach = useDispatch();

  const { isMd } = useWindowSize();

  const roles = useRoles(null);

  const navigate = useNavigate();

  const { data: cities } = useGetCitiesQuery();

  const cityOptions = useFormatForDropDownOptions(cities?.data ?? []);

  useEffect(() => {
    const city = cityOptions.find((item) => item.value === selection.city_id);
    setSelectCity(city as cityItem);
  }, [selection.city_id]);

  const btnList = [
    {
      key: "list",
      text: "Списком",
      icon: (color: string) => <ListIcon color={color} />,
    },
    {
      key: "map",
      text: "На карте",
      icon: (color: string) => <MarkerIcon color={color} />,
    },
  ];

  const [showContent, setShowContent] = useState<any>(btnList[0]);

  const checkShowMobile = (key: string) => {
    if (isMd) {
      return showContent.key === key;
    }

    return true;
  };

  const handleLeftClick = (id: number | null) => {
    if (id === recordForShowModalById) {
      setProjectId(0);
    } else if (id) {
      setProjectId(id);
    }
    dispach(toggleRecordForShowModal(id));
  };

  const projectList = useMemo(() => data ?? [], [data]);

  const apartmentsCount = projectList.reduce(
    (acc, curr) => acc + curr.apartment_count,
    0
  );

  return (
    <div className="bg-bg-default relative">
      <div className="br-container  py-[16px] md:py-[30px] flex flex-col gap-[20px]">
        <div className="flex justify-between relative">
          <PageTitle text="Проекты" />
          {isMd ? (
            <Dropdown
              placeholder={"Город"}
              value={selectCity}
              options={cityOptions}
              className={" select-bg-transparent min-w-[100px]"}
              onChange={setSelectCity}
            />
          ) : null}
        </div>
        {isLoading ? (
          <div className="h-[50vh]">
            <Loader />
          </div>
        ) : null}
        <AnimationFrame>
          <div className="flex gap-[20px]">
            {checkShowMobile("list") && (
              <div className="flex flex-col gap-[10px] flex-1">
                {projectList.map((item: Project) => (
                  <div key={item.id}>
                    <ProjectPageCard
                      item={item}
                      handleLeftClick={handleLeftClick}
                    />
                  </div>
                ))}
                {!isLoading && projectList?.length ? (
                  <Link to={"/exchange"}>
                    <div className="flex p-[20px] justify-between border-l-4 rounded-tr-lg rounded-br-lg text-[16px] items-center text-accent-default bg-white-default cursor-pointer border-red-default">
                      <h3 className="leading-[18px] font-medium">
                        Каталог квартир от Брусника. Обмен
                      </h3>
                      <h4 className=" font-normal leading-[20px]">
                        {apartmentsCount} квартир
                      </h4>
                    </div>
                  </Link>
                ) : null}
              </div>
            )}
            {checkShowMobile("map") && projectList?.length ? (
              <YMap projectList={projectList} />
            ) : null}
          </div>
        </AnimationFrame>
      </div>
      {!projectList.length && !isLoading ? <NotFound /> : null}

      {isMd && (
        <div className="flex justify-between gap-[10px] z-10 w-full pb-5  br-container">
          {btnList.map((item, idx) => (
            <ButtonForm
              key={`btn-item-${idx}`}
              text={item.text}
              leftIcon={item.icon(
                showContent.key === item.key ? "white" : "black"
              )}
              className={`py-[9px] rounded-[32px!important] border-[1px] w-full justify-center min-w-40 ${
                showContent.key === item.key
                  ? "bg-red-default border-red-default text-white-default"
                  : "bg-bg-default  border-[#D7DCE4]"
              }
            `}
              onClick={() => setShowContent(item)}
            />
          ))}
        </div>
      )}

      {(roles.isAdmin || roles.isAffiliate_manager) && (
        <div className=" hidden lg:fixed right-[30px]  bottom-[30px] lg:flex items-center justify-center">
          <div
            className={
              "rounded-full bg-accent-default flex items-center shadow-md border border-bg-default justify-center p-3 cursor-pointer"
            }
            onClick={() => navigate("edit")}
          >
            <PencilIcon color={"#FFFFFF"} />
          </div>
        </div>
      )}
      <RecordForShow
        project_id={projectId}
        handleToggle={() => handleLeftClick(projectId || null)}
        isOpenModal={recordForShowModalById === projectId}
      />
    </div>
  );
};

export default Projects;
