import Modal from "../Modal";
import React from "react";
import ButtonForm from "../Button/ButtonForm";

interface BookingModalCompletedProps {
  isOpen: boolean;
  setIsOpen: () => void;
  count?: number;
}

const BookingModalCompleted = ({
  isOpen,
  setIsOpen,
  count,
}: BookingModalCompletedProps) => {
  const bookingCount = count || 0;

  return (
    <Modal
      isOpen={isOpen}
      onClose={setIsOpen}
      className="mx-auto w-full md:w-[450px] lg:w-[500px] rounded-t-[30px] rounded-b-0 sm:rounded-[20px]"
    >
      <h3 className="text-[25px] font-dewi font-extrabold text-center mb-[10px]">
        Забронировано
      </h3>

      <p className="text-sm font-normal text-center mb-5">
        Квартира забронирована. У Вас в этом месяце <br />
        осталось {bookingCount <= 0 ? 0 : count} бронирований.
      </p>

      <ButtonForm
        text={"Отлично"}
        onClick={setIsOpen}
        className="bg-accent-default py-[16px] w-full
             text-white-default text-center flex justify-center hover:bg-accent-focus "
      />
    </Modal>
  );
};

export default BookingModalCompleted;
