import React, { Dispatch, SetStateAction } from "react";
import PageTitle from "components/PageTitle";
import { ActiveClientToggler } from "modules/BaseClients/BaseClientsFilters";

interface Props {
	title?: string;
	activeText?: string;
	allText?: string;
  activeClient: ActiveClientToggler;
  setActiveClientFilter: Dispatch<SetStateAction<ActiveClientToggler>>;
  hasTitle: boolean;
}

export const HeaderTitleToggle = ({
	title = "Название",
	activeText = "Активные",
	allText = "Все",
  activeClient,
  setActiveClientFilter,
  hasTitle,
}: Props) => {
  return (
    <div className="flex justify-between items-center pb-[10px]">
      {hasTitle ? <PageTitle text={title} /> : null}

      <div className="lg:w-auto w-full">
        <div className="p-[1.5px] sm:p-[3.5px] bg-white-default flex rounded-[32px]  relative">
          <div
            onClick={() => setActiveClientFilter(ActiveClientToggler.ACTIVE)}
            className={`flex-1 text-[14px] font-normal py-[7px] md:py-[13px] px-[13px] 
            items-center flex justify-center
            md:px-[24px] z-10 transition ease-in-out delay-100 cursor-pointer ${
              activeClient === ActiveClientToggler.ACTIVE &&
              "bg-accent-default text-white-default"
            } rounded-[32px] text-center `}
          >
						{activeText}
          </div>
          <div
            onClick={() => setActiveClientFilter(ActiveClientToggler.ALL)}
            className={`flex-1 text-[14px] font-normal z-10 py-[7px] md:py-[13px] px-[13px] 
            items-center flex justify-center
            md:px-[24px] transition ease-in-out delay-100 cursor-pointer md:whitespace-nowrap ${
              activeClient === ActiveClientToggler.ALL &&
              "bg-accent-default text-white-default"
            } rounded-[32px] text-center `}
          >
						{allText}
          </div>
        </div>
      </div>
    </div>
  );
};
