import React from "react";

const PersonIcon = ({ color = "#37465B" }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0682 4.00966C12.0682 5.65648 10.6742 7.12491 9 7.12491C7.32582 7.12491 5.93182 5.65648 5.93182 4.00966C5.93182 2.32695 7.35982 0.75 9 0.75C10.6402 0.75 12.0682 2.32695 12.0682 4.00966ZM17.25 13.7779C17.25 14.5917 16.7954 15.4261 15.5056 16.0984C14.191 16.7836 12.0781 17.2502 9 17.2502C5.92186 17.2502 3.80897 16.7836 2.49439 16.0984C1.20459 15.4261 0.75 14.5917 0.75 13.7779C0.75 13.0743 1.38661 12.2069 2.95458 11.4713C4.46298 10.7636 6.59955 10.3056 9 10.3056C11.4005 10.3056 13.537 10.7636 15.0454 11.4713C16.6134 12.2069 17.25 13.0743 17.25 13.7779Z"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default PersonIcon;
