import { apiService } from "..";
import { News, NewsCategories, NEWSRequest, NewsResponseType } from "./type";
import { MetaData } from "../exception";

export const addTagTypes = ["news", "categories"] as const;

export const newsApiHooks = apiService
  .enhanceEndpoints({ addTagTypes })
  .injectEndpoints({
    endpoints: (build) => ({
      getNews: build.query<MetaData<NewsResponseType>, string | void>({
        query: (query) => ({
          path: query ? `/news?${query}` : "/news",
        }),
        providesTags: ["news"],
      }),
      getNewsBanner: build.query<MetaData<NewsResponseType>, string | void>({
        query: (query) => ({
          path: query ? `/news/banner?${query}` : `/news/banner`,
        }),
        providesTags: ["news"],
      }),
      createNews: build.mutation<MetaData<News>, NEWSRequest>({
        query: (data) => ({
          path: "/news",
          method: "POST",
          body: { meta: {}, data },
        }),
        invalidatesTags: ["news"],
      }),
      updateNews: build.mutation<
        MetaData<News>,
        { data: NEWSRequest; id: number }
      >({
        query: ({ data, id }) => ({
          path: `/news/${id}`,
          method: "PUT",
          body: { meta: {}, data },
        }),
        invalidatesTags: ["news"],
      }),
      getNewsAll: build.query<MetaData<NewsResponseType>, string | null>({
        query: (query) => ({
          path: query ? `/news?is_draft=true&${query}` : `/news?is_draft=true `,
        }),
        providesTags: ["news"],
      }),
      getOneNews: build.mutation<MetaData<News>, number>({
        query: (id) => ({
          path: `/news/${id}`,
        }),
        invalidatesTags: ["news"],
      }),
      getNewsCategories: build.query<MetaData<NewsCategories[]>, void>({
        query: () => ({
          path: `/news/categories`,
        }),
        providesTags: ["categories"],
      }),
      toggleLikeNews: build.mutation<MetaData<{ isliked: boolean }>, number>({
        query: (id) => ({
          path: `/news/toggle-like`,
          method: "POST",
          body: { meta: {}, data: { id } },
        }),
        invalidatesTags: ["news"],
      }),
      toggleIsDraftInNews: build.mutation<
        MetaData<{ is_draft: boolean }>,
        number
      >({
        query: (id) => ({
          path: `/news/toggle-draft`,
          method: "POST",
          body: { meta: {}, data: { news_id: id } },
        }),
        invalidatesTags: ["news"],
      }),
      deleteNews: build.mutation<MetaData<News>, number>({
        query: (id) => ({
          path: `/news/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["news"],
      }),
      categoryCreate: build.mutation<MetaData, string>({
        query: (name) => ({
          path: `/news/categories`,
          method: "POST",
          body: { meta: {}, data: { name } },
        }),
        invalidatesTags: ["categories"],
      }),
      deleteCategory: build.mutation<MetaData, number>({
        query: (id) => ({
          path: `/news/categories/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["categories"],
      }),
      updateCategory: build.mutation<MetaData, { name: string; id: number }>({
        query: ({ name, id }) => ({
          path: `/news/categories/${id}`,
          method: "PUT",
          body: { meta: {}, data: { name } },
        }),
        invalidatesTags: ["categories"],
      }),
    }),
  });

export const {
  useGetNewsQuery,
  useGetNewsBannerQuery,
  useCreateNewsMutation,
  useUpdateNewsMutation,
  useGetNewsAllQuery,
  useGetOneNewsMutation,
  useToggleLikeNewsMutation,
  useGetNewsCategoriesQuery,
  useToggleIsDraftInNewsMutation,
  useDeleteNewsMutation,
  useCategoryCreateMutation,
  useDeleteCategoryMutation,
  useUpdateCategoryMutation,
} = newsApiHooks;
