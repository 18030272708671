import moment from "moment";
import { useCallback } from "react";

const useFormattedDate = () => {
  const formattedDate = useCallback((dateString: string, timing = false) => {
    if (timing) {
      return moment(dateString).format("DD.MM.YYYY HH:mm");
    } else {
      return moment(dateString).format("DD.MM.YYYY");
    }
  }, []);

  return formattedDate;
};

export default useFormattedDate;
