import React from "react";
import { Apartment } from "./dataTransformer";
import { useNavigate } from "react-router";
import { handleFormatPrice } from "hooks/handleFormatPrice";

interface propsType {
  data: Apartment;
}

const ChessCard = ({ data }: propsType) => {
  const { price, size, number, status, id, is_sold } = data;
  const navigate = useNavigate();

  const formatedprice = handleFormatPrice(price);

  const handleNavigateToDeatails = () => {
    if (!is_sold) navigate(`/apartments/card/${id}`);
  };

  return (
    <div
      className={`bg-white-default rounded-[8px] p-[10px] w-[200px] flex flex-col gap-[16px] 
      ${is_sold ? "opacity-50 cursor-default" : "cursor-pointer"}`}
      onClick={handleNavigateToDeatails}
    >
      <div className="flex justify-between">
        <div
          className={`min-w-[46px] h-[31px] px-[3px] rounded-[5px] flex 
      items-center justify-center 
      text-[16px] font-medium ${
        is_sold
          ? "bg-[#D9D9D9] text-accent-default"
          : "bg-[#A0CB3E] text-white-default "
      } `}
        >
          {number}С
        </div>
        <span className="text-[16px] leading-[20px] font-normal">
          №{number}
        </span>
      </div>
      <div className="text-[14px] text-accent-default font-normal leading-[18px] flex flex-col gap-[5px]">
        <p className={`"text-[#E7B400]"}`}>
          {status === "free" ? `${formatedprice}` : "Забронировано"}
        </p>
        <p>
          {size} m<sup>2</sup>
        </p>
      </div>
    </div>
  );
};

export default ChessCard;
