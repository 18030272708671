import React, { useEffect } from "react";
import ButtonForm from "../../../../components/Button/ButtonForm";
import PhoneIcon from "../../../../components/constant/icons/PhoneIcon";

import {
  RegisterFormState,
  agentNotExistFormDataType,
  userAgreementDataType,
} from "../../types";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import TextField from "../../../../components/Form/TextField/TextField";
import Dropdown from "components/Form/Dropdown/Dropdown";
import Label from "components/Form/Label";
import { getCityByIp, useGetCitiesQuery } from "data/api/city";
import { useRequestAgencyMutation } from "data/api/auth";
import { yupResolver } from "@hookform/resolvers/yup";
import Loader from "components/Loader";
import { ApiException } from "data/api/exception";
import { useFormatForDropDownOptions } from "hooks/useDropDownOption";
import showToast from "utils/showToast";
import { schemaRequestAgent } from "utils/schemas";
import { Step } from "enums/authEnums";
import MaskComponent from "components/MaskInput/MaskComponent";

interface PropsType {
  setFormState: React.Dispatch<React.SetStateAction<RegisterFormState>>;
  title?: React.ReactNode;
}

const NoteExistInListForm = ({ setFormState, title }: PropsType) => {
  const res = useGetCitiesQuery();
  const options = useFormatForDropDownOptions(res?.data?.data ?? []);
  const [mutate, { isLoading }] = useRequestAgencyMutation();

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<agentNotExistFormDataType>({
    resolver: yupResolver<userAgreementDataType | any>(schemaRequestAgent),
  });

  const onSubmit: SubmitHandler<agentNotExistFormDataType> = async (data) => {
    const user_id = Number(localStorage.getItem("userId"));
    try {
      const { data: res, error } = await mutate({
        data: {
          ...data,
          user_id,
          city_id: data.city_id?.value,
          ownerPhone: data.ownerPhone,
        },
      });
      if (res?.data.accessToken) {
        localStorage.setItem("token", res.data.accessToken);
        setFormState({ step: Step.SUCCESS_MESSAGE });
        localStorage.removeItem("register_status");
        localStorage.removeItem("registerType");
        return;
      }
      if (error?.message) {
        showToast(error.message, "error");
        return;
      }
    } catch (err) {
      const error = err as ApiException;
      console.log("err", error);
    }
  };

  useEffect(() => {
    async function initCity() {
      const city = await getCityByIp();
      if (city.location) {
        const matchedOption = options.find((el) =>
          city.location?.value.toLowerCase().includes(el.label.toLowerCase())
        );

        if (matchedOption) {
          console.log(matchedOption);
          // @ts-ignore
          setValue("city_id", matchedOption);
        } else {
          const defOption = options.find((el) => el.label === "Екатеринбург");

          if (defOption) {
            // @ts-ignore
            setValue("city_id", defOption);
          }
        }
      } else {
        const defOption = options.find((el) => el.label === "Екатеринбург");

        if (defOption) {
          // @ts-ignore
          setValue("city_id", defOption);
        }
      }
    }

    if (options.length > 0) {
      initCity();
    }
  }, [options, setValue]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-[17px]"
    >
      <h3 className="text-[25px] text-accent-default font-dewi font-extrabold leading-[30px] mb-[10px]">
        {title}
      </h3>
      {isLoading && <Loader />}
      <div className="flex flex-col gap-[17px]">
        <Label label="Основной город работы">
          <Controller
            name="city_id"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Dropdown
                name="city_id"
                options={options}
                errors={errors}
                value={value}
                onChange={onChange}
                placeholder="Город не выбран"
              />
            )}
          />
        </Label>
        <Controller
          name="ownerFullName"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              value={value}
              className=""
              placeholder="Введите название агентства"
              label=" Название агентства недвижимости"
              type="text"
              name="ownerFullName"
              onChange={onChange}
              errors={errors}
            />
          )}
        />
        <Label label="Телефон контактного лица агентства недвижимости">
          <Controller
            name="ownerPhone"
            control={control}
            render={({ field: { onChange } }) => (
              <MaskComponent
                name="ownerPhone"
                errors={errors}
                imgLeft={<PhoneIcon />}
                value={getValues("ownerPhone")}
                onChange={(value) => onChange(value)}
              />
            )}
          />
        </Label>
        <div className="flex flex-col gap-[4px]">
          <Controller
            name="title"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                value={value}
                className=""
                placeholder="ФИО контактного лица"
                label="ФИО контактного лица от агентства недвижимости"
                type="text"
                name="title"
                onChange={onChange}
                errors={errors}
              />
            )}
          />
          <span className="text-[12px] text-accent-default leading-[14.03px]">
            Введите ФИО контактного лица от агентства недвижимости
          </span>
        </div>
      </div>
      <div className="flex flex-col gap-[10px] mt-[10px]">
        <ButtonForm
          className="bg-accent-default py-[12px] w-full
             text-white-default text-center flex justify-center hover:bg-accent-focus "
          text="Далее"
          type="submit"
        />
        <ButtonForm
          className=" bg-bg-default py-[12px] w-full
             text-accent-default text-center flex justify-center hover:bg-accent-default hover:text-white-default"
          text="Назад"
          onClick={() => setFormState({ step: Step.SELECT_REGISTER_TYPE })}
        />
      </div>
    </form>
  );
};

export default NoteExistInListForm;
