import { apiService } from "..";
import { calculateBankItemData } from "../../../store/CalculatorReducer/type";
import { CalculateIpotekaData, MortgageResponseDto } from "./type";
import { formMortgageData } from "../../../modules/Calculator/MortgageModal";
import { MetaData } from "../exception";

export const addTagTypes = ["calculate"] as const;

export const calculateApiHooks = apiService
  .enhanceEndpoints({ addTagTypes })
  .injectEndpoints({
    endpoints: (build) => ({
			getCalculateList: build.mutation<MetaData<CalculateIpotekaData[]>, calculateBankItemData>({
        query: (data) => ({
          path: `/calculator`,
          body: { meta: {}, data: data },
          method: "POST",
        }),
      }),
			applicationIpoteka: build.mutation<MortgageResponseDto, formMortgageData>({
        query: (data) => ({
          path: `/mortgage-request`,
          body: { meta: {}, data: data },
          method: "POST",
        }),
      }),
    }),
  });

export const {
  useGetCalculateListMutation,
  useApplicationIpotekaMutation,
} = calculateApiHooks;
