import ThreeDotLoader from "components/Loader/ThreeDotLoader";
import { UserRole } from "enums/users";
import React from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "store";
import { selectUser, selectUserIsLoading } from "store/UserReducer/selectors";

interface PrivateRouteProps {
  element: JSX.Element;
  roles: UserRole[];
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element, roles = [] }) => {
  const user = useAppSelector(selectUser);
  const isLoading = useAppSelector(selectUserIsLoading);

  if (isLoading)
    return (
      <div className="bg-bg-default h-[100vh] w-full">
        <ThreeDotLoader />
      </div>
    );

  // if (!user) {
  //   return <Navigate to="/sign-in" replace />;
  // }

  if (!user?.data.is_verified) {
    return <Navigate to="/waiting" replace />;
  }

  const currentRole = user?.data.role;

  if (currentRole) {
    if (roles.length === 0 || roles.includes(currentRole)) {
      return element;
    }
  }

  return <Navigate to="/" replace />;
};

export default PrivateRoute;
