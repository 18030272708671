import React from "react";
import { IContact, WorkSchedule } from "data/api/contact/type";
import { formatPhoneNumber } from "hooks/useFormatInput";

interface Props {
  contact: IContact;
}

const Contact = ({ contact }: Props) => {
  const weekDay: Record<WorkSchedule["weekday"], string> = {
    0: "Пн",
    1: "Вт",
    2: "Ср",
    3: "Чт",
    4: "Пт",
    5: "Сб",
    6: "Вс",
  };
  return (
    <div className="w-[320px] sm:w-[406px] text-accent-default flex flex-col gap-[15px] text-sm font-normal">
      <h2 className=" text-2xl font-semibold">{contact.title}</h2>
      <div className="flex flex-col gap-[5px] w-[284px]">
        <p className="">{contact.address.title}</p>
        <a
          className=" no-underline text-blue-default"
          href={contact.address_link}
          target={"_blank"}
        >
          Как проехать
        </a>
      </div>
      {contact.work_schedule?.map((item) => {
        if (item.start_time && item.end_time) {
          return (
            <p>
              {weekDay[item.weekday]} {item.start_time + ""}-
              {item.end_time + ""}
            </p>
          );
        }
      })}

      {contact.phone_number.map((item) => {
        if (item.length >= 11) {
          return <p>Телефон: +7 {formatPhoneNumber(item)}</p>;
        }
      })}
    </div>
  );
};

export default Contact;
