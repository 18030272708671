import React, { useEffect, useState } from "react";
import Dropdown from "../../components/Form/Dropdown/Dropdown";
import PencilIcon from "../../components/constant/icons/PencilIcon";
import { useNavigate } from "react-router-dom";
import { cityItem } from "./index";
import { CloseIcon } from "yet-another-react-lightbox";
import NewsPageCard from "../../components/Cards/NewsPageCard";
import ButtonForm from "../../components/Button/ButtonForm";
import PlusIcon from "../../components/constant/icons/PlusIcon";
import NewsCreateEditModal from "./NewsCreateEditModal";
import NewsPreviewModal from "./NewsPreviewModal";
import NewsCategoryModal from "./NewsCategoryModal";
import { useWindowSize } from "../../hooks/useWindowSize";
import {
  getIsOpenCreateNews,
  handleOpenCreateNews,
} from "../../store/NewsReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  useDeleteNewsMutation,
  useGetNewsAllQuery,
  useGetNewsCategoriesQuery,
  useToggleIsDraftInNewsMutation,
} from "data/api/news";
import moment from "moment";
import { News, NewsCategories } from "data/api/news/type";
import Loader from "components/Loader";
import { useGetCitiesQuery } from "data/api/city";
import { useFormatForDropDownOptions } from "hooks/useDropDownOption";
import { objectToQueryString } from "utils/objectToQuery";
import NotFound from "components/NotFound";
import { RootState } from "store";
import AnimationFrame from "components/AnimationFrame.tsx";

const NewsEditPage = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [activeCategory, setActiveCategory] = useState<number>(0);

  const [query, setQuery] = useState<string>("");

  const { data: newsAll, isLoading } = useGetNewsAllQuery(query);

  const { data: newsCategories } = useGetNewsCategoriesQuery();

  const { data: cities } = useGetCitiesQuery();

  const cityOptions = useFormatForDropDownOptions(cities?.data ?? []);

  const [mutateIsDraft] = useToggleIsDraftInNewsMutation();

  const [mutateDelete] = useDeleteNewsMutation();

  const isCreateNewsItem = useSelector(getIsOpenCreateNews);

  const selection = useSelector((state: RootState) => state);

  const { isMd } = useWindowSize();

  const defaultSelected: News = {
    cover_image: "",
    title: "",
    content: "",
    views_count: 0,
    likes_count: 0,
    created_at: "",
    id: 1,
    primary_category_id: 0,
  };

  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState(false);

  const [isOpenCategoryModal, setIsOpenCategoryModal] = useState(false);

  const [selected, setSelected] = useState<News | null>(null);

  const [selectCity, setSelectCity] = useState<cityItem>();

  useEffect(() => {
    const query = objectToQueryString({
      city_id: selectCity?.value,
      category_id: activeCategory,
    });
    setQuery(query);
  }, [activeCategory, selectCity]);

  useEffect(() => {
    const city = cityOptions.find(
      (item) => item.value === selection.header.city_id
    );
    setSelectCity(city as cityItem);
  }, [selection.header.city_id]);

  const handleToggleIsDraft = async (id?: number) => {
    if (id) await mutateIsDraft(id);
  };

  const handleDelete = async (id?: number) => {
    if (id) await mutateDelete(id);
  };

  const editBlock = (item: News) => {
    return {
      toggle: {
        isToggle: item.is_draft,
        setIsToggle: (value: boolean) => handleToggleIsDraft(item?.id),
      },
      editNews: () => {
        setSelected(item);
        dispatch(handleOpenCreateNews(true));
      },
      deleteNews: () => handleDelete(item?.id),
    };
  };

  function handleCategory(item: NewsCategories) {
    setActiveCategory(item?.id);
  }

  return (
    <AnimationFrame>
      <div className={"bg-bg-default min-h-[50vh]"}>
        <div className="br-container py-[20px]">
          <div
            className={"hidden lg:flex items-center justify-between mb-[15px]"}
          >
            <h1 className={"text-[25px] font-dewi font-extrabold"}>
              Редактор новостей
            </h1>

            <ButtonForm
              leftIcon={<PlusIcon />}
              text={"Создать"}
              onClick={() => dispatch(handleOpenCreateNews(true))}
              className={
                "p-3 bg-accent-default rounded text-white-default max-w-[259px] w-full flex justify-center gap-[12px!important]"
              }
            />
          </div>

          <div className={"lg:mt-[18px]"}>
            <div className={"flex justify-between items-center gap-[50px]"}>
              <div className={"hidden lg:flex"}>
                <Dropdown
                  placeholder={"Город"}
                  value={selectCity}
                  options={[{ value: "", label: "Все" }, ...cityOptions]}
                  className={" select-bg-transparent min-w-[100px]"}
                  onChange={setSelectCity}
                />
              </div>

              <div
                className={
                  "flex items-center gap-[10px] flex-nowrap whitespace-nowrap overflow-auto toolbar-scroll pb-3"
                }
              >
                <ButtonForm
                  text={<PencilIcon color={"#FFFFFF"} size={isMd ? 18 : 24} />}
                  onClick={() => setIsOpenCategoryModal(true)}
                  className={
                    "md:p-[8px!important] p-[6px!important] bg-accent-default rounded-full"
                  }
                />

                {[{ name: "Все", id: 0 }, ...(newsCategories?.data || [])].map(
                  (item) => (
                    <div
                      key={`category-item-${item.id}`}
                      onClick={() => handleCategory(item)}
                      className={`
										md:py-[11px] py-[7px] 
										md:px-[24px] px-[15px] 
										rounded-full text-sm font-normal 
										md:leading-[18px] leading-[16px] 
										cursor-pointer
										border md:border-white-default border-[#D7DCE4]
										
										${
                      activeCategory === item.id
                        ? "bg-accent-default text-white-default"
                        : "bg-white-default"
                    }
										`}
                    >
                      {item.name}
                    </div>
                  )
                )}
              </div>
            </div>
            {isLoading && (
              <div className=" relative min-h-[30vh]">
                <Loader />
              </div>
            )}

            {newsAll?.data?.length ? (
              <div
                className={
                  "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[15px] md:mt-[20px] mt-[15px] xl:mt-[30px]"
                }
              >
                {newsAll.data.map((item) => (
                  <NewsPageCard
                    key={`news-block-item-${item?.id}`}
                    imageSrc={
                      process.env.REACT_APP_FILES_URL +
                        "/" +
                        item?.cover_image || ""
                    }
                    title={item?.title || ""}
                    watched={item?.views_count}
                    likes={item?.likes_count}
                    isLikeEnabled={item?.is_like_enabled}
                    date={`${
                      item?.published_at
                        ? moment(item.published_at).format("DD.MM.YYYY")
                        : ""
                    }`}
                    category={[
                      item?.primary_category?.name || "",
                      item?.secondary_category?.name || "",
                    ]}
                    onClick={(event) => {
                      if (["button", "checkbox"].includes(event.target.type))
                        return;

                      navigate(`/news/view/${item?.id}`);
                    }}
                    edit={editBlock(item)}
                  />
                ))}
              </div>
            ) : (
              !isLoading && <NotFound title="Новостей не найдено" />
            )}
          </div>
          <div className="hidden fixed right-[30px] bottom-[30px] lg:flex items-center justify-center">
            <div
              className={
                "rounded-full bg-red-default flex items-center justify-center p-3 cursor-pointer"
              }
              onClick={() => navigate("/news")}
            >
              <CloseIcon color={"#FFFFFF"} />
            </div>
          </div>
        </div>
      </div>

      <NewsCreateEditModal
        isOpen={isCreateNewsItem}
        selected={selected}
        setIsOpen={(isDelete) => {
          isDelete && setSelected(null);
          dispatch(handleOpenCreateNews(false));
        }}
        setSelect={(value) => {
          setSelected(value);
          setIsOpenPreviewModal(!isOpenPreviewModal);
        }}
      />

      <NewsPreviewModal
        isOpen={isOpenPreviewModal}
        selected={selected}
        setIsOpen={(value) => {
          setIsOpenPreviewModal(false);

          value
            ? setSelected(defaultSelected)
            : dispatch(handleOpenCreateNews(true));
        }}
        setSelect={setSelected}
      />

      <NewsCategoryModal
        isOpen={isOpenCategoryModal}
        setIsOpen={() => setIsOpenCategoryModal(false)}
        propsList={newsCategories?.data}
      />
    </AnimationFrame>
  );
};

export default NewsEditPage;
