import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Dropdown from "../../components/Form/Dropdown/Dropdown";
import Label from "../../components/Form/Label";
import ButtonForm from "../../components/Button/ButtonForm";
import TextField from "../../components/Form/TextField/TextField";
import { useWindowSize } from "../../hooks/useWindowSize";
import { calculateBankItemData } from "../../store/CalculatorReducer/type";
import { getFilterData, handleSetFilters } from "../../store/CalculatorReducer";
import { useDispatch, useSelector } from "react-redux";
import MortgageModal from "./MortgageModal";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useGetOnePremiseQuery } from "../../data/api/premises";

interface CalculatorFilterData {
  filterData: any;
  setFiltered: (data: calculateBankItemData) => void;
  isLoading: boolean;
}

const CalculatorFilter = ({
  filterData,
  setFiltered,
  isLoading,
}: CalculatorFilterData) => {
  const { isMd } = useWindowSize();

  const dispatch = useDispatch();
  const getFilter = useSelector(getFilterData);

  const { id } = useParams();

  const { data } = useGetOnePremiseQuery(id);

  const [isOpenMortgageModal, setIsOpenMortgageModal] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    setValue,
    watch,
    setError,
  } = useForm<calculateBankItemData>({
    defaultValues: {
      // price_month: getFilter?.price_month,
      // currency_apart: getFilter?.currency_apart,
      // percent: getFilter?.percent || 30,
      // price_all: getFilter?.price_all,
      project: getFilter?.project,
      premise_price: 7000000,
      ipoteka_time: 120,
      initial_payment: (7000000 / 100) * 30,
    },
  });

  const onSubmit: SubmitHandler<calculateBankItemData> = async (data) => {
    const updateData = {
      ...data,
      ipoteka_time: +data.ipoteka_time,
      initial_payment: +data.initial_payment,
      premise_price: +data.premise_price,
      project: undefined,
      // precent: 0,
      // initial: {
      //   max: data.initial_payment?.max || filterData.maxInitial,
      //   min: data.initial_payment?.min || filterData.minInitial,
      // },
      // period: {
      //   max: data.ipoteka_time?.max || filterData.maxMonth,
      //   min: data.ipoteka_time?.min || filterData.minMonth,
      // },
    };

    if (updateData.premise_price < updateData.initial_payment)
      return setError("premise_price", {
        type: "error",
        message: "Стоимость не должна быть меньше первоначального взноса",
      });

    setFiltered(updateData);
    dispatch(handleSetFilters(updateData));
  };

  const getValue = (
    opts: { label: string; value: string | number }[],
    val: number | string | undefined
  ) => {
    if (val) {
      return opts.filter((o) => o.value == val);
    }
    return null;
  };

  useEffect(() => {
    if (data?.data && id) {
      const submitData = {
        premise_price: +data?.data?.price,
        initial_payment: (+data?.data?.price / 100) * 30,
        ipoteka_time: 120,
      };

      setValue("premise_price", submitData.premise_price);
      setValue("initial_payment", submitData.initial_payment);
      setValue("ipoteka_time", submitData.ipoteka_time);

      onSubmit(submitData);
    }
  }, []);

  useEffect(() => {
    if (
      watch("premise_price") &&
      watch("initial_payment") &&
      watch("ipoteka_time")
    ) {
      const submitData = {
        premise_price: watch("premise_price"),
        initial_payment: watch("initial_payment"),
        ipoteka_time: watch("ipoteka_time"),
      };

      onSubmit(submitData);
    }
  }, []);

  return (
    <div
      className={
        "bg-white-default rounded lg:max-w-[38%] w-full lg:p-[30px] py-[20px] px-[15px]"
      }
    >
      {!isMd && (
        <h1
          className={
            "text-[25px] font-dewi font-extrabold mb-[30px] text-accent-default"
          }
        >
          Ипотечный калькулятор
        </h1>
      )}

      <form
        onSubmit={handleSubmit(onSubmit)}
        className={"flex flex-col gap-[20px] pr-[10px]"}
      >
        <Label label="Проект">
          <Controller
            name="project"
            control={control}
            rules={{
              required: {
                value: false,
                message: "Проект обязателен для заполнения",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <Dropdown
                name="project"
                value={getValue(filterData.optionsProject, value)}
                options={filterData.optionsProject}
                onChange={(value) => onChange(value.value)}
                placeholder="Проект не выбран"
                errors={errors}
                isSearchable
              />
            )}
          />
        </Label>

        <Label label="Стоимость квартиры">
          <div className={"flex gap-[10px]"}>
            <Controller
              name="premise_price"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Стоимость обязателен для заполнения",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  value={value}
                  className="w-full"
                  placeholder="Введите стоимость"
                  name="premise_price"
                  onChange={(e) => {
                    const updateValue = +e.target.value.replace(/[^0-9]/g, "");

                    onChange(updateValue <= 0 ? "" : updateValue);
                  }}
                  imgRight={"Руб."}
                  errors={errors}
                />
              )}
            />
            {/*<Controller*/}
            {/*  name="currency_apart"*/}
            {/*  control={control}*/}
            {/*  rules={{*/}
            {/*    required: {*/}
            {/*      value: true,*/}
            {/*      message: "Валюта обязателена для заполнения",*/}
            {/*    },*/}
            {/*  }}*/}
            {/*  render={({ field: { onChange, value } }) => (*/}
            {/*    <Dropdown*/}
            {/*      name="currency_apart"*/}
            {/*      options={filterData.optionsCurrency}*/}
            {/*      onChange={(value) => onChange(value.value)}*/}
            {/*      placeholder="Валюта не выбрана"*/}
            {/*      errors={errors}*/}
            {/*      className={"md:min-w-[37%] min-w-[45%]"}*/}
            {/*    />*/}
            {/*  )}*/}
            {/*/>*/}
          </div>
        </Label>

        {/*<Label label="Процентная ставка">*/}
        {/*  <Controller*/}
        {/*    name="percent"*/}
        {/*    control={control}*/}
        {/*    rules={{*/}
        {/*      required: {*/}
        {/*        value: true,*/}
        {/*        message: "Процентная ставка обязателена для заполнения",*/}
        {/*      },*/}
        {/*    }}*/}
        {/*    render={({ field: { onChange, value } }) => (*/}
        {/*      <TextField*/}
        {/*        value={value}*/}
        {/*        className="w-full"*/}
        {/*        placeholder="Введите процент"*/}
        {/*        type="number"*/}
        {/*        name="percent"*/}
        {/*        onChange={onChange}*/}
        {/*        imgRight={<PercentIcon />}*/}
        {/*      />*/}
        {/*    )}*/}
        {/*  />*/}
        {/*</Label>*/}

        <Label label={"Первоначальный взнос"}>
          <Controller
            name="initial_payment"
            control={control}
            rules={{
              required: {
                value: false,
                message: "Взнос обязателен для заполнения",
              },
            }}
            render={({ field: { onChange, value } }) => (
              // <MultiRangeSlider
              // 	step={1}
              //   min={filterData.minInitial}
              //   max={filterData.maxInitial}
              //   onChange={onChange}
              // />
              <TextField
                value={value}
                className="w-full"
                placeholder="Введите цену"
                // type="number"
                name="initial_payment"
                onChange={(e) => {
                  const updateValue = +e.target.value.replace(/[^0-9]/g, "");

                  onChange(updateValue <= 0 ? "" : updateValue);
                }}
                imgRight={"Руб."}
                errors={errors}
              />
            )}
          />
        </Label>

        <Label label={"Срок кредита"}>
          <Controller
            name="ipoteka_time"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Срок обязателен для заполнения",
              },
              min: {
                value: 1,
                message: "Срок обязателен для заполнения",
              },
            }}
            render={({ field: { onChange, value } }) => (
              // <MultiRangeSlider
              // 	step={1}
              //   min={filterData.minMonth}
              //   max={filterData.maxMonth}
              //   onChange={onChange}
              // />
              <TextField
                value={value}
                className="w-full"
                placeholder="Введите срок"
                type="number"
                name="ipoteka_time"
                onChange={onChange}
                errors={errors}
              />
            )}
          />
        </Label>

        {/*{filterData.calculate && (*/}
        {/*  <div className={"p-[20px] bg-bg-default flex"}>*/}
        {/*    {filterData.calculate.price_all && (*/}
        {/*      <div className={"w-full"}>*/}
        {/*        <p*/}
        {/*          className={*/}
        {/*            "text-sm font-normal text-accent-default mb-[10px]"*/}
        {/*          }*/}
        {/*        >*/}
        {/*          Общая стоимость*/}
        {/*        </p>*/}

        {/*        <p className={"font-medium text-accent-default"}>*/}
        {/*          {filterData.calculate.price_all} руб*/}
        {/*        </p>*/}
        {/*      </div>*/}
        {/*    )}*/}

        {/*    {filterData.calculate.price_month && (*/}
        {/*      <div className={"w-full"}>*/}
        {/*        <p*/}
        {/*          className={*/}
        {/*            "text-sm font-normal text-accent-default mb-[10px]"*/}
        {/*          }*/}
        {/*        >*/}
        {/*          Оплата в месяц*/}
        {/*        </p>*/}

        {/*        <p className={"font-medium text-accent-default"}>*/}
        {/*          {filterData.calculate.price_month} руб*/}
        {/*        </p>*/}
        {/*      </div>*/}
        {/*    )}*/}
        {/*  </div>*/}
        {/*)}*/}

        <div className="flex flex-col gap-[10px] mt-[10px]">
          <ButtonForm
            disabled={!isValid}
            text="Рассчитать ипотеку"
            type="submit"
            className={`py-3 px-6 bg-white-default 
						flex-1 flex justify-center 
						border rounded 
						${
              !isValid
                ? "border-red-disabled text-red-disabled"
                : "text-red-default border-red-default"
            }`}
            loading={isLoading}
          />

          <ButtonForm
            text="Заявка на ипотеку"
            type="button"
            className="py-3 px-6
							bg-white-default flex-1
							text-accent-default flex justify-center
							border border-accent-default rounded
							"
            onClick={() => setIsOpenMortgageModal(true)}
          />
        </div>
      </form>

      <MortgageModal
        isOpen={isOpenMortgageModal}
        setIsOpen={setIsOpenMortgageModal}
      />
    </div>
  );
};

export default CalculatorFilter;
