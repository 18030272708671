import React from "react";
import ImageUploadIcon from "components/constant/icons/ImageUploadIcon";

interface PropsType {
  title: string;
  requirementText: string;
  value?: string;
  handleImageUpload?: (value: React.ChangeEvent<HTMLInputElement>) => void;
  setValue?: (value: string) => void;
  disabled?: boolean;
}

const ImgUpload: React.FC<PropsType> = ({
  title,
  requirementText,
  value,
  handleImageUpload,
  disabled,
}) => {
  return (
    <div className="flex gap-[20px] items-center">
      <label
        className="flex flex-col justify-center border-2 border-dashed border-[#D7DCE4] rounded-md w-[136px] h-[90px] cursor-pointer overflow-hidden"
        htmlFor={`img-${title}`}
      >
        {value ? (
          <img
            src={value as string}
            alt="Uploaded"
            className="w-full h-auto"
            crossOrigin="anonymous"
          />
        ) : (
          <div className="flex flex-col items-center">
            <ImageUploadIcon width="34" height="33" color="#D7DCE4" />
          </div>
        )}
        <input
          id={`img-${title}`}
          type="file"
          accept="image/*"
          className="hidden"
          disabled={disabled}
          onChange={handleImageUpload}
        />
      </label>
      {title || requirementText ? (
        <div className="text-accent-default flex flex-col gap-[10px]">
          <p className="text-[14px] font-semibold leading-4">{title}</p>
          <p className="text-[12px] font-normal leading-[14px] w-[224px]">
            {requirementText}
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default ImgUpload;
