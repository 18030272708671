import { Deal } from "modules/Deal/type";
import { apiService } from "..";
import { MetaData } from "../exception";
import { DealStatus } from "enums/deals";

export const addTagTypes = ["leads", "leads2"] as const;

export const citiesApiHooks = apiService
  .enhanceEndpoints({ addTagTypes })
  .injectEndpoints({
    endpoints: (build) => ({
      getLeads: build.query<MetaData<Deal[], { statuses: string[] }>, string>({
        query: (query) => ({
          path: `/v3/leads?page=1&${query}`,
        }),
        providesTags: ["leads"],
      }),
      getLeads2Initial: build.query<
        MetaData<{ state: DealStatus; data: Deal[] }[], { statuses: string[] }>,
        string
      >({
        query: (query) => ({
          path: `/v3/leads?${query}&limit=10&is_initial=true`,
        }),
        keepUnusedDataFor: 0,
        providesTags: (_, __, args) => [{ type: "leads2", id: args }],
      }),
      getLeads2: build.query<MetaData<Deal[], { totalPage: number }>, string>({
        query: (query) => ({
          path: `/v3/leads?${query}&limit=10`,
        }),
        keepUnusedDataFor: 0,
      }),
    }),
  });

export const {
  useGetLeadsQuery,
  useLazyGetLeads2Query,
  useGetLeads2Query,
  useGetLeads2InitialQuery,
} = citiesApiHooks;
