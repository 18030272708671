import React from "react";
import EyeIcon from "../constant/icons/EyeIcon";
import LikeIcon from "../constant/icons/LikeIcon";
import ButtonForm from "../Button/ButtonForm";
import PencilIcon from "../constant/icons/PencilIcon";
import ToggleSwitcher from "../ToggleSwitcher";
import TrashIcon from "../constant/icons/TrashIcon";

interface CardProps {
  imageSrc: string;
  title: string;
  hashtag?: string[];
  likes?: number;
  watched?: number;
  date?: string;
  onClick?: (event: any) => void;
  edit?: any;
  item?: any;
  category?: string[];
  isLikeEnabled?: boolean;
  type?: string;
}

const NewsPageCard = ({
  imageSrc,
  title,
  likes,
  watched,
  date,
  onClick,
  edit,
  item,
  category,
  isLikeEnabled,
  type,
}: CardProps) => {
  return (
    <div
      className={`lg:min-w-full flex-1 bg-white-default rounded-lg overflow-hidden p-[10px] cursor-pointer mb-[10px]`}
      onClick={onClick}
    >
      <div className="w-full h-[160px] relative">
        <img
          src={imageSrc}
          alt={title}
          crossOrigin="anonymous"
          className="w-full h-full object-cover rounded"
        />

        {edit && (
          <div
            className={
              "absolute top-[5px] left-[5px] right-[5px] flex items-center justify-between"
            }
          >
            <ToggleSwitcher
              label=""
              isToggle={!edit.toggle?.["isToggle"]}
              setIsToggle={(value) => edit?.toggle.setIsToggle(!value)}
            />
            <div className={"flex gap-[5px]"}>
              <ButtonForm
                text={
                  <span className={"pointer-events-none"}>
                    <PencilIcon />
                  </span>
                }
                onClick={(e) => {
                  e.stopPropagation();
                  edit.editNews(item);
                }}
                className={
                  "p-[5px!important] bg-white-default rounded-xl border border-bg-default cursor-pointer"
                }
              />
              <ButtonForm
                text={
                  <span className={"pointer-events-none"}>
                    <TrashIcon />
                  </span>
                }
                onClick={(e) => {
                  e.stopPropagation();
                  edit.deleteNews(item?.id);
                }}
                className={
                  "p-[5px!important] bg-white-default rounded-xl border border-bg-default cursor-pointer"
                }
              />
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col justify-between p-[10px]">
        <div>
          <div className={"flex gap-3"}>
            {category && !!category?.length && Array.isArray(category)
              ? category.map((item, idx) => (
                  <p
                    key={`category-item-${idx}`}
                    className={
                      "text-xs font-medium text-accent-default opacity-50 mb-[5px]"
                    }
                  >
                    {item}
                    {category.length - 1 > idx && !!category[idx + 1]
                      ? ", "
                      : ""}
                  </p>
                ))
              : null}
          </div>

          <h3 className="text-[16px] leading-[18.75px] text-accent-default mb-[40px] font-medium">
            {title}
          </h3>
        </div>

        <div className="flex gap-[15px] items-center justify-between text-[12px] font-medium leading-[16px] opacity-50">
          {date && (
            <span className="py-2 px-[14px] rounded-full border border-bg-focus">
              {date}
            </span>
          )}

          <div className={"flex gap-[15px]"}>
            {(watched || watched === 0) && (
              <div className="flex items-center justify-center gap-[8px] ">
                <EyeIcon color="#37465B" width="18" />
                <span>{watched}</span>
              </div>
            )}
            {(isLikeEnabled || type === "event") && (
              <>
                {(likes || likes === 0) && (
                  <div className="flex items-center justify-center gap-[8px] cursor-pointer">
                    <LikeIcon color={"#37465B"} />
                    <span>{likes}</span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsPageCard;
