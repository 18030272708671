import { api } from "../data/api";

type ApiResponse = {
  meta: {
    type: "SUCCESS" | "ERROR";
    params: Record<string, any>;
  };
  data: {
    filename: string;
  };
};

class MyUploadAdapter {
  private loader: any;

  constructor(loader: any) {
    this.loader = loader;
  }

  upload() {
    return this.loader.file.then(
      (file: File) =>
        new Promise(async (resolve, reject) => {
          const formData = new FormData();
          formData.append("image", file);
          try {
            this.loader.uploaded = true;

            const result: ApiResponse = await api.fetch({
              path: "/api/file-upload/image",
              method: "POST",
              body: formData,
            });

            if (result) {
              resolve({
                default:
                  process.env.REACT_APP_FILES_URL + "/" + result.data.filename, // need url image
              });
            } else {
              reject(`не удалось загрузить файл: ${file.name}.`);
            }
          } catch (error) {
            console.error("не удалось загрузить файл:", error);
          }
        })
    );
  }
}

export default function MyCustomUploadAdapterPlugin(editor: any) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader: any) => {
    return new MyUploadAdapter(loader);
  };
}
