export enum UserRole {
  ADMIN = "ADMIN", // Администрация
  AGENT = "AGENT", // Агент
  MANAGER = "MANAGER", // Менеджер
  NEW_MEMBER = "NEW_MEMBER", // Новый Пользователь
  HEAD_OF_AGENCY = "HEAD_OF_AGENCY", // Руководитель агентства
  OZK_MANAGER = "OZK_MANAGER", // Менеджер
  AFFILIATE_MANAGER = "AFFILIATE_MANAGER", // Партнерский менеджер
}
export const roleOptions = [
  { value: UserRole.ADMIN, label: "Администратор" },
  { value: UserRole.AFFILIATE_MANAGER, label: "Партнерский менеджер" },
  { value: UserRole.AGENT, label: "Агент" },
  { value: UserRole.HEAD_OF_AGENCY, label: "Руководитель агентства" },
  { value: UserRole.MANAGER, label: "Менеджер" },
  { value: UserRole.OZK_MANAGER, label: "Оператор" },
  { value: UserRole.NEW_MEMBER, label: "Новый Пользователь" },
];

export const mamberLocalName = {
  ADMIN: "Администратор",
  AGENT: "Агент",
  MANAGER: "Менеджер",
  NEW_MEMBER: "Новый Пользователь",
  HEAD_OF_AGENCY: "Руководитель агентства",
  OZK_MANAGER: "Оператор",
  AFFILIATE_MANAGER: "Партнерский менеджер",
};
