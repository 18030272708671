import React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";

import ButtonForm from "components/Button/ButtonForm";
import { resetState, toggleGenerateSelection } from "store/ApartmentReducer";
import { Primise } from "enums/primisesType";

const SelectedObjectBar = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const dispatch = useDispatch();

  const location = useLocation();

  const selection = useSelector((state: RootState) => state.apartment);

  const generateSelection = () => {
    dispatch(toggleGenerateSelection());
    dispatch(resetState());
  };

  const handleClick = () => {
    navigate("/apartments");
  };

  const isAppartmentDeatailsPage =
    location.pathname === `/apartments/card/${id}`;

  console.log("isAppartmentDeatailsPage", isAppartmentDeatailsPage);

  return (
    <div
      className={` ${
        !isAppartmentDeatailsPage && "br-container"
      }  md:h-[102px] sticky flex bottom-[60px] lg:bottom-0 z-0`}
    >
      {!isAppartmentDeatailsPage && (
        <div className="filter lg:min-w-[346px]"></div>
      )}

      <div className="w-full  bg-white-default border-boder-default border-t-[2px] lg:border-l-[2px] py-[15px] px-[10px] sm:px-[30px]">
        <div
          className={`md:flex items-center justify-between  ${
            isAppartmentDeatailsPage && "br-container"
          }`}
        >
          <div className="flex-1 text-accent-default">
            <h3 className=" opacity-50 font-medium">Выбрано объектов:</h3>
            <div className=" grid grid-cols-2 gap-1">
              <div className="flex gap-[5px]  font-normal">
                <h3 className=" font-medium text-[16px]">Квартир:</h3>
                {selection.count[Primise.APARTMENT] || 0}
              </div>
              <div className="flex gap-[8px] font-normal">
                <h3 className=" font-medium text-[16px]">Паркинг:</h3>
                {selection.count[Primise.PARKING] || 0}
              </div>
              <div className="flex gap-[8px] font-normal">
                <h3 className=" font-medium text-[16px]">Кладовые:</h3>
                {selection.count[Primise.STOREROOM] || 0}
              </div>
              <div className="flex gap-[8px] font-normal">
                <h3 className=" font-medium text-[16px]">Коммерческие :</h3>
                {selection.count[Primise.COMMERCIAL] || 0}
              </div>
            </div>
          </div>
          <div className="flex gap-[10px] mt-5 md:mt-0 flex-1">
            <ButtonForm
              onClick={generateSelection}
              text="Отменить выделение"
              className="border hidden border-accent-default mt-[1px] py-3 px-6
 hover:bg-accent-default text-[14px] flex-[2] lg:flex justify-center

  hover:text-white-default whitespace-nowrap"
            />
            {selection.storedData.length < 1 ? (
              <div className="flex-[3]">
                <ButtonForm
                  text="Далее"
                  className="border bg-accent-default mt-[1px] py-3 px-6
             hover:accent-accent-disabled  text-[14px] w-full flex justify-center
             text-white-default"
                  disabled
                />
              </div>
            ) : (
              <Link
                to={
                  isAppartmentDeatailsPage
                    ? `/apartments/info?ids=[${selection.storedData}]`
                    : `info?ids=[${selection.storedData}]`
                }
                className="flex-[3]"
              >
                <ButtonForm
                  text="Далее"
                  // onClick={handleClick}
                  className="border bg-accent-default mt-[1px] py-3 px-6
               hover:accent-accent-disabled text-[14px] w-full flex justify-center
               text-white-default"
                />
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectedObjectBar;
