import React, { useEffect, useState } from "react";
import TableContainer from "components/Form/Table";
import PageTitle from "components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { toggleItemInStore } from "store/ApartmentReducer";
import {
  Apartment,
  groupByProjectName,
} from "../ApartmentList/dataTransformer";
import { header } from "../data";
import { useNavigate } from "react-router";
import { getItemStatusParking } from "../../../components/Form/Table/utils";
import { Link } from "react-router-dom";
import { useWindowSize } from "../../../hooks/useWindowSize";
import RadioButton from "components/RadioSelectionButton";
import Loader from "components/Loader";
import NotFound from "components/NotFound";

interface porpsType {
  data: any;
  isLoading: boolean;
}
interface TranfsdormedDataType {
  id: string;
  floor: number;
  section: string;
  number: string;
  building: string;
  price: string;
  size: string;
  status: string;
}
interface dataType {
  [projectName: string]: TranfsdormedDataType[];
}

const ParkingOrStoreroom = ({ data, isLoading }: porpsType) => {
  const { isMd } = useWindowSize();
  const [apartmentData, setApartmentData] = useState<dataType[] | any>([]);

  const selection = useSelector((state: RootState) => state.apartment);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleSelectItem = (id: number) => {
    dispatch(toggleItemInStore({ id }));
  };

  const handleJumpToApartmentDetails = (id: number) => {
    navigate(`/apartments/card/${id}`);
  };

  const formatedPrice = (price: number) => {
    return new Intl.NumberFormat("ru-Ru", {
      style: "currency",
      currency: "RUB",
    }).format(price);
  };

  const itemExists = (id: number) => {
    return selection.storedData.includes(id);
  };

  useEffect(() => {
    const transformedData = Object.entries(groupByProjectName(data))?.map(
      ([projectName, tableData]) => {
        return {
          projectName,
          data: tableData?.map((item: Apartment) => ({
            id: item?.id,
            floor: item?.floor,
            section: item?.section?.name,
            number: item?.number,
            building: item?.building?.name,
            price: formatedPrice(Number(item?.price)),
            size: item?.size,
            status: item?.status,
            is_sold: item?.is_sold,
            name: item?.name,
            project_id: item?.project?.id,
          })),
        };
      }
    );

    setApartmentData(transformedData);
  }, [data]);

  function handleSortBy(
    sort_by: string,
    order_by: string,
    project_id?: number
  ) {
    if (project_id) {
      const currentSearchParams = new URLSearchParams(window.location.search);
      currentSearchParams.set("project_id", project_id?.toString());
      currentSearchParams.set("sort_by", sort_by);
      currentSearchParams.set("order_by", order_by);
      navigate(`/apartments?${currentSearchParams.toString()}`, {
        replace: true,
      });
    }
  }

  return (
    <>
      {isLoading && (
        <div className=" relative min-h-[80vh]">
          <Loader />
        </div>
      )}
      {apartmentData.length && !isLoading
        ? apartmentData.map(
            (item: { projectName: string; data: Apartment[] }, i: number) => (
              <div key={i}>
                <PageTitle
                  text={item?.projectName}
                  className="mb-[20px] mt-[30px]"
                />

                {isMd ? (
                  <div className={"flex flex-col gap-[10px]"}>
                    {item?.data.map((_item, _idx) => (
                      <div className="flex gap-2">
                        {selection.generateSelection && (
                          <RadioButton
                            onChange={() => handleSelectItem(_item?.id)}
                            checked={itemExists(_item.id)}
                          />
                        )}

                        <div
                          key={`apart-item-mobile-${_idx}`}
                          className={
                            "p-[15px] rounded-lg flex justify-between bg-white-default w-full"
                          }
                        >
                          <div className={"flex flex-col gap-2"}>
                            <Link
                              to={`/apartments/card/${_item?.id}`}
                              className={"text-sm text-blue-default underline"}
                            >
                              {_item?.name}
                            </Link>

                            <p className={"text-sm"}>
                              <strong>Этаж:</strong> {_item?.floor} |{" "}
                              {_item?.size} м<sup>2</sup>
                            </p>
                          </div>

                          <div className={"flex flex-col gap-2"}>
                            <p className={"text-sm"}>{_item?.price}</p>

                            <p className={"text-sm"}>
                              {getItemStatusParking(_item)}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <TableContainer
                    handleSortBy={handleSortBy}
                    header={header}
                    content={item?.data}
                    doSelectData={selection.generateSelection}
                    handleSelectTableData={handleSelectItem}
                    handleJumpToApartmentDetails={handleJumpToApartmentDetails}
                  />
                )}
              </div>
            )
          )
        : !isLoading && <NotFound />}
    </>
  );
};

export default ParkingOrStoreroom;
