import React, { useEffect, useState } from "react";
import ButtonForm from "../../components/Button/ButtonForm";
import Modal from "../../components/Modal";
import CloseWithBorderIcon from "../../components/constant/icons/CloseWithBorderIcon";
import Collapse from "../../components/Form/Collapse";
import RecordForShow from "components/BookingAndVisits/RecordForShowForm";
import BookingFrom from "components/BookingAndVisits/BookingFrom";
import { useRoles } from "hooks/useRoles";
import { useDispatch, useSelector } from "react-redux";
import {
  handleToggleDealsInfo,
  toggleBookingModal,
  toggleNotificationFormModal,
  toggleRecordForShowModal,
} from "store/BaseClientsReducer";
import { RootState } from "store";
import { formatPhoneNumber } from "hooks/useFormatInput";
import { useDeleteClientMutation } from "data/api/base-clients";
import { ResponseStatusType } from "data/api/exception";
import showToast from "utils/showToast";
import BaseClientsAnchoringModal from "./BaseClientsAnchoringModal";
import DealsInfoModal from "modules/Deal/DealsInfoModal";
import { useNavigate } from "react-router";
import useFormattedDate from "hooks/useFormatDate";
import { renderColorByType } from "components/Form/Table/utils";

interface PropsComponent {
  selection: any;
  isOpen: boolean;
  toggleInfoModal: (id: number) => void;
  refetch?: () => void;
  resetData?: () => void;
}

const BaseClientsInfoModal = ({
  selection,
  isOpen,
  toggleInfoModal,
  refetch,
  resetData,
}: PropsComponent) => {
  const [selectedLead, setSelectedLead] = useState<any>();

  const navigate = useNavigate();

  const textList = [
    {
      text: "ФИО",
      key: "name",
    },
    {
      text: "Номер",
      key: "phone",
    },
    {
      text: "Регион",
      key: "region",
    },
    {
      text: "Пол",
      key: "sex",
    },
    {
      text: "Дата добавления",
      key: "created_at",
    },
    {
      text: "Тип закрепления",
      key: "fixing_type",
    },
    {
      text: "Окончание закрепления",
      key: "expiration_date",
    },
    {
      text: "Коментарий",
      key: "comment",
    },
  ];
  const textListForCollapse = [
    { text: "id", key: "id" },
    {
      text: "Проект",
      key: "project.name",
    },
    {
      text: "Этап сделки",
      key: "current_status",
    },
    {
      text: "Объект",
      key: "premise.name",
    },
    {
      text: "Статус сделки",
      key: "state",
    },
  ];

  const [wantToDeleteModal, setWantToDeleteModal] = useState<boolean>(false);

  const [isOpenRecordApplication, setIsOpenRecordApplication] = useState(false);

  const [isOpenAnchoringModal, setIsOpenAnchoringModal] =
    useState<boolean>(false);
  const selector = useSelector((state: RootState) => state.baseClients);
  const [deleteClient] = useDeleteClientMutation();
  const roles = useRoles(null);
  const dispatch = useDispatch();

  const formatDate = useFormattedDate();
  useEffect(() => {
    init();
  }, []);

  function init() {}

  function handleToggleRecordForShowModal() {
    dispatch(toggleRecordForShowModal());
    toggleInfoModal(selection.info.id);
  }
  function handleBookingModal() {
    dispatch(toggleBookingModal());
    toggleInfoModal(selection.info.id);
  }

  const handleDeleteEvent = async () => {
    const { data: res, error } = await deleteClient(selection.info.id);
    if (res?.meta?.type === ResponseStatusType.SUCCESS) {
      showToast("Успешно удалено!", "success", 2000);
      refetch?.();
      setWantToDeleteModal(false);
      toggleInfoModal(selection.info.id);
      resetData && resetData();
    }
    if (error?.message) {
      showToast(error?.message, "error", 2000);
      resetData && resetData();
    }
  };

  const handleOpenAnchoringModal = () => {
    setIsOpenAnchoringModal(!isOpenAnchoringModal);
    toggleInfoModal(selection.info.id);
  };

  const handleToggleNotificationForm = () => {
    dispatch(toggleNotificationFormModal());
    toggleInfoModal(selection.info.id);
  };

  const handleOpenDealsInfoModal = (lead: any) => {
    setSelectedLead(lead);
    if (lead?.id) {
      dispatch(handleToggleDealsInfo(lead.id));
      toggleInfoModal(selection?.info?.id);
    }
  };

  const handleCloseDealsInfoModal = () => {
    dispatch(handleToggleDealsInfo(0));
    toggleInfoModal(selection?.info?.id);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        className="w-full md:w-[500px] rounded-t-[30px] md:rounded-[20px]"
      >
        <div className={"flex justify-between"}>
          <h1 className={"font-extrabold text-[25px] font-dewi"}>
            Данные клиента
          </h1>

          <ButtonForm
            text={<CloseWithBorderIcon />}
            onClick={() => {
              toggleInfoModal(selection.info.id);
              resetData && resetData();
            }}
          />
        </div>

        <div
          className={
            "py-[30px] max-h-[70vh] overflow-y-auto toolbar-scroll pr-[5px] sm:pr-3"
          }
        >
          <div className={"mb-[15px]"}>
            {textList.map((item, idx) => {
              const searchKey = selection.info[item.key];

              if (!searchKey) return null;

              return (
                <p className={"text-sm mb-[10px]"} key={`text-info-key-${idx}`}>
                  <span className={"text-accent-default font-medium"}>
                    {item.text}:
                  </span>
                  <span
                    className={`ml-2 select-none ${
                      typeof selection.info[item.key] === "object"
                        ? `text-[${renderColorByType(
                            selection.info[item.key].key
                          )}]`
                        : ""
                    }`}
                  >
                    {item.key === "phone"
                      ? "+7 " + formatPhoneNumber(selection.info[item.key])
                      : item.key === "created_at" && selection.info[item.key]
                      ? formatDate(selection.info[item.key], true)
                      : typeof selection.info[item.key] === "object"
                      ? selection.info[item.key].key.replace(
                          /закрепление|закреплении| в/g,
                          ""
                        )
                      : selection.info[item.key]}
                  </span>
                </p>
              );
            })}
          </div>

          <div className={"max-h-[270px] overflow-auto toolbar-scroll pr-3"}>
            {selection.info?.leads?.length > 0 &&
              selection.info.leads.map((lead: any, idx: number) => {
                const createMappedArray = (
                  collapseItem: any,
                  textList: any[]
                ) => {
                  return textList
                    .filter(
                      (textItem) => collapseItem[textItem.key.split(".")[0]]
                    )
                    .map((textItem) => {
                      const keys = textItem.key.split(".");
                      let value = collapseItem;
                      keys.forEach((key: string) => (value = value[key]));
                      return {
                        title: textItem.text,
                        text: value,
                      };
                    });
                };
                return (
                  <Collapse
                    key={`collapse-item-${idx}`}
                    className={"mb-[10px]"}
                    handleOpenDealsInfoModal={handleOpenDealsInfoModal}
                    lead={lead}
                    label={lead?.project?.name}
                    content={[...createMappedArray(lead, textListForCollapse)]}
                  />
                );
              })}
          </div>
          <div className={"flex flex-col gap-[10px] pt-[20px]"}>
            <ButtonForm
              text="Записать на показ"
              className=" hover:bg-accent-disabled py-3 px-6
                     bg-accent-default xl:flex-1 flex justify-center
                      text-white-default hover:text-bg-default w-full"
              onClick={handleToggleRecordForShowModal}
            />
            {roles.isAgent && (
              <ButtonForm
                onClick={handleToggleNotificationForm}
                text="Отправить на закрепление"
                className=" hover:bg-accent-disabled py-3 px-6
                     bg-accent-default xl:flex-1 flex justify-center
                      text-white-default hover:text-bg-default w-full"
              />
            )}

            <ButtonForm
              onClick={handleOpenAnchoringModal}
              text="Проверить закрепление"
              className=" hover:bg-accent-disabled py-3 px-6
                     bg-accent-default xl:flex-1 flex justify-center
                      text-white-default hover:text-bg-default w-full"
            />
            <ButtonForm
              text="Забронировать"
              className="hover:bg-accent-disabled py-3 px-6
                     bg-accent-default xl:flex-1 flex justify-center
                      text-white-default hover:text-bg-default w-full"
              onClick={handleBookingModal}
            />
          </div>
          {roles.isOzk_manager || roles.isManager ? (
            <div className={"pt-[20px]"}>
              <ButtonForm
                onClick={() => navigate("/tasks")}
                text="Задачи по клиенту"
                className="bg-bg-default py-3 px-6 w-full
                     hover:bg-accent-default xl:flex-1 flex justify-center
                      hover:text-white-default"
              />
            </div>
          ) : null}

          <div className={"pt-[20px]"}>
            <ButtonForm
              text="Удалить"
              onClick={() => {
                setWantToDeleteModal(true);
              }}
              className="bg-white-default py-3 px-6 w-full border border-red-default
                     hover:bg-red-default xl:flex-1 flex justify-center
                      hover:text-white-default text-red-default"
            />
          </div>
        </div>
      </Modal>
      <RecordForShow
        client_Id={Number(selection.info.id)}
        client={selection.info}
        handleToggle={handleToggleRecordForShowModal}
        isOpenModal={selector.isOpenRecordForShow}
      />
      <Modal
        isOpen={isOpenRecordApplication}
        className="md:w-[500px] rounded-[20px]"
      >
        <div className={"flex justify-center"}>
          <h1
            className={
              "font-extrabold text-[25px] leading-[30px] font-dewi text-center"
            }
          >
            Заявка на показ отправлена
          </h1>
        </div>

        <div className={"mt-[10px]"}>
          <p className={"text-center text-sm"}>
            Мы свяжемся с вами для подтверждения
          </p>
        </div>
        <div className={"pt-[20px]"}>
          <ButtonForm
            text="Отлично"
            className="hover:bg-bg-default py-3 px-6
                     bg-accent-default xl:flex-1 flex justify-center
                      text-white-default hover:text-accent-default w-full"
            onClick={() => {
              setIsOpenRecordApplication(false);
            }}
          />
        </div>
      </Modal>
      <BaseClientsAnchoringModal
        client={{
          id: selection.info.id,
          name: selection.info.name,
          image: "",
          phone: selection.info?.phone,
        }}
        isOpen={isOpenAnchoringModal}
        setIsOpen={handleOpenAnchoringModal}
      />
      <BookingFrom
        isOpen={selector.isOpenBookingModal}
        handleToggle={handleBookingModal}
        client_info={selection?.info}
      />
      <Modal
        isOpen={wantToDeleteModal}
        className="mx-auto w-full sm:w-[440px] rounded-t-[30px] rounded-b-0 sm:rounded-lg"
      >
        <div className={"flex  mb-[20px] items-center justify-center"}>
          <div className="text-center text-accent-default">
            <h1 className={"font-extrabold text-[20px] font-dewi"}>Удаление</h1>
            <p className="text-sm font-normal">
              Вы уверены что хотите удалить клиент?
            </p>
          </div>
        </div>
        <div className="flex  gap-[10px] mt-[10px]">
          <ButtonForm
            text="Отмена"
            onClick={() => {
              setWantToDeleteModal(false);
              toggleInfoModal(selection.info.id);
            }}
            className="py-3 px-6 bg-white-default border border-accent-default hover:bg-accent-default xl:flex-1 flex justify-center hover:text-white-default w-full"
          />
          <ButtonForm
            onClick={handleDeleteEvent}
            text="Да"
            className="py-3 px-6  bg-red-default xl:flex-1 flex justify-center text-white-default w-full hover:bg-red-focus hover:text-bg-default"
          />
        </div>
      </Modal>
      <DealsInfoModal
        data={
          selectedLead && {
            ...selectedLead,
            client: {
              fullname: selection?.info?.name,
              phone_number: selection?.info?.phone,
            },
          }
        }
        isModalOpen={selector.isDealsInfoModalOpen === selectedLead?.id}
        handleClose={handleCloseDealsInfoModal}
      />
    </>
  );
};

export default BaseClientsInfoModal;
