import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { handleFormatPrice } from "hooks/handleFormatPrice";

interface propsType {
  handleOpenModal: () => void;
  item: any;
}
const ExchangeApartmentCard = ({ item, handleOpenModal }: propsType) => {
  const { name, price, photo, similiarapartmentcount } = item;

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleNavigateToDeatails = () => {
    navigate(`/apartments/card/${item.id}`);
  };

  return (
    <div className="bg-white-default rounded-[16px] sm:rounded-lg text-accent-default w-full flex-1 cursor-pointer hover:shadow-lg overflow-hidden">
      <div
        onClick={handleNavigateToDeatails}
        className="p-[20px] flex flex-col gap-[18px]"
      >
        <div className="flex justify-between items-start">
          <div>
            <h3 className="text-[16px] font-medium leading-[18.65px] mb-[5px]">
              {name}
            </h3>
            <p className="text-[12px] font-medium leading-[16px]">
              {handleFormatPrice(price)}
            </p>
          </div>
        </div>

        <div className="flex justify-center">
          <img
            crossOrigin="anonymous"
            src={process.env.REACT_APP_FILES_URL + "/" + photo}
            width={270}
            height={177}
            alt=""
          />
        </div>
        <div className="text-[12px] leading-[16px] font-medium">
          <p className="opacity-50">3 этаж</p>
          <p className="opacity-50">г. Москва, Химки, дом 5</p>
          <p className="opacity-50">ООО Брусника</p>
        </div>

        <div className="flex justify-between flex-col sm:flex-row gap-[10px] mt-[10px]">
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleOpenModal();
            }}
            className="border border-accent-default mt-[1px] py-3
              hover:bg-accent-default flex-1 flex justify-center text-[12px]
               hover:text-white-default rounded transition-all 
                duration-400
                "
          >
            Звонок от ОЗК
          </button>
        </div>
      </div>

      <hr className="opacity-10" />

      <span className="text-[12px] font-medium inline-block py-[8px] pl-[20px]">
        Таких квартир: {similiarapartmentcount}
      </span>
    </div>
  );
};

export default ExchangeApartmentCard;
