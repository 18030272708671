const ExchangeAdvantages1 = () => {
	return (
		<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_2068_108500)">
				<path
					d="M25.711 52.6604V54.4999H0V52.6604H4.59122V1.84672H0.66423V0H43.372V1.84672H39.3793V32.5H37.5107V1.84672H6.45982V52.6604H16.0656H25.711Z"
					fill="#3B4658"/>
				<path d="M16.6205 15.6844V10H14.7227V16.6222V17.5456H15.657H16.6205V15.6844Z" fill="#39465A"/>
				<path d="M28.6498 15.6844V10H26.752V16.6222V17.5456H27.6863H28.6498V15.6844Z" fill="#39465A"/>
				<path d="M16.6205 30.6844V25H14.7227V31.6222V32.5456H15.657H16.6205V30.6844Z" fill="#39465A"/>
				<path d="M28.6498 30.6844V25H26.752V31.6222V32.5456H27.6863H28.6498V30.6844Z" fill="#39465A"/>
				<path
					d="M48.8172 39.3145C46.9653 39.2561 45.1282 39.6579 43.4742 40.4831V39.0403C43.4742 36.9411 40.3793 35.3613 36.2771 35.3613C32.175 35.3613 29.0801 36.9411 29.0801 39.0403V52.3209C29.0801 54.4201 32.175 55.9999 36.2771 55.9999C39.1019 55.9999 41.445 55.2785 42.6348 54.1027C43.8683 55.1703 46.1165 55.8484 48.8172 55.8484C52.8245 55.8484 55.861 54.3479 55.9996 52.3209V42.9863C56.0142 40.8943 52.9194 39.3145 48.8172 39.3145ZM41.6056 52.2992C41.6056 52.9196 39.7297 54.1315 36.2771 54.1315C32.8246 54.1315 30.9487 52.9196 30.9487 52.2992V50.5391C32.6085 51.3621 34.4503 51.7614 36.3063 51.7005C38.1526 51.7574 39.984 51.3582 41.6348 50.5391L41.6056 52.2992ZM41.6056 47.971C41.6056 48.5913 39.7297 49.8033 36.2771 49.8033C32.8246 49.8033 30.9487 48.5913 30.9487 47.971V46.0305C32.6083 46.8553 34.4494 47.2594 36.3063 47.2063C38.1436 47.2504 39.9637 46.8466 41.6056 46.0305V47.971ZM41.6056 43.4768C41.6056 44.0972 39.7297 45.3091 36.2771 45.3091C32.8246 45.3091 30.9487 44.0972 30.9487 43.4768V41.5435C32.6085 42.3665 34.4503 42.7658 36.3063 42.7049C38.1526 42.7619 39.984 42.3627 41.6348 41.5435L41.6056 43.4768ZM36.3063 40.8582C32.8611 40.8582 30.9779 39.6535 30.9779 39.0331C30.9779 38.4127 32.8611 37.2008 36.3063 37.2008C39.7516 37.2008 41.6348 38.4127 41.6348 39.0331C41.6348 39.6535 39.7297 40.8582 36.3063 40.8582ZM54.1456 52.1766C54.1456 52.797 52.2624 54.0089 48.8172 54.0089C45.372 54.0089 43.4815 52.797 43.4815 52.1766V50.0125C45.1351 50.8308 46.9687 51.2299 48.8172 51.1739C50.6634 51.2299 52.4946 50.8308 54.1456 50.0125V52.1766ZM54.1456 47.4876C54.1456 48.108 52.2624 49.3127 48.8172 49.3127C45.372 49.3127 43.4815 48.108 43.4815 47.4732V45.5039C45.1351 46.3222 46.9687 46.7213 48.8172 46.6653C50.6634 46.7213 52.4946 46.3222 54.1456 45.5039V47.4876ZM48.8172 44.8186C45.3647 44.8186 43.4815 43.6066 43.4815 42.9863C43.4815 42.3659 45.3647 41.1612 48.8172 41.1612C52.2697 41.1612 54.1456 42.3659 54.1456 42.9863C54.1456 43.6066 52.2624 44.8186 48.8172 44.8186Z"
					fill="#3B4658"/>
			</g>
			<defs>
				<clipPath id="clip0_2068_108500">
					<rect width="56" height="56" fill="white"/>
				</clipPath>
			</defs>
		</svg>
	)
}

export default ExchangeAdvantages1;