const ExchangeAdvantages4 = () => {
	return (
		<svg width="63" height="57" viewBox="0 0 63 57" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_2068_108531)">
				<path
					d="M52.899 9.67795C53.1531 9.92993 53.4034 10.1881 53.6498 10.4524C55.571 12.5128 57.0931 14.8022 58.2195 17.2255C59.2932 19.5385 60.0421 22.0498 60.4007 24.7097C60.9796 29.0044 60.4748 33.1954 59.0964 37.0069C57.8837 40.3603 55.9947 43.4199 53.5728 45.9978C53.1858 46.4084 52.783 46.8092 52.3645 47.1994C50.3093 49.1157 48.0262 50.635 45.6098 51.7605C43.2918 52.8388 40.7742 53.5907 38.1073 53.9502C38.0866 53.953 38.0661 53.9564 38.0459 53.9604C29.9977 55.0188 21.5751 52.3036 15.6174 45.9141C15.2408 45.5102 14.608 45.488 14.2041 45.8647C13.8001 46.2413 13.778 46.8741 14.1546 47.278C22.6392 56.3776 35.7662 58.5573 46.4636 53.5697C49.7255 52.0509 52.6202 49.9319 55.0265 47.3715C60.2433 41.8205 63.1649 34.1947 62.5579 26.1121C62.3125 22.7745 61.4706 19.4686 60.0264 16.3667C58.5958 13.29 56.6314 10.5397 54.2663 8.21734C46.9772 1.01886 36.7738 -1.51277 27.6515 1.07775C15.1662 4.39061 8.10401 16.3938 6.72362 29.0137L2.49386 23.9532C2.13968 23.5294 1.50903 23.473 1.08527 23.8272C0.661516 24.1814 0.605121 24.812 0.959309 25.2358L6.73114 32.1413C7.08533 32.565 7.71598 32.6214 8.13974 32.2672L15.0452 26.4954C15.469 26.1412 15.5254 25.5106 15.1712 25.0868C14.817 24.6631 14.1864 24.6067 13.7626 24.9609L8.7176 29.1776C9.5706 21.4612 12.6007 14.1888 17.6402 9.14103C20.7154 6.06546 24.4158 4.06919 28.2602 2.98777C29.1955 2.7426 30.1622 2.55087 31.1601 2.41634C39.4563 1.29796 47.3652 4.22371 52.899 9.67795Z"
					fill="#3B4658"/>
				<path
					d="M36 36.2715C36 41.242 31.9706 45.2715 27 45.2715C22.0294 45.2715 18 41.242 18 36.2715C18 31.3009 22.0294 27.2715 27 27.2715C31.9706 27.2715 36 31.3009 36 36.2715Z"
					fill="white"/>
				<path fillRule="evenodd" clipRule="evenodd"
							d="M27 43.2715C30.866 43.2715 34 40.1375 34 36.2715C34 32.4055 30.866 29.2715 27 29.2715C23.134 29.2715 20 32.4055 20 36.2715C20 40.1375 23.134 43.2715 27 43.2715ZM27 45.2715C31.9706 45.2715 36 41.242 36 36.2715C36 31.3009 31.9706 27.2715 27 27.2715C22.0294 27.2715 18 31.3009 18 36.2715C18 41.242 22.0294 45.2715 27 45.2715Z"
							fill="#3B4658"/>
				<path
					d="M25.2188 36.8755H24V35.827H25.2188V32.2943C25.5836 32.2943 25.9774 32.2905 26.4002 32.2829C26.8231 32.2753 27.25 32.2715 27.6812 32.2715C29.928 32.2715 31.0514 33.0502 31.0514 34.6077C31.0514 35.3142 30.7737 35.8688 30.2182 36.2715C29.6627 36.6741 28.8834 36.8755 27.8801 36.8755H26.736V37.8213H28.7756V38.8698H26.736V40.2715H25.2188V38.8698H24V37.8213H25.2188V36.8755ZM27.8428 33.582C27.8345 33.582 27.4656 33.5896 26.736 33.6048V35.827H27.8304C28.8999 35.827 29.4347 35.4624 29.4347 34.733C29.4347 34.3304 29.302 34.0379 29.0367 33.8555C28.7797 33.6732 28.3818 33.582 27.8428 33.582Z"
					fill="#3B4658"/>
				<path fillRule="evenodd" clipRule="evenodd"
							d="M26.1919 17C24.757 17.8284 24.2654 19.6632 25.0938 21.0981L26.5939 23.6962C27.4223 25.131 29.257 25.6227 30.6919 24.7942L41.9503 18.2942C43.3851 17.4658 43.8768 15.631 43.0483 14.1962L42.7983 13.7631L44.0974 13.0131C44.3365 12.8751 44.6423 12.957 44.7804 13.1962L47.0304 17.0933C47.1685 17.3324 47.0865 17.6382 46.8474 17.7763L38.6201 22.5263C37.6635 23.0786 37.3358 24.3017 37.8881 25.2583L39.9734 28.8703C39.1703 29.8095 39.0029 31.1893 39.656 32.3205L44.156 40.1147C44.9845 41.5496 46.8192 42.0412 48.2541 41.2128C49.689 40.3844 50.1806 38.5496 49.3522 37.1147L44.8522 29.3205C44.1991 28.1893 42.9205 27.6443 41.7055 27.8703L39.8701 24.6913C39.7321 24.4522 39.814 24.1464 40.0531 24.0083L48.2804 19.2583C49.237 18.706 49.5647 17.4829 49.0124 16.5263L46.2624 11.7631C45.7101 10.8066 44.487 10.4788 43.5304 11.0311L41.7983 12.0311L41.5483 11.5981C40.7199 10.1632 38.8851 9.67153 37.4503 10.5L26.1919 17ZM38.4503 12.2321L27.1919 18.7321C26.7136 19.0082 26.5498 19.6198 26.8259 20.0981L28.3259 22.6962C28.602 23.1744 29.2136 23.3383 29.6919 23.0622L40.9503 16.5622C41.4285 16.286 41.5924 15.6744 41.3163 15.1962L39.8163 12.5981C39.5401 12.1198 38.9285 11.9559 38.4503 12.2321ZM41.7541 29.9545C41.2758 30.2306 41.1119 30.8422 41.3881 31.3205L45.8881 39.1147C46.1642 39.593 46.7758 39.7569 47.2541 39.4808C47.7324 39.2046 47.8963 38.593 47.6201 38.1147L43.1201 30.3205C42.844 29.8422 42.2324 29.6783 41.7541 29.9545Z"
							fill="#3B4658"/>
			</g>
			<defs>
				<clipPath id="clip0_2068_108531">
					<rect width="63" height="57" fill="white"/>
				</clipPath>
			</defs>
		</svg>
	)
}

export default ExchangeAdvantages4;