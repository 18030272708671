import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../index";

export interface SelectionState {
  isOpenCreateModal: boolean;
}
const initialState: SelectionState = {
  isOpenCreateModal: false,
};

const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    handleOpenCreateEvents: (state, action: PayloadAction<boolean>) => {
      state.isOpenCreateModal = action.payload;
    },
  },
});

export const { handleOpenCreateEvents } = eventsSlice.actions;

export const getIsOpenCreateEvents = (state: RootState) =>
  state.events.isOpenCreateModal;

export default eventsSlice.reducer;
