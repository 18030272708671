import React, { useState } from "react";
import ButtonForm from "../../components/Button/ButtonForm";
import CloseWithBorderIcon from "../../components/constant/icons/CloseWithBorderIcon";
import Label from "../../components/Form/Label";
import Dropdown from "../../components/Form/Dropdown/Dropdown";
import Modal from "../../components/Modal";
import MaskComponent from "../../components/MaskInput/MaskComponent";
import PhoneIcon from "../../components/constant/icons/PhoneIcon";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useWindowSize } from "../../hooks/useWindowSize";
import { SearchField } from "../../components/Form/SearchField";

interface FormSubmit {
	client: any;
	phone_number: string;
}

interface ExchangeModalApplicationPhoneProps {
	isOpenModal: boolean;
	setIsOpenModal: (value: boolean) => void;
}

const ExchangeModalApplicationPhone = (
	{
		isOpenModal,
		setIsOpenModal
	}: ExchangeModalApplicationPhoneProps
) => {
	const { isMd } = useWindowSize();
	const [isStatusComplete, setIsStatusComplete] = useState(false);

	const {
		handleSubmit,
		control,
		reset,
		formState: { isValid },
	} = useForm<FormSubmit>();

	const onSubmit: SubmitHandler<FormSubmit> = async (data) => {
		console.log(data)

		reset();
		setIsStatusComplete(true);
	};

	return (
		<Modal
			isOpen={isOpenModal}
			onClose={() => {
				setIsStatusComplete(false);
				setIsOpenModal(false);
			}}
			className="mx-auto w-full md:w-[450px] lg:w-[500px] rounded-t-[30px] rounded-b-0 sm:rounded-[20px]"
		>
			{isStatusComplete ? (
				<>
					<h3 className="text-[25px] font-dewi font-extrabold text-center mb-[10px]">
						Заявка принята
					</h3>

					<p className="text-sm font-normal text-center mb-5">
						Статус по данной заявке будет отправлен <br/>
						уведомлением на ваш аккаунт
					</p>

					<ButtonForm
						text={"Отлично"}
						onClick={() => {
							setIsStatusComplete(false);
							setIsOpenModal(false);
						}}
						className="bg-accent-default py-[16px] w-full
             text-white-default text-center flex justify-center hover:bg-accent-focus "
					/>
				</>
			) : (
				<>
					<div className={`flex ${isMd ? "justify-center" : "justify-between"} mb-[20px]`}>
						<h1 className={"font-extrabold text-[25px] font-dewi"}>
							Заявка на звонок
						</h1>

						{!isMd && (
							<ButtonForm
								text={<CloseWithBorderIcon/>}
								onClick={() => setIsOpenModal(false)}
							/>
						)}
					</div>
					<div className="flex flex-col gap-5">
						<form
							onSubmit={handleSubmit(onSubmit)}
							className=" pt-[20px] flex flex-col gap-5 max-h-[80vh] overflow-y-auto pr-3 toolbar-scroll"
						>
							<Label label="Клиент">
								<Controller
									name="client"
									control={control}
									rules={{
										required: {
											value: true,
											message: "",
										},
									}}
									render={({ field: { onChange, value } }) => (
										<SearchField
											onChange={(value) => onChange(value?.id)}
											className="rounded-[4px!important]"
										/>
									)}
								/>
							</Label>

							<Label label="Телефон для связи" className="w-full">
								<Controller
									name="phone_number"
									control={control}
									rules={{
										required: {
											value: true,
											message: "",
										},
									}}
									render={({ field: { onChange, value } }) => (
										<MaskComponent
											name="phone_number"
											imgLeft={<PhoneIcon/>}
											value={value}
											onChange={onChange}
										/>
									)}
								/>
							</Label>

							<ButtonForm
								type={"submit"}
								className={
									" px-6 py-3 w-full mt-3 justify-center bg-accent-default text-white-default hover:bg-accent-disabled  hover:text-bg-default  hover:bg-bg-defau text-ellipsis whitespace-nowrap"
								}
								text="Отправить заявку"
							/>
						</form>
					</div>
				</>
				)}
		</Modal>
	)
}

export default ExchangeModalApplicationPhone;