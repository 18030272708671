import React, { useState } from "react";

import { parsePhoneNumberFromString, AsYouType } from "libphonenumber-js";

export const formatPhoneNumber = (input: string | undefined) => {
  let formattedNumber = "";

  if (input) {
    let numericInput = input.replace(/\D/g, "");

    const gonnaPast = numericInput.length > 10;

    if (numericInput.startsWith("7") && numericInput.length > 10) {
      if (numericInput.length > 15) {
        numericInput = unformatPhoneNumber(numericInput).slice(1);
      } else {
        numericInput = numericInput.slice(1);
      }
    }
    if (numericInput.startsWith("+7") && numericInput.length > 10) {
      if (numericInput.length > 15) {
        numericInput = unformatPhoneNumber(numericInput).slice(1);
      } else {
        numericInput = numericInput.slice(2);
      }
    }
    numericInput = numericInput.slice(0, 10);

    for (let i = 0; i < numericInput.length; i++) {
      if (i === 0) {
        formattedNumber += "(";
      } else if (i === 3) {
        formattedNumber += ")";
      }
      if (i === 3 || i === 6 || i === 8) {
        formattedNumber += " ";
      }
      formattedNumber += numericInput?.[i];
    }
  }

  return formattedNumber;
};

export const unformatPhoneNumber = (input: string) => {
  const cleanedNumber = input.replace(/[^\d]/g, "");

  const formattedNumber = `7${cleanedNumber}`;

  return formattedNumber;
};

export function isOnlyPhoneNumber(value: string): string {
  const removeExtraSigns = value.replace(/[^a-zA-Z-а-я-А-Я-0-9\s]/g, "");

  const isNumeric = /^[0-9\s]+$/.test(removeExtraSigns);

  if (isNumeric) {
    return removeExtraSigns.replace(/\s+/g, "");
  }

  return removeExtraSigns;
}

// export function formatRussianPhoneNumber(phoneNumber: string): string {
//   if (phoneNumber.startsWith("8")) {
//     const formattedNumber = new AsYouType("RU").input(phoneNumber);
//     return formattedNumber;
//   }

//   if (phoneNumber.startsWith("7")) {
//     phoneNumber = `+${phoneNumber}`;
//   }

//   const parsedNumber = parsePhoneNumberFromString(phoneNumber, "RU");

//   return parsedNumber
//     ? `+${parsedNumber.countryCallingCode} (${parsedNumber.nationalNumber.slice(
//         0,
//         3
//       )}) ${parsedNumber.nationalNumber.slice(
//         3,
//         6
//       )} ${parsedNumber.nationalNumber.slice(
//         6,
//         8
//       )} ${parsedNumber.nationalNumber.slice(8)}`
//     : phoneNumber;
// }

const useFormatInput = () => {
  const [formattedPhone, setFormattedPhone] = useState("");

  const handlePhoneInputChange = (value: string) => {
    const formattedNumber = formatPhoneNumber(value);
    setFormattedPhone(formattedNumber);
  };

  return {
    handlePhoneInputChange,
    formattedPhone,
    setFormattedPhone,
  };
};

export default useFormatInput;
