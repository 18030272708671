import React from "react";
import ButtonForm from "../../../../components/Button/ButtonForm";
import { Link } from "react-router-dom";

type propsType = {
  title?: string;
  message: string;
  buttonText: string;
  path?: string;
};
const SuccessfullySubmited = ({
  title,
  message,
  buttonText,
  path = "/",
}: propsType) => {
  const handleClick = () => {
    // showToast("Вы успешно вошли в систему", "success");
  };
  return (
    <div className="text-center">
      <h3 className="text-[25px] text-accent-default font-dewi  font-extrabold leading-[30px] mb-[5px]">
        {title}
      </h3>
      <span className="text-[14px] inline-block text-accent-default mb-[20px]">
        {message}
      </span>
      <Link to={path}>
        <ButtonForm
          text={buttonText}
          onClick={handleClick}
          className="bg-accent-default py-[16px] w-full
             text-white-default text-center flex justify-center hover:bg-accent-focus "
        />
      </Link>
    </div>
  );
};

export default SuccessfullySubmited;
