import React, { useEffect, useState } from "react";
import ButtonForm from "../../components/Button/ButtonForm";
import Modal from "../../components/Modal";
import { SearchField } from "../../components/Form/SearchField";
import DatePickerField from "../../components/Form/DatePicker/DatePickerField";
import Dropdown, {
  selectOption,
} from "../../components/Form/Dropdown/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsOpenFilters,
  getIsOpenSearch,
  handleFiltersSearch,
  handleFiltersSelect,
  handleOpenFilters,
  handleOpenSearch,
  toggleNotificationFormModal,
} from "../../store/BaseClientsReducer";
import { PaginationType } from ".";
import Loader from "components/Loader";
import { formatPhoneNumber } from "hooks/useFormatInput";
import { FixingTypeWithSmall } from "enums/clientFixingType";
import { ActiveClientToggler } from "./BaseClientsFilters";
import { ActiveClientTogglerComp } from "components/ToggleSwitcher/ActiveClientToggler";
import {
  ExpireDateColumn,
  renderColorByType,
} from "../../components/Form/Table/utils";
import { useWindowSize } from "../../hooks/useWindowSize";
import NotFound from "components/NotFound";
import { useRoles } from "hooks/useRoles";

interface BaseClientsMobile {
  items: any[];
  dateStartDefault?: string | null;
  dateEndDefault?: string | null;
  setIsOpenAnchoringModal?: () => void;
  paginationInfo: PaginationType | undefined;
  handleUpdateList: () => void;
  isLoading: boolean;
  handleLeftClickTable: (item: any) => void;
  handleChangeFields: (
    searchSelectItem: any,
    dateStart: Date,
    dateEnd: Date,
    selectSecond: any,
    activeClient: ActiveClientToggler
  ) => void;
}

const BaseClientsMobile = ({
  items,
  dateStartDefault = null,
  dateEndDefault = null,
  setIsOpenAnchoringModal,
  paginationInfo,
  handleUpdateList,
  isLoading,
  handleChangeFields,
  handleLeftClickTable,
}: BaseClientsMobile) => {
  const dispatch = useDispatch();

  const storeIsOpenFilters = useSelector(getIsOpenFilters);

  const storeIsOpenSearch = useSelector(getIsOpenSearch);

  const { isMd } = useWindowSize();

  const [isOpenSearch, setIsOpenSearch] = useState(false);

  const [isOpenFilters, setIsOpenFilters] = useState(false);

  const [isOpenDate, setIsOpenDate] = useState(false);

  const roles = useRoles(null);

  const [searchSelectItem, setSearchSelectItem] = useState(null);

  const [activeClient, setActiveClientFilter] = useState<ActiveClientToggler>(
    ActiveClientToggler.ACTIVE
  );

  const [dateStart, setDateStart] = useState<Date | null>(
    dateStartDefault ? new Date(dateStartDefault) : null
  );
  const [dateEnd, setDateEnd] = useState<Date | null>(
    dateEndDefault ? new Date(dateEndDefault) : null
  );

  const [selectFastenings, setSelectFastenings] = useState<
    selectOption[] | null
  >(null);

  const [optionsFirst, setOptionsFirst] = useState<selectOption[]>([]);

  useEffect(() => {
    storeIsOpenFilters && setIsOpenFilters(storeIsOpenFilters);
  }, [storeIsOpenFilters]);

  useEffect(() => {
    storeIsOpenSearch && setIsOpenSearch(storeIsOpenSearch);
  }, [storeIsOpenSearch]);

  useEffect(() => {
    getSelectSecondList();

    return () => {
      dispatch(handleFiltersSelect(false));
      dispatch(handleFiltersSearch(false));
    };
  }, []);

  function getSelectSecondList() {
    // $getList().then((response) => {
    const response = [
      { label: "Все", value: "" },
      {
        label: "Проверка лида",
        value: FixingTypeWithSmall.LEAD_VERIFICATION,
      },
      {
        label: "Отказ в закреплении",
        value: FixingTypeWithSmall.CENCEL_FIXING,
      },
      {
        label: "Слабое закрепление",
        value: FixingTypeWithSmall.WEAK_FIXING,
      },
      {
        label: "Сильное закрепление",
        value: FixingTypeWithSmall.STRONG_FIXING,
      },
    ];

    if (!response) return;

    setOptionsFirst(response);
  }

  function onChangeSearchField(value: any) {
    if (value) {
      dispatch(handleOpenSearch(false));
      setIsOpenSearch(false);
    }
    dispatch(handleFiltersSearch(!!value));
    setSearchSelectItem(value);
  }

  const handleOpenInfoModal = (item: any) => {
    handleLeftClickTable(item);
  };

  const handleToggleNotificationForm = () => {
    dispatch(toggleNotificationFormModal());
  };

  useEffect(() => {
    handleChangeFields(
      searchSelectItem,
      dateStart as Date,
      dateEnd as Date,
      selectFastenings,
      activeClient
    );
  }, [searchSelectItem, dateStart, dateEnd, selectFastenings, activeClient]);

  return (
    <div className={"bg-bg-default min-h-[50vh]"}>
      <div className="flex items-center justify-center w-full mt-5">
        <ActiveClientTogglerComp
          activeClient={activeClient}
          setActiveClientFilter={setActiveClientFilter}
          hasTitle={false}
        />
      </div>

      <div className={"px-[15px] py-[20px] min-h-[30vh] relative"}>
        <ButtonForm
          text="Проверить закрепление"
          onClick={setIsOpenAnchoringModal}
          className={
            "w-full bg-accent-default hover:bg-accent-focus p-3 justify-center text-white-default mb-[10px]"
          }
        />

        {roles.isAgent && (
          <ButtonForm
            text={"Форма уведомления"}
            className={
              "w-full bg-accent-default hover:bg-accent-focus text-white-default p-3 justify-center"
            }
            onClick={handleToggleNotificationForm}
          />
        )}

        {isLoading ? (
          <div className=" h-[200px] relative">
            <Loader />
          </div>
        ) : items?.length ? (
          <>
            <div className={"py-5"}>
              {items?.map((item) => (
                <div
                  key={item?.id}
                  onClick={() => handleOpenInfoModal(item)}
                  className={
                    "flex justify-between p-[15px] mb-[10px] rounded-lg bg-white-default"
                  }
                >
                  <div className={"left"}>
                    <p className={"mb-[5px] text-sm font-medium w-[150px]"}>
                      {item?.name}
                    </p>

                    <p className={"text-sm select-none"}>
                      +7 {formatPhoneNumber(item?.phone)}
                    </p>
                  </div>

                  <div className={"right text-right flex flex-col items-end"}>
                    <div
                      className={"mb-[5px] text-sm font-medium"}
                      style={{
                        color: renderColorByType(item?.fixing_type.key),
                      }}
                    >
                      {item?.fixing_type.key.replace(
                        /закрепление|закреплении| в/g,
                        ""
                      )}
                    </div>

                    <p className={"text-sm text-end select-none"}>
                      {typeof item.datePin === "object" ? (
                        <ExpireDateColumn expiration_date={item.datePin?.key} />
                      ) : (
                        item.datePin
                      )}
                    </p>
                  </div>
                </div>
              ))}
            </div>

            {paginationInfo
              ? !(paginationInfo?.currPage >= paginationInfo?.totalPage) && (
                  <button
                    type={"button"}
                    className={
                      "py-3 px-6 mx-auto text-blue-default text-sm font-medium flex mt-[15px]"
                    }
                    onClick={() => handleUpdateList()}
                  >
                    Загрузить еще
                  </button>
                )
              : null}
          </>
        ) : (
          !isLoading && <NotFound />
        )}
      </div>

      <Modal
        isOpen={isOpenSearch}
        onClose={() => {
          dispatch(handleOpenSearch(false));
          setIsOpenSearch(false);
        }}
        className={"md:w-auto w-full rounded-t-[30px] sm:rounded-[8px]"}
      >
        <SearchField
          selectItem={searchSelectItem}
          isOpenSearch={isOpenSearch}
          onChange={onChangeSearchField}
          isShowBtnDelete={true}
          searchQuery={`is_active=${
            activeClient === ActiveClientToggler.ACTIVE
          }`}
          className={"md:rounded-[4px!important]"}
        />
      </Modal>

      <Modal
        isOpen={isOpenFilters}
        onClose={() => {
          dispatch(
            handleFiltersSelect(
              !!searchSelectItem ||
                !!selectFastenings ||
                !!dateEnd ||
                !!dateStart
            )
          );
          dispatch(handleOpenFilters(false));
          setIsOpenFilters(false);
        }}
        className={"md:w-auto w-full rounded-t-[30px] sm:rounded-[8px "}
      >
        <div
          className={`flex flex-col items-center max-h-[80vh] toolbar-scroll pr-3 ${
            isOpenDate ? "" : "overflow-y-auto"
          }`}
        >
          <h3 className="text-xl font-extrabold text-accent-default mb-[30px] font-dewi">
            Фильтр
          </h3>
          <div className="w-full">
            <SearchField
              selectItem={searchSelectItem}
              searchQuery={`is_active=${
                activeClient === ActiveClientToggler.ACTIVE
              }`}
              onChange={onChangeSearchField}
              isShowBtnDelete={true}
              className={" w-full rounded-[4px!important]"}
            />
          </div>

          <Dropdown
            placeholder={"Статус закрепления"}
            value={selectFastenings}
            isShowBtnDelete={true}
            deleteItem={() => setSelectFastenings(null)}
            options={optionsFirst}
            className={"mt-[10px] w-full"}
            onChange={setSelectFastenings}
          />
          <div className={"flex flex-col mt-[10px] gap-[10px] w-full"}>
            {/*<DatePickerField*/}
            {/*  className={"w-full"}*/}
            {/*  startDate={dateStart}*/}
            {/*  placeholder={"Закреплен до..."}*/}
            {/*  isIcon*/}
            {/*  onChangeDate={setDateStart}*/}
            {/*/>*/}

            <DatePickerField
              className={`w-full ${!isMd && "calendar-block"} icons-show`}
              startDate={dateStart}
              placeholder={"Дата добавления"}
              maxDate={dateEnd}
              isIcon
              isRightIcon
              isClearable
              onChangeDate={setDateStart}
              isMobile={isMd}
              setIsOpen={setIsOpenDate}
            />

            <DatePickerField
              className={`w-full ${!isMd && "calendar-block"} icons-show`}
              startDate={dateEnd}
              placeholder={"Окончание закрепления до"}
              maxDate={dateEnd}
              isIcon
              isRightIcon
              isClearable
              onChangeDate={setDateEnd}
              isMobile={isMd}
              setIsOpen={setIsOpenDate}
            />
          </div>

          <ButtonForm
            text="Применить"
            onClick={() => {
              dispatch(
                handleFiltersSelect(
                  !!searchSelectItem ||
                    !!selectFastenings ||
                    !!dateEnd ||
                    !!dateStart
                )
              );
              dispatch(handleOpenFilters(false));
              setIsOpenFilters(false);
            }}
            className={
              "mt-[30px] max-h-[48px] px-6 w-full justify-center bg-accent-default text-white-default text-ellipsis whitespace-nowrap p-3"
            }
          />
        </div>
      </Modal>
    </div>
  );
};

export default BaseClientsMobile;
