import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SelectionState {
  isOpenProjectCreateModal: boolean;
}
const initialState: SelectionState = {
  isOpenProjectCreateModal: false,
};

const projectssSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    handleOpenProjectCreateModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenProjectCreateModal = action.payload;
    },
  },
});

export const { handleOpenProjectCreateModal } = projectssSlice.actions;

export default projectssSlice.reducer;
