import ExpireIcon from "components/constant/icons/ExpireIcon";
import WarningIcon from "components/constant/icons/WarningIcon";
import { FixingType } from "enums/clientFixingType";
import useFormattedDate from "hooks/useFormatDate";
import { checkExpirationStatus } from "utils/checkdateExpirationDate";

export const ExpireDateColumn = ({
  expiration_date,
}: {
  expiration_date: string;
}) => {
  const formatDate = useFormattedDate();

  const status = checkExpirationStatus(expiration_date);

  switch (status) {
    case "expired":
      return (
        <span className="flex items-center gap-2 text-red-default">
          <ExpireIcon /> Закончился
        </span>
      );

    case "gonna_expire":
      return (
        <span className="flex items-center gap-2 text-[#DAAA00]">
          <WarningIcon /> {formatDate(expiration_date, false)}
        </span>
      );
    case "success":
      return <>{expiration_date ? formatDate(expiration_date, false) : ""}</>;

    default:
      return <>{expiration_date ? formatDate(expiration_date, false) : ""}</>;
  }
};

export function getItemStatusParking(item: any) {
  if (item.is_sold) {
    return <span className="  text-[#EF3B24]">Продано</span>;
  } else if (item.status === "free") {
    return <span className=" text-[#4BCC36] ">Свободно</span>;
  } else {
    return <span className=" text-[#EF3B24]">Забронировано</span>;
  }
}

export function renderColorByType(params: FixingType) {
  switch (params) {
    case FixingType.STRONG_FIXING:
      return "#3583FF";
    case FixingType.WEAK_FIXING:
      return "#4BCC36";
    case FixingType.CENCEL_FIXING:
      return "#EF3B24";
    default:
      return "#3583FF";
  }
}
