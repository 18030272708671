import React, { ChangeEvent, useEffect, useState } from "react";

interface PropsType {
  label?: string;
  isToggle?: boolean;
  setIsToggle?: (value: boolean) => void;
  className?: string;
}

const ToggleSwitcher = ({
  label,
  isToggle = false,
  setIsToggle,
  className,
}: PropsType) => {
  const [isChecked, setIsChecked] = useState<boolean>(isToggle);

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const newCheckedState = e.target.checked;
    setIsChecked(newCheckedState);
    setIsToggle && setIsToggle(newCheckedState);
  };

  useEffect(() => {
    setIsChecked(isToggle);
  }, [isToggle]);

  return (
    <label
      className={`relative flex justify-between items-center text-sm leading-6 text-accent-default font-normal ${className}`}
    >
      {label}
      <input
        checked={isChecked}
        onChange={handleCheckboxChange}
        type="checkbox"
        className="absolute left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md cursor-pointer"
      />
      <span
        onClick={(e) => e.stopPropagation()}
        className="w-[36px] h-[21px] flex items-center flex-shrink-0 cursor-pointer p-[2px] toggler bg-[#BEC0C5]
        rounded-[100px] duration-300 ease-in-out peer-checked:bg-accent-default
        after:w-[15px] after:h-[15px] after:bg-white-default after:rounded-full
        after:shadow-md after:duration-300 peer-checked:after:translate-x-[17px]"
      ></span>
    </label>
  );
};

export default ToggleSwitcher;
