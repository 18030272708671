import React, { useEffect, useState } from "react";
import Modal from "../../components/Modal";
import ButtonForm from "../../components/Button/ButtonForm";

interface FullCalendarMobileModalProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  select: any;
  list: any[];
  handleOpenModal: (item: any) => void;
}

const FullCalendarMobileModal = ({
  isOpen,
  setIsOpen,
  select,
  list,
  handleOpenModal,
}: FullCalendarMobileModalProps) => {
  const [calendarList, setCalendarList] = useState([]);

  const formatDateModal = (dateString: string) => {
    if (!dateString) return "";

    const date = new Date(dateString);

    const options: any = {
      weekday: "short",
      day: "2-digit",
      month: "long",
    };

    return new Intl.DateTimeFormat("ru-RU", options).format(date);
  };

  const filterDates = (selectDate: string, list: any[]) => {
    if (!selectDate) return [];

    const selectedDate = selectDate.split("T")[0];

    return list.filter((item) => {
      const itemDate = item.date.split("T")[0];

      return itemDate === selectedDate;
    });
  };

  useEffect(() => {
    const filterList = filterDates(select.date, list);

    const updateList = [...filterList].reduce((acc, item) => {
      const dateKey = item.start.split("T")[0];

      if (!acc[dateKey]) {
        acc[dateKey] = { date: item.start, timeList: [] };
      }

      acc[dateKey].timeList.push(item);
      return acc;
    }, {});

    console.log(Object.values(updateList), "Object.values(updateList)");

    setCalendarList(Object.values(updateList));
  }, [select]);

  function handleOpen(item: any) {
    setIsOpen(false);
    handleOpenModal(item);
  }

  if (!select) return null;

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      className="w-full md:w-[500px] rounded-t-[30px] md:rounded-lg"
    >
      <div className={"flex justify-center"}>
        <h1 className={"font-extrabold text-[20px] font-dewi capitalize"}>
          {formatDateModal(select.start)}
        </h1>
      </div>

      <div
        className={
          "py-[15px] flex flex-col gap-[30px] max-h-[400px] overflow-auto"
        }
      >
        {calendarList.length &&
          calendarList.map((item: any, index) => (
            <div
              key={index}
              className={"border-t border-dashed border-[#D7DCE4] pt-2"}
            >
              {/*<p className={"text-sm mb-[20px] capitalize"}>{formatDateModal(item.date)}</p>*/}

              <div className={"flex flex-col gap-[30px]"}>
                <div className={"flex gap-3"}>
                  <div className={"text-sm"}>
                    {item.date.split("T")[1].slice(0, 5)}
                  </div>

                  <div className={"flex flex-col gap-3"}>
                    {item.timeList.length &&
                      item.timeList.map((i: any, idx: number) => (
                        <div
                          key={`${index}-${idx}`}
                          className={`border-l-[3px] pl-[10px]`}
                          style={{
                            borderLeftColor: i.backgroundColorMobile,
                          }}
                          onClick={() => handleOpen(i)}
                        >
                          <p
                            className={`text-sm capitalize ${
                              i.groupId === "pokaz" ? "text-blue-default" : ""
                            }`}
                          >
                            {i.title}
                          </p>

                          <p className={"text-sm"}>{i.name}</p>

                          <p className={"text-sm"}>{i.project}</p>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>

      <div className={"border-t border-[#D7DCE4] py-[20px]"}>
        <ButtonForm
          onClick={() => setIsOpen(false)}
          type="submit"
          text="Готово"
          className="py-3 px-6 border border-bg-default bg-bg-default xl:flex-1 flex justify-center text-accent-default w-full hover:bg-accent-disabled hover:text-bg-default"
        />
      </div>
    </Modal>
  );
};

export default FullCalendarMobileModal;
