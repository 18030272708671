import CancelIcon from "components/constant/icons/CancelIcon";
import { images } from "utils/resource";
import { handleFormatPrice } from "hooks/handleFormatPrice";
import { Apartment } from "../ApartmentList/dataTransformer";
import { useDispatch } from "react-redux";
import { handleDeteleteItemInStore } from "store/ApartmentReducer";
import { Primise } from "enums/primisesType";
import moment from "moment";

interface propsType {
  item: Apartment;
  hanldeRemove: (arg: number) => void;
  actions: boolean;
}

const InfoApartmentCard = ({ item, hanldeRemove, actions }: propsType) => {
  const {
    id,
    end_date,
    name,
    number,
    price,
    similiarApartmentCount,
    title,
    type,
    feature,
    feature_new,
    quarter,
    year,
  } = item;

  const dispatch = useDispatch();

  const handleDeleteData = (e: React.MouseEvent, id: number, type: Primise) => {
    e.stopPropagation();
    dispatch(handleDeteleteItemInStore({ type, id }));
  };

  const handleJumpToSeeProduct = (e: React.MouseEvent) => {
    e.stopPropagation();
    window.location.href = `https://ekaterinburg.brusnika.ru/flat`;
  };

  return (
    <div
      onClick={handleJumpToSeeProduct}
      className="rounded-lg bg-white-default min-w-[315px] sm:min-w-[400px] flex-1 cursor-pointer "
    >
      <div className="p-[20px] flex flex-col gap-[20px] ">
        <div className=" flex gap-[20px] justify-between">
          <div className="flex gap-[20px] ">
            <img src={images.apartmentImg} width={57} height={55} alt="" />
            <div>
              <h3 className="text-[16px] font-medium leading-[18.65px] mb-[5px]">
                {title}
              </h3>
              <p className="text-[12px] font-medium leading-[16px]">
                {handleFormatPrice(price)}
              </p>
            </div>
          </div>
          {actions && (
            <button
              onClick={(e) => {
                hanldeRemove(id);
                handleDeleteData(e, id, type);
              }}
              className="outline-none p-1"
            >
              <CancelIcon />
            </button>
          )}
        </div>
        <div className="text-[12px] leading-[16px] font-medium opacity-50">
          <p>{name}</p>
          <p>Квартира {number}</p>
          {item?.quarter && item?.year && (
            <p>
              Срок сдачи {item?.quarter} квартал {item?.year} года
            </p>
          )}
        </div>
        {feature_new ? (
          <div className="flex gap-[10px] flex-wrap">
            {/* {feature_new?.map((item, i) => ( */}
            <div
              // key={i}
              className="text-[12px] w-fit px-3 h-[28px] font-normal 
             rounded-[20px] border border-light-blue-10 flex items-center justify-center"
            >
              {feature_new}
            </div>
            {/* ))} */}
          </div>
        ) : null}
      </div>
      <hr className="opacity-10" />
      <span className="text-[12px] font-medium inline-block py-[8px] pl-[20px]">
        {similiarApartmentCount && `Таких квартир: ${similiarApartmentCount}`}
      </span>
    </div>
  );
};

export default InfoApartmentCard;
