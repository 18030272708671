import { UserRole } from "enums/users";
import { useAppSelector } from "store";
import { selectUser, selectUserIsLoading } from "store/UserReducer/selectors";

type UserRoleFlags = {
  -readonly [Key in keyof typeof UserRole as `is${Capitalize<
    Lowercase<Key>
  >}`]: boolean;
};

interface Res extends UserRoleFlags {
  isAvailable: boolean;
  currentRole?: UserRole;
  isLoading?: boolean;
}
export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const useRoles = (role: UserRole | UserRole[] | null): Res => {
  const data = useAppSelector(selectUser);
  const isLoading = useAppSelector(selectUserIsLoading);
  const currentRole = data?.data?.role;

  let res: Res = {
    isAvailable: false,
    isLoading: isLoading,
    currentRole: currentRole,
    ...Object.keys(UserRole).reduce((acc, key) => {
      if (key === currentRole) {
        acc[`is${capitalizeFirstLetter(key)}` as keyof UserRoleFlags] = true;
      } else {
        acc[`is${capitalizeFirstLetter(key)}` as keyof UserRoleFlags] = false;
      }

      return acc;
    }, {} as UserRoleFlags),
  };
  if (role === null) {
    res.isAvailable = true;
    return res;
  }
  return res;
};
