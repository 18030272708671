import React from "react";
import { MyApplication } from "./type";
import MyApplicationCard from "./MyApplicationCard";
import { DealStatus } from "enums/deals";
import AnimationFrame from "components/AnimationFrame.tsx";

interface MyApplicationListProps {
  status: DealStatus;
  data: MyApplication[];
}

const MyApplicationList: React.FC<MyApplicationListProps> = ({ status, data }) => {
  return (
    <div className=" mb-[20px] md:mb-[30px]">
      <h2 className=" text-[20px] md:text-2xl font-semibold mb-[10px] text-accent-default">
        {status}
      </h2>
      <AnimationFrame>
        <div className="grid  sm:grid-cols-2 lg:grid-cols-1 gap-[10px]">
          {data.map((item) => (
            <MyApplicationCard key={item.id} data={item} />
          ))}
        </div>
      </AnimationFrame>
    </div>
  );
};

export default MyApplicationList;
