import React, { useState, useCallback } from "react";
import Cropper, { Area } from "react-easy-crop";
import { getCroppedImg } from "./cropUtils";
import ImageUploadIcon from "components/constant/icons/ImageUploadIcon";

const ImgUploadWithCroping = ({
  title,
  requirementText,
  value,
  handleImageUpload,
  aspect = [19, 8],
}: {
  title: string;
  requirementText: string;
  value: string;
  handleImageUpload: (file: File) => void;
  aspect?: number[];
}) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setImageSrc(reader.result as string);
      };
    }
  };

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc!, croppedAreaPixels);
      const file = new File([croppedImage], "croppedImage.jpg", {
        type: "image/jpeg",
      });
      handleImageUpload(file);
      setImageSrc(null);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, imageSrc, handleImageUpload]);

  return (
    <>
      <div className="flex gap-[20px] items-center">
        <label
          className="flex flex-col justify-center border-2 border-dashed border-[#D7DCE4] rounded-md w-[136px] h-[90px] cursor-pointer overflow-hidden"
          htmlFor={`img-${title}`}
        >
          {value ? (
            <img
              src={value}
              crossOrigin="anonymous"
              alt="Selected"
              style={{ width: "100%" }}
            />
          ) : (
            <div>
              <div className="flex flex-col items-center">
                <ImageUploadIcon width="34" height="33" color="#D7DCE4" />
              </div>
            </div>
          )}
          <input
            id={`img-${title}`}
            type="file"
            accept="image/*"
            className="hidden"
            onChange={handleFileChange}
          />
        </label>
        <div className="text-accent-default flex flex-col gap-[10px]">
          <p className="text-[14px] font-semibold leading-4">{title}</p>
          <p className="text-[12px] font-normal leading-[14px] sm:w-[224px]">
            {requirementText}
          </p>
        </div>
      </div>
      {imageSrc && (
        <div className="w-full">
          <div style={{ position: "relative", width: "100%", height: 200 }}>
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={aspect[0] / aspect[1]}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          </div>
          <button
            className="mt-2 bg-accent-default text-white-default px-3 py-1 rounded text-sm"
            onClick={showCroppedImage}
          >
            Обрезать и загрузить
          </button>
        </div>
      )}
    </>
  );
};
export default ImgUploadWithCroping;
