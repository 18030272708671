import Label from "components/Form/Label";
import { UserData } from "components/Form/SearchField";
import MaskComponent from "components/MaskInput/MaskComponent";
import { useCheckingThefasteningMutation } from "data/api/base-clients";
import { ResponseStatusType } from "data/api/exception";
import { useEffect, useState } from "react";
import { checkExpirationStatus } from "utils/checkdateExpirationDate";
import showToast from "utils/showToast";
import ButtonForm from "../../components/Button/ButtonForm";
import TextField from "../../components/Form/TextField/TextField";
import Modal from "../../components/Modal";
import CloseWithBorderIcon from "../../components/constant/icons/CloseWithBorderIcon";
import PhoneIcon from "../../components/constant/icons/PhoneIcon";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useDispatch } from "react-redux";
import { toggleFasteningFormModal } from "store/HeaderReducer";
import { useLocation } from "react-router";
import { useAppSelector } from "store";
import {
  toggleBookingModal,
  toggleNotificationFormModal,
  toggleNotificationFormModal2,
} from "store/BaseClientsReducer";
import { selectUser } from "store/UserReducer/selectors";
import { FixingType, FixingTypeWithSmall } from "enums/clientFixingType";
import { useRoles } from "hooks/useRoles";
import Loader from "components/Loader";

interface NotFoundItemProps {
  nameValue: string;
  phoneValue: string;
  handleClipClient: () => void;
}
type StatusMessageItem = {
  key: string;
  content: (item: {
    name: string;
    phone: string;
    handle: () => void;
  }) => JSX.Element;
};

const NotFoundItem = ({
  nameValue,
  phoneValue,
  handleClipClient,
}: NotFoundItemProps) => {
  const roles = useRoles(null);
  return (
    <div className={"pt-[10px]"}>
      <p className="text-center mb-[20px] text-red-default font-medium text-sm">
        Клиент не закреплен
      </p>

      {roles.isAgent && (
        <ButtonForm
          text="Закрепить клиента"
          disabled={!nameValue || !phoneValue}
          className="hover:bg-bg-default py-3 px-6
                     bg-accent-default xl:flex-1 flex justify-center
                      text-white-default hover:text-accent-default w-full"
          onClick={handleClipClient}
        />
      )}
    </div>
  );
};

interface SuccessClipClientProps {
  nameValue: string;
  date?: string;
  status?: string;
  client_id?: number;
  phone?: string;
  handle?: () => void;
}

const SuccessClipClient = ({ nameValue, date }: SuccessClipClientProps) => {
  return (
    <div className={"pt-[10px]"}>
      <p className="text-center mb-[12px] font-normal text-sm">
        По клиенту <span className={"font-semibold"}>{nameValue}</span>{" "}
        установлено закрепление до
        <span className={"font-semibold ml-1"}>{date}</span>
      </p>

      <p className={"text-center mb-[20px] font-medium text-sm text-[#0FBB00]"}>
        Успешное закрепление
      </p>
    </div>
  );
};

interface ExpireClipClientProps
  extends SuccessClipClientProps,
    NotFoundItemProps {}

const ExpireClipClient = ({
  date,
  nameValue,
  phoneValue,
  handleClipClient,
}: ExpireClipClientProps) => {
  const roles = useRoles(null);
  return (
    <div className={"pt-[10px]"}>
      <p className="text-center mb-[12px] font-normal text-sm">
        Срок закрепления за клиентом
        <span className={"font-semibold"}>{nameValue}</span> истекает
        <span className={"font-semibold"}>{date}</span>
      </p>

      <p className={"text-center mb-[12px] font-normal text-sm"}>
        Если покупка для клиента актуальна, то направьте повторную форму
        уведомления на клиента или забронируйте помещение.
      </p>

      <p className={"text-center mb-[20px] font-medium text-sm text-[#DAAA00]"}>
        Истечение срока закрепления
      </p>

      {roles.isAgent && (
        <ButtonForm
          text="Закрепить клиента"
          disabled={!nameValue || !phoneValue}
          className="hover:bg-bg-default py-3 px-6
                     bg-accent-default xl:flex-1 flex justify-center
                      text-white-default hover:text-accent-default w-full"
          onClick={handleClipClient}
        />
      )}
    </div>
  );
};

const CannotClipClient = ({ nameValue }: SuccessClipClientProps) => {
  return (
    <div className={"pt-[10px]"}>
      <p className="text-center mb-[12px] font-normal text-sm">
        Клиент <span className={"font-semibold"}>{nameValue}</span> уже
        обращался в Бруснику.
      </p>

      <p
        className={"text-center mb-[12px] font-normal text-sm text-red-default"}
      >
        Закрепление за вами невозможно.
      </p>

      <p className={"text-center mb-[20px] font-medium text-sm text-[#0FBB00]"}>
        {FixingType.STRONG_FIXING}
      </p>
    </div>
  );
};

const AlreadyBooked = ({
  nameValue,
  status,
  handle,
}: SuccessClipClientProps) => {
  return (
    <div className={"pt-[10px]"}>
      <p className="text-center mb-[12px] font-normal text-sm">
        Клиент <span className={"font-semibold"}>{nameValue}</span> уже
        обращался в Бруснику.
      </p>

      <p
        className={"text-center mb-[12px] font-normal text-sm text-red-default"}
      >
        Закрепление за Вами возможно только при бронировании помещения.
      </p>
      <p className={"text-center mb-[20px] font-medium text-sm text-[#B8B8B8]"}>
        {status}
      </p>
      <ButtonForm
        text="Забронировать"
        className="hover:bg-bg-default py-3 px-6
                     bg-accent-default xl:flex-1 flex justify-center
                      text-white-default hover:text-accent-default w-full"
        onClick={handle}
      />
    </div>
  );
};

const NeedToPin = ({
  date,
  nameValue,
  phoneValue,
  handleClipClient,
  status,
}: ExpireClipClientProps) => {
  const role = useRoles(null);

  console.log("status", status);
  return (
    <div className={"pt-[10px]"}>
      {/* <p className="text-center mb-[12px] font-normal text-sm">
        Клиент <span className={"font-semibold"}>{nameValue}</span> уже
        обращался в Бруснику.
      </p> */}
      {/* <p
        className={"text-center mb-[12px] font-normal text-sm text-red-default"}
      >
      </p> */}{" "}
      <div className="flex items-center flex-col justify-center gap-2 mb-[20px] ">
        <p className="text-sm text-center">
          {!date && (
            <>
              По клиенту <span className="font-semibold">{nameValue}</span> не
              установлено закрепление
            </>
          )}
        </p>
        <p
          className={`text-center font-medium text-sm ${
            status === FixingTypeWithSmall.CENCEL_FIXING
              ? "text-red-default"
              : status === FixingTypeWithSmall.LEAD_VERIFICATION
              ? "text-blue-default"
              : "text-[#0FBB00]"
          }`}
        >
          {status}
        </p>
      </div>
      {role.isAgent && (
        <ButtonForm
          text="Закрепить клиента"
          disabled={!nameValue || !phoneValue}
          className="hover:bg-bg-default py-3 px-6
                     bg-accent-default xl:flex-1 flex justify-center
                      text-white-default hover:text-accent-default w-full"
          onClick={handleClipClient}
        />
      )}
    </div>
  );
};

interface PropsComponent {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  client?: UserData;
  handleToggle?: () => void;
}

const BaseClientsAnchoringModal = ({
  isOpen,
  setIsOpen,
  client,
  handleToggle,
}: PropsComponent) => {
  const { isSm } = useWindowSize();

  const { pathname } = useLocation();

  const [nameValue, setNameValue] = useState("");

  const [phoneValue, setPhoneValue] = useState("");

  const [statusMessageSelect, setStatusMessageSelect] = useState<any>(null);

  const me = useAppSelector(selectUser);

  const statusMessageList: StatusMessageItem[] = [
    {
      key: "not_found",
      content: (item: any) => (
        <NotFoundItem
          nameValue={item.name}
          phoneValue={item.phone}
          handleClipClient={item.handle}
        />
      ),
    },
    {
      key: "success",
      content: (item: any) => (
        <SuccessClipClient nameValue={item.name} date={item.date} />
      ),
    },
    {
      key: "gonna_expire",
      content: (item: any) => (
        <ExpireClipClient
          date={item.date}
          nameValue={item.name}
          phoneValue={item.phone}
          handleClipClient={item.handle}
        />
      ),
    },
    {
      key: "expire",
      content: (item: any) => (
        <ExpireClipClient
          date={item.date}
          nameValue={item.name}
          phoneValue={item.phone}
          handleClipClient={item.handle}
        />
      ),
    },
    {
      key: "cannot",
      content: (item: any) => <CannotClipClient nameValue={item.name} />,
    },
    {
      key: "weak_booked",
      content: (item: any) => (
        <AlreadyBooked
          nameValue={item?.name}
          status={item?.status}
          handle={item.handle}
        />
      ),
    },
    {
      key: "needToPin",
      content: (item: any) => (
        <NeedToPin
          nameValue={item?.name}
          phoneValue={item?.phone}
          handleClipClient={item?.handle}
          status={item?.status}
        />
      ),
    },
  ];

  const [fasteningMutate, { isLoading }] = useCheckingThefasteningMutation();

  const dispatch = useDispatch();

  useEffect(() => {
    init();
  }, [client]);

  function init() {
    if (client?.id) {
      setNameValue(client.name);
      client.phone && setPhoneValue(client.phone);
    }
  }

  function handleBookingModal() {
    dispatch(toggleBookingModal());
  }

  const handleOpenFasteningModal = (key: string) => {
    handleModalClose();
    if (
      key === "gonna_expire" ||
      key === "expire" ||
      key === "not_found" ||
      key === "needToPin"
    ) {
      handleModalClose?.();
      dispatch(
        toggleNotificationFormModal2({ name: nameValue, phone: phoneValue })
      );

      if (pathname === "/base-clients")
        return dispatch(toggleNotificationFormModal());

      dispatch(toggleFasteningFormModal(true));
    } else if (key === "weak_booked") {
      handleModalClose?.();
      handleBookingModal();
    }
  };

  async function handleCheckClient() {
    const query = `fullname=${nameValue}&phone_number=${phoneValue}`;

    const { data: res, error } = await fasteningMutate(query);

    if (error?.message) {
      showToast(error?.message, "error", 2000);
      return;
    }

    if (res?.meta?.type === ResponseStatusType.SUCCESS) {
      let findItem: StatusMessageItem | undefined;

      if (!res.data) {
        findItem = statusMessageList.find((item) => item.key === "not_found");
      } else {
        if (me?.data?.id !== res.data.agent_id) {
          if (res.data?.fixing_type === FixingTypeWithSmall.STRONG_FIXING) {
            findItem = statusMessageList.find((item) => item.key === "cannot");
          } else if (
            res.data?.fixing_type === FixingTypeWithSmall.WEAK_FIXING
          ) {
            findItem = statusMessageList.find(
              (item) => item.key === "weak_booked"
            );
          } else if (
            res.data?.fixing_type === FixingTypeWithSmall.CENCEL_FIXING ||
            res.data?.fixing_type === FixingTypeWithSmall.LEAD_VERIFICATION
          ) {
            findItem = statusMessageList.find(
              (item) => item.key === "needToPin"
            );
          }
        } else if (res.data?.expiration_date) {
          if (res.data?.fixing_type === FixingTypeWithSmall.LEAD_VERIFICATION || res.data?.fixing_type === FixingTypeWithSmall.CENCEL_FIXING ) {
            findItem = statusMessageList.find(
              (item) => item.key === "needToPin"
            );
          } else {
            const status = checkExpirationStatus(res.data.expiration_date);
            switch (status) {
              case "expired":
                findItem = statusMessageList.find(
                  (item) => item.key === "expire"
                );
                break;

              case "gonna_expire":
                findItem = statusMessageList.find(
                  (item) => item.key === "gonna_expire"
                );
                break;

              case "success":
                findItem = statusMessageList.find(
                  (item) => item.key === "success"
                );
                break;

              default:
                findItem = undefined;
            }
          }
        } else if (!res.data?.expiration_date) {
          findItem = statusMessageList.find((item) => item.key === "needToPin");
        }
      }

      const props: any = {
        name: nameValue,
        phone: phoneValue,
        status: res?.data?.fixing_type,
        date: "05.09.2024",
        client_id: res?.data?.id,
        handle: () => findItem && handleOpenFasteningModal(findItem?.key),
      };

      // Set the status message if findItem is found
      if (findItem) {
        setStatusMessageSelect(findItem.content(props));
      }
    }
  }

  // async function handleClipClient() {
  //   const query = `fullname=${nameValue}&phone_number=${phoneValue}`;

  //   const { data: res, error } = await clipFasteningMutate(query);
  //   if (error?.message) {
  //     showToast(error?.message, "error", 2000);
  //     return;
  //   }

  //   const findItem = statusMessageList.find((item) => res?.data?.status);

  //   const props: any = {
  //     name: nameValue,
  //     phone: phoneValue,
  //     date: "28.06.2024", // TODO: res?.data?.date
  //     handle: () => handleClipClient(),
  //   };

  //   findItem && setStatusMessageSelect(findItem.content(props));
  // }

  function clearFields() {
    setNameValue("");
    setPhoneValue("");
    setStatusMessageSelect(null);
  }

  function handleModalClose() {
    clearFields();
    setIsOpen(false);
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        className="md:w-[500px] w-full rounded-t-[30px] sm:rounded-lg"
        onClose={handleModalClose}
      >
        <div className={"flex md:justify-between justify-center mb-3"}>
          <h1 className={"font-extrabold text-[25px] font-dewi"}>
            Проверка <br className="hidden md:block" /> закрепления
          </h1>

          {!isSm && (
            <ButtonForm
              text={<CloseWithBorderIcon />}
              onClick={handleModalClose}
            />
          )}
        </div>

        {isLoading && (
          <div>
            <Loader />
          </div>
        )}

        <div className={"py-[10px]"}>
          <TextField
            value={nameValue}
            placeholder="Введите ФИО"
            label="ФИО"
            type="text"
            name="firstName"
            onChange={(e) => {
              setStatusMessageSelect(null);
              setNameValue(e.target.value);
            }}
            className={
              "min-w-[175px] xl:min-w-[238px] md:rounded-[4px!important]"
            }
          />

          <Label className={"mt-[20px]"} label="Номер телефона">
            <MaskComponent
              name="phone"
              imgLeft={<PhoneIcon />}
              value={phoneValue}
              onChange={(value: string) => {
                setStatusMessageSelect(null);
                setPhoneValue(value);
              }}
            />
          </Label>
        </div>

        {statusMessageSelect && statusMessageSelect}

        <div className={"pt-[10px]"}>
          <ButtonForm
            text="Проверить"
            disabled={!!statusMessageSelect}
            className="hover:bg-bg-default py-3 px-6
                     bg-accent-default xl:flex-1 flex justify-center
                      text-white-default hover:text-accent-default w-full"
            onClick={handleCheckClient}
          />
        </div>
      </Modal>
    </>
  );
};

export default BaseClientsAnchoringModal;
