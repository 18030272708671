import React, { useEffect, useMemo, useRef, useState } from "react";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import timeGridPlugin from "@fullcalendar/timegrid";
import rrulePlugin from "@fullcalendar/rrule";
import { FullCalendarComponentEventItem } from "./FullCalendarComponentEventItem";
import SpinnerIcon from "../../components/constant/icons/SpinnerIcon";
import { useGetCalendarQuery } from "data/api/calendar";
import { useFormatForDropDownOptions } from "hooks/useDropDownOption";
import { useGetCitiesQuery } from "data/api/city";
// import {  } from "../../data/api/profile";
import FullCalendarMobileModal from "./FullCalendarMobileModal";
import { useAppSelector } from "store";
import { selectUser } from "store/UserReducer/selectors";
import Loader from "components/Loader";

interface FullCalendarMobileProps {
	handleOpenModal: (item: any) => void;
	isOpenMobileModal: boolean;
	listCalendar: any[];
	selectedDate: Date | string;
}

const FullCalendarMobile = (
	{
		handleOpenModal,
		isOpenMobileModal,
		listCalendar,
		selectedDate,
	}: FullCalendarMobileProps) => {
	const slotMinTime = "10:00:00";
	const slotMaxTime = "18:00:00";

	const calendarRef = useRef<any>(null);

	const [state, setState] = useState<any | null>(null);

	const {data, isLoading} = useGetCalendarQuery(state?.value);
	const res = useGetCitiesQuery();
	const options = useFormatForDropDownOptions(res?.data?.data ?? []);
	const userData = useAppSelector(selectUser);
	const profileData = userData?.data;

	const [contentModal, setContentModal] = useState<any>({});

	const [isOpenModal, setIsOpenModal] = useState(false);

	const timeStringToMinutes = (timeString: string) => {
		const [hours, minutes, seconds] = timeString.split(":").map(Number);
		return hours * 60 + minutes + seconds / 60;
	};

	const minTimeInMinutes = timeStringToMinutes(slotMinTime);
	const maxTimeInMinutes = timeStringToMinutes(slotMaxTime);

	const differenceInMinutes = maxTimeInMinutes - minTimeInMinutes;

	const differenceInHours = differenceInMinutes / 60;

	useEffect(() => {
		if (isOpenMobileModal) {
			setIsOpenModal(true);
		}
	}, [isOpenMobileModal]);

	useEffect(() => {
		if (calendarRef.current && selectedDate) {
			calendarRef.current.getApi().gotoDate(selectedDate);
		}
	}, [selectedDate]);

	useEffect(() => {
		if (!state && profileData?.city?.id) {
			const findCity = options.find(
				(item) => item.value === profileData?.city?.id,
			);

			findCity && setState(findCity);
		}
	}, [profileData, state]);

	function searchData(id: number | string, key: string) {
		return listCalendar.map(i => (
			{
				...i,
				backgroundColor: i.backgroundColorMobile
			}
		)).find((i) => +i.id === id && key === i.title);
	}

	function onClickCalendarNew(info: any) {
	}

	function onClickCalendar(item: any) {
		setContentModal(item);
		setIsOpenModal(true);
	}

	if (isLoading)
		return (
			<div className="flex justify-center items-center h-[40vh] px-[20px] py-[10px] relative">
				<Loader/>
			</div>
		);

	return (
		<>
			<div
				className={
					"relative bg-white-default rounded-lg overflow-hidden calendar-block-mobile z-0"
				}
			>
				<FullCalendar
					ref={calendarRef}
					plugins={[
						timeGridPlugin,
						resourceTimelinePlugin,
						interactionPlugin,
						dayGridPlugin,
						rrulePlugin,
					]}
					initialView={"dayGridMonth"}
					schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
					locale="ru"
					height={`${145.5 * differenceInHours}px`}
					allDaySlot={false}
					slotLabelFormat={{
						hour: "2-digit",
						minute: "2-digit",
						omitZeroMinute: false,
						meridiem: "short",
					}}
					eventDisplay="block"
					slotLabelInterval="01:00"
					slotDuration="00:30:00"
					snapDuration="00:30:00"
					slotMinTime={slotMinTime || "08:00:00"}
					slotMaxTime={slotMaxTime || "20:00:00"}
					slotLabelContent={({text}) => {
						return <span className="customTimeLine">{text}</span>;
					}}
					titleFormat={(arg) => {
						const month = new Date(arg.date.marker).toLocaleString("default", {
							month: "long",
						});
						const year = arg.date.year;

						return `${month} ${year}`;
					}}
					headerToolbar={{
						start: "prev,title,next", // will normally be on the left. if RTL, will be on the right
						center: "",
						end: "", // will normally be on the right. if RTL, will be on the left
					}}
					themeSystem="bootstrap5"
					firstDay={1} // Первый день понедельник, начинается с воскресенья
					eventContent={(info) => {
						return (
							<FullCalendarComponentEventItem
								info={info}
								item={searchData(Number(info.event.id), info.event.title)}
								isMobile
							/>
						);
					}}
					eventTextColor="#000"
					eventBorderColor="transparent"
					dateClick={(info) => {
						const date = new Date();
						const selectedDate = info.date;

						if (date > selectedDate) {
							return;
						}

						if (onClickCalendarNew) onClickCalendarNew(info);
					}}
					eventClick={(info) => {
						const item = listCalendar.find(
							(i) => i.id === Number(info.event.id),
						);

						onClickCalendar(item);
					}}
					events={listCalendar}
					showNonCurrentDates={false} // Показывать дни других месяцев
				/>
			</div>

			<FullCalendarMobileModal
				isOpen={isOpenModal}
				setIsOpen={setIsOpenModal}
				select={contentModal}
				list={listCalendar}
				handleOpenModal={handleOpenModal}
			/>
		</>
	);
};

export default FullCalendarMobile;
