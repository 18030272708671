import React, { Dispatch, SetStateAction } from "react";
import { EventsCategory, eventsCategoryOptions } from "enums/Events";

interface Props {
  setCategory: Dispatch<SetStateAction<EventsCategory>>;
  category: EventsCategory;
}

const EventsCategories = ({ setCategory, category }: Props) => {
  return (
    <div className="flex gap-[10px] no-scrollbar overflow-x-auto md:pb-2">
      {[
        { label: "Все", value: EventsCategory.ALL },
        ...eventsCategoryOptions,
      ].map((item, i) => (
        <div
          key={i}
          onClick={() => setCategory(item.value)}
          className={`py-[7px] px-[15px] lg:py-[11px] lg:px-6 flex text-center rounded-[32px] text-sm font-normal  cursor-pointer ${
            category === item.value
              ? "bg-accent-default text-white-default"
              : "bg-white-default text-accent-default"
          }`}
        >
          {item.label}
        </div>
      ))}
    </div>
  );
};

export default EventsCategories;
