import React, { ChangeEvent, useMemo, useRef, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router";

import ImageUploadIcon from "components/constant/icons/ImageUploadIcon";
import TextField from "components/Form/TextField/TextField";
import Dropdown from "components/Form/Dropdown/Dropdown";
import ButtonForm from "components/Button/ButtonForm";
import PencilIcon from "components/constant/icons/PencilIcon";
import Modal from "components/Modal";
import Loader from "components/Loader";
import Label from "components/Form/Label";
import PhoneIcon from "components/constant/icons/PhoneIcon";
import { UseSMSCodeSender } from "hooks/useSMSCodeSender";
import showToast from "utils/showToast";
import { images } from "utils/resource";

import { useGetCitiesQuery } from "data/api/city";
import { useFormatForDropDown } from "hooks/useDropDownOption";
import { useGetAllAgenciesQuery } from "data/api/agency";
import { useUploadFileMutation } from "data/api/file";
import {
  profileApiHooks,
  useUpdatePhoneMutation,
  useVerifyUserPhoneMutation,
} from "data/api/profile";

import { Agency } from "data/api/agency/type";
import { City } from "data/api/city/type";
import { mamberLocalName, roleOptions, UserRole } from "enums/users";
import { userProfileFormDataType } from "./type";
import { ResponseStatusType } from "data/api/exception";
import { useRoles } from "hooks/useRoles";
import MaskComponent from "components/MaskInput/MaskComponent";
import { useAppDispatch, useAppSelector } from "store";
import {
  selectUser,
  selectUserUpdatIsLoading,
} from "store/UserReducer/selectors";
import { updateUserAction } from "store/UserReducer/actions";
import { logOutAction } from "store/UserReducer";
import { useWindowSize } from "hooks/useWindowSize";
import { handleSelectCity } from "store/HeaderReducer";
import { useIMask } from "react-imask";
import { keycloak, logout } from "services";

const Profile = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [isEditable, setIsEditable] = useState(false);

  const [isSMSCodeVisible, setIsSMSCodeVisible] = useState(false);

  const [formStatus, setFormStatus] = useState(0);

  const { isSm } = useWindowSize();

  const navigate = useNavigate();

  const data = useAppSelector(selectUser);

  const isLoading = useAppSelector(selectUserUpdatIsLoading);

  const dispatch = useAppDispatch();

  const { data: agencies } = useGetAllAgenciesQuery();

  const [smsVerify, setSmsVerify] = useState(0);

  const { counter, handleResent, updateCounter } = UseSMSCodeSender({});

  const [phoneMutate] = useUpdatePhoneMutation();

  const [phoneVerify] = useVerifyUserPhoneMutation();

  const [uploadFile] = useUploadFileMutation();

  const { data: cities } = useGetCitiesQuery();

  const roles = useRoles(null);

  const optionsCity = useFormatForDropDown<City>(cities?.data ?? []);

  const optionsAgencies = useFormatForDropDown<Agency>(
    agencies?.data ?? [],
    "legalName"
  );

  const opts = {
    mask: "+{7} (000) 000-00-00",
    lazy: false,
    overwrite: true,
    placeholderChar: "_",
  };

  const { value, ref, setUnmaskedValue, unmaskedValue } = useIMask(opts, {
    defaultTypedValue: data?.data.phone,
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    setValue,
  } = useForm<userProfileFormDataType>({
    values: {
      role: data?.data.role,
      firstName: data?.data.firstName,
      lastName: data?.data.lastName,
      email: data?.data.email,
      avatar: data?.data.avatar,
      city_id: data?.data.city?.id,
      agency_id: data?.data.agency?.id,
    },
  });

  async function handleVerifyPhone() {
    const res = await phoneVerify({
      data: { code: smsVerify },
    });
    if (res?.data?.meta?.type === ResponseStatusType.SUCCESS) {
      setIsSMSCodeVisible(false);
      return res;
    }
    if (res.error?.message) {
      showToast(res.error.message, "error", 2000);
      return;
    }
  }

  const onSubmit: SubmitHandler<userProfileFormDataType> = async (formData) => {
    if (isSMSCodeVisible) {
      const verifyRes = await handleVerifyPhone();
      if (verifyRes?.data?.meta?.type === ResponseStatusType.SUCCESS) {
        updateUserData(formData);
        setUnmaskedValue(unmaskedValue);
        setSmsVerify(0);
        return;
      }
      if (verifyRes?.error?.message) {
        showToast(verifyRes?.error.message, "error", 2000);
        return;
      }
    } else {
      updateUserData(formData);
    }
  };

  async function updateUserData(newData: userProfileFormDataType) {
    if (isDirty || isSMSCodeVisible || newData !== data?.data.avatar) {
      try {
        const oldCity = data?.data?.city?.id ?? newData.city_id;
        const res = await dispatch(
          updateUserAction({ data: newData })
        ).unwrap();
        if (!isPhoneNumberSameWithUsers) {
        }
        setFormStatus(200);

        if (newData.city_id !== oldCity && isSm) {
          setTimeout(() => {
            dispatch(handleSelectCity(Number(newData.city_id)));
            sessionStorage.setItem("city_id", newData.city_id + "");
          }, 2000);
        }
        setUnmaskedValue(res?.data.phone || "");
      } catch (err) {
        const error = err as Error;
        showToast(error.message, "error", 2000);
      }
    } else {
      setUnmaskedValue(data?.data.phone || "");
      setIsEditable(false);
    }
  }

  const handleFileInputClick = () => {
    fileInputRef.current?.click();
  };

  const handleModalClose = () => {
    setFormStatus(0);
    setIsEditable(false);
    setIsSMSCodeVisible(false);
  };

  const handleEditClick = () => {
    setIsEditable(true);
  };

  const handleLogOut = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("phone");
    localStorage.removeItem("userId");
    localStorage.removeItem("old_token");

    await logout();

    navigate("/sign-in");
    dispatch(profileApiHooks.util.resetApiState());
    dispatch(logOutAction());
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("image", file);
      try {
        const response = await uploadFile(formData).unwrap();
        const fileName = response.data.filename;
        setValue("avatar", fileName);
      } catch (error) {
        console.error("File upload failed", error);
      }
    }
  };

  const isPhoneNumberSameWithUsers = useMemo(() => {
    return unmaskedValue && unmaskedValue === data?.data.phone;
  }, [unmaskedValue, data?.data.phone]);

  const handlePhoneSubmit = async () => {
    if (unmaskedValue && !isSMSCodeVisible)
      try {
        const { data: res, error } = await phoneMutate({
          data: { phone: unmaskedValue },
        });

        if (res?.data.user_id) {
          setIsSMSCodeVisible(true);
          updateCounter();
          localStorage.setItem("phone", unmaskedValue);
        }
        if (error?.message) {
          showToast(error.message, "error", 2000);
        }
      } catch (error) {
        console.log("err", error);
      }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value;
    handleValueChange(inputValue);
  };

  const handleResentCode = () => {
    handleResent();
    setSmsVerify(0);
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData("Text");
    handleValueChange(pastedText);
  };

  const handleValueChange = (inputValue: string) => {
    let cleanedValue = inputValue.replace(/\D/g, "");

    if (cleanedValue.startsWith("7") && cleanedValue.length > 10) {
      cleanedValue = cleanedValue.slice(1);
    } else if (cleanedValue.startsWith("8") && cleanedValue.length > 9) {
      cleanedValue = cleanedValue.slice(1);
    }

    const normalizedValue = cleanedValue.slice(0, 10);
    setUnmaskedValue(normalizedValue);
  };

  const getValue = (
    opts: { label: string | number; value: string | number }[],
    val: number | string | undefined
  ) => {
    if (val) {
      return opts.filter((o) => o.value == val);
    }
    return null;
  };

  const imgUrl = process.env.REACT_APP_FILES_URL;
  const remainingBooking = data?.meta?.data?.remaining_user_creation_limit ?? 0;

  return (
    <div className="bg-bg-default">
      <div className="br-container py-8 flex justify-center">
        <div className="w-[440px] p-8 bg-white-default rounded-lg">
          <div className="flex flex-col items-center gap-4">
            <Controller
              name="avatar"
              control={control}
              render={({ field: { value } }) => (
                <div className="relative">
                  <img
                    src={value ? `${imgUrl}/${value}` : images.defaultImg}
                    className="w-[100px] h-[100px] rounded-full"
                    alt="profile"
                    crossOrigin="anonymous"
                  />
                  {isEditable && (
                    <div
                      onClick={handleFileInputClick}
                      className="w-[34px] h-[34px] flex items-center border border-boder-default cursor-pointer justify-center bg-white-default absolute right-0 bottom-0 rounded-lg"
                    >
                      <input
                        type="file"
                        className="hidden"
                        onChange={handleFileChange}
                        ref={fileInputRef}
                        disabled={!isEditable}
                      />

                      <ImageUploadIcon />
                    </div>
                  )}
                </div>
              )}
            />
            <div className="text-center">
              <h2 className="text-[24px] font-semibold leading-[28px]">
                {data?.data.fullName}
              </h2>
              <p className="text-[14px] font-medium leading-[18px] text-accent-disabled text-center mt-[2px]">
                {/* @ts-ignore */}
                {mamberLocalName[data?.data?.role]}
              </p>
              {(roles.isAgent || roles.isHead_of_agency) && (
                <p className="text-[14px] font-medium leading-[18px] text-red-default text-center mt-[10px]">
                  Осталось бронирований:
                  <span className="ml-1">
                    {remainingBooking < 0 ? 0 : remainingBooking}
                  </span>
                </p>
              )}
            </div>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-5 mt-10 relative"
          >
            {isLoading && <Loader />}
            <Controller
              name="firstName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  className={isEditable ? "" : "opacity-50"}
                  placeholder="Введите Ваше имя"
                  label="Имя"
                  disabled={!isEditable}
                  errors={errors}
                />
              )}
            />
            <Controller
              name="lastName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  value={value}
                  className={isEditable ? "" : "opacity-50"}
                  placeholder="Ваша фамилия"
                  label="Фамилия"
                  disabled={!isEditable}
                  errors={errors}
                />
              )}
            />

            <Label
              label="Номер телефона"
              className={isEditable ? "" : "opacity-50"}
            >
              <div className="relative">
                <div
                  className={`form-icon-left absolute top-0 bottom-0 flex items-center justify-center left-[16px]`}
                >
                  <PhoneIcon />
                </div>
                {!isPhoneNumberSameWithUsers && (
                  <div
                    className={`form-icon-right absolute top-0 bottom-0 flex items-center justify-center right-[16px]`}
                  >
                    <button
                      onClick={handlePhoneSubmit}
                      disabled={unmaskedValue.length !== 11}
                      type="button"
                      className={`text-blue-default ${
                        unmaskedValue.length !== 11 && "opacity-50"
                      } text-[12px] font-medium`}
                    >
                      Отправить код
                    </button>
                  </div>
                )}
                <input
                  ref={ref as React.LegacyRef<HTMLInputElement>}
                  value={value}
                  type="text"
                  name="phone"
                  className="outline-none border border-boder-default py-3 pl-[45px] w-full text-sm rounded text-accent-default placeholder:text-accent-default"
                  onChange={handleInputChange}
                  onPaste={handlePaste}
                  placeholder="+7 (909)"
                  disabled={!isEditable}
                />
              </div>
            </Label>

            {isSMSCodeVisible && (
              <TextField
                value={smsVerify}
                placeholder="Введите код"
                label="Код из смс"
                type="text"
                onChangeValue={(value) => setSmsVerify(Number(value))}
                maxLength={6}
                imgRight={
                  <>
                    {counter === 0 ? (
                      <span
                        onClick={handleResentCode}
                        className="leading-[18px] text-blue-default text-[14px] flex items-center h-full justify-center cursor-pointer font-medium"
                      >
                        Отправить повторно
                      </span>
                    ) : (
                      <span className="text-[14px] flex items-center justify-center text-accent-default font-medium">
                        00:{counter >= 10 ? counter : `0${counter}`}
                      </span>
                    )}
                  </>
                }
                errors={errors}
              />
            )}
            <Controller
              name="email"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  value={value}
                  className={isEditable ? "" : "opacity-50"}
                  placeholder="Ваша почта"
                  label="Почта"
                  type="email"
                  disabled={!isEditable}
                  errors={errors}
                />
              )}
            />
            <Label label="Роль" className={isEditable ? "" : "opacity-50"}>
              <Controller
                name="role"
                control={control}
                render={({ field: { onChange } }) => (
                  <Dropdown
                    onChange={onChange}
                    options={roleOptions}
                    placeholder="Должность"
                    disabled
                    errors={errors}
                    name="role"
                    value={{
                      // @ts-ignore
                      label: mamberLocalName[data?.data?.role],
                      value: data?.data.role,
                    }}
                  />
                )}
              />
            </Label>
            <Label label="Ваш город" className={isEditable ? "" : "opacity-50"}>
              <Controller
                name="city_id"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Dropdown
                    onChange={(e) => onChange(e.value)}
                    options={optionsCity}
                    placeholder="Город не выбран"
                    interactive={isEditable}
                    errors={errors}
                    name="city"
                    value={getValue(optionsCity, value)}
                  />
                )}
              />
            </Label>
            {roles.currentRole !== UserRole.ADMIN &&
              roles.currentRole !== UserRole.AFFILIATE_MANAGER &&
              roles.currentRole !== UserRole.MANAGER &&
              roles.currentRole !== UserRole.OZK_MANAGER && (
                <Label
                  label="Агентство недвижимости"
                  className={isEditable ? "" : "opacity-50"}
                >
                  <Controller
                    name="agency_id"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Dropdown
                        onChange={(e) => onChange(e.value)}
                        options={optionsAgencies}
                        disabled={roles.isHead_of_agency || !isEditable}
                        placeholder="Агенство не выбран"
                        value={getValue(optionsAgencies, value)}
                        errors={errors}
                      />
                    )}
                  />
                </Label>
              )}
            {isEditable && (
              <ButtonForm
                type="submit"
                disabled={!isPhoneNumberSameWithUsers && !smsVerify}
                className={`py-[12px] w-full
             text-center flex justify-center mt-6
                bg-accent-default text-white-default
              `}
                text="Сохранить"
              />
            )}
          </form>
          {!isEditable && (
            <ButtonForm
              type="button"
              leftIcon={<PencilIcon />}
              onClick={handleEditClick}
              className={`border-[1px] border-accent-default py-[12px] w-full
            hover:bg-accent-default hover:text-white-default text-center flex  mt-[44px] justify-center  bg-white-default text-accent-default
            `}
              text={"Редактировать"}
            />
          )}
          <ButtonForm
            type="button"
            onClick={handleLogOut}
            className={`border-[1px] border-accent-default py-[12px] w-full
            hover:bg-accent-default hover:text-white-default text-center flex justify-center mt-[15px] bg-white-default text-accent-default
            `}
            text={"Выйти"}
          />
        </div>
      </div>
      <Modal
        isOpen={formStatus === 200}
        className="md:w-[419px] rounded-lg"
        containerClassName="items-center justify-center"
      >
        <h2 className="text-[25px] leading-[30px] text-center text-accent-default font-extrabold mb-[10px] ">
          Успешно
        </h2>
        <p className="mb-[20px] text-[14px] leading-[18px] text-center text-accent-default font-normal">
          Ваши персональные данные обновляются
        </p>
        <ButtonForm
          className="bg-accent-default py-[16px] w-full
             text-white-default text-center flex justify-center hover:bg-accent-focus "
          text={"Закрыть"}
          onClick={handleModalClose}
        />
      </Modal>
    </div>
  );
};

export default Profile;
