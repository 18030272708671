import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Apartment } from "modules/Apartment/ApartmentList/dataTransformer";
import { Primise } from "enums/primisesType";
import { ApartmentFilterRange } from "data/api/premises";

export interface SelectionState {
  type: Primise;
  chess: boolean;
  generateSelection: boolean;
  storedData: number[];
  count: { [key in Primise]: number };
  filteredData: Apartment[];
  recordForShowModalById: number | null;
  recordBookingById: number | null;
  rangeData: ApartmentFilterRange | null;
}

const initialState: SelectionState = {
  rangeData: null,
  type: Primise.APARTMENT,
  chess: false,
  generateSelection: false,
  storedData: [],
  recordForShowModalById: null,
  recordBookingById: null,
  filteredData: [],
  count: {
    [Primise.APARTMENT]: 0,
    [Primise.PARKING]: 0,
    [Primise.STOREROOM]: 0,
    [Primise.COMMERCIAL]: 0,
  },
};

const selectionSlice = createSlice({
  name: "apartment",
  initialState,
  reducers: {
    selectType: (state, action: PayloadAction<Primise>) => {
      state.type = action.payload;
    },
    chessAppearance: (state) => {
      if (state.generateSelection) {
        state.chess = false;
        return;
      }
      state.chess = !state.chess;
    },
    toggleGenerateSelection: (state) => {
      state.generateSelection = !state.generateSelection;
    },
    toggleItemInStore: (
      state,
      action: PayloadAction<{ id: number; type?: Primise }>
    ) => {
      const { id, type } = action.payload;

      const itemType = type ?? state.type;

      if (itemType) {
        const itemIndex = state.storedData.indexOf(id);

        if (itemIndex !== -1) {
          state.storedData.splice(itemIndex, 1);
          if (state.count[itemType] !== 0) {
            state.count[itemType] -= 1;
          }
        } else {
          state.storedData.push(id);
          state.count[itemType] += 1;
        }
      }
    },
    resetState: (state) => {
      state.storedData = initialState.storedData;
      state.count = initialState.count;
    },
    handleDeteleteItemInStore: (
      state,
      action: PayloadAction<{ type: Primise; id: number }>
    ) => {
      const { type, id } = action.payload;
      const itemIndex = state.storedData.indexOf(id);
      if (itemIndex !== -1) {
        state.storedData.splice(itemIndex, 1);
        state.count[type] -= 1;
      }
    },
    handleStoreFilteredData: (state, action: PayloadAction<Apartment[]>) => {
      state.filteredData = action.payload;
    },
    toggleRecordForShowModal: (state, action: PayloadAction<number | null>) => {
      const cardId = action.payload;
      state.recordForShowModalById =
        state.recordForShowModalById === cardId ? null : cardId;
    },
    toggleBookingModal: (state, action: PayloadAction<number | null>) => {
      const cardId = action.payload;
      state.recordBookingById =
        state.recordBookingById === cardId ? null : cardId;
    },
    setApartmentFilterRangeData: (
      state,
      action: PayloadAction<ApartmentFilterRange | null>
    ) => {
      state.rangeData = action.payload;
    },
  },
});

export const {
  selectType,
  chessAppearance,
  toggleGenerateSelection,
  toggleItemInStore,
  resetState,
  handleDeteleteItemInStore,
  handleStoreFilteredData,
  toggleRecordForShowModal,
  toggleBookingModal,
  setApartmentFilterRangeData,
} = selectionSlice.actions;
export default selectionSlice.reducer;
