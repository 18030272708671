import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Modal from "components/Modal";
import CircleXIcon from "components/constant/icons/CircleXIcon";
import Dropdown from "components/Form/Dropdown/Dropdown";
import ToggleSwitcher from "components/ToggleSwitcher";
import ButtonForm from "components/Button/ButtonForm";
import ImageUploadIcon from "components/constant/icons/ImageUploadIcon";
import TegField from "components/Form/TegField";
import ImageSelectModal from "./ImageSelectModal";
import { useGetApartmentImagesQuery } from "data/api/premises";
import { useRoles } from "hooks/useRoles";
import { images } from "utils/resource";

type RadioBtn = {
  id: number;
  label: string;
  value: boolean;
};

export type Tags = string[];

export interface State {
  tags: Tags;
  selectedImg?: string | undefined;
  purchaseoptions?: string;
  logo?: string;
  radioBtnData?: RadioBtn[];
}
interface PropsType {
  isOpen: boolean;
  setIsOpen: React.Dispatch<SetStateAction<boolean>>;
  imgList: { src: string }[];
  stateAddForm: State;
  setStateAddForm: Dispatch<SetStateAction<State>>;
  setGenirateModal: Dispatch<SetStateAction<boolean>>;
  purchaseOptions: any;
}
const AdvertisementForm = ({
  isOpen,
  setIsOpen,
  setGenirateModal,
  stateAddForm,
  setStateAddForm,
  purchaseOptions,
}: PropsType) => {
  const [photoSelectModal, setPhotoSelectModal] = useState(false);

  const [selectedImgId, setSelectedImgId] = useState<string>();

  const { data: imagesData } = useGetApartmentImagesQuery();

  const [imagesArr, setImage] = useState<string[]>([]);

  const purchaseOptions2 = [...purchaseOptions];

  const roles = useRoles(null);

  const logoToggleOptions = [
    { label: "Нет", value: "false" },
    { label: "Логотип", value: "true" },
  ];

  const toggleRadioValue = (id: number) => {
    setStateAddForm((prevState: State) => ({
      ...prevState,
      radioBtnData:
        prevState.radioBtnData &&
        prevState.radioBtnData.map((item) =>
          item.id === id ? { ...item, value: !item.value } : item
        ),
    }));
  };

  const handleSelectImage = () => {
    if (selectedImgId) {
    }
    setStateAddForm((prevState: State) => ({
      ...prevState,
      selectedImg: selectedImgId,
    }));
    setSelectedImgId(selectedImgId);
    setPhotoSelectModal(false);
  };

  const handleGenerate = () => {
    if (!selectedImgId) {
      setStateAddForm((prevState: State) => ({
        ...prevState,
        selectedImg: imagesArr[0] ?? images.Maketbg1,
      }));
    }
    setIsOpen(false);
    setGenirateModal(true);
  };

  useEffect(() => {
    if (imagesData) {
      const imageFilenames = imagesData.data?.map((image) => image?.image);
      setImage(imageFilenames);
    }
  }, [imagesData]);

  return (
    <Modal className="w-full md:w-[500px] rounded-[8px]" isOpen={isOpen}>
      <div className="overflow-y-auto max-h-[75vh] pr-3 toolbar-scroll">
        <div className=" text-accent-default mb-[20px] flex items-center justify-between">
          <h1 className="font-dewi text-[25px] font-extrabold leading-[30px]">
            Рекламный макет
          </h1>
          <button onClick={() => setIsOpen(false)}>
            <CircleXIcon />
          </button>
        </div>
        <div className="flex flex-col gap-[20px]">
          <div
            className="cursor-pointer"
            onClick={() => setPhotoSelectModal(true)}
          >
            <div className="flex gap-[20px] items-center">
              <div className="flex flex-col justify-center border-2 border-dashed border-[#D7DCE4] rounded-md w-[136px] h-[90px] cursor-pointer">
                {stateAddForm.selectedImg ? (
                  <div className="w-[136px] h-[90px] overflow-hidden rounded-lg">
                    <img
                      src={`${process.env.REACT_APP_FILES_URL}/${stateAddForm.selectedImg}`}
                      crossOrigin="anonymous"
                      alt="Uploaded"
                      className="w-fit h-[90px]"
                      width={30}
                    />
                  </div>
                ) : (
                  <div className="flex flex-col items-center">
                    <ImageUploadIcon width="34" height="33" color="#D7DCE4" />
                  </div>
                )}
              </div>
              <div className="text-accent-default flex flex-col gap-[10px]">
                <p className="text-[14px] font-semibold leading-4">Фон</p>
                <p className="text-[12px] font-normal leading-[14px]">
                  Выберите изображение
                </p>
              </div>
            </div>
          </div>
          <div className=" flex flex-col gap-[10px]">
            <h3 className=" text-accent-default text-sm font-semibold">
              Вариант покупки
            </h3>
            <Dropdown
              name="purchase_options"
              options={purchaseOptions}
              value={
                purchaseOptions2.find(
                  (item) => item.label === stateAddForm.purchaseoptions
                ) || null
              }
              onChange={(value) => {
                setStateAddForm((prev: State) => ({
                  ...prev,
                  purchaseoptions: value.label,
                }));
              }}
              placeholder="Вариант покупки не выбран"
            />
          </div>
          <div className=" flex flex-col gap-[10px]">
            <h3 className=" text-accent-default text-sm font-semibold">
              Застройщик
            </h3>
            <Dropdown
              name="logo"
              value={
                logoToggleOptions.find(
                  (item) => item.value === stateAddForm.logo
                ) || null
              }
              options={logoToggleOptions}
              onChange={(value) => {
                setStateAddForm((prev: State) => ({
                  ...prev,
                  logo: value.value,
                }));
              }}
              placeholder="Логотип"
            />
          </div>

          <div className="flex flex-col gap-[10px]">
            <h3 className=" text-accent-default text-sm font-semibold">
              Данные для выведения на макете
            </h3>
            {stateAddForm.radioBtnData &&
              stateAddForm.radioBtnData.map((item) => (
                <div key={item.id} className="flex flex-col gap-[10px]">
                  <ToggleSwitcher
                    label={item.label}
                    isToggle={item.value}
                    setIsToggle={() => toggleRadioValue(item.id)}
                  />
                  <hr className=" bg-boder-default opacity-20" />
                </div>
              ))}
          </div>
          <TegField
            tegsStateForm={stateAddForm.tags}
            setTegsStateForm={setStateAddForm}
            boldLabel="Уникальные параметры"
            placeholder="Параметры помещения"
            maxLength={15}
            maxList={stateAddForm.tags.length > 4}
          />
        </div>

        <div className="flex flex-col gap-[10px] mt-[20px]">
          <ButtonForm
            text=" Сгенерировать макет"
            onClick={handleGenerate}
            type="button"
            className=" py-3 px-6 
            bg-accent-default flex-1
            text-white-default flex justify-center
            hover:bg-accent-disabled
            hover:
            "
          />
          <ButtonForm
            text="Отмена"
            onClick={() => setIsOpen(false)}
            className=" bg-bg-default py-3 px-6 flex-1
             hover:bg-accent-default flex justify-center
              hover:text-white-default"
          />
        </div>

        {/* Image Selection Modal */}
        <ImageSelectModal
          images={imagesArr}
          aspect={[16, 9]}
          setImage={setImage}
          photoSelectModal={photoSelectModal}
          setPhotoSelectModal={setPhotoSelectModal}
          handleSelectImage={handleSelectImage}
          selectedImgId={selectedImgId}
          setSelectedImgId={setSelectedImgId}
          isImageUploadExist={roles.isAdmin}
          page="apartment"
          imagesData={imagesData?.data}
          sizeInfo="1600/900"
        />
      </div>
    </Modal>
  );
};

export default AdvertisementForm;
