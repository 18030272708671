export const formatUrlComingFromServer = (url: string): string => {
  let trimmedUrl = url?.trim();
  if (
    !trimmedUrl?.startsWith("http://") &&
    !trimmedUrl?.startsWith("https://") &&
    trimmedUrl
  ) {
    if (trimmedUrl === "https:/") {
      trimmedUrl = "https://";
    }
    trimmedUrl = `https://${trimmedUrl}`;
  }

  trimmedUrl = trimmedUrl?.replace(/^(https?:\/\/)+/i, "https://");

  return trimmedUrl;
};
