import React, { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import TextField from "../../../../components/Form/TextField/TextField";
import Dropdown from "../../../../components/Form/Dropdown/Dropdown";
import ButtonForm from "../../../../components/Button/ButtonForm";
import { RegisterFormState, userFormDataType } from "../../types";
import EmailIcon from "../../../../components/constant/icons/EmailIcon";
import { yupResolver } from "@hookform/resolvers/yup";
import Label from "components/Form/Label";
import { ApiException } from "data/api/exception";
import { useSignUpUserMutation } from "data/api/auth";
import DatePickerField from "components/Form/DatePicker/DatePickerField";
import { getCityByIp, useGetCitiesQuery } from "data/api/city";
import Loader from "components/Loader";
import { useNavigate } from "react-router";
import { useFormatForDropDownOptions } from "hooks/useDropDownOption";
import showToast from "utils/showToast";
import { schemaNewAgentForm } from "utils/schemas";
import { RegisterType, Step } from "enums/authEnums";
import { RegisterStatus } from "enums/registerStatus";
import { useWindowSize } from "hooks/useWindowSize";

type propsType = {
  title?: React.ReactNode;
  setFormState: React.Dispatch<React.SetStateAction<RegisterFormState>>;
  registerType: RegisterType;
};

const UserDataForm = ({ title, setFormState }: propsType) => {
  const res = useGetCitiesQuery();

  const options = useFormatForDropDownOptions(res?.data?.data ?? []);

  const [mutate, { isLoading }] = useSignUpUserMutation();

  const navigate = useNavigate();

  const { isMd } = useWindowSize();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    setError,
  } = useForm<userFormDataType>({
    resolver: yupResolver<userFormDataType | any>(schemaNewAgentForm),
  });

  const onSubmit: SubmitHandler<userFormDataType> = async (data) => {
    const id = Number(localStorage.getItem("userId"));

    const registerType = localStorage.getItem("registerType") as RegisterType;

    try {
      const { data: res, error } = await mutate({
        data: {
          ...data,
          id,
          city_id: data.city_id?.value,
        },
      });

      if (res?.data.message === "ok") {
        localStorage.setItem("register_status", RegisterStatus.ATTACHMENT);
        if (registerType === RegisterType.NEW_AGENCY) {
          setFormState({ step: Step.NEW_AGENCY_AGREEMENT_FORM });
          return;
        } else {
          setFormState({ step: Step.ATTACH_TO_AGENCY_FORM });
          return;
        }
      }
      const err = error as ApiException;
      if (err.status === 409) {
        setError("email", {
          type: "manual",
          message: "Этот адрес электронной почты уже существует",
        });
        return;
      } else {
        if (error?.message) {
          showToast(error.message, "error");
          return;
        }
      }
    } catch (err) {
      const error = err as ApiException;
      console.log("err>>>.", error);
    }
  };

  function changeStatus(newStatus: string) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("status", newStatus);
    navigate({
      pathname: window.location.pathname,
      search: searchParams.toString(),
    });
  }

  useEffect(() => {
    async function initCity() {
      const city = await getCityByIp();
      if (city.location) {
        const matchedOption = options.find((el) =>
          city.location?.value.toLowerCase().includes(el.label.toLowerCase())
        );

        if (matchedOption) {
          console.log(matchedOption);
          // @ts-ignore
          setValue("city_id", matchedOption);
        } else {
          const defOption = options.find((el) => el.label === "Екатеринбург");

          if (defOption) {
            // @ts-ignore
            setValue("city_id", defOption);
          }
        }
      } else {
        const defOption = options.find((el) => el.label === "Екатеринбург");

        if (defOption) {
          // @ts-ignore
          setValue("city_id", defOption);
        }
      }
    }

    if (options.length > 0) {
      initCity();
    }
  }, [options, setValue]);

  const getEighteenYearsAgoDate = () => {
    const today = new Date();
    return new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate() - 1
    );
  };

  return (
    <React.Fragment>
      <h3 className="text-[25px] text-accent-default font-dewi font-extrabold leading-[30px] mb-[20px]">
        {title}
      </h3>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-[17px]"
      >
        {isLoading && <Loader />}

        <Controller
          name="firstName"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              value={value}
              className=""
              placeholder="Имя"
              label="Имя"
              type="text"
              name="firstName"
              onChange={onChange}
              errors={errors}
            />
          )}
        />
        <Controller
          name="lastName"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              value={value}
              className=""
              placeholder="Фамилия"
              label="Фамилия"
              type="text"
              name="lastName"
              onChange={onChange}
              errors={errors}
            />
          )}
        />
        <Label label="Выберите город">
          <Controller
            name="city_id"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Dropdown
                name="city_id"
                options={options}
                errors={errors}
                value={value}
                onChange={onChange}
                placeholder="Город не выбран"
              />
            )}
          />
        </Label>
        <Label label="Дата рожения">
          <Controller
            name="birthDate"
            control={control}
            render={({ field: { onChange } }) => (
              <DatePickerField
                onChangeDate={onChange}
                className={`w-full`}
                name="birthDate"
                placeholder="Выберите дату"
                isRightIcon={true}
                isIcon
                maxDate={getEighteenYearsAgoDate()}
                errors={errors}
              />
            )}
          />
        </Label>
        <Controller
          name="email"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              value={value}
              className=""
              placeholder="Адрес почты"
              label="Ваша почта"
              type="email"
              name="email"
              onChange={onChange}
              imgLeft={<EmailIcon />}
              errors={errors}
            />
          )}
        />
        <div className="flex flex-col gap-[10px] mt-[10px]">
          <ButtonForm
            className="bg-accent-default py-[12px] w-full
             text-white-default text-center flex justify-center hover:bg-accent-focus "
            text="Далее"
            type="submit"
          />
          <ButtonForm
            className=" bg-bg-default border-[1px] border-accent-default py-[12px] w-full
             text-accent-default text-center flex justify-center 
              hover:bg-accent-default hover:text-white-default"
            text="Назад"
            onClick={() => setFormState({ step: Step.SELECT_REGISTER_TYPE })}
          />
        </div>
      </form>
    </React.Fragment>
  );
};

export default UserDataForm;
