import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { RootState } from "store";
import { useDispatch, useSelector } from "react-redux";

import ButtonForm from "components/Button/ButtonForm";
import Dropdown from "components/Form/Dropdown/Dropdown";
import Label from "components/Form/Label";
import PageTitle from "components/PageTitle";
import { Filters } from "store/ApartmentReducer/type";
import { useFormatForDropDownOptions } from "hooks/useDropDownOption";
import { useWindowSize } from "../../../hooks/useWindowSize";

import { RangeDataType, primisesTypeData, rangeData } from "../data";
import { handleOpenFilters } from "store/BaseClientsReducer";
import { Primise } from "enums/primisesType";
import { useGetPremisesSeasonsQuery } from "data/api/premises";
import { objectToQueryString } from "utils/objectToQuery";
import MultiRangeSlider from "components/Form/MultiRangeSlider";
import { useGetCitiesQuery } from "data/api/city";
import useGetSectionByBuildingId from "hooks/commonApiCalls/useGetSectionsByBuildingId";
import useGetBuildingByProjectId from "hooks/commonApiCalls/useGetBuildingsbyProjectId";
import useGetProjectByCityId from "hooks/commonApiCalls/useGetProjectByCityId";
import Modal from "components/Modal";
import { useSearchParams } from "react-router-dom";
import { selectType } from "store/ApartmentReducer";

interface propsType {
  title: string;
  storeIsOpenFilters?: boolean;
}

const Filter = ({ title, storeIsOpenFilters }: propsType) => {
  const { isMd, isSm } = useWindowSize();

  const selection = useSelector((state: RootState) => state);

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const location = useLocation();

  const dispatch = useDispatch();

  const { data: cities } = useGetCitiesQuery();

  const [localFilters, setLocalFilters] = useState<Filters>({
    type: (searchParams.get("type") as Primise) ?? Primise.APARTMENT,
    city_id: searchParams.get("city_id")
      ? Number(searchParams.get("city_id"))
      : selection.header.city_id,
    endYear: searchParams.get("endYear") || undefined,

    rooms: searchParams.get("rooms") || undefined,
    project_id: searchParams.get("project_id")
      ? Number(searchParams.get("project_id"))
      : undefined,
    building_id: searchParams.get("building_id")
      ? Number(searchParams.get("building_id"))
      : undefined,
    section_id: searchParams.get("section_id")
      ? Number(searchParams.get("section_id"))
      : undefined,
    min_size: searchParams.get("min_size") || undefined,
    max_size: searchParams.get("max_size") || undefined,
    min_price: searchParams.get("min_price") || undefined,
    max_price: searchParams.get("max_price") || undefined,
    min_floor: searchParams.get("min_floor") || undefined,
    max_floor: searchParams.get("max_floor") || undefined,
    min_number: searchParams.get("min_number") || undefined,
    max_number: searchParams.get("max_number") || undefined,
    year: searchParams.get("year") || undefined,
    quarter: searchParams.get("quarter") || undefined,
  });

  const [rangeData, setRangeData] = useState<RangeDataType[]>();

  const [mobileCityFilter, setMobileCityFilter] = useState(
    searchParams.get("city_id")
      ? Number(searchParams.get("city_id"))
      : selection.header.city_id
  );

  const { data: seasonData } = useGetPremisesSeasonsQuery(
    objectToQueryString({ ...localFilters, year: "", quarter: "" })
  );

  const { projects } = useGetProjectByCityId({
    cityId: localFilters?.city_id,
  });

  const { building } = useGetBuildingByProjectId({
    projectID: localFilters?.project_id,
  });

  const { sections } = useGetSectionByBuildingId({
    buildingId: localFilters?.building_id,
  });

  const cityOptions = useFormatForDropDownOptions(cities?.data ?? []);

  const projectOptions = useFormatForDropDownOptions(projects ?? []);

  const buildingOptions = useFormatForDropDownOptions(building ?? []);

  const sectionOptions = useFormatForDropDownOptions(sections ?? []);

  const seasonOptions = seasonData?.data?.map((item) => {
    return {
      label: `${item.season_name} квартал ${item.year} года`,
      value: `year=${item.year}&quarter=${item.season_name}`,
      filter: item,
    };
  });

  const removeQuery = () => {
    const currentPath = location.pathname;

    navigate(currentPath, { replace: true });
  };

  useEffect(() => {
    if (
      !isMd &&
      localFilters.city_id &&
      localFilters.city_id !== selection.header.city_id
    ) {
      removeQuery();
      setLocalFilters({
        city_id: selection.header.city_id,
        type: Primise.APARTMENT,
      });
    }
  }, [selection.header.city_id]);

  useEffect(() => {
    if (selection.apartment.rangeData) {
      setRangeData([
        {
          label: "Площадь",
          min: Math.floor(selection.apartment.rangeData?.min_size) || 5,
          max: Math.ceil(selection.apartment.rangeData?.max_size) || 150,
          step: 1,
          filterKeys: ["min_size", "max_size"],
        },
        {
          label: "Стоимость",
          min: parseInt(selection.apartment.rangeData?.min_price || "0"),
          max: parseInt(selection.apartment.rangeData?.max_price || "43000000"),
          step: 1,
          filterKeys: ["min_price", "max_price"],
        },
        {
          label: "Этаж",
          min: selection.apartment.rangeData?.min_floor || 1,
          max: selection.apartment.rangeData?.max_floor || 10,
          step: 1,
          filterKeys: ["min_floor", "max_floor"],
        },
      ]);
    }
  }, [selection.apartment.rangeData]);

  const isMobile = isMd || isSm;

  const updateLocalFilter = (key: keyof Filters, value: string) => {
    if (key === "quarter" || key === "year") {
      setLocalFilters((prevFilters) => ({
        ...((prevFilters as Filters) || null),
        [key]: value,
      }));
    } else {
      setLocalFilters((prevFilters) => ({
        ...((prevFilters as Filters) || null),
        [key]: value,
        year: "",
        quarter: "",
      }));
    }
  };

  const handleTypeSelect = (e: { value: string }) => {
    updateLocalFilter("type", e.value);
    setLocalFilters((prev) => ({
      ...((prev as Filters) || null),
      rooms: "",
    }));
  };

  const handleToggleRooms = (item: string) => {
    if (item === localFilters?.rooms) {
      updateLocalFilter("rooms", "");
      return;
    }
    updateLocalFilter("rooms", item);
  };

  const handleProjectChange = (e: any) => {
    const updatedFilters = {
      project_id: e.value,
      building_id: 0,
      section_id: 0,
      year: "",
      quarter: "",
    };
    setLocalFilters((prev) => ({ ...prev, ...updatedFilters }));
  };

  const handleBuildingChange = (e: any) => {
    const updatedFilters = {
      building_id: e.value,
      section_id: 0,
      year: "",
      quarter: "",
    };
    setLocalFilters((prev) => ({ ...prev, ...updatedFilters }));
  };
  const handleSectionChange = (e: any) => {
    const updatedFilters = {
      section_id: e.value,
      year: "",
      quarter: "",
    };
    setLocalFilters((prev) => ({ ...prev, ...updatedFilters }));
  };

  const getValue = (
    opts: { label: string; value: string | number }[],
    val: number | string | undefined
  ) => {
    if (val) {
      return opts.filter((o) => o.value == val);
    }
    return null;
  };

  // const rangeData: RangeDataType[] = [
  //   {
  //     label: "Площадь",
  //     min: store.getState().apartment.rangeData?.min_size || 5,
  //     max: store.getState().apartment.rangeData?.max_size || 150,
  //     step: 1,
  //     filterKeys: ["min_size", "max_size"],
  //   },
  //   {
  //     label: "Стоимость",
  //     min: parseInt(store.getState().apartment.rangeData?.min_price || "0"),
  //     max: parseInt(
  //       store.getState().apartment.rangeData?.max_price || "43000000"
  //     ),
  //     step: 1,
  //     filterKeys: ["min_price", "max_price"],
  //   },
  //   {
  //     label: "Этаж",
  //     min: store.getState().apartment.rangeData?.min_floor || 1,
  //     max: store.getState().apartment.rangeData?.max_floor || 10,
  //     step: 1,
  //     filterKeys: ["min_floor", "max_floor"],
  //   },
  // ];

  const handleFilterPrimises = () => {
    const searchParams = new URLSearchParams();

    if (localFilters) {
      Object.entries(localFilters).forEach(([key, value]) => {
        if (value !== undefined && value !== null && value !== "") {
          searchParams.set(key, value.toString());
        }
      });
    }

    dispatch(selectType(localFilters.type));

    navigate("/apartments?" + searchParams.toString(), { replace: true });
  };

  // Filter Mobile Version

  if (isMd) {
    if (storeIsOpenFilters) {
      return (
        <Modal
          className="rounded-t-[30px] md:rounded-lg w-full md:w-[500px] "
          isOpen={storeIsOpenFilters}
          onClose={() => dispatch(handleOpenFilters(false))}
        >
          <div
            className={`bg-white-default sm:p-[20px] xl:p-[30px] self-start lg:min-w-[346px] h-[78vh] overflow-y-scroll toolbar-scroll overflow-x-hidden px-[10px]`}
          >
            <PageTitle text={title} />
            <div className="flex flex-col gap-[25px] mt-[30px]">
              <Label label="Тип помещения">
                <Dropdown
                  onChange={handleTypeSelect}
                  options={primisesTypeData}
                  name="type"
                  placeholder="Все"
                  className="w-full"
                  value={getValue(primisesTypeData, localFilters?.type)}
                />
              </Label>
              <Label label="Город" className="w-full lg:flex-1">
                <Dropdown
                  name="city"
                  options={[...cityOptions]}
                  onChange={(e) => {
                    setLocalFilters((prev) => ({
                      ...prev,
                      city_id: e.value,
                      building_id: undefined,
                      project_id: undefined,
                      section_id: undefined,
                    }));
                    setMobileCityFilter(e.value);
                  }}
                  value={getValue(cityOptions, localFilters.city_id as number)}
                  placeholder="Город"
                />
              </Label>
              <Label label="Проект">
                <Dropdown
                  disabled={!projectOptions.length}
                  onChange={handleProjectChange}
                  options={[{ label: "Все", value: null }, ...projectOptions]}
                  value={getValue(projectOptions, localFilters?.project_id)}
                  placeholder="Все"
                  className="w-full"
                  name="project_id"
                />
              </Label>
              <Label label="Дом">
                <Dropdown
                  onChange={handleBuildingChange}
                  options={[{ label: "Все", value: null }, ...buildingOptions]}
                  placeholder="Все"
                  className="w-full"
                  disabled={
                    buildingOptions.length === 0 || !localFilters?.project_id
                  }
                  name="building_id"
                  value={getValue(
                    [{ label: "Все", value: 0 }, ...buildingOptions],
                    localFilters?.building_id
                  )}
                />
              </Label>
              <Label label="Секция">
                <Dropdown
                  onChange={handleSectionChange}
                  options={[{ label: "Все", value: "" }, ...sectionOptions]}
                  placeholder="Все"
                  className="w-full"
                  disabled={
                    !localFilters?.building_id || sectionOptions.length === 0
                  }
                  name="section_id"
                  value={getValue(sectionOptions, localFilters?.section_id)}
                />
              </Label>
              <Label label="Срок сдачи">
                <Dropdown
                  onChange={(e) => {
                    updateLocalFilter("year", e?.filter?.year);
                    updateLocalFilter("quarter", e?.filter?.season_name);
                  }}
                  options={
                    seasonOptions && seasonOptions.length
                      ? [
                          { label: "Все", value: "", filter: null },
                          ...(seasonOptions || []),
                        ]
                      : []
                  }
                  placeholder="Все"
                  className="w-full"
                  name="season_id"
                  value={getValue(
                    seasonOptions || [],
                    `year=${localFilters?.year}&quarter=${localFilters.quarter}`
                  )}
                />
              </Label>

              {localFilters?.type === Primise.APARTMENT ? (
                <Label label="Комнат">
                  <div className="flex gap-2 items-center mb-[10px]">
                    <div
                      onClick={() => handleToggleRooms("0")}
                      className={`text-[12px] font-normal w-[70px] h-[45px] border border-light-blue-10 
               leading-[18.75px] rounded-full text-accent-default cursor-pointer
             flex items-center justify-center ${
               "0" == localFilters?.rooms &&
               "bg-accent-default text-white-default"
             } `}
                    >
                      Студия
                    </div>
                    {["1", "2", "3", "4+"].map((item) => (
                      <div
                        onClick={() => handleToggleRooms(item)}
                        key={item}
                        className={`text-[16px] font-normal w-[46px] h-[45px] border border-light-blue-10 
               leading-[18.75px] rounded-full text-accent-default cursor-pointer
             flex items-center justify-center ${
               item == localFilters?.rooms &&
               "bg-accent-default text-white-default"
             } `}
                      >
                        {item}
                      </div>
                    ))}
                  </div>
                </Label>
              ) : null}

              {rangeData?.map(
                ({
                  label,
                  min,
                  max,
                  step,
                  filterKeys,
                }: {
                  label: string;
                  min: number;
                  max: number;
                  step: number;
                  filterKeys: string[];
                }) => (
                  <Label label={label} key={label}>
                    <MultiRangeSlider
                      onChange={(value) => {
                        updateLocalFilter(
                          filterKeys[0] as keyof Filters,
                          value.min.toString()
                        );
                        updateLocalFilter(
                          filterKeys[1] as keyof Filters,
                          value.max.toString()
                        );
                      }}
                      max={max}
                      min={min}
                      step={step}
                      value={
                        localFilters[
                          filterKeys[0] as keyof Filters["max_number"]
                        ] &&
                        localFilters[
                          filterKeys[1] as keyof Filters["max_number"]
                        ] && [
                          localFilters[
                            filterKeys[0] as keyof Filters["max_number"]
                          ],
                          localFilters[
                            filterKeys[1] as keyof Filters["max_number"]
                          ],
                        ]
                      }
                    />
                  </Label>
                )
              )}
              {/* <button className="text-[14px] leading-[24px] font-medium text-blue-default hover:text-blue-focus">
            Больше фильтров
          </button> */}
              <div className="flex flex-col sm:gap-[10px]">
                <ButtonForm
                  text="Применить"
                  className={`border border-accent-default mt-[1px] py-3 px-6
             hover:bg-accent-default flex justify-center
              hover:text-white-default w-full
              ${isMobile ? "bg-accent-default text-white-default" : ""}
              `}
                  onClick={(e) => {
                    handleFilterPrimises();
                    dispatch(handleOpenFilters(false));
                  }}
                />
                {isMobile && (
                  <ButtonForm
                    text="Отмена"
                    className="border border-accent-default py-3 px-6 flex justify-center w-full mt-[10px]"
                    onClick={() => dispatch(handleOpenFilters(false))}
                  />
                )}
              </div>
            </div>
          </div>
        </Modal>
      );
    } else {
      return null;
    }
  } else {
    // Filter Desktop Version

    return (
      <div
        className={`filter bg-white-default
        sm:p-[20px] xl:p-[30px] self-start md:min-w-[346px]
        `}
      >
        <PageTitle text={title} />
        <div className="flex flex-col gap-[25px] mt-[30px]">
          <Label label="Тип помещения">
            <Dropdown
              onChange={handleTypeSelect}
              options={primisesTypeData}
              name="type"
              placeholder="Все"
              className="w-full"
              value={getValue(primisesTypeData, localFilters?.type)}
            />
          </Label>
          {/* <Label label="Город" className="w-full hidden lg:flex-1">
            <Dropdown
              name="city"
              options={[...cityOptions]}
              onChange={(e) => handleSelectCity(e.value)}
              value={getValue(cityOptions, localFilters.city_id as number)}
              placeholder="Город"
            />
          </Label> */}
          <Label label="Проект">
            <Dropdown
              disabled={!projectOptions.length}
              onChange={handleProjectChange}
              options={[{ label: "Все", value: null }, ...projectOptions]}
              value={getValue(projectOptions, localFilters?.project_id)}
              placeholder="Все"
              className="w-full"
              name="project_id"
            />
          </Label>
          <Label label="Дом">
            <Dropdown
              onChange={handleBuildingChange}
              options={[{ label: "Все", value: null }, ...buildingOptions]}
              placeholder="Все"
              className="w-full"
              disabled={
                buildingOptions.length === 0 || !localFilters?.project_id
              }
              name="building_id"
              value={getValue(
                [{ label: "Все", value: 0 }, ...buildingOptions],
                localFilters?.building_id
              )}
            />
          </Label>
          <Label label="Секция">
            <Dropdown
              onChange={handleSectionChange}
              options={[{ label: "Все", value: "" }, ...sectionOptions]}
              placeholder="Все"
              className="w-full"
              disabled={
                !localFilters?.building_id || sectionOptions.length === 0
              }
              name="section_id"
              value={getValue(sectionOptions, localFilters?.section_id)}
            />
          </Label>
          <Label label="Срок сдачи">
            <Dropdown
              onChange={(e) => {
                updateLocalFilter("year", e?.filter?.year);
                updateLocalFilter("quarter", e?.filter?.season_name);
              }}
              options={
                seasonOptions && seasonOptions.length
                  ? [{ label: "всё", value: "" }, ...(seasonOptions || [])]
                  : []
              }
              placeholder="Все"
              className="w-full"
              name="season_id"
              value={getValue(
                seasonOptions || [],
                `year=${localFilters?.year}&quarter=${localFilters.quarter}`
              )}
            />
          </Label>

          {localFilters?.type === Primise.APARTMENT ? (
            <Label label="Комнат">
              <div className="flex gap-2 items-center mb-[10px]">
                <div
                  onClick={() => handleToggleRooms("0")}
                  className={`text-[12px] font-normal w-[70px] h-[45px] border border-light-blue-10 
               leading-[18.75px] rounded-full text-accent-default cursor-pointer
             flex items-center justify-center ${
               "0" == localFilters?.rooms &&
               "bg-accent-default text-white-default"
             } `}
                >
                  Студия
                </div>
                {["1", "2", "3", "4"].map((item) => (
                  <div
                    onClick={() => handleToggleRooms(item)}
                    key={item}
                    className={`text-[16px] font-normal w-[46px] h-[45px] border border-light-blue-10  leading-[18.75px] rounded-full text-accent-default cursor-pointer flex items-center justify-center ${
                      item === localFilters?.rooms &&
                      "bg-accent-default text-white-default"
                    } `}
                  >
                    {item === "4" ? "4+" : item}
                  </div>
                ))}
              </div>
            </Label>
          ) : null}

          {rangeData?.map(
            ({
              label,
              min,
              max,
              step,
              filterKeys,
            }: {
              label: string;
              min: number;
              max: number;
              step: number;
              filterKeys: string[];
            }) => (
              <Label label={label} key={label}>
                <MultiRangeSlider
                  onChange={(value) => {
                    updateLocalFilter(
                      filterKeys[0] as keyof Filters,
                      value.min.toString()
                    );
                    updateLocalFilter(
                      filterKeys[1] as keyof Filters,
                      value.max.toString()
                    );
                  }}
                  max={max}
                  min={min}
                  step={step}
                  value={
                    localFilters[
                      filterKeys[0] as keyof Filters["max_number"]
                    ] &&
                    localFilters[
                      filterKeys[1] as keyof Filters["max_number"]
                    ] && [
                      localFilters[
                        filterKeys[0] as keyof Filters["max_number"]
                      ],
                      localFilters[
                        filterKeys[1] as keyof Filters["max_number"]
                      ],
                    ]
                  }
                />
              </Label>
            )
          )}
          {/* <button className="text-[14px] leading-[24px] font-medium text-blue-default hover:text-blue-focus">
            Больше фильтров
          </button> */}
          <div className="flex flex-col sm:gap-[10px]">
            <ButtonForm
              text="Применить"
              className={`border border-accent-default mt-[1px] py-3 px-6
             hover:bg-accent-default flex justify-center
              hover:text-white-default w-full
              ${isMobile ? "bg-accent-default text-white-default" : ""}
              `}
              onClick={handleFilterPrimises}
            />
            {isMobile && (
              <ButtonForm
                text="Отмена"
                className="border border-accent-default py-3 px-6 flex justify-center w-full mt-[10px]"
                onClick={() => dispatch(handleOpenFilters(false))}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default Filter;
