export enum PuchaseOptions {
    MORTAGE = "mortage",
    INSTALLMENT = "installment",
    BILL = "bill",
    FULL_PAYMENT = "full_payment",
  }

  export const purchaseOptions = [
    { label: "Ипотека", value: PuchaseOptions.MORTAGE },
    { label: "Рассрочка", value: PuchaseOptions.INSTALLMENT },
    { label: "Полная оплата", value: PuchaseOptions.FULL_PAYMENT },
    { label: "Счет", value: PuchaseOptions.BILL },
  ];
