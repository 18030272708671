import React from "react";

const LinkAngledIcon = (
	{
		color = "#3583FF"
	}
) => {
  return (
		<svg
			width="24"
			height="25"
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.379 10.6907L5.14554 12.9242C4.31141 13.7583 3.83173 14.8933 3.8405 16.0861C3.84926 17.2789 4.31844 18.4208 5.19216 19.2675C6.03884 20.1413 7.18097 20.6104 8.37359 20.6192C9.59342 20.6282 10.7015 20.1755 11.5357 19.3414L13.7692 17.1079M16.622 14.3097L18.8554 12.0762C19.6896 11.2421 20.1692 10.1071 20.1605 8.9143C20.1517 7.72149 19.6825 6.5796 18.8088 5.73287C17.9623 4.88638 16.8204 4.41717 15.6276 4.40841C14.4348 4.39964 13.2997 4.85209 12.4655 5.68625L10.232 7.9197M8.61359 15.8274L15.314 9.12701"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default LinkAngledIcon;
