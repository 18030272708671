import {
  Apartment,
  ChessDataType,
} from "modules/Apartment/ApartmentList/dataTransformer";
import { apiService } from "..";
import { MetaData } from "../exception";

interface Season {
  id: number;
  created_at: string;
  updated_at: string;
  season_name: number;
  year: string;
  date: string;
}

export interface AdvertisementImage {
  id: number;
  ext_id: string;
  image: string;
}

export type ApartmentFilterRange = {
  max_floor: number;
  max_price: string;
  max_size: number;
  min_floor: number;
  min_price: string;
  min_size: number;
};

export const addTagTypes = ["premises", "images"] as const;

export const primisesApiHooks = apiService
  .enhanceEndpoints({ addTagTypes })
  .injectEndpoints({
    endpoints: (build) => ({
      getPremises: build.query<
        MetaData<Apartment[], ApartmentFilterRange>,
        string
      >({
        query: (query) => ({
          path: `/premises?${query}`,
        }),
        providesTags: ["premises"],
      }),
      getPremisesChess: build.mutation<
        MetaData<ChessDataType[], ApartmentFilterRange>,
        string
      >({
        query: (query) => ({
          path: `/premises/chess?${query}`,
        }),
      }),
      getPremisesByBuildingId: build.mutation<MetaData<Apartment[]>, number>({
        query: (id) => ({
          path: `/premises?building_id=${id}`,
        }),
      }),
      getPremisesSeasons: build.query<MetaData<Season[]>, string>({
        query: (query) => ({
          path: `/premises/seasons?${query}`,
        }),
        providesTags: ["premises"],
      }),
      getOnePremise: build.query<MetaData<Apartment>, string | undefined>({
        query: (id) => ({
          path: `/premises/${id}`,
        }),
        providesTags: ["premises"],
      }),
      PickPremises: build.query<MetaData<Apartment[]>, number[]>({
        query: (query) => {
          const idQueryList = query.reduce((acc, curr, idx) => {
            acc += `${idx !== 0 ? "&" : ""}ids=${curr}`;

            return acc;
          }, "");

          return {
            path: `/premises/cherry-pick/{ids}?${idQueryList}`,
          };
        },
        providesTags: ["premises"],
      }),
      PickPremisesLink: build.mutation<MetaData<string>, number[]>({
        query: (query) => {
          const idQueryList = query.reduce((acc, curr, idx) => {
            acc += `${idx !== 0 ? "&" : ""}ids=${curr}`;

            return acc;
          }, "");

          return {
            path: `/premises/link/{ids}?${idQueryList}`,
          };
        },
      }),
      PickPremisesSharedPage: build.mutation<MetaData<Apartment[]>, string>({
        query: (link) => {
          return {
            path: `/premises/decrypt/${link}`,
          };
        },
      }),
      uploadApartmentImages: build.mutation<MetaData, string>({
        query: (fileData) => ({
          path: "/apartment-image",
          method: "POST",
          body: { meta: {}, data: { image: fileData } },
        }),
        invalidatesTags: ["images"],
      }),
      getApartmentImages: build.query<MetaData<AdvertisementImage[]>, void>({
        query: () => ({
          path: "/apartment-image",
          method: "GET",
        }),
        providesTags: ["images"],
      }),
      deleteApartmentImages: build.mutation<
        MetaData<AdvertisementImage[]>,
        number
      >({
        query: (id) => ({
          path: `/apartment-image/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["images"],
      }),
    }),
  });

export const {
  useGetPremisesQuery,
  useGetPremisesChessMutation,
  usePickPremisesLinkMutation,
  usePickPremisesSharedPageMutation,
  usePickPremisesQuery,
  useGetPremisesByBuildingIdMutation,
  useGetOnePremiseQuery,
  useGetPremisesSeasonsQuery,
  useGetApartmentImagesQuery,
  useUploadApartmentImagesMutation,
  useDeleteApartmentImagesMutation,
} = primisesApiHooks;
