import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { calculateBankItemData, calculateFormData } from "./type";

export interface SelectionState {
	filter: calculateBankItemData | null;
}
const initialState: SelectionState = {
	filter: null,
};

const calculatorSlice = createSlice({
  name: "calculator",
  initialState,
  reducers: {
    handleSetFilters: (state, action: PayloadAction<calculateBankItemData>) => {
      state.filter = action.payload;
    },
  },
});

export const { handleSetFilters } =
  calculatorSlice.actions;

export const getFilterData = (state: RootState) =>
  state.calculator.filter;
export default calculatorSlice.reducer;
