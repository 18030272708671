import { configureStore } from "@reduxjs/toolkit";
import selectionReducer from "./ApartmentReducer";
import baseClientsReducer from "./BaseClientsReducer";
import headerSlice from "./HeaderReducer";
import { apiService } from "data/api";
import CalendarReducer from "./CalendarReducer";
import TrainingReducer from "./TrainingReducer";
import ContactReducer from "./ContactReducer";
import AdminstrationReducer from "./AdminstrationReducer";
import NewsReducer from "./NewsReducer";
import CalculatorReducer from "./CalculatorReducer";
import userSlice from "./UserReducer";
import HomPageReducer from "./HomPageReducer";
import { useDispatch, useSelector } from "react-redux";
import Analytics from "./Analytics";
import EvenstReducer from "./EvenstReducer";
import ProjectReducer from "./ProjectReducer";
import TasksReducer from "./TasksReducer";

const store = configureStore({
  reducer: {
    apartment: selectionReducer,
    baseClients: baseClientsReducer,
    header: headerSlice,
    calculator: CalculatorReducer,
    news: NewsReducer,
    calendar: CalendarReducer,
    adminstration: AdminstrationReducer,
    training: TrainingReducer,
    contact: ContactReducer,
    user: userSlice,
    home: HomPageReducer,
    analytics: Analytics,
    events: EvenstReducer,
    projects: ProjectReducer,
    tasks: TasksReducer,
    [apiService.reducerPath]: apiService.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiService.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();
export default store;
