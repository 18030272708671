import React, { useEffect, useState } from "react";
import ApartmentCard from "./ApartmentCard";
import PageTitle from "components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleBookingModal,
  toggleItemInStore,
  toggleRecordForShowModal,
} from "store/ApartmentReducer";
import ChessList from "./ChessList";
import { RootState } from "store";
import {
  Apartment,
  ChessDataType,
  groupByProjectName,
  transformData,
} from "./dataTransformer";
import Loader from "components/Loader";
import { motion } from "framer-motion";
import AnimationFrame from "components/AnimationFrame.tsx";
import RecordForShow from "components/BookingAndVisits/RecordForShowForm";
import BookingFrom from "components/BookingAndVisits/BookingFrom";
import {
  useGetPremisesChessMutation,
  useGetPremisesQuery,
} from "data/api/premises";
import ExchangeModalApplicationPhone from "modules/Exchange/ExchangeModalApplicationPhone";
import NotFound from "components/NotFound";
import { useLocation, useNavigate } from "react-router";
import BackIcon from "../../../components/constant/icons/BackIcon";
import { iFilterDeals } from "../../Deal";
import { useWindowSize } from "hooks/useWindowSize";
import { PaginationType } from "modules/BaseClients";

interface porpsType {
  data: Apartment[];
  isLoading: boolean;
  searchQuery: string;
}

const ApartmentList: React.FC<porpsType> = ({
  data,
  isLoading,
  searchQuery,
}) => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const projectId = queryParams.get("project_id");

  const [paginationInfo, setPaginationInfo] = useState<PaginationType>({
    currPage: 1,
    limit: 1,
    total: 0,
    totalPage: 0,
  });

  const selection = useSelector((state: RootState) => state.apartment);

  const [clickedItem, setClikedItem] = useState<Apartment | null>(null);

  const [isASCPrice, setIsASCPrice] = useState(true);

  const [isASCSquare, setIsASCSquare] = useState(true);

  const [chessData, setChessData] = useState<ChessDataType[] | null>(null);

  const [filter, setFilter] = useState<iFilterDeals>({
    price: "ASC",
    size: "ASC",
  });

  const { isSm } = useWindowSize();

  const [applicationPhoneModalOpen, setApplicationPhoneModalOpen] =
    useState(false);

  const [getChessMutation, { isLoading: chessLoader }] =
    useGetPremisesChessMutation();

  const dispatch = useDispatch();

  const { refetch } = useGetPremisesQuery(location.search.slice(1));

  const navigate = useNavigate();

  useEffect(() => {
    setPaginationInfo((prev) => ({
      ...prev,
      currPage: 1,
    }));
    if (selection.chess) {
      initChess();
    }
  }, [selection.chess, searchQuery]);

  useEffect(() => {
    if (selection.chess) {
      initChess();
    }
  }, [paginationInfo.currPage]);

  async function initChess() {
    try {
      const { data: res } = await getChessMutation(
        searchQuery +
          `&page=${paginationInfo.currPage}&limit=${paginationInfo.limit}`
      );

      if (paginationInfo.currPage === 1) {
        setChessData(res?.data || null);
      } else {
        setChessData((prev) => [...(prev || []), ...(res?.data || [])]);
      }

      setPaginationInfo((prev) => ({
        ...prev,
        total: res?.meta?.links?.total || 0,
        totalPage: res?.meta?.links?.totalPage || 0,
      }));
    } catch (error) {
      console.log("error", error);
    }
  }

  const handleSelectItem = (id: number) => {
    dispatch(toggleItemInStore({ id }));
  };

  const handleClickBooking = (item: Apartment | null) => {
    if (item) {
      dispatch(toggleBookingModal(item.id));
      setClikedItem(item);
    }
  };

  const handleClickRecord = (item: Apartment | null) => {
    if (selection.recordForShowModalById === item?.id) {
      setClikedItem(null);
      dispatch(toggleRecordForShowModal(item.id));
    } else if (item) {
      setClikedItem(item);
      dispatch(toggleRecordForShowModal(item.id));
    }
  };

  const handleClickAppLicationPhone = () => {
    setApplicationPhoneModalOpen(true);
  };

  const handleOrderData = (
    key: string,
    is: boolean,
    set: (val: boolean) => void
  ) => {
    set(!is);
    setFilter((prev: any) => ({ ...prev, [`${key}`]: !is ? "ASC" : "DESC" }));

    handleSortBy(key, !is ? "ASC" : "DESC");
  };

  function handleSortBy(sort_by: string, order_by: string) {
    const currentSearchParams = new URLSearchParams(window.location.search);
    currentSearchParams.set("sort_by", sort_by);
    currentSearchParams.set("order_by", order_by);
    navigate(`/apartments?${currentSearchParams.toString()}`, {
      replace: true,
    });
  }

  const handleUpdateList = () => {
    setPaginationInfo((prev) => ({
      ...prev,
      currPage: prev.currPage + 1,
    }));
  };

  return (
    <div className="py-[20px] overflow-x-hidden relative min-h-[50vh] ">
      {(isLoading || chessLoader) && <Loader />}
      {selection.chess ? (
        <div className="flex flex-col gap-6">
          {chessData?.map((project) => (
            <div key={project.id}>
              <div className="sm:mb-[30px]">
                <PageTitle text={project.name} />
              </div>
              {project?.buildings?.map((building) => (
                <ChessWithBuildingName
                  building={building}
                  key={building.id}
                  isLoading={chessLoader}
                />
              ))}

              <hr className=" text-boder-default my-[20px] " />
            </div>
          ))}
          <div className="absolute w-[50px] sm:w-[120px] right-0 bottom-[30px] top-0 opacity-70 bg-custom-gradient pointer-events-none "></div>

          {paginationInfo
            ? !(paginationInfo?.currPage >= paginationInfo?.totalPage) && (
                <button
                  type={"button"}
                  className={
                    "py-3 px-6 mx-auto text-blue-default text-sm font-medium flex mt-[15px]"
                  }
                  onClick={() => handleUpdateList()}
                >
                  Загрузить еще
                </button>
              )
            : null}
        </div>
      ) : (
        <>
          {data?.length
            ? Object.entries(groupByProjectName(data) || {})?.map(
                ([projectName, apartments]) => (
                  <div key={projectName}>
                    {!isSm && <PageTitle text={projectName} />}
                    <div
                      className={"flex absolute top-0 md:right-0 md:top-[20px]"}
                    >
                      <button
                        className="w-[100px] border-none outline-none h-[34px] rounded-[4px] flex items-center
												justify-center gap-1 bg-transparent text-sm"
                        onClick={() =>
                          handleOrderData("price", isASCPrice, setIsASCPrice)
                        }
                      >
                        Цена
                        <div
                          className={`p-[7px] ${
                            isASCPrice ? "-rotate-90" : "rotate-90"
                          } `}
                        >
                          <BackIcon />
                        </div>
                      </button>
                      <button
                        className="w-[100px] border-none outline-none h-[34px] rounded-[4px] flex items-center
												justify-center gap-1 bg-transparent text-sm"
                        onClick={() =>
                          handleOrderData("size", isASCSquare, setIsASCSquare)
                        }
                      >
                        Площадь
                        <div
                          className={`p-[7px] ${
                            isASCSquare ? "-rotate-90" : "rotate-90"
                          } `}
                        >
                          <BackIcon />
                        </div>
                      </button>
                    </div>
                    <AnimationFrame>
                      <div className=" mt-[30px] mb-[30px] grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-3 gap-5 auto-rows-fr">
                        {apartments?.map((item: Apartment) => (
                          <motion.div
                            key={item.id}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: 20 }}
                            transition={{ duration: 1 }}
                          >
                            <ApartmentCard
                              handleSelectItem={handleSelectItem}
                              handleClickBooking={handleClickBooking}
                              handleClickRecord={handleClickRecord}
                              handleClickAppLicationPhone={
                                handleClickAppLicationPhone
                              }
                              item={item}
                              key={item.id}
                            />
                          </motion.div>
                        ))}
                      </div>
                    </AnimationFrame>
                  </div>
                )
              )
            : !isLoading && (
                <div className="mt-[20px]">
                  <NotFound />
                </div>
              )}
        </>
      )}
      <RecordForShow
        project_id={clickedItem?.project?.id}
        handleToggle={() => handleClickRecord(clickedItem)}
        isOpenModal={selection.recordForShowModalById === clickedItem?.id}
      />
      <BookingFrom
        isOpen={selection.recordBookingById === clickedItem?.id}
        primise_id={clickedItem?.id}
        handleToggle={() => handleClickBooking(clickedItem)}
        refetch={refetch}
      />
      <ExchangeModalApplicationPhone
        isOpenModal={applicationPhoneModalOpen}
        setIsOpenModal={setApplicationPhoneModalOpen}
      />
    </div>
  );
};

export default ApartmentList;

const ChessWithBuildingName = ({
  building,
  isLoading,
}: {
  building: any;
  isLoading: boolean;
}) => {
  const [activeSection, setActiveSection] = useState<string>("");

  const [sectionSelect, setSectionSelect] = useState<Apartment[]>([]);

  useEffect(() => {
    const transformedData = transformData(building?.premises, "name");
    const firstSection = Object.keys(transformedData)?.[0];
    if (firstSection) {
      setActiveSection(firstSection);
      setSectionSelect(transformedData[firstSection]);
    } else {
      setSectionSelect([]);
    }
  }, [building]);

  const handleSectionClick = (sectionName: string, apartments: Apartment[]) => {
    setActiveSection(sectionName);
    setSectionSelect(apartments);
  };

  const sortBySectionName = (data: Apartment[]) => {
    const sorted = Object.entries(transformData(data, "name") || {}).sort(
      (a, b) => {
        const getNumber = (name: string): number => {
          const match = name.match(/\d+/);
          return match ? parseInt(match[0], 10) : 0;
        };
        return getNumber(a[0]) - getNumber(b[0]);
      }
    );

    return sorted;
  };

  return (
    <div key={building.id}>
      <h3 className="text-[14px] sm:text-[20px] text-accent-default font-dewi mt-5">
        {building.name}
      </h3>
      <div className="flex flex-nowrap gap-[10px] mt-[20px] mb-[15px] overflow-auto toolbar-scroll pb-2">
        {building?.premises?.length
          ? sortBySectionName(building?.premises)?.map(
              ([sectionName, apartments]) => (
                <div
                  onClick={() => handleSectionClick(sectionName, apartments)}
                  key={sectionName}
                  className={`text-accent-default min-w-[70px] sm:min-w-[80px] cursor-pointer list-none underline font-medium text-[14px] sm:text-[16px] ${
                    activeSection === sectionName ? "text-blue-default" : ""
                  }`}
                >
                  {!isNaN(Number(sectionName))
                    ? `Секция  ${sectionName}`
                    : sectionName}
                </div>
              )
            )
          : !isLoading && (
              <div className="mx-auto">
                <NotFound />
              </div>
            )}
      </div>
      <ChessList data={sectionSelect} />
    </div>
  );
};
