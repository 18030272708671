import React, { Dispatch, SetStateAction, useState } from "react";
import Dropdown from "components/Form/Dropdown/Dropdown";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { BarchartFilterEnum } from "../BarchartList";
import { dropDownOptionsType } from "modules/Apartment/Filter/type";
import {
  RegisteredUserDataType,
  UserByCityDataTypeInAnalytics,
} from "data/api/analytics/type";
import moment from "moment";
import { TimeFilter } from "../data";

interface Props {
  data: RegisteredUserDataType[] | UserByCityDataTypeInAnalytics[] | null;
  title: string;
  options: dropDownOptionsType[];
  type: BarchartFilterEnum;
  defaultOption: dropDownOptionsType | null;
  setOptions: Dispatch<SetStateAction<dropDownOptionsType | null>>;
  className?: string;
  selectedTab: TimeFilter;
}

const BarChartComponent = ({
  data,
  title,
  options,
  type,
  defaultOption,
  setOptions,
  className,
  selectedTab,
}: Props) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const formatDate = (dateString: string, length = "short") => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("ru-RU", {
      month: length === "long" ? "long" : "short",
    });
    return { day, month };
  };

  const capitalizeFirstLetter = (str: string) => {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleMouseEnter = (index: number) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  const CustomTooltip = ({
    active,
    payload,
    label,
  }: {
    active?: boolean;
    payload?: any[];
    label?: string;
  }) => {
    if (active && payload && payload.length) {
      const { count, date } = payload[0].payload;

      return (
        <div
          style={{
            backgroundColor: "#fff",
            padding: "5px",
            border: "1px solid #ddd",
            borderRadius: "5px",
            textAlign: "center",
            top: 0,
          }}
        >
          {type === BarchartFilterEnum.CITY ? (
            <>
              <p>{`${payload[0].payload.city_name}`}</p>
              <p> {count}</p>
            </>
          ) : (
            <>
              <p>
                {data && data.length > 0 && "date" in data[0]
                  ? capitalizeFirstLetter(formatDate(date || "", "long").month)
                  : ""}{" "}
                {selectedTab !== TimeFilter.YEAR ? `: ${label}` : null}
              </p>
              <p> {count}</p>
            </>
          )}
        </div>
      );
    }

    return null;
  };

  const label =
    selectedTab === TimeFilter.TODAY
      ? capitalizeFirstLetter(moment().format("D MMM"))
      : selectedTab === TimeFilter.WEEK
      ? "Неделя"
      : selectedTab === TimeFilter.MONTH
      ? capitalizeFirstLetter(moment().format("MMMM"))
      : moment().format("Y");

  return (
    <div
      className={`pt-[15px] pr-[15px] bg-white-default rounded-lg ${className}`}
    >
      <div className="flex justify-between items-center">
        <h3 className="text-accent-default text-[16px] pl-[15px] font-medium">
          {title}
        </h3>
        <Dropdown
          options={options}
          className={"select-bg-transparent outline-none min-w-[100px]"}
          onChange={(value) => setOptions(value)}
          value={defaultOption}
        />
      </div>
      <div
        style={{
          width: "100%",
          height: type !== BarchartFilterEnum.CITY ? 230 : 220,
        }}
      >
        <ResponsiveContainer>
          <BarChart
            data={data || []}
            margin={{
              top: 30,
              bottom: 10,
            }}
            barSize={10}
          >
            <CartesianGrid vertical={false} />

            {type !== BarchartFilterEnum.CITY && (
              <XAxis
                dataKey={(item: RegisteredUserDataType) =>
                  item.date && selectedTab === TimeFilter.YEAR
                    ? formatDate(item.date).month.toString()
                    : item.date
                    ? formatDate(item.date).day.toString()
                    : ""
                }
                label={{
                  value: label,
                  position: "left",
                  offset: -8,
                }}
              />
            )}

            <YAxis />
            <Tooltip
              cursor={{ fill: "transparent" }}
              content={<CustomTooltip />}
            />
            <Bar
              dataKey="count"
              stackId="a"
              radius={[10, 10, 0, 0]}
              onMouseLeave={handleMouseLeave}
            >
              {data?.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={index === activeIndex ? "#FF0000" : "#37465B"}
                  onMouseEnter={() => handleMouseEnter(index)}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default BarChartComponent;
