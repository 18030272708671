import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SelectionState {
  isOpenFilterModalAnalitics: boolean;
  isOpenStartDateCalendar: boolean;
  isOpenEndDateCalendar: boolean;
}
const initialState: SelectionState = {
  isOpenFilterModalAnalitics: false,
  isOpenStartDateCalendar: false,
  isOpenEndDateCalendar: false,
};

const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    handleOpenAnaliticsFilter: (state, action: PayloadAction<boolean>) => {
      state.isOpenFilterModalAnalitics = action.payload;
    },
    handleOpenAnaliticsFilterCalendarSt: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isOpenStartDateCalendar = action.payload;
    },
    handleOpenAnaliticsFilterCalendarEnd: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isOpenEndDateCalendar = action.payload;
    },
  },
});

export const {
  handleOpenAnaliticsFilter,
  handleOpenAnaliticsFilterCalendarSt,
  handleOpenAnaliticsFilterCalendarEnd,
} = analyticsSlice.actions;

export default analyticsSlice.reducer;
