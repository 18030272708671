import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useRoles } from "hooks/useRoles";
import BarChartComponent from "./charts/BarChart";
import { IFiltersAnalytics } from ".";
import { dropDownOptionsType } from "modules/Apartment/Filter/type";
import { roleOptions } from "enums/users";
import {
  useGetActiveUsersMutation,
  useGetRegisteredUsersMutation,
  useGetUsersByCityAnalyticsMutation,
} from "data/api/analytics";
import { objectToQueryString } from "utils/objectToQuery";
import {
  RegisteredUserDataType,
  UserByCityDataTypeInAnalytics,
} from "data/api/analytics/type";
import { statisticsByCityOptions, TimeFilter } from "./data";

export enum BarchartFilterEnum {
  CITY = "Город",
  ROLE = "Роль",
}

interface IProps {
  filter: IFiltersAnalytics;
  setFilter: Dispatch<SetStateAction<IFiltersAnalytics>>;
  selectedTab: TimeFilter;
}

const BarchartList = ({ filter, setFilter, selectedTab }: IProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const [registeredUsers, setRegisteredUsers] =
    useState<dropDownOptionsType | null>({
      value: "",
      label: "Все",
    });
  const [activeUsers, setActiveUsers] = useState<dropDownOptionsType | null>({
    value: "",
    label: "Все",
  });
  const [userStatus, setUserStatus] = useState<dropDownOptionsType | null>({
    value: "",
    label: "Все",
  });

  const [registeredUsersList, setRegisteredUsersList] = useState<
    RegisteredUserDataType[] | null
  >(null);
  const [activeUsersList, setActiveUsersList] = useState<
    RegisteredUserDataType[] | null
  >(null);
  const [usersByCityList, setUsersByCityList] = useState<
    UserByCityDataTypeInAnalytics[] | null
  >(null);

  const roles = useRoles(null);

  const [registeredUsersMutate] = useGetRegisteredUsersMutation();
  const [activeUsersMutate] = useGetActiveUsersMutation();
  const [usersByCityMutation] = useGetUsersByCityAnalyticsMutation();

  const rolesOptions = [{ value: "", label: "Все" }, ...roleOptions];

  const fetchData = async (
    fetchFunction: () => Promise<any>,
    setter: Dispatch<SetStateAction<any>>
  ) => {
    setIsLoading(true);
    try {
      const { data, error } = await fetchFunction();
      if (data) setter(data.data);
      if (error) console.error("API Error:", error);
    } catch (err) {
      console.error("Unexpected Error:", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!roles.isAdmin) return;
    if (roles.isAdmin) {
      fetchData(
        () =>
          registeredUsersMutate(
            objectToQueryString({ ...filter, role: registeredUsers?.value })
          ),
        setRegisteredUsersList
      );
    }
  }, [roles.isAdmin, filter, registeredUsers]);

  useEffect(() => {
    if (roles.isAdmin) {
      fetchData(
        () =>
          activeUsersMutate(
            objectToQueryString({ ...filter, role: activeUsers?.value })
          ),
        setActiveUsersList
      );
    }
  }, [roles.isAdmin, filter, activeUsers]);

  useEffect(() => {
    if (roles.isAdmin) {
      fetchData(
        () =>
          usersByCityMutation(
            objectToQueryString({ ...filter, type: userStatus?.value })
          ),
        setUsersByCityList
      );
    }
  }, [roles.isAdmin, filter, userStatus]);

  return (
    <>
      {roles.isAdmin && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[10px] order-2">
          <BarChartComponent
            data={registeredUsersList}
            title="Зарегистрированные агенты"
            type={BarchartFilterEnum.ROLE}
            options={rolesOptions}
            defaultOption={registeredUsers}
            setOptions={setRegisteredUsers}
            selectedTab={selectedTab}
          />
          <BarChartComponent
            data={activeUsersList}
            title="Активные пользователи"
            type={BarchartFilterEnum.ROLE}
            options={rolesOptions}
            defaultOption={activeUsers}
            setOptions={setActiveUsers}
            selectedTab={selectedTab}
          />
          <BarChartComponent
            data={usersByCityList}
            title="Статистика по городам"
            type={BarchartFilterEnum.CITY}
            options={statisticsByCityOptions}
            defaultOption={userStatus}
            setOptions={setUserStatus}
            className="pb-[15px]"
            selectedTab={selectedTab}
          />
        </div>
      )}
    </>
  );
};

export default BarchartList;
