import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import Modal from "components/Modal";
import ButtonForm from "components/Button/ButtonForm";
import CloseWithBorderIcon from "components/constant/icons/CloseWithBorderIcon";
import TextField from "components/Form/TextField/TextField";
import { InvitedUsers } from "data/api/event/type";
import SearchIcon from "components/constant/icons/SearchIcon";
import { useDeleteUserInvitationMutation } from "data/api/event";
import showToast from "utils/showToast";
import TrashIcon from "components/constant/icons/TrashIcon";
import CancelIcon from "components/constant/icons/CancelIcon";
import { images } from "utils/resource";

interface Props {
  isRecordedUserModalOpen: boolean;
  setIsRecordedUserModalOpen: Dispatch<SetStateAction<boolean>>;
  invitedUsers: InvitedUsers[] | undefined;
  setIsRecordAgentModalOpen: Dispatch<SetStateAction<boolean>>;
  refetch: () => void;
}

const InvitedUsersModal = ({
  isRecordedUserModalOpen,
  setIsRecordedUserModalOpen,
  setIsRecordAgentModalOpen,
  invitedUsers,
  refetch,
}: Props) => {
  const [invitedUserSearch, setInvitedUserSearch] = useState("");
  const [filteredInvitedUser, setFilteredInvitedUser] =
    useState<InvitedUsers[]>();

  const [mutateDeleteInvitation] = useDeleteUserInvitationMutation();

  useEffect(() => {
    if (invitedUserSearch) {
      const filtered = invitedUsers?.filter((agent) =>
        agent.user.fullName
          .toLowerCase()
          .includes(invitedUserSearch.toLowerCase())
      );
      setFilteredInvitedUser(filtered);
    } else {
      setFilteredInvitedUser(invitedUsers);
    }
  }, [invitedUsers, invitedUserSearch]);

  const handleDeleteUserInvitation = async (id: number) => {
    const { error } = await mutateDeleteInvitation({ data: { id: id } });
    if (error?.message) {
      showToast(error.message, "error", 2000);
      return;
    }
    showToast("Успешно удалено", "success", 2000);
    refetch();
  };

  return (
    <Modal
      isOpen={isRecordedUserModalOpen}
      className="mx-auto w-full  sm:w-[440px] rounded-t-[30px] rounded-b-0 sm:rounded-lg relative"
    >
      <div className="mb-[20px]  ">
        <div className={"flex justify-between  mb-3"}>
          <h1 className={"font-extrabold text-[20px] font-dewi"}>
            Записанные <br /> пользователи
          </h1>
          <ButtonForm
            text={<CloseWithBorderIcon />}
            onClick={() => setIsRecordedUserModalOpen(false)}
          />
        </div>
        <TextField
          value={invitedUserSearch}
          onChange={(e) => setInvitedUserSearch(e.target.value)}
          imgLeft={<SearchIcon color="#14191A" />}
          classNameInput="rounded-[50px]"
          placeholder="Введите текст для поиска"
        />
        <div className="absolute h-[1px] bg-boder-default left-0 right-0 mt-3"></div>
      </div>

      <div className="flex flex-col gap-[5px] max-h-[56vh] overflow-y-auto toolbar-scroll pr-3 pb-1">
        {filteredInvitedUser?.length ? (
          filteredInvitedUser?.map((user) => (
            <div
              key={user.id}
              className="p-3 flex justify-between items-center w-full bg-white-default border border-boder-default rounded-[4px]"
            >
              <div className="flex items-center gap-1">
                <img
                  src={
                    user.user?.avatar
                      ? process.env.REACT_APP_FILES_URL +
                        "/" +
                        user.user?.avatar
                      : images.profileImg
                  }
                  crossOrigin="anonymous"
                  width={24}
                  height={24}
                  alt="img user"
                  className=" w-[24px] h-[24px] rounded-full"
                />
                <div className="pl-3">
                  <p className="text-sm font-semibold">{user.user.fullName}</p>
                  <p className="text-[12px] font-normal">
                    {user.user.agency?.title}
                  </p>
                </div>
              </div>

              <div
                className={`flex items-center justify-center  text-[12px]  gap-[10px] ${
                  user.is_accepted === null && "opacity-70"
                } ${
                  user.is_accepted === true
                    ? "text-[#36AF22]"
                    : user.is_accepted === false
                    ? "text-red-default"
                    : "text-accent-default"
                }`}
              >
                {user.is_invited &&
                  (user.is_accepted !== null
                    ? user.is_accepted
                      ? "Принял"
                      : "Не принял"
                    : "Приглашен")}

                <div className=" cursor-pointer w-5 flex items-center justify-start">
                  {user.is_invited && user.is_accepted !== true ? (
                    <div onClick={() => handleDeleteUserInvitation(user.id)}>
                      <TrashIcon color="#EF3B24" />
                    </div>
                  ) : (
                    <div onClick={() => handleDeleteUserInvitation(user.id)}>
                      <CancelIcon />
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center text-accent-default opacity-70 mt-4">
            Еще не приглашенные пользователи
          </div>
        )}
      </div>
      <div className="pt-[30px]">
        <ButtonForm
          onClick={() => {
            setIsRecordedUserModalOpen(false);
            setIsRecordAgentModalOpen(true);
          }}
          text="Записать пользователя"
          className="py-3 px-6 bg-white-default border border-accent-default hover:bg-accent-default xl:flex-1 flex justify-center hover:text-white-default w-full"
        />
      </div>
    </Modal>
  );
};

export default InvitedUsersModal;
