import React, { useLayoutEffect, useRef } from "react";
import { images } from "utils/resource";
import { Office } from ".";
import MinusIcon from "components/constant/icons/MinusIcon";
import PlusIcon from "components/constant/icons/PlusIcon";

interface Props {
  contactsList?: Office[];
}

const ContactsMap = ({ contactsList }: Props) => {
  const mapRef = useRef<any>(null);

  useLayoutEffect(() => {
    if (contactsList?.length !== 0) {
      handleInit();
    }
  }, [contactsList]);

  const handleInit = () => {
    window.ymaps.ready(init);
    function init() {
      if (!mapRef.current) {
        mapRef.current = new window.ymaps.Map("map", {
          center: [contactsList?.[0].city.lat, contactsList?.[0].city.long],
          zoom: 9,
          controls: [],
        });
      }

      const customHintLayout = window.ymaps.templateLayoutFactory.createClass(
        `<div class="bg-white-default w-[200px] max-h-[100px] rounded-lg text-accent-default text-start py-2 px-3 text-xs overflow-hidden shadow-md font-medium ">
           <p class="">{{ properties.location }}</p> 
        </div>`
      );

      mapRef.current.geoObjects.removeAll();

      if (contactsList?.length !== 0) {
        contactsList?.forEach((ofis) => {
          const MyIconContentLayout =
            window.ymaps.templateLayoutFactory.createClass(
              `<div class="w-14 h-14 p-1 rounded-full bg-white-default relative after:absolute after:bottom-[-7px] after:left-4  after:h-0  after:w-0 after:border-r-[10px] after:border-t-[10px]
                after:border-l-[10px] after:border-r-transparent after:border-l-transparent after:border-t-white-default translate-x-[-50%] translate-y-[-100%]">
                <img src="${images.cardImg}" class="w-full h-full rounded-full" />
              </div>`
            );

          const myPlacemark = new window.ymaps.Placemark(
            [Number(ofis.lat), Number(ofis.long)],
            {
              location: ofis.location,
            },
            {
              hintLayout: customHintLayout,
              // Опции.
              // Необходимо указать данный тип макета.
              iconLayout: "default#imageWithContent",
              // Своё изображение иконки метки.
              iconImageHref: "images/ball.png",
              // Размеры метки.
              iconImageSize: [48, 48],
              // Смещение левого верхнего угла иконки относительно
              // её "ножки" (точки привязки).
              iconImageOffset: [-24, -24],
              // Смещение слоя с содержимым относительно слоя с картинкой.
              iconContentOffset: [15, 15],
              iconContentLayout: MyIconContentLayout,
            }
          );
          mapRef.current.geoObjects.add(myPlacemark);
        });

        mapRef.current.setCenter(
          [contactsList?.[0].city.lat, contactsList?.[0].city.long],
          9,
          { duration: 1500 }
        );
      }
    }
  };

  const zoomIn = () => {
    if (mapRef.current) {
      const currentZoom = mapRef.current.getZoom();
      mapRef.current.setZoom(currentZoom + 1, { duration: 300 });
    }
  };

  const zoomOut = () => {
    if (mapRef.current) {
      const currentZoom = mapRef.current.getZoom();
      mapRef.current.setZoom(currentZoom - 1, { duration: 300 });
    }
  };
  return (
    <div id="map" className="flex-1 h-[437px] w-full overflow-hidden relative">
      <div className="absolute top-[50%] -translate-y-[50%] hidden sm:flex  right-[10%]  flex-col gap-2 z-10 ">
        <button
          onClick={zoomIn}
          className="w-12 bg-white-default h-12 bg-white rounded-full shadow-md flex items-center justify-center hover:bg-gray-200"
        >
          <PlusIcon color="#37465B" />
        </button>
        <button
          onClick={zoomOut}
          className="w-12 bg-white-default h-12 bg-white rounded-full shadow-md flex items-center justify-center hover:bg-gray-200"
        >
          <MinusIcon />
        </button>
      </div>
    </div>
  );
};

export default ContactsMap;
