import { Deal } from "modules/Deal/type";
import { apiService } from "..";
import { MetaData } from "../exception";
import { Client } from "../base-clients/type";

export const addTagTypes = ["exchange"] as const;

export const citiesApiHooks = apiService
  .enhanceEndpoints({ addTagTypes })
  .injectEndpoints({
    endpoints: (build) => ({
      getLeads: build.query<MetaData<Deal[], { statuses: string[] }>, string>({
        query: (query) => ({
          path: `/leads?${query}&page=1&limit=500`,
        }),
        providesTags: ["exchange"],
      }),
      getExchange: build.mutation<MetaData<Deal[]>, string>({
        query: (query) => ({
          path: query ? `/exchange-request?${query}` : "/exchange-request"
        }),
      }),
    }),
  });

export const {
	useGetLeadsQuery,
	useGetExchangeMutation
} = citiesApiHooks;
