import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TrainingCategoryType, TrainingType } from "data/api/training/type";

interface SelectionState {
  categories: TrainingCategoryType[];
  deletedCategories: number[];
  trainings: TrainingType[];
  deletedTrainings: number[];
  isSubmitTriggered: boolean;
}

const initialState: SelectionState = {
  categories: [],
  deletedCategories: [],
  trainings: [],
  deletedTrainings: [],
  isSubmitTriggered: false,
};

const trainingSlice = createSlice({
  name: "training",
  initialState,
  reducers: {
    handleCreateTrainingCategory: (
      state,
      action: PayloadAction<TrainingCategoryType>
    ) => {
      const categoryWithNewPrefix = {
        ...action.payload,
        training: [],
        ref_id: `new-${action.payload.id}`,
      };
      state.categories.push(categoryWithNewPrefix);
    },
    handleDeleteTrainingCategories: (
      state,
      action: PayloadAction<TrainingCategoryType>
    ) => {
      const { id, ref_id } = action.payload;
      if (ref_id == undefined || !ref_id.startsWith("new-")) {
        state.deletedCategories.push(id);
      }
      state.categories = state.categories.filter((item) => item.id != id);
    },
    handleUpdateTrainingCategories: (
      state,
      action: PayloadAction<TrainingCategoryType>
    ) => {
      const { id, ref_id } = action.payload;
      const indexOfUpdatedItem = state.categories.findIndex(
        (categ) => categ.id === id
      );
      if (ref_id?.startsWith("new-")) {
        state.categories[indexOfUpdatedItem] = action.payload;
      } else {
        state.categories[indexOfUpdatedItem] = {
          ...action.payload,
          ref_id: `old-${action.payload.id}`,
        };
      }
    },
    handleInitTrainingCategories: (
      state,
      action: PayloadAction<TrainingCategoryType[]>
    ) => {
      state.categories = [...action.payload];
    },
    handleInitTrainings: (state, action: PayloadAction<TrainingType[]>) => {
      state.trainings = action.payload;
    },
    handleCreateTrainings: (state, action: PayloadAction<TrainingType>) => {
      const { category_id, category_ref_id } = action.payload;
      const trainingWithNewKeyword = {
        ...action.payload,
        ref_id: `new-${action.payload.id}`,
        category_ref_id: !category_ref_id?.startsWith("new-")
          ? `old-${category_id}`
          : category_ref_id,
      };
      state.trainings?.push(trainingWithNewKeyword);
      const categoryIndex = state.categories.findIndex(
        (categ) => categ.id == category_id
      );
      if (categoryIndex >= 0) {
        state.categories[categoryIndex].training?.push(trainingWithNewKeyword);
      }
    },
    hanldeUpdateTraining: (state, action: PayloadAction<TrainingType>) => {
      const { id, ref_id, category_id } = action.payload;
      const indexOfCategory = state.categories.findIndex(
        (categ) => categ.id === category_id
      );
      const indexOfCategoryTraining = state.categories[
        indexOfCategory
      ].training?.findIndex((training) => training.id === id);
      console.log("indexOfCategory", indexOfCategory);
      console.log("indexOfCategoryTraining", indexOfCategoryTraining);

      const indexOfUpdatedItem = state.trainings.findIndex(
        (training) => training.id === id
      );
      if (ref_id?.startsWith("new-")) {
        state.trainings[indexOfUpdatedItem] = action.payload;
        state.categories[indexOfCategory].training[indexOfCategoryTraining] =
          action.payload;
      } else if (ref_id === undefined || ref_id?.startsWith("old-")) {
        state.trainings[indexOfUpdatedItem] = {
          ...action.payload,
          ref_id: `old-${action.payload.id}`,
          category_ref_id: `old_${action.payload.category_id}`,
        };
        state.categories[indexOfCategory].training[indexOfCategoryTraining] = {
          ...action.payload,
          ref_id: `old-${action.payload.id}`,
          category_ref_id: `old_${action.payload.category_id}`,
        };
      }
    },
    handleDeleteTrainings: (state, action: PayloadAction<TrainingType>) => {
      const { id, ref_id, category_id } = action.payload;
      const indexOfCategory = state.categories.findIndex(
        (categ) => categ.id === category_id
      );

      if (ref_id === undefined || !ref_id.startsWith("new-")) {
        state.deletedTrainings.push(id);
      }
      state.trainings = state.trainings.filter((item) => item.id !== id);
      state.categories[indexOfCategory].training = state.categories[
        indexOfCategory
      ].training?.filter((item) => item.id != id);
    },
    handleReset: (state) => {
      state = initialState;
    },
    handleResetDelete: (state) => {
      state.deletedCategories = [];
      state.deletedTrainings = [];
    },
    triggerSubmitTraining(state) {
      state.isSubmitTriggered = true;
    },
    resetSubmitTraining(state) {
      state.isSubmitTriggered = false;
    },
  },
});

export const {
  handleCreateTrainingCategory,
  handleDeleteTrainingCategories,
  handleUpdateTrainingCategories,
  handleInitTrainingCategories,
  handleInitTrainings,
  handleCreateTrainings,
  hanldeUpdateTraining,
  handleDeleteTrainings,
  handleReset,
  handleResetDelete,
  triggerSubmitTraining,
  resetSubmitTraining,
} = trainingSlice.actions;

export default trainingSlice.reducer;
