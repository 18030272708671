import React, { ChangeEvent, Dispatch, SetStateAction } from "react";

import Label from "components/Form/Label";
import TextField from "components/Form/TextField/TextField";
import PhoneIcon from "components/constant/icons/PhoneIcon";
import DatePickerTimeField from "components/Form/DatePicker/DatePickerTimeField";
import ClockIcon from "components/constant/icons/ClockIcon";
import CancelIcon from "components/constant/icons/CancelIcon";
import PlusIcon from "components/constant/icons/PlusIcon";
import { IContact, WorkSchedule } from "data/api/contact/type";
import moment from "moment";
import { useDispatch } from "react-redux";
import { handleDeleteContact } from "store/ContactReducer";
import MaskComponent from "components/MaskInput/MaskComponent";
import SearchByAddress from "./SearchByAddress";

interface IProps {
  inputsValue: IContact;
  setInputValue: Dispatch<SetStateAction<IContact[]>>;
  index: number;
  contacts?: IContact[];
}

export interface AddressDetail {
  title: string;
  lat: number;
  long: number;
  city?: string;
}

const ContactEditForm: React.FC<IProps> = ({
  inputsValue,
  setInputValue,
  index,
  contacts,
}) => {
  const dispatch = useDispatch();

  const weekDay: Record<WorkSchedule["weekday"], string> = {
    0: "Пн",
    1: "Вт",
    2: "Ср",
    3: "Чт",
    4: "Пт",
    5: "Сб",
    6: "Вс",
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index?: number
  ) => {
    const { name, value } = e.target;

    setInputValue((prev) =>
      prev.map((input, i) => {
        if (i === index) {
          if (name === "address") {
            return {
              ...input,
              ref_id: input.ref_id?.startsWith("new-")
                ? input.ref_id
                : `old-${Date.now()}`,
              [name]: {
                ...input[name],
                title: value,
              },
            };
          } else {
            return {
              ...input,
              ref_id: input.ref_id?.startsWith("new-")
                ? input.ref_id
                : `old-${Date.now()}`,
              [name]: value,
            };
          }
        }
        return input;
      })
    );
  };

  const handleWorkingHoursChange = (
    index: number,
    weekday: number | null,
    timeType: "start_time" | "end_time",
    selectedTime: Date | null
  ) => {
    setInputValue((prev) =>
      prev.map((input, i) =>
        i === index
          ? {
              ...input,
              ref_id: input.ref_id?.startsWith("new-")
                ? input.ref_id
                : `old-${Date.now()}`,
              work_schedule: input.work_schedule.map((schedule) =>
                schedule.weekday === weekday
                  ? {
                      ...schedule,
                      [timeType]: selectedTime
                        ? moment(selectedTime).format("HH:mm")
                        : null,
                    }
                  : schedule
              ),
            }
          : input
      )
    );
  };

  const handlePhoneChange = (
    index: number,
    phoneIndex: number,
    value: string
  ) => {
    setInputValue((prev) =>
      prev.map((input, i) => {
        if (i !== index) return input;

        return {
          ...input,
          ref_id: input.ref_id?.startsWith("new-")
            ? input.ref_id
            : contacts?.[index].phone_number[phoneIndex] !== value
            ? `old-${Date.now()}`
            : "",
          phone_number: input.phone_number.map((phone, pIndex) =>
            pIndex === phoneIndex ? value : phone
          ),
        };
      })
    );
  };

  const removePhoneNumber = (index: number, phoneIndex: number) => {
    setInputValue((prev) =>
      prev.map((input, i) =>
        i === index
          ? {
              ...input,
              ref_id: input.ref_id?.startsWith("new-")
                ? input.ref_id
                : `old-${Date.now()}`,
              phone_number: input.phone_number.filter((_, pIndex) =>
                inputsValue.phone_number.length > 1
                  ? pIndex !== phoneIndex
                  : input.phone_number
              ),
            }
          : input
      )
    );
  };

  const addPhoneNumber = (index: number) => {
    setInputValue((prev) =>
      prev.map((input, i) =>
        i === index
          ? {
              ...input,
              ref_id: input.ref_id?.startsWith("new-")
                ? input.ref_id
                : `old-${Date.now()}`,
              phone_number: [...input.phone_number, ""],
            }
          : input
      )
    );
  };

  const handleAddressSelect = (item: AddressDetail) => {
    setInputValue((prev) =>
      prev.map((input, i) => {
        if (i === index) {
          return {
            ...input,
            ref_id: input.ref_id?.startsWith("new-")
              ? input.ref_id
              : `old-${Date.now()}`,
            address: {
              id: input.address.id,
              title: item.title,
              lat: String(item.lat),
              long: String(item.long),
            },
            address_link: `https://yandex.ru/maps/?ll=${item?.long},${item?.lat}`,
          };
        }
        return input;
      })
    );
  };

  function convertTimeStringToDate(timeString: Date | undefined): Date | null {
    if (!timeString) {
      return null;
    }

    const currentDate = moment();

    const dateTimeString = `${currentDate.format("YYYY-MM-DD")}T${timeString}`;

    const dateTimeMoment = moment(dateTimeString);

    return dateTimeMoment.toDate();
  }

  const addMinutesToTime = (
    timeString: Date | undefined,
    minutesToAdd: number
  ) => {
    const updatedTime = moment(timeString, "HH:mm").add(
      minutesToAdd,
      "minutes"
    );
    return updatedTime.toDate();
  };

  function deleteForm() {
    if (inputsValue.ref_id && inputsValue.ref_id.startsWith("new-")) {
      setInputValue((prev) => prev.filter((input, i) => i !== index));
    } else {
      setInputValue((prev) => prev.filter((input, i) => i !== index));
      dispatch(handleDeleteContact(inputsValue.id));
    }
  }

  return (
    <div className="w-[406px] flex flex-col gap-5 border border-dashed border-blue-default p-[15px] rounded-[4px] relative self-start z-0">
      <button onClick={deleteForm} className="absolute top-3 right-3">
        <CancelIcon color="#EF3B24" />
      </button>
      <TextField
        value={inputsValue.title}
        placeholder="Заголовок"
        label="Заголовок"
        className="flex-1"
        name="title"
        onChange={(e) => handleChange(e, index)}
      />
      <SearchByAddress
        handleAddressSelect={handleAddressSelect}
        index={index}
        inputsValue={inputsValue?.address?.title}
        handleChange={handleChange}
      />
      <div className="flex flex-col gap-[10px]">
        <p className="text-sm text-accent-default mb-2">Пн - Вс 9:00-21:00</p>
        {inputsValue.work_schedule.map((day, i) => (
          <div key={`${day.weekday + i}`} className="flex gap-[10px]">
            <button
              className={`w-[58px] h-[47px] ${
                day.start_time && day.start_time
                  ? day.weekday === 5 || day.weekday === 6
                    ? "bg-red-default text-white-default"
                    : "bg-accent-default text-white-default"
                  : "border border-accent-default text-accent-default "
              }   outline-none rounded-[4px] text-[16px] font-medium`}
            >
              {weekDay[day.weekday]}
            </button>
            <DatePickerTimeField
              timeInterVal={15}
              onChangeDate={(date) =>
                handleWorkingHoursChange(index, day.weekday, "start_time", date)
              }
              startDate={convertTimeStringToDate(day.start_time)}
              isIcon={<ClockIcon />}
            />
            <DatePickerTimeField
              timeInterVal={15}
              onChangeDate={(date) =>
                handleWorkingHoursChange(index, day.weekday, "end_time", date)
              }
              minTime={addMinutesToTime(day?.start_time, 15)}
              disabled={!day.start_time}
              startDate={convertTimeStringToDate(day.end_time)}
              isIcon={<ClockIcon />}
            />
          </div>
        ))}
      </div>
      {inputsValue.phone_number.map((phone, phoneIndex) => (
        <div key={`phone-${phoneIndex}`} className="flex gap-4 ">
          <Label label="Телефон" className="w-full">
            <MaskComponent
              name="phone_number"
              key={`input-${phoneIndex}`}
              imgLeft={<PhoneIcon />}
              value={phone}
              onChange={(value) => handlePhoneChange(index, phoneIndex, value)}
            />
          </Label>
          <button
            className="mt-5"
            onClick={() => removePhoneNumber(index, phoneIndex)}
          >
            <CancelIcon color="#EF3B24" />
          </button>
        </div>
      ))}

      <button
        className="h-[48px] w-full rounded-lg flex items-center justify-center border border-dashed border-blue-default outline-none"
        onClick={() => addPhoneNumber(index)}
      >
        <PlusIcon width={28} height={28} color="#3583FF" />
      </button>
    </div>
  );
};

export default ContactEditForm;
