import {  useMemo } from "react";
import {
  dropDownOptionsType,
} from "modules/Apartment/Filter/type";

type optionsType = {
  id: number;
  name: string;
  fullname?: string;
}[];

type ItemType = {
  [key: string]: any;
};

export const useFormatForDropDownOptions = (
  data: optionsType,
  formatOption?: string
) => {
  const options = useMemo(() => {
    const formatOptions = (
      list: ItemType[],
      labelKey: string,
      valueKey: string | number
    ): dropDownOptionsType[] => {
      return list.map((item) => ({
        label: item[labelKey],
        value: item[valueKey],
      }));
    };

    const optionsArray = formatOptions(data, formatOption || "name", "id");
    return optionsArray;
  }, [data, formatOption]);

  return options;
};

// For Test ====
type dropDownOptions = {
  label: string | number;
  value: string | number;
};

export const useFormatForDropDown = <T extends Record<string, any>>(
  data: T[],
  formatOption?: string
) => {
  const options = useMemo(() => {
    const formatOptions = (
      list: T[],
      labelKey: keyof T,
      valueKey: keyof T
    ): dropDownOptions[] => {
      return list.map((item) => ({
        label: item[labelKey] as string | number,
        value: item[valueKey] as string | number,
      }));
    };

    const optionsArray = formatOptions(data, formatOption || "name", "id");
    return optionsArray;
  }, [data, formatOption]);

  return options;
};
