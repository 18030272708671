import { useEffect, useState } from "react";
import { BookingForm } from "components/BookingAndVisits/BookingFrom";
import { Control, useWatch } from "react-hook-form";
import { useGetNotBookedPrimisesMutation } from "data/api/booking";
import { Apartment } from "modules/Apartment/ApartmentList/dataTransformer";
import { objectToQueryString } from "utils/objectToQuery";

interface UseNotBookedPrimisesProps {
  control: Control<BookingForm>;
}

const useNotBookedPrimises = ({ control }: UseNotBookedPrimisesProps) => {
  const [primises, setPrimises] = useState<Apartment[] | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [getNotBookedPromisesMutation] = useGetNotBookedPrimisesMutation();

  const buildingId = useWatch({
    control,
    name: "building",
  });

  const primisesType = useWatch({
    control,
    name: "type",
  });

  useEffect(() => {
    if (primisesType && buildingId) {
      const filterObj = {
        building_id: buildingId,
        type: primisesType,
      };

      const query = objectToQueryString(filterObj);

      const fetchPrimises = async () => {
        setIsLoading(true);
        try {
          const { data: res } = await getNotBookedPromisesMutation(
            query
          ).unwrap();

          setPrimises(res);
        } catch (err) {
          console.log("Failed to fetch primises data.");
        } finally {
          setIsLoading(false);
        }
      };

      fetchPrimises();
    }
  }, [buildingId, primisesType, getNotBookedPromisesMutation]);

  return { primises, isLoading };
};

export default useNotBookedPrimises;
