import PersonIcon from "components/constant/icons/adminstration/PersonIcon";
import CoinOnHandIcon from "components/constant/icons/Analytics/CoinOnHandIcon";
import CoinsIcon from "components/constant/icons/Analytics/CoinsIcon";
import DealsWonIcon from "components/constant/icons/Analytics/DealsWonIcon";
import EventRegisterIcon from "components/constant/icons/Analytics/EventRegisterIcon";
import NumOfTransacIcon from "components/constant/icons/Analytics/NumOfTransacIcon";
import EyeIcon from "components/constant/icons/EyeIcon";
import LikeIcon from "components/constant/icons/LikeIcon";
import { DealStatus, DealSteps } from "enums/deals";
import { label } from "yet-another-react-lightbox";

export enum TimeFilter {
  TODAY = "Today",
  WEEK = "Week",
  MONTH = "Month",
  YEAR = "Year",
}

export const data = {
  registeredAgentChart: {
    data: [
      {
        name: "9",
        uv: 4000,
        amt: 2400,
      },
      {
        name: "10",
        uv: 3000,
        amt: 2210,
      },
      {
        name: "11",
        uv: 2000,
        amt: 2290,
      },
      {
        name: "12",
        uv: 2780,
        amt: 20000,
      },
      {
        name: "13",
        uv: 1890,
        amt: 15000,
      },
      {
        name: "14",
        uv: 2390,
        amt: 10000,
      },
      {
        name: "15",
        uv: 3490,
        amt: 5000,
      },
      {
        name: "16",
        uv: 1800,
        amt: 5000,
      },
      {
        name: "17",
        uv: 490,
        amt: 5000,
      },
      {
        name: "18",
        uv: 2490,
        amt: 5000,
      },
      {
        name: "19",
        uv: 1490,
        amt: 5000,
      },
    ],
    title: "Зарегистрированные агенты",
    dropdown: false,
    field: "register",
  },
  activeAgentChart: {
    data: [
      {
        name: "9",
        uv: 4000,
        amt: 2400,
      },
      {
        name: "10",
        uv: 3000,
        amt: 2210,
      },
      {
        name: "11",
        uv: 2000,
        amt: 2290,
      },
      {
        name: "12",
        uv: 2780,
        amt: 20000,
      },
      {
        name: "13",
        uv: 1890,
        amt: 15000,
      },
      {
        name: "14",
        uv: 2390,
        amt: 10000,
      },
      {
        name: "15",
        uv: 3490,
        amt: 5000,
      },
      {
        name: "16",
        uv: 1800,
        amt: 5000,
      },
      {
        name: "17",
        uv: 490,
        amt: 5000,
      },
      {
        name: "18",
        uv: 2490,
        amt: 5000,
      },
      {
        name: "19",
        uv: 1490,
        amt: 5000,
      },
    ],
    title: "Активные пользователи",
    dropdown: true,
    field: "active",
  },
  cityChart: {
    data: [
      {
        name: "9",
        uv: 4000,
        amt: 2400,
      },
      {
        name: "10",
        uv: 3000,
        amt: 2210,
      },
      {
        name: "11",
        uv: 2000,
        amt: 2290,
      },
      {
        name: "12",
        uv: 2780,
        amt: 20000,
      },
      {
        name: "13",
        uv: 1890,
        amt: 15000,
      },
      {
        name: "14",
        uv: 2390,
        amt: 10000,
      },
      {
        name: "15",
        uv: 3490,
        amt: 5000,
      },
      {
        name: "16",
        uv: 1800,
        amt: 5000,
      },
      {
        name: "17",
        uv: 490,
        amt: 5000,
      },
      {
        name: "18",
        uv: 2490,
        amt: 5000,
      },
      {
        name: "19",
        uv: 1490,
        amt: 5000,
      },
    ],
    title: "Статистика по городам",
    dropdown: true,
    field: "byCity",
  },
};

// export const infoCartData1 = [
//   {
//     id: 1,
//     title: "Размер\nвознаграждений",
//     icons: CoinOnHandIcon,
//     count: "25 000 000 Руб",
//     bottomLeft: "+452 000 руб",
//     bottomRight: "+53%",
//   },
//   {
//     id: 2,
//     title: "Среднее\nвознаграждение",
//     icons: CoinsIcon,
//     count: "25 000 000 Руб",
//     bottomLeft: "+452 000 руб",
//     bottomRight: "+53%",
//   },
// ];

export enum MainInfoDataFormat {
  MONEY = "MONEY",
  SIZE = "SIZE",
  COUNT = "COUNT",
}

export const infoCartData = {
  complited_leads_fee: {
    id: 1,
    title: "Размер\nвознаграждений",
    icons: CoinOnHandIcon,
    type: MainInfoDataFormat.MONEY,
  },
  complited_leads_fee_avg: {
    id: 2,
    title: "Среднее\nвознаграждение",
    icons: CoinsIcon,
    type: MainInfoDataFormat.MONEY,
  },
  completed_leads: {
    id: 3,
    title: "Выиграно\nсделок",
    icons: DealsWonIcon,
    type: MainInfoDataFormat.COUNT,
  },
  leads_count: {
    id: 4,
    title: "Кол-во\nсделок",
    icons: NumOfTransacIcon,
    dropdownd: "Показ",
    type: MainInfoDataFormat.SIZE,
  },
  news_views: {
    id: 5,
    title: "Просмотр\nновостей",
    icons: EyeIcon,
    type: MainInfoDataFormat.COUNT,
  },
  news_likes: {
    id: 6,
    title: "Лайки на\nновости",
    icons: LikeIcon,
    type: MainInfoDataFormat.COUNT,
  },
  complited_leads_avg_price: {
    id: 7,
    title: "Средний чек\nсделки",
    icons: NumOfTransacIcon,
    type: MainInfoDataFormat.MONEY,
  },
  complited_leads_avg_size: {
    id: 8,
    title: "Средний чек\nсделки",
    icons: NumOfTransacIcon,
    type: MainInfoDataFormat.SIZE,
  },
  premise_basket_links_count: {
    id: 9,
    title: "Кол-во сгенерированных\nподборок",
    icons: NumOfTransacIcon,
    type: MainInfoDataFormat.COUNT,
  },
  event_invitations: {
    id: 9,
    title: "Записей на\nмероприятия",
    icons: EventRegisterIcon,
    type: MainInfoDataFormat.COUNT,
  },
};

export const personalInfoCardData = {
  clients: {
    id: 1,
    title: "Заведенных клиентов",
    icons: PersonIcon,
    type: MainInfoDataFormat.COUNT,
  },
  completed_leads: {
    id: 2,
    title: "Выиграно сделок",
    icons: DealsWonIcon,
    type: MainInfoDataFormat.COUNT,
  },
  premise_basket_links_count: {
    id: 6,
    title: "Cформированных подборок",
    icons: NumOfTransacIcon,
    type: MainInfoDataFormat.COUNT,
  },
  complited_leads_avg_price: {
    id: 4,
    title: "Средний чек сделки",
    icons: NumOfTransacIcon,
    type: MainInfoDataFormat.MONEY,
  },
  complited_leads_avg_size: {
    id: 5,
    title: "Средняя площадь сделки",
    icons: NumOfTransacIcon,
    type: MainInfoDataFormat.SIZE,
  },
};

export const dealsInfoSteps = [
  { id: 1, color: "bg-[#36C979]", label: "Выиграна" },
  { id: 2, color: "bg-[#FF005C]", label: "Проиграна" },
  { id: 3, color: "bg-[#3B4A60]", label: "На паузе" },
  { id: 4, color: "bg-[#3B4A60]", label: "На паузе" },
  { id: 5, color: "bg-[#3B4A60]", label: "На паузе" },
  { id: 6, color: "bg-[#3B4A60]", label: "На паузе" },
  { id: 7, color: "bg-[#3B4A60]", label: "На паузе" },
  { id: 8, color: "bg-[#3B4A60]", label: "На паузе" },
  { id: 9, color: "bg-[#3B4A60]", label: "На паузе" },
];

export const dealsColor = {
  [DealSteps.CHECK_LEAD]: "bg-[#36C979]",
  [DealSteps.ON_PAUSE]: "bg-[#3B4A60]",
  [DealSteps.FAILED]: "bg-[#36C979]",
  others: "bg-[#3B4A60]",
};

export function setColorByStatus(status: DealSteps) {
  switch (status) {
    case "выиграна":
      return "#36C979";
    case "на паузе":
      return "#DEBE4E";
    case "отмененная бронь":
      return "#ff8474";
    case "проиграна":
      return "#ef3b24";
    case "слетевшая бронь":
      return "#ff8474";
    default:
      return "#f4f4f4";
  }
}
export const dateSelectTabs = [
  { id: 1, label: "Сегодня", value: TimeFilter.TODAY },
  { id: 2, label: "Неделя", value: TimeFilter.WEEK },
  { id: 3, label: "Месяц", value: TimeFilter.MONTH },
  { id: 4, label: "Год", value: TimeFilter.YEAR },
];

export const statisticsByCityOptions = [
  { label: "Все", value: "" },
  { label: "Зарегистрировано агентов", value: "Зарегистрировано агентов" },
  { label: "Активные агенты", value: "Активные агенты" },
  { label: "Активные сотрудники", value: "Активные сотрудники" },
];
