import React from "react";

const EmailIcon = ({ color = "#3B4658" }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.6875 7.25L11.3596 12.0403C11.7449 12.3168 12.2551 12.3168 12.6404 12.0403L19.3125 7.25M5.25 19.5H18.75C19.9926 19.5 21 18.4553 21 17.1667V7.83333C21 6.54467 19.9926 5.5 18.75 5.5H5.25C4.00736 5.5 3 6.54467 3 7.83333V17.1667C3 18.4553 4.00736 19.5 5.25 19.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EmailIcon;
