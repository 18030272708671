interface PropsType {
  list: string[];
  index: number;
}

const NewsBannerVertical = ({ list, index }: PropsType) => {
  return (
    <div className="slider-container relative">
      {list.map((img, idx) => (
        <div key={`slider-img-item-${index}${idx}`}>
          <img
            src={process.env.REACT_APP_FILES_URL + "/" + img}
            width={"100%"}
            className="md:h-[404px] h-[142px] object-cover"
            crossOrigin="anonymous"
          />
        </div>
      ))}
    </div>
  );
};

export default NewsBannerVertical;
