import React, { FC, ReactNode } from "react";
import LoaderSm from "../Loader/LoaderSm";

interface ButtonFormProps {
  loading?: boolean;
  leftIcon?: ReactNode | undefined;
  rightIcon?: ReactNode | undefined;
  text: string | ReactNode;
  onClick?: (event: any) => void;
  onMouseEnter?: (event: any) => void;
  onMouseLeave?: (event: any) => void;
  className?: string;
  type?: "submit" | "reset" | "button" | undefined;
  gap?: number;
  disabled?: boolean;
  id?: any;
}
const ButtonForm: FC<ButtonFormProps> = ({
  loading = false,
  leftIcon,
  rightIcon,
  text,
  onClick,
  onMouseEnter,
  onMouseLeave,
  className,
  type = "button",
  gap = 10,
  disabled = false,
  id,
}) => {
  function onClickBtn(event: any) {
    onClick && onClick(event);
  }

  return (
    <button
      id={id}
      onMouseOver={onMouseEnter}
      onMouseLeave={onMouseLeave}
      type={type}
      onClick={(event) => onClickBtn(event)}
      className={`
                ${className}
                text-sm 
                relative 
                flex 
                rounded-[4px] 
                transition-all
                duration-400
                items-center 
                outline-none
                text-center
                px-2
                ${disabled && "bg-accent-disabled text-boder-focus"}
            `}
      style={{
        gap: leftIcon || rightIcon ? gap : 0,
        cursor: disabled ? "no-drop" : "pointer",
        pointerEvents: disabled ? "none" : "auto",
      }}
      disabled={disabled || loading}
    >
      {leftIcon && <span className="header__left">{leftIcon}</span>}

      {loading ? (
        <div className="vertical-center" style={{ position: "relative" }}>
					<LoaderSm />
        </div>
      ) : (
        text
      )}
      {rightIcon && <span className="header__right">{rightIcon}</span>}
    </button>
  );
};

export default ButtonForm;
