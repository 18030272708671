import React, { FC, ReactNode, useState } from "react";
import ArrowTableIcon from "../../constant/icons/Table/ArrowTableIcon";
import SpinnerIcon from "../../constant/icons/SpinnerIcon";
import { formatPhoneNumber } from "hooks/useFormatInput";
import PencilIcon from "components/constant/icons/PencilIcon";
import { User, UserStatus } from "data/api/profile/type";
import { images } from "utils/resource";
import useFormattedDate from "hooks/useFormatDate";

export interface DataItem {
  [key: string]: User;
}

export interface TableColumn {
  key: keyof User;
  title?: string;
  width?: string;
  sortable?: boolean;
}

interface TableContainerProps {
  header: TableColumn[];
  content: User[] | undefined;
  setData?: (list: User[] | undefined) => void;
  sortable?: boolean;
  textContentEmpty?: string | ReactNode;
  onRightClickTrContent?: (e: MouseEvent, item: User) => void;
  onLeftClickTrContent?: (e: User) => void;
  isLastRightText?: boolean;
  loading?: boolean;
  tableBodyUnderBlock?: any;
  doSelectData?: boolean;
  handleSelectTableData?: (id: number) => void;
  handleEditTableItem: (data: User) => void;
  handleSortBy?: (sort_by: string, order_by: string) => void;
}

const AdminTableContainer: FC<TableContainerProps> = ({
  header,
  content,
  setData,
  sortable = true,
  textContentEmpty = "Пусто",
  onRightClickTrContent,
  onLeftClickTrContent,
  isLastRightText = true,
  loading = false,
  tableBodyUnderBlock,
  handleEditTableItem,
  handleSortBy,
}) => {
  const [sortConfig, setSortConfig] = useState<{
    key: keyof User;
    direction: string;
  } | null>(null);

  const formatDate = useFormattedDate();

  const handleSort = (column: TableColumn) => {
    if (!column.sortable && !sortable) return;

    let direction = "ASC";
    if (
      sortConfig &&
      sortConfig.key === column.key &&
      sortConfig.direction === "ASC"
    ) {
      direction = "DESC";
    }
    setSortConfig({ key: column.key, direction });
    const data: any = {
      fullName: "fullname",
      agency: "agency_name",
      roleText: "role",
      city: "city_name",
      status: "status",
      created_at: "registered_at",
    };
    handleSortBy?.(data[column.key], direction);
  };

  function getStatus(status: string) {
    switch (status) {
      case "active":
        return "Активный";
      case "blocked":
        return "Заблокирован";
      case "registered":
        return "Зарегистрирован";
      case "isNew":
        return (
          <div className="flex items-center gap-2">
            <div className="w-[7px] h-[7px] rounded-full bg-red-default"></div>
            Регистрация
          </div>
        );
      default:
        return null;
    }
  }
  function getColumnWidths(headers: string) {
    switch (headers) {
      case "fullName": {
        return "w-[200px]";
      }
      case "agency": {
        return "w-[180px]";
      }
      case "roleText": {
        return "w-[180px]";
      }

      default: {
        return "w-[100px] xl:w-[130px]";
      }
    }
  }

  const imgUrl = process.env.REACT_APP_FILES_URL;

  return (
    <div className="overflow-x-auto">
      <table className=" table-auto border-collapse w-full bg-white-default rounded-lg ">
        <thead className="table__header">
          <tr className={"border-b-[1px] border-boder-default"}>
            {header.map((column, idx) => {
              const isDesc =
                sortConfig?.key === column.key &&
                sortConfig.direction === "DESC";

              return (
                <th
                  key={column.key}
                  style={{ width: column.width }}
                  className={
                    idx === header.length - 1 && isLastRightText
                      ? "py-4 px-[15px] 2xl:px-5 "
                      : "py-4 px-[15px] 2xl:px-5 "
                  }
                >
                  <div
                    className={`table__header__th flex ${getColumnWidths(
                      column.key
                    )}`}
                  >
                    <p
                      className={"mr-2 text-[14px] 2xl:text-base font-semibold"}
                    >
                      {column.title}
                    </p>

                    {column.sortable && sortable && (
                      <button
                        onClick={() => handleSort(column)}
                        className={`transition-all ${
                          isDesc ? "rotate-180" : ""
                        }`}
                      >
                        {sortConfig?.direction &&
                        sortConfig.direction === "ASC" ? (
                          <ArrowTableIcon />
                        ) : (
                          <ArrowTableIcon />
                        )}
                      </button>
                    )}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>

        <tbody
          className={`table__content ${
            loading ? "table__content-loading" : ""
          }`}
        >
          {content?.length ? (
            content?.map((item, index) => (
              <React.Fragment key={`table-content-item-${index}`}>
                <tr
                  key={index}
                  className={`cursor-pointer w-full hover:bg-bg-default m-1 ${
                    item.status === UserStatus.BLOCKED ? "opacity-50" : ""
                  }`}
                >
                  {header.map((column, idx) => {
                    return (
                      <td
                        onContextMenu={(e: any) => {
                          onRightClickTrContent &&
                            onRightClickTrContent(e, item);
                        }}
                        onClick={() => {
                          onLeftClickTrContent && onLeftClickTrContent(item);
                        }}
                        key={column.key}
                        colSpan={idx === header.length - 1 ? header.length : 1}
                        className={`py-4 px-3 text-[14px] 2xl:text-[16px] 2xl:px-7 max-w-[320px]  overflow-hidden whitespace-nowrap text-ellipsis   ${
                          idx === header.length - 1 && isLastRightText
                            ? "last"
                            : ""
                        }`}
                      >
                        <div className={`${getColumnWidths(column.key)}`}>
                          {column.key === "fullName" ? (
                            <div className="flex gap-3 items-center">
                              <img
                                src={
                                  item.avatar
                                    ? `${imgUrl}/${item.avatar}`
                                    : images.defaultImg
                                }
                                alt="user image"
                                width={50}
                                height={50}
                                className="rounded-full w-[50px] h-[50px]"
                                crossOrigin="anonymous"
                              />
                              <div className="flex flex-col ">
                                <p className="text-[16px] text-accent-default font-semibold">
                                  {item?.fullName}
                                </p>
                                <p className="text-sm text-accent-disabled font-medium">
                                  {item.phone
                                    ? "+7 " + formatPhoneNumber(item.phone)
                                    : null}
                                </p>
                              </div>
                            </div>
                          ) : typeof item[column.key] === "object" ? (
                            <>
                              {column.key === "agency"
                                ? item[column.key]?.legalName
                                : column.key === "city"
                                ? item[column.key]?.name
                                : null}
                            </>
                          ) : (
                            <>
                              {column.key === "created_at"
                                ? formatDate(item[column.key], false)
                                : column.key === "status"
                                ? !item.is_verified
                                  ? getStatus("isNew")
                                  : getStatus(item.status)
                                : item[column.key]}
                            </>
                          )}
                        </div>
                      </td>
                    );
                  })}
                  <td className="flex gap-5 items-center py-6 px-3  2xl:pl-9 ">
                    <div
                      onClick={() => handleEditTableItem(item)}
                      className="h-[34px] w-[34px] rounded flex items-center justify-center border border-accent-default"
                    >
                      <PencilIcon />
                    </div>
                  </td>
                </tr>

                {tableBodyUnderBlock && tableBodyUnderBlock(item)}
              </React.Fragment>
            ))
          ) : (
            <tr>
              <td colSpan={header.length}>
                <div className={"flex justify-center items-center"}>
                  {loading ? (
                    <>
                      <div
                        className="flex justify-center items-center h-full px-[20px] py-[10px]"
                        style={{ position: "relative" }}
                      >
                        <SpinnerIcon />
                      </div>
                    </>
                  ) : (
                    <p className={"m-6"}>{textContentEmpty}</p>
                  )}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AdminTableContainer;
