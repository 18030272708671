import React from "react";

const LinkIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.37851 7.19075L2.14505 9.4242C1.31092 10.2583 0.831241 11.3933 0.840007 12.5861C0.848773 13.7789 1.31796 14.9208 2.19167 15.7675C3.03836 16.6413 4.18048 17.1104 5.3731 17.1192C6.59293 17.1282 7.70103 16.6755 8.53521 15.8414L10.7687 13.6079M13.6215 10.8097L15.8549 8.57621C16.6891 7.74208 17.1688 6.60711 17.16 5.4143C17.1512 4.22149 16.682 3.0796 15.8083 2.23287C14.9618 1.38638 13.8199 0.917172 12.6271 0.908406C11.4343 0.899641 10.2992 1.35209 9.46498 2.18625L7.23153 4.4197M5.6131 12.3274L12.3135 5.62701"
        stroke="#3583FF"
        strokeWidth="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default LinkIcon;
