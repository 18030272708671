export interface ApiException {
  message: string;
  status: number;
}

export interface Links {
  totalPage: number;
  currPage: number;
  limit: number;
  total: number;
}

export interface LinksOfGlobalSearch {
  totalPage: number;
  currPage: number;
  module_name: string;
  limit: number;
  total: number;
}

export enum ResponseStatusType {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export type Meta<M = unknown, L = unknown> = {
  params: object;
  data: M;
  prompt: {
    id: number;
    labels: string[];
    meta: object;
  };
  links: L;
  taskId: string;
  type: ResponseStatusType;
};

export interface MetaData<T = unknown, M = unknown, L = Links> {
  meta?: Meta<M, L>;
  data: T;
}
