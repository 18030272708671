import Keycloak from "keycloak-js";

const keycloakConfig = {
  url: process.env.REACT_APP_KEYCLOACK_URL ?? "",
  realm: "Staging",
  clientId: process.env.REACT_APP_CLIENT_ID ?? "",
};

const keycloak = new Keycloak(keycloakConfig);

const login = async () => {
  const redirectUri =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000/sign-in"
      : process.env.REACT_APP_KEYCLOACK_REDIRECT_URL;
  try {
    if (keycloak.didInitialize) {
      await keycloak.login({ prompt: "login", redirectUri });
    } else {
      await keycloak.init({
        checkLoginIframe: false,
        onLoad: "login-required",
        pkceMethod: false,
        redirectUri,
      });
    }
  } catch (error) {
    console.error("Failed to initialize adapter:", error);
  }
};

const logout = async () => {
  const redirectUri =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000/sign-in"
      : process.env.REACT_APP_KEYCLOACK_REDIRECT_URL;
  try {
    if (keycloak.didInitialize) {
      await keycloak?.logout({ redirectUri });
    } else {
      await keycloak.init({ checkLoginIframe: false });
      await keycloak?.logout({ redirectUri });
    }
  } catch (error) {
    console.error("Logout failed:", error);
  }
};

export { keycloak, login, logout };
