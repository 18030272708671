import { createSlice } from "@reduxjs/toolkit";
import { User, UserMeta } from "data/api/profile/type";
import { getUserAction, updateUserAction } from "./actions";
import { MetaData } from "data/api/exception";
import { StateMeta } from "store/interfaces";

interface State {
  data: MetaData<User, UserMeta> | null;
  meta: StateMeta;
  updateMeta: StateMeta;
}

const initialState: State = {
  data: null,
  meta: { loading: true, error: null },
  updateMeta: { loading: false, error: null },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers(builder) {
    builder.addCase(getUserAction.pending, (state) => {
      state.meta.loading = true;
      state.meta.error = null;
    });
    builder.addCase(getUserAction.fulfilled, (state, { payload }) => {
      state.meta.loading = false;
      state.data = payload;
    });
    builder.addCase(getUserAction.rejected, (state, { payload }) => {
      state.meta.loading = false;
      state.meta.error = payload?.message ?? "что-то пошло не так";
    });

    builder.addCase(updateUserAction.pending, (state) => {
      state.updateMeta.loading = true;
      state.updateMeta.error = null;
    });
    builder.addCase(updateUserAction.fulfilled, (state, { payload }) => {
      state.updateMeta.loading = false;
      if (state.data) {
        state.data.data = payload.data;
      }
    });
    builder.addCase(updateUserAction.rejected, (state, { payload }) => {
      state.updateMeta.loading = false;
      state.updateMeta.error = payload?.message ?? "что-то пошло не так";
    });
  },
  reducers: {
    decreaseBookingCount: (state) => {
      if (state.data?.meta) {
        state.data.meta.data.remaining_user_creation_limit--;
      }
    },
    logOutAction: (state) => {
      state.data = null;
    },
  },
});

export const { decreaseBookingCount, logOutAction } = userSlice.actions;

export default userSlice.reducer;
