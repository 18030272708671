import React from "react";

const ModalIcon = ({ color = "#EF3B24" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 1H18C18.2652 1 18.5196 1.10536 18.7071 1.29289C18.8946 1.48043 19 1.73478 19 2V3.586C18.9999 3.85119 18.8946 4.10551 18.707 4.293L12.292 10.707C12.1048 10.8947 11.9998 11.1489 12 11.414V17.719C12 17.871 11.9653 18.021 11.8987 18.1576C11.832 18.2942 11.735 18.4138 11.6152 18.5073C11.4954 18.6008 11.3558 18.6658 11.2071 18.6973C11.0584 18.7288 10.9044 18.7259 10.757 18.689L8.757 18.189C8.54075 18.1348 8.34881 18.01 8.21166 17.8342C8.0745 17.6585 8.00001 17.4419 8 17.219V11.414C7.99994 11.1488 7.89455 10.8945 7.707 10.707L1.292 4.293C1.10482 4.10534 0.999792 3.85105 1 3.586V2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ModalIcon;
