import React, { useEffect, useState } from "react";
import CalculatorFilter from "./CalculatorFilter";
import { calculateBankItemData } from "../../store/CalculatorReducer/type";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useGetCalculateListMutation } from "../../data/api/calculate";
import { CalculateIpotekaData } from "../../data/api/calculate/type";
import ArrowDropdown from "../../components/constant/icons/ArrowDropdown";
import { useFormatForDropDownOptions } from "../../hooks/useDropDownOption";
import useGetProjectByCityId from "../../hooks/commonApiCalls/useGetProjectByCityId";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import AnimationFrame from "components/AnimationFrame.tsx";

const MortgageCalculator = () => {
  const { isMd } = useWindowSize();

  const [mutate] = useGetCalculateListMutation();

  const selection = useSelector((state: RootState) => state.header);

  const { projects } = useGetProjectByCityId({
    cityId: selection.city_id,
  });

  const optionsProject = useFormatForDropDownOptions(projects ?? []);
  const [optionsCurrency, setOptionsCurrency] = useState([
    {
      value: "rub",
      label: "Рубль",
    },
  ]);

  const [minInitial, setMinInitial] = useState(0);
  const [maxInitial, setMaxInitial] = useState(2000000);
  const [minMonth, setMinMonth] = useState(1);
  const [maxMonth, setMaxMonth] = useState(240);

  const [bankList, setBankList] = useState<CalculateIpotekaData[]>([]);
  const [filteredBankList, setFilteredBankList] = useState<
    CalculateIpotekaData[]
  >([]);

  const [calculate, setCalculate] = useState<calculateBankItemData | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (bankList) {
      setFilteredBankList(
        [...bankList].map((item) => ({
          ...item,
          isCollapse: false,
        }))
      );
    }
  }, [bankList]);

  useEffect(() => {
    if (calculate) {
      loadList();
    }
  }, [calculate]);

  async function loadList() {
    if (!calculate) return;
    setIsLoading(true);

    const requestObj = {
      ...calculate,
      project: undefined,
    };

    const { data: res } = await mutate(requestObj);

    if (res) {
      setIsLoading(false);
      res?.data && setBankList(res?.data);
    }
  }

  function handleOpenCollapse(index: number) {
    const listUpdate = [...filteredBankList].map((i, idx) => {
      if (idx === index) {
        return {
          ...i,
          isCollapse: !i.isCollapse,
        };
      }

      return i;
    });

    setFilteredBankList(listUpdate);
  }

  return (
    <div className={"bg-bg-default"}>
      <AnimationFrame>
        <div
          className={`${
            !isMd ? "br-container " : ""
          } lg:pt-[20px] md:pb-[90px] pb-[30px]`}
        >
          <div className={"flex flex-wrap lg:flex-nowrap lg:gap-[10px]"}>
            <CalculatorFilter
              filterData={{
                optionsProject,
                optionsCurrency,
                minInitial,
                maxInitial,
                minMonth,
                maxMonth,
                calculate,
              }}
              setFiltered={(data) => {
                setCalculate({
                  ...data,
                  // price_all: data.premise_price * (data.percent / 100) + data.premise_price,
                  // price_month: data.ipoteka_time.max,
                });
              }}
              isLoading={isLoading}
            />

            <div
              className={
                "lg:max-w-[62%] w-full gap-[10px] flex flex-col lg:p-0 p-[15px]"
              }
            >
              {!isLoading && filteredBankList.length ? (
                filteredBankList.map((item, idx) => (
                  <div
                    key={`filter-bank-item-${idx}`}
                    className={
                      "bg-white-default rounded-md p-[20px] cursor-pointer"
                    }
                    onClick={() => handleOpenCollapse(idx)}
                  >
                    <div className={"flex items-center justify-between whitespace-nowrap"}>
                      <p className={"font-medium text-accent-default"}>
                        {item.name}
                      </p>

                      <div className={"flex md:gap-[67px]"}>
                        {(item.program_name && !isMd) && (
                          <p
                            className={
                              "flex items-center gap-[5px] text-sm font-medium text-accent-default text-right"
                            }
                          >
                            {item.program_name}

                            <span
                              className={`transition ${
                                item.isCollapse ? "rotate-180" : ""
                              }`}
                            >
                              <ArrowDropdown
                                color={item.isCollapse ? "#EF3B24" : "#37465B"}
                              />
                            </span>
                          </p>
                        )}

                        <p
                          className={
                            "flex items-center text-sm font-medium text-accent-default"
                          }
                        >
                          {item.monthly_installment} руб/мес
                        </p>
                      </div>
                    </div>

										{(item.program_name && isMd) && (
											<>
												<hr className={"border-[#D7DCE4] my-[10px]"}></hr>

												<p
													className={
														"flex items-center justify-between gap-[5px] text-sm font-medium text-accent-default text-right w-full"
													}
												>
													{item.program_name}

													<span
														className={`transition ${
															item.isCollapse ? "rotate-180" : ""
														}`}
													>
                              <ArrowDropdown
																color={item.isCollapse ? "#EF3B24" : "#37465B"}
															/>
                            </span>
												</p>
											</>
										)}

										{item.isCollapse && (
											<div
												className={
													"md:border-t border-[#D7DCE4] md:mt-[15px] md:pt-[15px] mt-[10px] gap-[5px]"
												}
											>
												{!isMd && (
													<p
														className={"text-sm font-medium text-accent-default"}
													>
														{item.program_name}
													</p>
												)}

												<p
													className={
														"text-sm font-medium text-accent-default opacity-75"
													}
                        >
                          {item.program_description}
                        </p>
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div className={"w-full flex justify-center p-[20px]"}>
                  Данные не найдены
                </div>
              )}
            </div>
          </div>
        </div>
      </AnimationFrame>
    </div>
  );
};

export default MortgageCalculator;
