import { apiService } from "..";
import { MetaData } from "../exception";
import { TasksType, TasksFormSubmit, TaskgetOneType } from "./type";

export const addTagTypes = ["tasks"] as const;

export const baseTasksApiHooks = apiService
  .enhanceEndpoints({ addTagTypes })
  .injectEndpoints({
    endpoints: (build) => ({
      createTasks: build.mutation<MetaData, TasksFormSubmit>({
        query: (data) => ({
          path: `/tasks`,
          body: { meta: {}, data: data },
          method: "POST",
        }),
        invalidatesTags: ["tasks"],
      }),
      getTasks: build.mutation<MetaData<TasksType[]>, string>({
        query: (query) => ({
          path: `/tasks?${query}`,
          method: "GET",
        }),
      }),
      getOneTask: build.mutation<MetaData<TaskgetOneType>, number>({
        query: (id) => ({
          path: `/tasks/${id}`,
          method: "GET",
        }),
      }),
      finishTask: build.mutation<
        MetaData<TaskgetOneType>,
        { comment: string; id: number }
      >({
        query: (data) => ({
          path: `/tasks/finish/${data.id}`,
          body: { meta: {}, data: { comment: data.comment } },
          method: "PUT",
        }),
      }),
    }),
  });

export const {
  useCreateTasksMutation,
  useGetTasksMutation,
  useGetOneTaskMutation,
  useFinishTaskMutation,
} = baseTasksApiHooks;
