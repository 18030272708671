import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../index";

export interface SelectionState {
  isEdit: boolean;
}
const initialState: SelectionState = {
  isEdit: false,
};

const homePageSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    handleEditModeInHomePage: (state, action: PayloadAction<boolean>) => {
      state.isEdit = action.payload;
    },
  },
});

export const { handleEditModeInHomePage } = homePageSlice.actions;

export default homePageSlice.reducer;
