import React from "react";
import { images } from "utils/resource";

interface Props {
  title?: string;
}

const NotFound = ({ title = "Ничего не найдено" }: Props) => {
  return (
    <div className=" text-center text-accent-default min-h-[30vh] opacity-60  flex flex-col items-center justify-center gap-3">
      <img src={images.notFound} alt="" className="w-[100px] h-[100px]" />
      {title}
    </div>
  );
};

export default NotFound;
