import React from "react";

const DealsWonIcon = ({ color = "#3583FF" }) => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.30156 20.6H3.50155C2.17607 20.6 1.10155 19.5254 1.10156 18.2L1.10166 3.80001C1.10166 2.47453 2.17618 1.40002 3.50166 1.40002H14.3019C15.6274 1.40002 16.7019 2.47454 16.7019 3.80002V10.4M11.9019 17.2L14.1019 19.4L18.9019 14.5998M5.30194 6.20002H12.5019M5.30194 9.80002H12.5019M5.30194 13.4H8.90194"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DealsWonIcon;
