import React, { useEffect, useState } from "react";
import DatePickerField from "components/Form/DatePicker/DatePickerField";
import Dropdown from "components/Form/Dropdown/Dropdown";
import Label from "components/Form/Label";
import TextField from "components/Form/TextField/TextField";
import SearchIcon from "components/constant/icons/SearchIcon";
import { useGetCitiesQuery } from "data/api/city";
import { useFormatForDropDownOptions } from "hooks/useDropDownOption";
import { roleOptions, UserRole } from "enums/users";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import Modal from "components/Modal";
import { handleOpenAdminsFilter } from "store/AdminstrationReducer";
import CircleXIcon from "components/constant/icons/CircleXIcon";
import { useWindowSize } from "hooks/useWindowSize";
import ButtonForm from "components/Button/ButtonForm";
import CancelIcon from "components/constant/icons/CancelIcon";
import { iFilter } from ".";
import { withDebounce } from "hooks/useDebounce";
import { isOnlyPhoneNumber } from "hooks/useFormatInput";
import {
	getIsOpenSearch,
	handleFiltersSearch,
	handleFiltersSelect,
	handleOpenSearch
} from "../../store/BaseClientsReducer";

interface IProps {
  filter: iFilter;
  handleChangeFields: (
    fullname?: string,
    role?: UserRole | null,
    city_id?: number | null,
    registered_at?: string
  ) => void;
}

const AdminisFilter = ({ handleChangeFields, filter }: IProps) => {
  const [searchInput, setSearchInput] = useState<string>("");

  const [debounceSearch, setDebounceSearch] = useState("");

  const [city_id, setCity_id] = useState<number | null>();

  const [role, setRoles] = useState<UserRole | null>();

  const [registeredAt, setRegisteredAt] = useState<string>();
  const [registeredEnd, setRegisteredEnd] = useState<string>();

  const { data: cities } = useGetCitiesQuery();

  const dispatch = useDispatch();

  const windowSize = useWindowSize();

  const cityOptions = useFormatForDropDownOptions(cities?.data ?? []);

  useEffect(() => {
    handleChangeFields(
      isOnlyPhoneNumber(searchInput),
      role,
      city_id,
      registeredAt
    );
  }, [searchInput, city_id, role, registeredAt]);

	useEffect(() => {
		return () => {
			dispatch(handleFiltersSelect(false));
		}
	}, []);

  const handleClearFilter = () => {
		dispatch(handleFiltersSelect(false));
    setSearchInput("");
    setCity_id(null);
    setRoles(null);
    setRegisteredAt("");
  };

  const handleSearch = (value: string) => {
    const filteredValue = value.replace(/[^a-zA-Z-а-я-А-Я-0-9\s()+]/g, "");
    setDebounceSearch(value);
    withDebounce(() => setSearchInput(filteredValue));
  };

  const getValue = (
    opts: { label: string; value: string | number | null }[],
    val: number | string | undefined
  ) => {
    if (val) {
      return opts.filter((o) => o.value == val);
    }
    return null;
  };

  const getFormDate = (date: Date): string | null => {
    if (!date) return null;

    let utcTime = date.getTime() - date.getTimezoneOffset() * 60000;

    let targetDate = new Date();

    targetDate.setTime(utcTime);

    return targetDate.toISOString();
  };

  return (
    <AdminstrationFilterMobile>
      <div className="flex flex-col lg:flex-row gap-[15px] items-center mt-6">
				{windowSize.isLg ? (
					<TextField
						value={debounceSearch}
						label="Поиск"
						onChangeValue={handleSearch}
						placeholder="ФИО/Телефон/Агенство"
						className="w-full lg:flex-1"
						imgLeft={<SearchIcon />}
						imgRight={
							debounceSearch.length ? (
								<div
									onClick={() => {
										setDebounceSearch("");
										setSearchInput("");
									}}
									className="w-[20px] h-full flex items-center justify-center bg-white-default"
								>
									<CancelIcon color="#FF0000" />
								</div>
							) : null
						}
					/>
				) : null}
        <Label label="Город" className="w-full lg:flex-1">
          <Dropdown
            name="city"
            options={cityOptions}
            onChange={(value) => setCity_id(value?.value)}
            value={getValue(cityOptions, city_id as number)}
            placeholder="Город"
            isClearable={true}
          />
        </Label>
        <Label label="Роль" className="w-full lg:flex-1">
          <Dropdown
            name="role"
            options={roleOptions}
            onChange={(value) => setRoles(value?.value)}
            placeholder="Роль"
            isClearable={true}
          />
        </Label>
        <Label label="Регистрация" className="w-full lg:flex-1">
          <div className={"flex gap-3"}>
						<DatePickerField
							className={`w-full icons-show ${!windowSize.isMd && "calendar-block"}`}
							placeholder={"От"}
							isIcon
							onChangeDate={(value) =>
								setRegisteredAt((value && getFormDate(value)) || "")
							}
							isClearable={true}
						/>
						<DatePickerField
							className={`w-full icons-show ${!windowSize.isMd && "calendar-block"}`}
							placeholder={"До"}
							isIcon
							onChangeDate={(value) =>
								setRegisteredEnd((value && getFormDate(value)) || "")
							}
							isClearable={true}
						/>
					</div>
        </Label>
        {!windowSize.isLg ? (
          <div className="w-full flex flex-col gap-3 mt-4">
            <ButtonForm
              text="Применить"
              onClick={() => {
								dispatch(handleFiltersSelect(!!debounceSearch || !!city_id || !!role || !!registeredAt));
								dispatch(handleOpenAdminsFilter(false));
              }}
              className={
                "max-h-[48px] px-6 w-full justify-center bg-accent-default text-white-default text-ellipsis whitespace-nowrap p-3"
              }
            />
            <ButtonForm
              text="Сбросить"
              onClick={handleClearFilter}
              className={
                "max-h-[48px] px-6 w-full justify-center bg-bg-default text-accent-default text-ellipsis whitespace-nowrap p-3"
              }
            />
          </div>
        ) : null}
      </div>
    </AdminstrationFilterMobile>
  );
};

export const AdminSearch = ({ handleChangeFields, filter }: IProps) => {
	const storeIsOpenSearch = useSelector(getIsOpenSearch);

  const [searchInput, setSearchInput] = useState<string>("");

  const [debounceSearch, setDebounceSearch] = useState("");

  const dispatch = useDispatch();

	useEffect(() => {
		return () => {
			dispatch(handleFiltersSearch(false));
		}
	}, []);

  useEffect(() => {
    handleChangeFields(
      isOnlyPhoneNumber(searchInput),
    );
  }, [searchInput]);

  const handleSearch = (value: string) => {
    const filteredValue = value.replace(/[^a-zA-Z-а-я-А-Я-0-9\s()+]/g, "");
    setDebounceSearch(value);
		dispatch(handleFiltersSearch(!!value));
    withDebounce(() => setSearchInput(filteredValue));
  };

  return (
		<Modal
			className=" w-full md:w-[500px]  rounded-t-[30px] md:rounded-[30px] "
			isOpen={storeIsOpenSearch}
			onClose={() => {
				dispatch(handleOpenSearch(false));
			}}
		>
			<div className="max-h-[80vh] overflow-auto toolbar-scroll ">
				<div className="flex flex-col lg:flex-row gap-[15px] items-center mt-6">
					<TextField
						value={debounceSearch}
						label="Поиск"
						onChangeValue={handleSearch}
						placeholder="ФИО/Телефон/Агенство"
						className="w-full lg:flex-1"
						imgLeft={<SearchIcon />}
						imgRight={
							debounceSearch.length ? (
								<div
									onClick={() => {
										setDebounceSearch("");
										setSearchInput("");
									}}
									className="w-[20px] h-full flex items-center justify-center bg-white-default"
								>
									<CancelIcon color="#FF0000" />
								</div>
							) : null
						}
					/>
				</div>
			</div>
		</Modal>
  );
};

export default AdminisFilter;

interface Props {
  children: React.ReactNode;
}

export const AdminstrationFilterMobile = ({ children }: Props) => {
  const windowSize = useWindowSize();
  const selection = useSelector(
    (state: RootState) => state.adminstration.isOpenFilterModal
  );
  const dispatch = useDispatch();

  const handleOpenModal = () => {
    dispatch(handleOpenAdminsFilter(false));
  };

	useEffect(() => {
		return () => {
			dispatch(handleFiltersSelect(false));
		}
	}, []);

  if (windowSize.isLg) {
    return <>{children}</>;
  } else {
    return (
      <Modal
        className=" w-full md:w-[500px]  rounded-t-[30px] md:rounded-[30px] "
        isOpen={selection}
				onClose={handleOpenModal}
      >
        <div className="text-accent-default text-center relative ">
          <h1 className="text-[25px] font-dewi font-extrabold leading-[30px]">Фильтр</h1>
        </div>
        <div className="max-h-[80vh] overflow-auto toolbar-scroll ">
          {children}
        </div>
      </Modal>
    );
  }
};
