import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import Dropdown from "../../../../components/Form/Dropdown/Dropdown";
import TextField from "../../../../components/Form/TextField/TextField";
import PhoneIcon from "../../../../components/constant/icons/PhoneIcon";
import FileInput from "../../../../components/Form/FileInput";
import CheckBox from "../../../../components/Form/CheckBox";
import ButtonForm from "../../../../components/Button/ButtonForm";
import DownloadIcon from "../../../../components/constant/icons/DownloadIcon";
import { RegisterFormState, userAgreementDataType } from "../../types";

import Label from "components/Form/Label";
import { getCityByIp, useGetCitiesQuery } from "data/api/city";
import { ApiException } from "data/api/exception";
import { handleDownload, useRegisterAgencyMutation } from "data/api/auth";
import Loader from "components/Loader";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFormatForDropDownOptions } from "hooks/useDropDownOption";
import { newAgentRegisterForm } from "utils/schemas";
import { Step } from "enums/authEnums";
import MaskComponent from "components/MaskInput/MaskComponent";
import { selectUser, selectUserError } from "store/UserReducer/selectors";
import { useDispatch, useSelector } from "react-redux";
import { getUserAction } from "store/UserReducer/actions";

interface propsType {
  title?: React.ReactNode;
  setFormState: React.Dispatch<React.SetStateAction<RegisterFormState>>;
}

const NewAgencyAgreementForm = ({ title, setFormState }: propsType) => {
  const res = useGetCitiesQuery();

  const [mutate, { isLoading }] = useRegisterAgencyMutation();

  const options = useFormatForDropDownOptions(res?.data?.data ?? []);

  const user = useSelector(selectUser);

  // const isLoading = useSelector(selectUserIsLoading);

  const [checkBox, setCheckBox] = useState(false);

  const error = useSelector(selectUserError);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    setError,
    getValues,
  } = useForm<userAgreementDataType>({
    resolver: yupResolver<userAgreementDataType | any>(newAgentRegisterForm),
    defaultValues: { isOwner: false },
  });

  const onSubmit: SubmitHandler<userAgreementDataType> = async (data) => {
    const user_id = Number(localStorage.getItem("userId"));
    try {
      const { data: res } = await mutate({
        data: {
          ...data,
          user_id,
          phone: data.phone,
          ownerPhone: data.ownerPhone,
          city_id: data.city_id?.value,
        },
      });
      if (res?.data?.accessToken) {
        localStorage.setItem("token", res.data.accessToken);
        setFormState({ step: Step.SUCCESSFUL_REGISTRATION });
        // dispatch(getUserAction());
        localStorage.removeItem("register_status");
        localStorage.removeItem("registerType");
      }
    } catch (err) {
      const error = err as ApiException;
      console.log("err", error);
    }
  };

  useEffect(() => {
    async function initCity() {
      const city = await getCityByIp();
      if (city.location) {
        const matchedOption = options.find((el) =>
          city.location?.value.toLowerCase().includes(el.label.toLowerCase()),
        );

        if (matchedOption) {
          console.log(matchedOption);
          // @ts-ignore
          setValue("city_id", matchedOption);
        } else {
          const defOption = options.find((el) => el.label === "Eкатеринбург");

          if (defOption) {
            // @ts-ignore
            setValue("city_id", defOption);
          }
        }
      } else {
        const defOption = options.find((el) => el.label === "Eкатеринбург");

        if (defOption) {
          // @ts-ignore
          setValue("city_id", defOption);
        }
      }
    }

    if (options.length > 0) {
      initCity();
    }
  }, [options, setValue]);

  const downloadContractFile = async () => {
    const CONTRACT_OFFER =
      "https://cdn.brusnika.ru/media/core/agent_page/contract_offer/%D0%94%D0%BE%D0%B3%D0%BE%D0%B2%D0%BE%D1%80_%D0%BF%D1%80%D0%B8%D1%81%D0%BE%D0%B5%D0%B4%D0%B8%D0%BD%D0%B5%D0%BD%D0%B8%D1%8F_%D0%95%D0%9A%D0%91_01_10.pdf?_gl=1*f3w0q5*_gcl_au*MTk0NjU5NjE1NS4xNzE5ODE2OTk2*_ga*NDEyODc3NjExLjE3MTk4MTY5OTY.*_ga_RM6GT3MWMR*MTcyMzE4Nzg5Ny44LjEuMTcyMzE4NzkwOS40OC4wLjE1NjQ3Nzg1MDA";

    await handleDownload(CONTRACT_OFFER, "contract_offer.pdf");
  };

  const downloadRulesFile = async () => {
    const RULES =
      "https://cdn.brusnika.ru/media/core/agent_page/rules_document/%D0%9F%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0_%D0%91%D1%80%D1%83%D1%81%D0%BD%D0%B8%D0%BA%D0%B0.%D0%90%D0%B3%D0%B5%D0%BD%D1%82_2_Dq0kIbx.pdf?_gl=1*1iieyo2*_gcl_au*MTk0NjU5NjE1NS4xNzE5ODE2OTk2*_ga*NDEyODc3NjExLjE3MTk4MTY5OTY.*_ga_RM6GT3MWMR*MTcyMzE4Nzg5Ny44LjEuMTcyMzE4NzkwOS40OC4wLjE1NjQ3Nzg1MDA";
    await handleDownload(RULES, "rules_document.pdf");
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-[17px]"
    >
      <h3 className="text-[25px] text-accent-default font-dewi font-extrabold leading-[30px] mb-[20px]">
        {title}
      </h3>
      {isLoading && <Loader />}

      <Label label="Основной город работы агентства">
        <Controller
          name="city_id"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Dropdown
              name="city_id"
              options={options}
              errors={errors}
              value={value}
              onChange={onChange}
              placeholder="Город не выбран"
            />
          )}
        />
      </Label>
      <Controller
        name="legalName"
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            value={value}
            className=""
            placeholder="Введите название компании"
            label="Юридическое название компании"
            type="text"
            name="legalName"
            onChange={onChange}
            errors={errors}
          />
        )}
      />
      <Controller
        name="title"
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            value={value}
            className=""
            placeholder="Введите название агентства"
            label="Название агентства"
            type="text"
            name="title"
            onChange={onChange}
            errors={errors}
          />
        )}
      />
      <Controller
        name="inn"
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            value={value}
            className=""
            maxLength={12}
            placeholder="123 456 78 9010"
            label="ИНН агентства"
            type="text"
            name="inn"
            onChange={onChange}
            errors={errors}
          />
        )}
      />
      <Label label="Номер телефона агентства">
        <Controller
          name="phone"
          control={control}
          render={({ field: { onChange, value } }) => (
            <MaskComponent
              name="phone"
              errors={errors}
              imgLeft={<PhoneIcon />}
              value={getValues("phone")}
              onChange={(value) => onChange(value)}
            />
          )}
        />
      </Label>
      <Controller
        name="email"
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            value={value}
            className=""
            placeholder="Введите адрес почты агентства"
            label="Почта агентства"
            type="email"
            name="email"
            onChange={onChange}
            errors={errors}
          />
        )}
      />
      {/* <Controller
        name="ownerFullName"
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            value={value}
            className=""
            placeholder="имя"
            label="Полное имя владельца"
            type="text"
            name="ownerFullName"
            onChange={onChange}
            errors={errors}
          />
        )}
      /> */}
      {/* <Label label="Номер телефона агентства">
        <Controller
          name="ownerPhone"
          control={control}
          render={({ field: { onChange } }) => (
            <MaskComponent
              errors={errors}
              name="ownerPhone"
              imgLeft={<PhoneIcon />}
              value={getValues("ownerPhone")}
              onChange={(value) => onChange(value)}
            />
          )}
        />
      </Label> */}
      <Controller
        name="entry_doc"
        control={control}
        render={({ field: { value } }) => (
          <FileInput
            value={value}
            label="Свидетельство о внесении записи в ОГРН/ОГРНИП"
            name="entry_doc"
            setValue={setValue}
            errors={errors}
            setError={setError}
          />
        )}
      />
      <Controller
        name="company_card_doc"
        control={control}
        render={({ field: { value } }) => (
          <FileInput
            value={value}
            label="Карточка предприятия в редактируемом формате"
            name="company_card_doc"
            setValue={setValue}
            errors={errors}
            setError={setError}
          />
        )}
      />
      <Controller
        name="tax_registration_doc"
        control={control}
        render={({ field: { value } }) => (
          <FileInput
            value={value}
            label="Свидетельство о постановке на налоговой учет ИНН/КПП"
            name="tax_registration_doc"
            setValue={setValue}
            errors={errors}
            setError={setError}
          />
        )}
      />

      <Controller
        name="authority_signatory_doc"
        control={control}
        render={({ field: { value } }) => (
          <FileInput
            value={value}
            label="Документ, удостоверяющий полномочия подписанта"
            subLabel="Для ИП - паспорт"
            name="authority_signatory_doc"
            setValue={setValue}
            errors={errors}
            setError={setError}
          />
        )}
      />
      <div className="flex">
        <CheckBox
          link={
            <>
              Согласие на
              <a
                target="_blank"
                className=" text-blue-default underline ml-1"
                href="https://ekaterinburg.brusnika.ru/personal_data"
              >
                обработку персональных данных
              </a>
            </>
          }
          label=""
          onChange={setCheckBox}
          value={checkBox}
          // name="isOwner"
        />
      </div>

      <Controller
        name="isOwner"
        control={control}
        rules={{ required: false }}
        render={({ field: { onChange, value } }) => (
          <CheckBox
            label="Я руководитель агентства"
            onChange={onChange}
            value={value}
            name="isOwner"
          />
        )}
      />
      <div className="flex flex-col gap-[8px] mt-[10px]">
        <ButtonForm
          text="Скачать “Правила работы”"
          leftIcon={<DownloadIcon />}
          onClick={downloadRulesFile}
          className="bg-white-default border-[1px] border-accent-default py-[12px] w-full
          text-accent-default text-center flex justify-center hover:bg-bg-focus"
        />
        <ButtonForm
          text="Скачать “Договор-оферта”"
          onClick={downloadContractFile}
          leftIcon={<DownloadIcon />}
          className="bg-white-default border-[1px] border-accent-default py-[12px] w-full
          text-accent-default text-center flex justify-center hover:bg-bg-focus"
        />
      </div>
      <div className="flex flex-col gap-[10px] mt-[10px]">
        <ButtonForm
          disabled={!checkBox}
          className="bg-accent-default py-[12px] w-full
             text-white-default text-center flex justify-center hover:bg-accent-focus "
          text="Далее"
          type="submit"
        />
        <ButtonForm
          className=" bg-bg-default py-[12px] w-full
             text-accent-default text-center flex justify-center hover:bg-accent-default hover:text-white-default"
          text="Назад"
          onClick={() => setFormState({ step: Step.SELECT_REGISTER_TYPE })}
        />
      </div>
    </form>
  );
};

export default NewAgencyAgreementForm;
