import React, { FC, ReactNode, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { ru } from "date-fns/locale/ru";

import "react-datepicker/dist/react-datepicker.css";
import Calendar from "../../constant/icons/Calendar";
import { dataPickerType } from "../../../modules/Auth/types";
import { FieldErrors } from "react-hook-form";
import CancelIcon from "components/constant/icons/CancelIcon";

type DateInterface = Date | null;

interface DatePickerProps {
  startDate?: DateInterface;
  onChangeDate: (date: Date | null) => void;
  disabled?: boolean;
  className?: string;
  minTime?: any;
  maxTime?: any;
  placeholder?: string;
  isRightIcon?: boolean;
  isIcon?: ReactNode | boolean;
  name?: string;
  errors?: FieldErrors<dataPickerType>;
  timeInterVal?: number;
}

const DatePickerTimeField: FC<DatePickerProps> = ({
  startDate,
  onChangeDate,
  disabled,
  className = "",
  minTime,
  maxTime,
  placeholder,
  isRightIcon,
  isIcon = false,
  name,
  errors,
  timeInterVal = 30,
}) => {
  const dateStart = (date: any): DateInterface => {
    if (typeof date === "string") return new Date(date);

    return startDate || null;
  };

  const [startDateLocal, setStartDate] = useState<DateInterface>(
    dateStart(startDate)
  );

  const onDateRange = (value: Date | null) => {
    onChangeDate(value || new Date());
  };

  const handleClear = () => {
    onChangeDate(null);
    setStartDate(null);
  };

  useEffect(() => {
    if (dateStart(startDate) !== startDateLocal)
      setStartDate(dateStart(startDate));
  }, [startDate]);

  let handleDateChangeRaw = (e: { preventDefault: () => void }) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (startDate) setStartDate(dateStart(startDate));
  }, [startDate]);

  return (
    <>
      <div className={`relative calendar-time-block ${className}`}>
        {isIcon && (
          <div
            className={`absolute bottom-0 h-full flex items-center justify-center w-[60px] z-10 ${
              isRightIcon ? "right-0" : "left-0"
            }`}
          >
            {isIcon === true ? <Calendar color={"#3582F6"} /> : isIcon}
          </div>
        )}

        <div className="w-full relative">
          <DatePicker
            locale={ru}
            selected={startDateLocal}
            onChange={(date) => {
              onDateRange(date);

              setStartDate(date);
            }}
            placeholderText={placeholder}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={timeInterVal}
            timeCaption="Время"
            timeFormat="HH:mm"
            dateFormat="HH:mm"
            disabled={disabled}
            onChangeRaw={handleDateChangeRaw}
            minTime={minTime || new Date(0, 0, 0, 0, 0)}
            maxTime={maxTime || new Date(0, 0, 0, 23, 59)}
            className={`
					py-[12px] 
					px-[12px] 
					placeholder:text-accent-disabled 
					w-full
					border-[1px] 
					border-boder-default 
					outline-none 
					text-[14px] 
					rounded-[4px]
					${isIcon ? (isRightIcon ? "pr-[55px]" : "pl-[55px]") : ""}
					`}
          />
          {startDateLocal && (
            <button
              onClick={handleClear}
              className="absolute right-3 cursor-pointer top-[50%] -translate-y-[50%]"
            >
              <CancelIcon />
            </button>
          )}
        </div>
      </div>
      {errors?.[name as keyof dataPickerType] && (
        <div className="text-[12px] leading-[14.06px] flex flex-col">
          <p className="text-red-default mb-[5px]">
            {errors?.[name as keyof dataPickerType]?.message}
          </p>
        </div>
      )}
    </>
  );
};

export default DatePickerTimeField;
