import React, { useMemo, useState } from "react";

import CheckBox from "components/Form/CheckBox";
import Dropdown from "components/Form/Dropdown/Dropdown";
import { SearchField } from "components/Form/SearchField";
import MyApplicationCategory from "./MyApplicationList";
import { useGetLeadsQuery } from "data/api/lead";
import { useGetProjectsQuery } from "data/api/project";
import { useFormatForDropDownOptions } from "hooks/useDropDownOption";
import BackIcon from "components/constant/icons/BackIcon";
import { objectToQueryString } from "utils/objectToQuery";
import {
  getIsOpenFilters,
  handleOpenFilters,
} from "store/BaseClientsReducer";
import { useDispatch, useSelector } from "react-redux";
import Modal from "components/Modal";
import ButtonForm from "components/Button/ButtonForm";
import {
  Option,
  categorizeDeals,
  dealsOptions,
  sortAndFilterArray,
} from "./dealUtils";
import Loader from "components/Loader";
import { RootState } from "store";
import { DealStatus } from "enums/deals";
import { primisesTypeData } from "modules/Apartment/data";

const MyApplication = () => {
  const selection = useSelector((state: RootState) => state.header);

  const [checked, setChecket] = useState(false);
  const [mobileChecked, setMobileChecked] = useState(checked);
  const [isASC, setIsASC] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { data: projects } = useGetProjectsQuery(selection.city_id);
  const [filter, setFilter] = useState({
    project_id: "",
    premise_type: "",
    status: "",
    client_id: 0,
    created_at: "ASC",
  });
  const [mobileFilter, setMobileFilter] = useState(filter);

  const options = useFormatForDropDownOptions(projects?.data ?? []);
  const { data, isLoading } = useGetLeadsQuery(objectToQueryString(filter)); // TODO: place with a new API on request

  const storeIsOpenFilters = useSelector(getIsOpenFilters);
  const dispatch = useDispatch();

  const handleOrderData = () => {
    setIsASC(!isASC);
    setFilter((prev) => ({ ...prev, created_at: !isASC ? "ASC" : "DESC" }));
  };

  const getValue = useMemo(
    () =>
      (opts: Option[], val: string | undefined): Option | Option[] => {
        if (val) {
          return opts.filter((o) => o.value === val);
        }
        return opts?.[0];
      },
    []
  );
  const handleFilter = () => {
    setFilter(mobileFilter);
    setChecket(mobileChecked);
    dispatch(handleOpenFilters(false));
  };

  const handleMenuOpen = () => {
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  return (
		<div className="">
			<div className="flex  justify-between lg:mb-[30px] relative">
				<div
					className=" hidden
           lg:flex gap-[10px]"
				>
					<SearchField
						className="w-[250px] hidden lg:block xl:w-full ml-auto md:rounded-[4px!important]"
						onChange={(value) =>
							setFilter((prev) => ({
								...prev,
								client_id: value?.id,
							}))
						}
					/>
					<Dropdown
						onChange={(e) =>
							setFilter((prev) => ({...prev, project_id: e.value}))
						}
						options={[{value: "", label: "Все"}, , ...options]}
						name="type"
						placeholder="Проект"
						className=" w-[130px] xl:w-[226px]"
					/>
					<Dropdown
						onChange={(e) =>
							setFilter((prev) => ({...prev, premise_type: e.value}))
						}
						options={[{value: "", label: "Все"}, , ...primisesTypeData]}
						name="type"
						placeholder="Помещение"
						className=" w-[130px] xl:w-[226px]"
					/>
					<Dropdown
						onChange={(e) =>
							setFilter((prev) => ({...prev, status: e.value}))
						}
						options={dealsOptions}
						name="type"
						placeholder="Этап сделки"
						className=" w-[130px] xl:w-[226px]"
					/>
					<div className="ml-[10px] flex items-center whitespace-nowrap">
						<CheckBox
							value={checked}
							onChange={() => setChecket(!checked)}
							label={"Только не активные"}
						/>
					</div>
					<div className="ml-[10px] flex items-center">
						<ButtonForm
							text="Заявка на обмен"
							onClick={handleFilter}
							className="whitespace-nowrap hover:bg-accent-disabled py-3 px-6 bg-accent-default xl:flex-1 flex justify-center text-white-default hover:text-bg-default w-full"
						/>
					</div>
				</div>
				<button
					className="w-[100px] border-none outline-none h-[34px] rounded-[4px] flex items-center justify-center gap-1 bg-white-default absolute right-0 -bottom-[35px] lg:-bottom-[63px] text-sm
          "
					onClick={handleOrderData}
				>
					По дате
					<div className={` ${isASC ? "-rotate-90" : "rotate-90"} `}>
						<BackIcon/>
					</div>
				</button>
			</div>
			{isLoading && <Loader/>}

			{data?.data.length
				? sortAndFilterArray(
					Object.keys(categorizeDeals(data?.data, checked))
				).map((status) => (
					<MyApplicationCategory
						key={status}
						status={status as DealStatus}
						data={
							categorizeDeals(data?.data, checked)[status as DealStatus]
						}
					/>
				))
				: !isLoading && (
				<h1 className="text-[22px] text-accent-default text-center mt-[100px]">
					Заявки не найдены
				</h1>
			)}
			<Modal
				isOpen={storeIsOpenFilters}
				className={`mx-auto w-full md:w-[450px] lg:w-[500px] rounded-t-[30px] rounded-b-0 sm:rounded-[20px] md:rounded-[20px] 
         ${isMenuOpen ? "h-[90vh]" : "h-auto"}`}
				onClose={() => dispatch(handleOpenFilters(false))}
			>
				<h2 className="text-[20px] font-dewi font-extrabold text-center mb-[30px]">
					Фильтр
				</h2>
				<div className="flex flex-col gap-[10px]">
					<SearchField
						className="w-[250px] hidden lg:block xl:w-full ml-auto md:rounded-[4px!important]"
						onChange={(value) =>
							setFilter((prev) => ({
								...prev,
								client_id: value?.id,
							}))
						}
					/>
					<Dropdown
						onChange={(e) =>
							setMobileFilter((prev) => ({...prev, project_id: e.value}))
						}
						options={[{value: "", label: "Все"}, , ...options]}
						name="type"
						placeholder="Проект"
						className="w-full"
						value={getValue(
							[{label: "Проект", value: ""}, ...options],
							mobileFilter.project_id
						)}
					/>
					<Dropdown
						onChange={(e) =>
							setMobileFilter((prev) => ({...prev, premise_type: e.value}))
						}
						options={[{value: "", label: "Все"}, , ...primisesTypeData]}
						name="type"
						placeholder="Помещение"
						className="w-full"
						value={getValue(
							[
								{value: "", label: "Помещение"},
								...[{value: "", label: "Все"}, ...primisesTypeData],
							],
							mobileFilter.premise_type
						)}
					/>
					<Dropdown
						onChange={(e) =>
							setMobileFilter((prev) => ({...prev, status: e.value}))
						}
						options={dealsOptions}
						name="type"
						placeholder="Этап сделки"
						className="w-full"
						value={getValue(
							[{value: "", label: "Этап сделки"}, ...dealsOptions],
							mobileFilter.status
						)}
						onMenuOpen={handleMenuOpen}
						onMenuClose={handleMenuClose}
					/>
					<div className="mt-[10px]">
						<CheckBox
							value={mobileChecked}
							onChange={() => setMobileChecked(!mobileChecked)}
							label={"Только не активные"}
						/>
					</div>
				</div>
				<div className="pt-[30px]">
					<ButtonForm
						text="Применить"
						onClick={handleFilter}
						className="hover:bg-accent-disabled py-3 px-6 bg-accent-default xl:flex-1 flex justify-center text-white-default hover:text-bg-default w-full"
					/>
				</div>
			</Modal>
		</div>
	);
};

export default MyApplication;
