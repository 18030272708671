import React, { ReactNode } from "react";
import ArrowDropdown from "../constant/icons/ArrowDropdown";
import { Link } from "react-router-dom";

interface PropsType {
  title: string;
  link?: string;
  className?: string;
  leftIcon?: React.ReactNode;
}

const BreadCrump = ({
  title,
  link,
  className = " text-[20px] xl:text-[24px] text-accent-default font-semibold",
  leftIcon = false,
}: PropsType) => {
  return (
    <OptionalLink link={link} className="flex gap-[5px] mb-[6px] items-center">
      {leftIcon && (
        <div className="w-[24px] cursor-default h-[24px] flex items-center justify-center">
          {leftIcon}
        </div>
      )}
      <h2 className={` ${className}`}>{title}</h2>
      {link && (
        <div className="-rotate-[90deg] w-[24px] h-[24px] flex items-center justify-center">
          <ArrowDropdown color="#3583FF" />
        </div>
      )}
    </OptionalLink>
  );
};

const OptionalLink = (props: {
  children: ReactNode;
  link?: string;
  className: string;
}) => {
  if (props.link) {
    return <Link {...props} to={props.link} />;
  }
  return <div {...props} />;
};

export default BreadCrump;
