import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import PageTitle from "components/PageTitle";
import Calendar from "components/constant/icons/Calendar";
import MinusIcon from "components/constant/icons/MinusIcon";
import { useWindowSize } from "hooks/useWindowSize";
import { useRoles } from "hooks/useRoles";
import Dropdown from "components/Form/Dropdown/Dropdown";
import { useFormatForDropDownOptions } from "hooks/useDropDownOption";
import { useGetCitiesQuery } from "data/api/city";
import { cityItem } from "modules/News";
import moment from "moment";
import CalendarDatePickerField from "components/Calendar/CalendarDatePickerField";
import useFormattedDate from "hooks/useFormatDate";
import ButtonForm from "components/Button/ButtonForm";
import CloseWithBorderIcon from "components/constant/icons/CloseWithBorderIcon";
import Modal from "components/Modal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import {
  handleOpenAnaliticsFilter,
  handleOpenAnaliticsFilterCalendarEnd,
  handleOpenAnaliticsFilterCalendarSt,
} from "store/Analytics";
import { dateSelectTabs, TimeFilter } from "./data";
import { IFiltersAnalytics } from ".";

interface IProps {
  filter: IFiltersAnalytics;
  setFilter: Dispatch<SetStateAction<IFiltersAnalytics>>;
  setSelectedTab: Dispatch<SetStateAction<TimeFilter>>;
  selectedTab: TimeFilter;
}

export const AnalyticsFilter = ({
  filter,
  setFilter,
  selectedTab,
  setSelectedTab,
}: IProps) => {
  const { isLg } = useWindowSize();

  const roles = useRoles(null);

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(handleOpenAnaliticsFilter(false));
  };

  const { data: cities } = useGetCitiesQuery();

  const selection = useSelector((state: RootState) => state);

  const [dateStart, setDateStart] = useState<moment.Moment | null>(
    moment().startOf("month")
  );

  const [dateEnd, setDateEnd] = useState<moment.Moment | null>(
    moment().endOf("month")
  );

  const [selectCity, setSelectCity] = useState<cityItem | null>(null);

  const { isMd } = useWindowSize();

  const formatedate = useFormattedDate();

  const cityOptions = useFormatForDropDownOptions(cities?.data ?? []);

  const handleSelectDate = (value: TimeFilter) => {
    setSelectedTab(value);
    switch (value) {
      case TimeFilter.TODAY: {
        const startOfToday = moment().startOf("day");
        const endOfToday = moment().endOf("day");

        setFilter((prev) => ({
          ...prev,
          fromDate: startOfToday.toISOString(),
          toDate: endOfToday.toISOString(),
        }));

        setDateStart(startOfToday);
        setDateEnd(endOfToday);
        break;
      }

      case TimeFilter.WEEK: {
        const startOfWeek = moment().startOf("isoWeek");
        const endOfWeek = moment().endOf("isoWeek");

        setFilter((prev) => ({
          ...prev,
          fromDate: startOfWeek.toISOString(),
          toDate: endOfWeek.toISOString(),
        }));

        setDateStart(startOfWeek);
        setDateEnd(endOfWeek);
        break;
      }

      case TimeFilter.MONTH: {
        const startOfMonth = moment().startOf("month");
        const endOfMonth = moment().endOf("month");

        setFilter((prev) => ({
          ...prev,
          fromDate: startOfMonth.toISOString(),
          toDate: endOfMonth.toISOString(),
        }));

        setDateStart(startOfMonth);
        setDateEnd(endOfMonth);
        break;
      }

      case TimeFilter.YEAR: {
        const startOfYear = moment().startOf("year");
        const endOfYear = moment().endOf("year");

        setFilter((prev) => ({
          ...prev,
          fromDate: startOfYear.toISOString(),
          toDate: endOfYear.toISOString(),
        }));

        setDateStart(startOfYear);
        setDateEnd(endOfYear);
        break;
      }

      default:
        setDateStart(null);
        setDateEnd(null);
    }
  };

  useEffect(() => {
    const startOfMonth = moment().startOf("month");
    const endOfMonth = moment().endOf("month");
    setFilter((prev) => ({
      ...prev,
      fromDate: startOfMonth.toISOString(),
      toDate: endOfMonth.toISOString(),
    }));
  }, []);

  useEffect(() => {
    const defaultValue = cityOptions.find(
      (item) => item.value === selection.header.city_id
    );
    if (defaultValue) {
      setFilter((prev) => prev && { ...prev, city_id: +defaultValue?.value });
      setSelectCity(defaultValue as cityItem);
    }
  }, [selection.header.city_id]);

  const handleSelectCity = (value: cityItem) => {
    setFilter((prev) => ({ ...prev, city_id: +value.value }));
    setSelectCity(value);
  };

  const handleSelectDateFrom = (date: moment.Moment) => {
    setDateStart(date);
    setFilter((prev) => ({ ...prev, fromDate: date.toISOString() }));
  };

  const handleSelectDateTo = (date: moment.Moment) => {
    setDateEnd(date);
    setFilter((prev) => ({ ...prev, toDate: date.toISOString() }));
  };

  const startDate = dateStart
    ? dateStart.toDate()
    : moment().startOf("month").toDate();
  const endDate = dateEnd ? dateEnd.toDate() : moment().endOf("month").toDate();

  return (
    <>
      {isLg ? (
        <div className="flex justify-between items-center">
          <div className="flex w-full flex-col gap-7 ">
            {isLg && (
              <PageTitle
                text={
                  roles.isAdmin
                    ? "Аналитика по платформе"
                    : "Ваша персональная аналитика"
                }
              />
            )}
            {roles.isAdmin && (
              <div className="flex  justify-between lg:justify-start  items-center gap-5">
                <h2 className="text-[18px] text-accent-default font-dewi font-extrabold">
                  {moment().format("D MMMM")}
                </h2>
                <Dropdown
                  placeholder={"Город"}
                  value={selectCity}
                  options={[{ value: "", label: "Все" }, ...cityOptions]}
                  className={"select-bg-transparent outline-none min-w-[100px]"}
                  onChange={handleSelectCity}
                />
              </div>
            )}
          </div>
          {isLg && (
            <div className="flex flex-col-reverse xl:flex-row gap-5 items-center self-end">
              <div className="flex bg-white-default p-[4px] rounded-full w-fit mx-auto">
                {dateSelectTabs.map((tab) => (
                  <button
                    key={tab.id}
                    onClick={() => handleSelectDate(tab.value)}
                    className={`px-6 py-[8px] rounded-full w-[112px] text-sm transition-all duration-300 ${
                      selectedTab === tab.value
                        ? " bg-accent-default text-white-default"
                        : "text-accent-default"
                    }`}
                  >
                    {tab.label}
                  </button>
                ))}
              </div>
              <div className="w-[330px] px-[4px] flex text-sm items-center bg-white-default justify-between rounded-full py-[10px] text-accent-default calendar-block relative">
                <div className="flex-1 flex items-center justify-center gap-3">
                  <div className={"md:w-auto w-full z-10 "}>
                    <CalendarDatePickerField
                      startDate={startDate}
                      customIcon={<Calendar />}
                      onChangeDate={(value) => {
                        handleSelectDateFrom(moment(value));
                      }}
                      className={"h-full"}
                      isMobile={isMd}
                    />
                  </div>

                  <p>
                    {startDate
                      ? formatedate(startDate.toISOString(), false)
                      : formatedate(new Date().toISOString(), false)}
                  </p>
                </div>
                <MinusIcon />
                <div className="flex-1 flex items-center justify-center gap-3">
                  <div className={"md:w-auto w-[50px] z-10 "}>
                    <CalendarDatePickerField
                      startDate={endDate}
                      customIcon={<Calendar />}
                      onChangeDate={(value) => {
                        handleSelectDateTo(moment(value));
                      }}
                      className={"h-full"}
                      isMobile={isMd}
                    />
                  </div>
                  <p>
                    {endDate
                      ? formatedate(endDate.toISOString(), false)
                      : formatedate(new Date().toISOString(), false)}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="flex w-full flex-col gap-7 -order-1 ">
            {isLg && (
              <PageTitle
                text={
                  roles.isAdmin
                    ? "Аналитика по платформе"
                    : "Ваша персональная аналитика"
                }
              />
            )}
            {roles.isAdmin && (
              <div className="flex  justify-between lg:justify-start  items-center gap-5">
                <h2 className="text-[18px] text-accent-default font-dewi font-extrabold">
                  {moment().format("D MMMM")}
                </h2>
                <Dropdown
                  placeholder={"Город"}
                  value={selectCity}
                  options={[{ value: "", label: "Все" }, ...cityOptions]}
                  className={"select-bg-transparent outline-none min-w-[100px]"}
                  onChange={handleSelectCity}
                />
              </div>
            )}
          </div>
          <Modal
            isOpen={selection.analytics.isOpenFilterModalAnalitics}
            className="w-full md:w-[500px] rounded-t-[30px] md:rounded-[20px]"
            onClose={handleClose}
          >
            <div className={"flex justify-between"}>
              <h1 className={"font-extrabold text-[25px] font-dewi"}>Фильтр</h1>

              <ButtonForm
                text={<CloseWithBorderIcon />}
                onClick={handleClose}
              />
            </div>
            <div className="flex flex-col gap-[10px] mt-5">
              <div className="flex bg-bg-default  rounded-full w-full p-1">
                {dateSelectTabs.map((tab) => (
                  <button
                    key={tab.id}
                    onClick={() => handleSelectDate(tab.value)}
                    className={`px-[12px] py-[8px] rounded-full flex-1 text-sm transition-all duration-300 ${
                      selectedTab === tab.value
                        ? " bg-accent-default text-white-default"
                        : "text-accent-default"
                    }`}
                  >
                    {tab.label}
                  </button>
                ))}
              </div>
              <div className="w-full px-[4px] flex text-sm items-center bg-bg-default justify-between rounded-full py-[10px] text-accent-default calendar-block relative">
                <div className="flex-1 flex items-center justify-center cursor-pointer gap-3">
                  <div
                    onClick={() =>
                      dispatch(
                        handleOpenAnaliticsFilterCalendarSt(
                          !selection.analytics.isOpenStartDateCalendar
                        )
                      )
                    }
                  >
                    <Calendar />
                  </div>
                  {selection.analytics.isOpenStartDateCalendar && (
                    <div
                      className={
                        "md:w-auto w-full absolute right-0 bottom-[45px]"
                      }
                    >
                      <CalendarDatePickerField
                        startDate={startDate}
                        customIcon={<Calendar />}
                        onChangeDate={(value) => {
                          handleSelectDateFrom(moment(value));
                          dispatch(
                            handleOpenAnaliticsFilterCalendarSt(
                              !selection.analytics.isOpenStartDateCalendar
                            )
                          );
                        }}
                        className={"h-full"}
                        isMobile={isMd}
                      />
                    </div>
                  )}

                  <p
                    onClick={() =>
                      dispatch(
                        handleOpenAnaliticsFilterCalendarSt(
                          !selection.analytics.isOpenStartDateCalendar
                        )
                      )
                    }
                  >
                    {startDate
                      ? formatedate(startDate.toISOString(), false)
                      : formatedate(new Date().toISOString(), false)}
                  </p>
                </div>
                <MinusIcon />
                <div className="flex-1 flex items-center justify-center cursor-pointer gap-3  ">
                  <div
                    onClick={() =>
                      dispatch(
                        handleOpenAnaliticsFilterCalendarEnd(
                          !selection.analytics.isOpenEndDateCalendar
                        )
                      )
                    }
                  >
                    <Calendar />
                  </div>

                  {selection.analytics.isOpenEndDateCalendar && (
                    <div
                      className={
                        "w-full md:w-auto  absolute right-0  bottom-[45px]"
                      }
                    >
                      <CalendarDatePickerField
                        startDate={endDate}
                        customIcon={<Calendar />}
                        onChangeDate={(value) => {
                          handleSelectDateTo(moment(value));
                          dispatch(
                            handleOpenAnaliticsFilterCalendarEnd(
                              !selection.analytics.isOpenEndDateCalendar
                            )
                          );
                        }}
                        className={"h-full"}
                        isMobile={isMd}
                      />
                    </div>
                  )}

                  <p
                    onClick={() =>
                      dispatch(
                        handleOpenAnaliticsFilterCalendarEnd(
                          !selection.analytics.isOpenEndDateCalendar
                        )
                      )
                    }
                  >
                    {endDate
                      ? formatedate(endDate.toISOString(), false)
                      : formatedate(new Date().toISOString(), false)}
                  </p>
                </div>
              </div>
              <div className=" flex flex-col gap-[10px] mt-5">
                <ButtonForm
                  text="Применить"
                  onClick={handleClose}
                  className={
                    " max-h-[48px] px-6 py-4 w-full rounded-lg justify-center bg-accent-default text-white-default text-ellipsis whitespace-nowrap"
                  }
                />

                <ButtonForm
                  onClick={() => {
                    handleClose();
                    handleSelectDate(TimeFilter.MONTH);
                  }}
                  text="Сбросить"
                  className={
                    " max-h-[48px] px-6 py-4 w-full rounded-lg justify-center bg-bg-default text-accent-default text-ellipsis whitespace-nowrap"
                  }
                />
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};
