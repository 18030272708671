export const handleFormatPrice = (price: string | undefined) => {
  const formatedPrice = new Intl.NumberFormat("ru-RU", {
    style: "currency",
    currency: "RUB",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(Number(price));

  return formatedPrice;
};
