import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { CloseIcon } from "yet-another-react-lightbox";
import PageTitle from "components/PageTitle";
import {
  useGetProjectsQuery,
  useProjectDeleteMutation,
} from "data/api/project";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import Loader from "components/Loader";
import AnimationFrame from "components/AnimationFrame.tsx";
import { Project } from "modules/Deal/type";
import ProjectPageCard from "components/Cards/ProjectPageCard";
import ButtonForm from "components/Button/ButtonForm";
import CreateEditProjectModal from "./CreateEditProjectModal";
import { handleOpenProjectCreateModal } from "store/ProjectReducer";
import { ResponseStatusType } from "data/api/exception";
import showToast from "utils/showToast";
import Modal from "components/Modal";
import { useWindowSize } from "hooks/useWindowSize";
import Dropdown from "components/Form/Dropdown/Dropdown";
import { cityItem } from "modules/News";
import { useGetCitiesQuery } from "data/api/city";
import { useFormatForDropDownOptions } from "hooks/useDropDownOption";
import useGetProjectByCityId from "hooks/commonApiCalls/useGetProjectByCityId";

const ProjectEditPage = () => {
  const selection = useSelector((state: RootState) => state.header);

  const [wantToDeleteModal, setWantToDeleteModal] = useState(false);

  const [projectId, setProjectId] = useState<number | null>(null);

  const [selectCity, setSelectCity] = useState<cityItem | null>(null);

  const {
    projects: data,
    isLoading,
    refetch,
  } = useGetProjectByCityId({
    cityId: selectCity ? Number(selectCity?.value) : selection.city_id,
  });

  const [deleteMutation] = useProjectDeleteMutation();

  const [projectSelected, setProjectSelected] = useState<Project | null>(null);

  const { isLg } = useWindowSize();

  const dispatch = useDispatch();

  const { data: cities } = useGetCitiesQuery();

  const cityOptions = useFormatForDropDownOptions(cities?.data ?? []);

  const navigate = useNavigate();

  useEffect(() => {
    const city = cityOptions.find((item) => item.value === selection.city_id);
    setSelectCity(city as cityItem);
  }, [selection.city_id]);

  const handleOpenModalProjectCreate = () => {
    dispatch(handleOpenProjectCreateModal(true));
  };

  const projectList = useMemo(() => data ?? [], [data]);

  const handleUpdate = (data: Project) => {
    setProjectSelected(data);
    handleOpenModalProjectCreate();
  };

  const handleOpenDeleteModal = (id: number) => {
    setWantToDeleteModal(true);
    setProjectId(id);
  };

  const handleDelete = async () => {
    if (projectId)
      try {
        const { data: res, error } = await deleteMutation(projectId);
        if (res?.meta?.type === ResponseStatusType.SUCCESS) {
          showToast("Проект успешно удален!", "success", 2000);
          setProjectId(null);
          setWantToDeleteModal(false);
          refetch();
        }
        if (error?.message) {
          showToast(error?.message, "error", 2000);
        }
      } catch (error) {}
  };

  return (
    <section className={"bg-bg-default relative min-h-[50vh]"}>
      <div className="br-container  py-[16px] md:py-[30px] flex flex-col gap-[20px]">
        <div className="flex justify-between relative">
          <PageTitle text="Проекты" />
          {isLg ? (
            <ButtonForm
              onClick={handleOpenModalProjectCreate}
              text="Добавить проект"
              className=" bg-accent-default text-white-default py-3 px-6
             hover:bg-accent-disabled w-[255px] flex justify-center
              hover:text-bg-default whitespace-nowrap"
            />
          ) : (
            <Dropdown
              placeholder={"Город"}
              value={selectCity}
              options={cityOptions}
              className={" select-bg-transparent min-w-[100px]"}
              onChange={setSelectCity}
            />
          )}
        </div>
        {isLoading && <Loader />}
        <AnimationFrame>
          <div className="grid  xl:grid-cols-2 gap-[10px]">
            {projectList.map((item: Project) => (
              <div key={item.id}>
                <ProjectPageCard
                  item={item}
                  handleUpdate={handleUpdate}
                  handleDelete={handleOpenDeleteModal}
                />
              </div>
            ))}
          </div>
        </AnimationFrame>
      </div>
      <div className=" hidden fixed right-[30px] bottom-[30px] lg:flex items-center justify-center">
        <div
          className={
            "rounded-full bg-red-default flex items-center justify-center p-3 cursor-pointer"
          }
          onClick={() => navigate("/projects")}
        >
          <CloseIcon color={"#FFFFFF"} />
        </div>
      </div>
      <CreateEditProjectModal
        projectSelected={projectSelected}
        setProjectSelected={setProjectSelected}
        refetch={refetch}
      />

      <Modal
        isOpen={wantToDeleteModal}
        className="mx-auto w-full sm:w-[440px] rounded-t-[30px] rounded-b-0 sm:rounded-lg"
        onClose={() => {
          setWantToDeleteModal(false);
          setProjectId(null);
        }}
      >
        <div className={"flex  mb-[20px] items-center justify-center"}>
          <div className="text-center text-accent-default">
            <h1 className={"font-extrabold text-[20px] font-dewi"}>Удаление</h1>
            <p className="text-sm font-normal">
              Вы действительно хотите удалить проект?
            </p>
          </div>
        </div>
        <div className="flex  gap-[10px] mt-[10px]">
          <ButtonForm
            text="Отмена"
            onClick={() => {
              setWantToDeleteModal(false);
              setProjectId(null);
            }}
            className="py-3 px-6 bg-white-default border border-accent-default hover:bg-accent-default xl:flex-1 flex justify-center hover:text-white-default w-full"
          />
          <ButtonForm
            onClick={handleDelete}
            text="Да"
            className="py-3 px-6  bg-red-default xl:flex-1 flex justify-center text-white-default w-full hover:bg-red-focus hover:text-bg-default"
          />
        </div>
      </Modal>
    </section>
  );
};

export default ProjectEditPage;
