import React from "react";

const EditCalendarIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 8.91425H11.5M7.30952 3V4.54304M15.5 4.54285C15.5 4.54285 11.4052 4.54285 7.5 4.54285C5.84315 4.54285 4.5 5.92436 4.5 7.62855V17.9143C4.5 19.6185 5.84315 21 7.5 21H17.5C19.1569 21 20.5 19.6185 20.5 17.9143V12"
        stroke="#37465B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4375 11.9064L14.9532 14.0627M12.0781 11.9064L19.1648 4.57227C19.9278 3.80924 21.1649 3.80924 21.9279 4.57227C22.691 5.3353 22.691 6.57241 21.9279 7.33543L14.5938 14.4221L11 15.5002L12.0781 11.9064Z"
        stroke="#37465B"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditCalendarIcon;
