import { ReactNode } from "react";
import BackIcon from "../components/constant/icons/BackIcon";
import BellIcon from "../components/constant/icons/BellIcon";
import ChessIcon from "../components/constant/icons/ChessIcon";
import GenerateIcons from "../components/constant/icons/GenerateIcons";
import ModalIcon from "../components/constant/icons/ModalIcon";
import SearchIcon from "../components/constant/icons/SearchIcon";
import {
  handleOpenFilters,
  handleOpenModal,
  handleOpenSearch,
} from "../store/BaseClientsReducer";
import store from "../store";
import {
  chessAppearance,
  resetState,
  toggleGenerateSelection,
} from "store/ApartmentReducer";
import MorgageIcon from "components/constant/icons/MorgageIcon";
import AddIconInCircle from "components/constant/icons/AddIconInCircle";
import PencilIcon from "components/constant/icons/PencilIcon";
import PlusIcon from "components/constant/icons/PlusIcon";
import CancelIcon from "components/constant/icons/CancelIcon";
import {
  toggleCheckingFasteningModal,
  toggleFasteningFormModal,
  toggleNotificationModal,
  toggleRecordForShowModal,
  toggleSearchModal,
} from "store/HeaderReducer";
import { CloseIcon } from "yet-another-react-lightbox";
import PlusIconBig from "../components/constant/icons/PlusIconBig";
import { handleOpenCreateNews } from "../store/NewsReducer";
import Calendar from "../components/constant/icons/Calendar";
import { handleOpenDatePicker } from "../store/CalendarReducer";
import { UserRole } from "enums/users";
import { handleOpenAdminsFilter } from "store/AdminstrationReducer";
import TickIcon from "components/constant/icons/TickIcon";
import IconSearchPeopleIcon from "components/constant/icons/Header/SearchPeopleIcon";
import PinPeopleIcon from "components/constant/icons/Header/PinPeopleIcon";
import EditCalendarIcon from "components/constant/icons/Header/EditCalendarIcon";
import { handleOpenCreateEvents } from "store/EvenstReducer";
import { handleOpenAnaliticsFilter } from "store/Analytics";
import { triggerSubmitTraining } from "store/TrainingReducer";
import { handleOpenProjectCreateModal } from "store/ProjectReducer";
import { Primise } from "enums/primisesType";
import {
  handleOpenCreateTasks,
  handleOpenMortgageTask,
} from "store/TasksReducer";

type IconConfigType = (navigate: any) => {
  [key: string]: {
    leftIcons: { icon: ReactNode; onClick: () => void }[];
    rightIcons: { icon: ReactNode; onClick: () => void }[];
  };
};
export type PageTitles = {
  [key: string]: string;
};

export const activeNavLinksRecord: Record<string, string> = {
  "/events/edit": "Мероприятия",
  "/events": "Мероприятия",
  "/events/view/:id": "Мероприятия",
  "/mortgage-calculator": "Ипотечный калькулятор",
  "/calendar": "Календарь",
  "/news": "Новости",
  "/tasks": "Задачи",
  "/news/edit": "Новость",
  "/news/view/:id": "Новость",
  "/training": "Обучение",
  "/training/edit": "Обучение",
  "/administration": "Администрирование",
  "/analytics": "Аналитика",
  "/contacts": "Контакты",
};

export const navLinksNested = [
  {
    label: "Главная",
    path: "/",
    notAllowedRoles: [],
  },
  {
    label: "Клиенты",
    path: "/base-clients",
    notAllowedRoles: [],
  },
  {
    label: "Сделки",
    path: "/deals",
    notAllowedRoles: [],
  },
  { label: "Календарь", path: "/calendar", notAllowedRoles: [] },
  {
    label: "Проекты",
    path: "/projects",
    notAllowedRoles: [],
  },
  {
    label: "Квартиры",
    path: "/apartments",
    notAllowedRoles: [],
  },

  {
    label: "Еще",
    nav: [
      { label: "Новости", path: "/news", notAllowedRoles: [] },

      {
        label: "Мероприятия",
        path: "/events",
        notAllowedRoles: [],
      },
      { label: "Обучение", path: "/training", notAllowedRoles: [] },
      {
        label: "Задачи",
        path: "/tasks",
        notAllowedRoles: [UserRole.AGENT, UserRole.HEAD_OF_AGENCY],
      },
      // {
      //   label: "Чат",
      //   path: "/chat",
      //   notAllowedRoles: [],
      // },
      {
        label: "Ипотечный калькулятор",
        path: "/mortgage-calculator",
        notAllowedRoles: [UserRole.AFFILIATE_MANAGER],
      },

      // {
      //   label: "Обмен",
      //   path: "/exchange",
      //   notAllowedRoles: [UserRole.MANAGER],
      // },
      // {
      //   label: "Аналитика",
      //   path: "/analytics",
      //   notAllowedRoles: [UserRole.MANAGER],
      // },
      {
        label: "Администрирование",
        path: "/administration",
        notAllowedRoles: [
          UserRole.AGENT,
          UserRole.MANAGER,
          UserRole.NEW_MEMBER,
        ],
      },
      {
        label: "Контакты",
        path: "/contacts",
        notAllowedRoles: [],
      },
    ],
  },
];

export const navLinksFlat = [
  {
    label: "Главная",
    path: "/",
    notAllowedRoles: [],
  },
  {
    label: "Клиенты",
    path: "/base-clients",
    notAllowedRoles: [],
  },
  {
    label: "Сделки",
    path: "/deals",
    notAllowedRoles: [],
  },
  { label: "Календарь", path: "/calendar", notAllowedRoles: [] },
  {
    label: "Проекты",
    path: "/projects",
    notAllowedRoles: [],
  },
  {
    label: "Квартиры",
    path: "/apartments",
    notAllowedRoles: [],
  },
  { label: "Новости", path: "/news", notAllowedRoles: [] },
  {
    label: "Мероприятия",
    path: "/events",
    notAllowedRoles: [],
  },
  { label: "Обучение", path: "/training", notAllowedRoles: [] },
  {
    label: "Задачи",
    path: "/tasks",
    notAllowedRoles: [UserRole.AGENT, UserRole.HEAD_OF_AGENCY],
  },
  // {
  //   label: "Чат",
  //   path: "/chat",
  //   notAllowedRoles: [],
  // },
  {
    label: "Ипотечный калькулятор",
    path: "/mortgage-calculator",
    notAllowedRoles: [UserRole.AFFILIATE_MANAGER],
  },

  // {
  //   label: "Обмен",
  //   path: "/exchange",
  //   notAllowedRoles: [UserRole.MANAGER],
  // },

  // {
  //   label: "Аналитика",
  //   path: "/analytics",
  //   notAllowedRoles: [UserRole.MANAGER],
  // },
  {
    label: "Администрирование",
    path: "/administration",
    notAllowedRoles: [UserRole.AGENT, UserRole.MANAGER],
  },
  {
    label: "Контакты",
    path: "/contacts",
    notAllowedRoles: [],
  },
];

export const pageTitles: PageTitles = {
  "/": "Главная",
  "/base-clients": "Клиенты",
  "/deals": "Сделки",
  "/projects": "Проекты",
  "/apartments": "Квартиры",
  "/apartments/:id": "Квартиры",
  "/apartments/card/:id": "Квартира",
  "/apartments/info": "Подборки",
  "/tasks": "Задачи",
  "/events/edit": "Редактор",
  "/events": "Мероприятия",
  "/events/view/:id": "Мероприятия",
  "/mortgage-calculator": "Ипотечный калькулятор",
  "/calendar": "Календарь",
  "/news": "Новости",
  "/news/edit": "Редактор",
  "/news/view/:id": "Новость",
  "/training": "Обучение",
  "/training/edit": "Редактор",
  "/administration": "Администрирование",
  "/exchange": "Обмен",
  "/analytics": "Аналитика",
  "/contacts": "Контакты",
};

export const headerIconLinks = [
  {
    id: 2,
    icon: <IconSearchPeopleIcon />,
    to: "/",
    title: "Проверка закрепления",
    onClick: () => store.dispatch(toggleCheckingFasteningModal(true)),
  },
  {
    id: 3,
    title: "Закрепление клиента",
    icon: <PinPeopleIcon />,
    to: "/",
    onClick: () => store.dispatch(toggleFasteningFormModal(true)),
  },
  {
    id: 4,
    title: "Запись на показ",
    icon: <EditCalendarIcon />,
    to: "/",
    onClick: () => store.dispatch(toggleRecordForShowModal()),
  },
  {
    id: 5,
    title: "Уведомление",
    icon: <BellIcon />,
    to: "/",
    onClick: () => store.dispatch(toggleNotificationModal(true)),
  },
];

export const iconConfig: IconConfigType = (navigate) => {
  const permissionForIcons =
    store.getState().user.data?.data?.role === UserRole.ADMIN ||
    store.getState().user.data?.data?.role === UserRole.AFFILIATE_MANAGER;

  const primisesType = store.getState().apartment.type;

  return {
    "/": {
      leftIcons: [
        {
          icon: <IconSearchPeopleIcon />,
          onClick: () => store.dispatch(toggleCheckingFasteningModal(true)),
        },
        {
          icon: <PinPeopleIcon />,
          onClick: () => store.dispatch(toggleFasteningFormModal(true)),
        },
      ],
      rightIcons: [
        {
          icon: <EditCalendarIcon />,
          onClick: () => store.dispatch(toggleRecordForShowModal()),
        },
        {
          icon: (
            <SearchIcon
              color={
                store.getState().baseClients.isFiltersSearch
                  ? "#EF3B24"
                  : "#37465B"
              }
            />
          ),
          onClick: () => store.dispatch(toggleSearchModal()),
        },
      ],
    },
    "/base-clients": {
      leftIcons: [
        {
          icon: <BackIcon />,
          onClick: () => navigate(-1),
        },
      ],
      rightIcons: [
        {
          icon: (
            <SearchIcon
              color={
                store.getState().baseClients.isFiltersSearch
                  ? "#EF3B24"
                  : "#37465B"
              }
            />
          ),
          onClick: () => store.dispatch(handleOpenSearch(true)),
        },
        {
          icon: (
            <ModalIcon
              color={
                store.getState().baseClients.isFiltersSelect
                  ? "#EF3B24"
                  : "#37465B"
              }
            />
          ),
          onClick: () => store.dispatch(handleOpenFilters(true)),
        },
      ],
    },
    "/deals": {
      leftIcons: [
        {
          icon: <BackIcon />,
          onClick: () => navigate(-1),
        },
      ],
      rightIcons: [
        {
          icon: (
            <SearchIcon
              color={
                store.getState().baseClients.isFiltersSearch
                  ? "#EF3B24"
                  : "#37465B"
              }
            />
          ),
          onClick: () => store.dispatch(handleOpenSearch(true)),
        },
        {
          icon: (
            <ModalIcon
              color={
                store.getState().baseClients.isFiltersSelect
                  ? "#EF3B24"
                  : "#37465B"
              }
            />
          ),
          onClick: () => store.dispatch(handleOpenFilters(true)),
        },
      ],
    },

    "/projects": {
      leftIcons: [
        {
          icon: <BackIcon />,
          onClick: () => navigate(-1),
        },
      ],
      rightIcons: permissionForIcons
        ? [
            {
              icon: <PencilIcon />,
              onClick: () => navigate("projects/edit"),
            },
          ]
        : [],
    },
    "/projects/edit": {
      leftIcons: [
        {
          icon: <CancelIcon color="#EF3B24" />,
          onClick: () => navigate("/projects"),
        },
      ],
      rightIcons: [
        {
          icon: <PlusIcon color="#313945" width={100} height={100} />,
          onClick: () => store.dispatch(handleOpenProjectCreateModal(true)),
        },
      ],
    },

    "/apartments": {
      leftIcons: [
        {
          icon: <BackIcon />,
          onClick: () => navigate(-1),
        },
      ],
      rightIcons:
        primisesType === Primise.APARTMENT
          ? [
              {
                icon: <GenerateIcons />,
                onClick: () => {
                  if (store.getState().apartment.generateSelection) {
                    store.dispatch(toggleGenerateSelection());
                    store.dispatch(resetState());
                  } else {
                    store.dispatch(toggleGenerateSelection());
                    store.dispatch(chessAppearance());
                  }
                },
              },
              {
                icon: <ChessIcon />,
                onClick: () => store.dispatch(chessAppearance()),
              },
              {
                icon: (
                  <ModalIcon
                    color={
                      store.getState().baseClients.isFiltersSelect
                        ? "#EF3B24"
                        : "#37465B"
                    }
                  />
                ),
                onClick: () => store.dispatch(handleOpenFilters(true)),
              },
            ]
          : [
              {
                icon: <GenerateIcons />,
                onClick: () => {
                  if (store.getState().apartment.generateSelection) {
                    store.dispatch(toggleGenerateSelection());
                    store.dispatch(resetState());
                  } else {
                    store.dispatch(toggleGenerateSelection());
                    store.dispatch(chessAppearance());
                  }
                },
              },
              {
                icon: (
                  <ModalIcon
                    color={
                      store.getState().baseClients.isFiltersSelect
                        ? "#EF3B24"
                        : "#37465B"
                    }
                  />
                ),
                onClick: () => store.dispatch(handleOpenFilters(true)),
              },
            ],
    },
    "/apartments/:id": {
      leftIcons: [
        {
          icon: <BackIcon />,
          onClick: () => navigate(-1),
        },
      ],
      rightIcons: [
        {
          icon: <GenerateIcons />,
          onClick: () => {
            if (store.getState().apartment.generateSelection) {
              store.dispatch(toggleGenerateSelection());
              store.dispatch(resetState());
            } else {
              store.dispatch(toggleGenerateSelection());
              store.dispatch(chessAppearance());
            }
          },
        },
        {
          icon: <ChessIcon />,
          onClick: () => store.dispatch(chessAppearance()),
        },
        {
          icon: (
            <ModalIcon
              color={
                store.getState().baseClients.isFiltersSelect
                  ? "#EF3B24"
                  : "#37465B"
              }
            />
          ),
          onClick: () => store.dispatch(handleOpenFilters(true)),
        },
      ],
    },
    "/tasks": {
      leftIcons: [
        {
          icon: <BackIcon />,
          onClick: () => navigate(-1),
        },
      ],
      rightIcons: [
        {
          icon: <MorgageIcon />,
          onClick: () => store.dispatch(handleOpenMortgageTask(true)),
        },

        {
          icon: <AddIconInCircle />,
          onClick: () => store.dispatch(handleOpenCreateTasks(true)),
        },
      ],
    },
    "/events": {
      leftIcons: [
        {
          icon: <BackIcon />,
          onClick: () => navigate(-1),
        },
      ],
      rightIcons: permissionForIcons
        ? [
            {
              icon: <PlusIcon color="#313945" width={100} height={100} />,
              onClick: () => navigate("events/edit"),
            },
            {
              icon: <PencilIcon />,
              onClick: () => navigate("events/edit"),
            },
          ]
        : [],
    },

    "/events/edit": {
      leftIcons: [
        {
          icon: <CancelIcon color="#EF3B24" />,
          onClick: () => navigate("/events"),
        },
      ],
      rightIcons: [
        {
          icon: <PlusIcon color="#313945" width={100} height={100} />,
          onClick: () => store.dispatch(handleOpenCreateEvents(true)),
        },
        // {
        //   icon: <PencilIcon />,
        //   onClick: () => {},
        // },
      ],
    },
    "/news": {
      leftIcons: [
        {
          icon: <BackIcon />,
          onClick: () => navigate(-1),
        },
      ],
      rightIcons: permissionForIcons
        ? [
            {
              icon: <PlusIconBig />,
              onClick: () => {
                navigate("/news/edit");
                store.dispatch(handleOpenCreateNews(true));
              },
            },
            {
              icon: <PencilIcon />,
              onClick: () => navigate("/news/edit"),
            },
          ]
        : [],
    },
    "/news/view/:id": {
      leftIcons: [
        {
          icon: <BackIcon />,
          onClick: () => navigate(-1),
        },
      ],
      rightIcons: permissionForIcons
        ? [
            {
              icon: <PlusIconBig />,
              onClick: () => {
                navigate("/news/edit");
                store.dispatch(handleOpenCreateNews(true));
              },
            },
            {
              icon: <PencilIcon />,
              onClick: () => navigate("/news/edit"),
            },
          ]
        : [],
    },
    "/news/edit": {
      leftIcons: [
        {
          icon: <CloseIcon color={"#EF3B24"} />,
          onClick: () => navigate(-1),
        },
      ],
      rightIcons: [
        {
          icon: <PlusIconBig />,
          onClick: () => store.dispatch(handleOpenCreateNews(true)),
        },
      ],
    },
    "/calendar": {
      leftIcons: [
        {
          icon: <BackIcon />,
          onClick: () => navigate(-1),
        },
      ],
      rightIcons: [
        {
          icon: <Calendar color={"#37465B"} />,
          onClick: () => store.dispatch(handleOpenDatePicker(true)),
        },
      ],
    },
    "/training": {
      leftIcons: [
        {
          icon: <BackIcon />,
          onClick: () => navigate(-1),
        },
      ],
      rightIcons: permissionForIcons
        ? [
            {
              icon: <PencilIcon />,
              onClick: () => navigate("/training/edit"),
            },
          ]
        : [],
    },
    "/contacts": {
      leftIcons: [
        {
          icon: <BackIcon />,
          onClick: () => navigate(-1),
        },
      ],
      rightIcons: permissionForIcons
        ? [
            {
              icon: <PencilIcon />,
              onClick: () => navigate("/contacts/edit"),
            },
          ]
        : [],
    },
    "/training/edit": {
      leftIcons: [
        {
          icon: <CloseIcon color={"#EF3B24"} />,
          onClick: () => navigate(-1),
        },
      ],
      rightIcons: [
        {
          icon: <TickIcon color="#37465B" width={20} height={13} />,
          onClick: () => {
            store.dispatch(triggerSubmitTraining());
          },
        },
      ],
    },
    "/administration": {
      leftIcons: [
        {
          icon: <BackIcon />,
          onClick: () => navigate(-1),
        },
      ],
      rightIcons: [
        {
          icon: (
            <SearchIcon
              color={
                store.getState().baseClients.isFiltersSearch
                  ? "#EF3B24"
                  : "#37465B"
              }
            />
          ),
          onClick: () => store.dispatch(handleOpenSearch(true)),
        },
        {
          icon: (
            <ModalIcon
              color={
                store.getState().baseClients.isFiltersSelect
                  ? "#EF3B24"
                  : "#37465B"
              }
            />
          ),
          onClick: () => store.dispatch(handleOpenAdminsFilter(true)),
        },
      ],
    },

    "/exchange": {
      leftIcons: [
        {
          icon: <BackIcon />,
          onClick: () => navigate(-1),
        },
      ],
      rightIcons: [
        {
          icon: <PencilIcon />,
          onClick: () =>
            store.dispatch(
              handleOpenModal(!store.getState().baseClients.isOpenModal)
            ),
        },
        {
          icon: (
            <ModalIcon
              color={
                store.getState().baseClients.isFiltersSelect
                  ? "#EF3B24"
                  : "#37465B"
              }
            />
          ),
          onClick: () => store.dispatch(handleOpenFilters(true)),
        },
      ],
    },
    "/analytics": {
      leftIcons: [
        {
          icon: <BackIcon />,
          onClick: () => navigate(-1),
        },
      ],
      rightIcons: [
        {
          icon: <Calendar color={"#37465B"} />,
          onClick: () => store.dispatch(handleOpenAnaliticsFilter(true)),
        },
      ],
    },
  };
};

export function findClosestKey(path: string): string {
  if (pageTitles[path]) {
    return path;
  }

  const keys = Object.keys(pageTitles);
  for (const key of keys) {
    const regexPattern = key.replace(/:[^\s/]+/g, "([^\\s/]+)");
    const regex = new RegExp(`^${regexPattern}$`);

    if (regex.test(path)) {
      return key;
    }
  }

  return "";
}
