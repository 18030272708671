import React, { useLayoutEffect, useRef } from "react";
import { Project } from "modules/Deal/type";
import { images } from "utils/resource";
import { renderImage } from "utils/renderImage";
import PlusIcon from "components/constant/icons/PlusIcon";
import MinusIcon from "components/constant/icons/MinusIcon";
import { useNavigate } from "react-router-dom";

interface YMapProps {
  projectList: Project[];
}

declare global {
  interface Window {
    ymaps: any;
  }
}

const YMap: React.FC<YMapProps> = ({ projectList }) => {
  const navigate = useNavigate();
  const mapRef = useRef<any>(null);

  useLayoutEffect(() => {
    if (projectList.length !== 0) {
      handleInit();
    }
  }, [projectList]);

  const handleInit = () => {
    window.ymaps.ready(init);
    function init() {
      if (!mapRef.current) {
        mapRef.current = new window.ymaps.Map("map", {
          center: [projectList[0].city.lat, projectList[0].city.long],
          zoom: 9,
          controls: [],
        });
      }

      const customHintLayout = window.ymaps.templateLayoutFactory.createClass(
        `<div class="bg-white-default w-[170px] rounded-lg text-accent-default text-start py-2 px-3 text-xs overflow-hidden shadow-md font-medium flex flex-col gap-[5px]">
           <span>{{ properties.name }}</span> 
             <span class="opacity-60 max-w-[150px] w-[150px]  overflow-hidden whitespace-nowrap text-ellipsis">{{ properties.location }}</span>
            
        </div>`
      );

      mapRef.current.geoObjects.removeAll();

      if (projectList.length !== 0) {
        projectList.forEach((proj) => {
          const MyIconContentLayout =
            window.ymaps.templateLayoutFactory.createClass(
              `<div class="w-14 h-14 p-1 rounded-full bg-white-default relative after:absolute after:bottom-[-7px] after:left-4  after:h-0  after:w-0 after:border-r-[10px] after:border-t-[10px] 
            after:border-l-[10px] after:border-r-transparent after:border-l-transparent after:border-t-white-default translate-x-[-50%] translate-y-[-100%]">
            <img crossOrigin="anonymous" src="${
              renderImage(proj.photo) || images.defaultProjectImg
            }" class="w-full h-full rounded-full" />
          </div>`
            );

          const myPlacemark = new window.ymaps.Placemark(
            [Number(proj.lat), Number(proj.long)],
            {
              name: proj.name,
              location: proj.location,
            },
            {
              hintLayout: customHintLayout,
              // Опции.
              // Необходимо указать данный тип макета.
              iconLayout: "default#imageWithContent",
              // Своё изображение иконки метки.
              iconImageHref: "images/ball.png",
              // Размеры метки.
              iconImageSize: [48, 48],
              // Смещение левого верхнего угла иконки относительно
              // её "ножки" (точки привязки).
              iconImageOffset: [-24, -24],
              // Смещение слоя с содержимым относительно слоя с картинкой.
              iconContentOffset: [15, 15],
              // Макет содержимого.
              iconContentLayout: MyIconContentLayout,
            }
          );

          mapRef.current.geoObjects.add(myPlacemark);

          myPlacemark.events.add("click", () => {
            navigate(`/apartments?project_id=${proj.id}`);
          });
        });

        mapRef.current.setCenter(
          [projectList[0].city.lat, projectList[0].city.long],
          9,
          { duration: 1500 }
        );
      }
    }
  };

  const zoomIn = () => {
    if (mapRef.current) {
      const currentZoom = mapRef.current.getZoom();
      mapRef.current.setZoom(currentZoom + 1, { duration: 300 });
    }
  };

  const zoomOut = () => {
    if (mapRef.current) {
      const currentZoom = mapRef.current.getZoom();
      mapRef.current.setZoom(currentZoom - 1, { duration: 300 });
    }
  };

  return (
    <div
      id="map"
      className="flex-1 h-[680px] max-w-[700px] overflow-hidden relative"
    >
      <div className="absolute top-[50%] -translate-y-[50%] right-4 flex flex-col gap-2 z-10">
        <button
          onClick={zoomIn}
          className="w-12 bg-white-default h-12 bg-white rounded-full shadow-md flex items-center justify-center hover:bg-gray-200"
        >
          <PlusIcon color="#37465B" />
        </button>
        <button
          onClick={zoomOut}
          className="w-12 bg-white-default h-12 bg-white rounded-full shadow-md flex items-center justify-center hover:bg-gray-200"
        >
          <MinusIcon />
        </button>
      </div>
    </div>
  );
};

export default YMap;
