import { Icons } from "../interface";

const ZoomInIcon = (
	{
		width = 24,
		height = 24,
		color = '#EF3B24',
		strokeWidth = 1.5
	}: Icons
) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M17.6218 16.3209C17.2248 15.9369 16.5918 15.9474 16.2078 16.3443C15.8237 16.7412 15.8342 17.3743 16.2312 17.7583L17.6218 16.3209ZM19.7043 21.1183C20.1012 21.5023 20.7343 21.4918 21.1183 21.0949C21.5023 20.698 21.4918 20.0649 21.0949 19.6809L19.7043 21.1183ZM10.3996 14.3996C10.3996 14.9519 10.8473 15.3996 11.3996 15.3996C11.9519 15.3996 12.3996 14.9519 12.3996 14.3996H10.3996ZM12.3996 8.39961C12.3996 7.84732 11.9519 7.39961 11.3996 7.39961C10.8473 7.39961 10.3996 7.84732 10.3996 8.39961H12.3996ZM8.39961 10.3996C7.84732 10.3996 7.39961 10.8473 7.39961 11.3996C7.39961 11.9519 7.84732 12.3996 8.39961 12.3996V10.3996ZM14.3996 12.3996C14.9519 12.3996 15.3996 11.9519 15.3996 11.3996C15.3996 10.8473 14.9519 10.3996 14.3996 10.3996V12.3996ZM18.2796 11.4396C18.2796 15.2172 15.2172 18.2796 11.4396 18.2796V20.2796C16.3218 20.2796 20.2796 16.3218 20.2796 11.4396H18.2796ZM11.4396 18.2796C7.66198 18.2796 4.59961 15.2172 4.59961 11.4396H2.59961C2.59961 16.3218 6.55741 20.2796 11.4396 20.2796V18.2796ZM4.59961 11.4396C4.59961 7.66198 7.66198 4.59961 11.4396 4.59961V2.59961C6.55741 2.59961 2.59961 6.55741 2.59961 11.4396H4.59961ZM11.4396 4.59961C15.2172 4.59961 18.2796 7.66198 18.2796 11.4396H20.2796C20.2796 6.55741 16.3218 2.59961 11.4396 2.59961V4.59961ZM16.2312 17.7583L19.7043 21.1183L21.0949 19.6809L17.6218 16.3209L16.2312 17.7583ZM12.3996 14.3996V11.3996H10.3996V14.3996H12.3996ZM12.3996 11.3996V8.39961H10.3996V11.3996H12.3996ZM8.39961 12.3996H11.3996V10.3996H8.39961V12.3996ZM11.3996 12.3996H14.3996V10.3996H11.3996V12.3996Z"
				fill={color}
			/>
		</svg>
	);
};

export default ZoomInIcon;
