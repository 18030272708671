import React, { useEffect, useState } from "react";
import DatePickerField from "../../components/Form/DatePicker/DatePickerField";
import Dropdown, {
  selectOption,
} from "../../components/Form/Dropdown/Dropdown";
import ButtonForm from "../../components/Button/ButtonForm";
import { SearchField } from "../../components/Form/SearchField";
import { typeItem } from "./index";
import { FixingTypeWithSmall } from "enums/clientFixingType";
import { ActiveClientTogglerComp } from "components/ToggleSwitcher/ActiveClientToggler";
import { useRoles } from "hooks/useRoles";

export enum ActiveClientToggler {
  ACTIVE = "Активные",
  ALL = "",
}

interface BaseClientsFilters {
  handleChangeFields: (
    searchSelectItem: any,
    dateStart: Date,
    dateEnd: Date,
    selectSecond: any,
    activeClient: ActiveClientToggler
  ) => void;
  setIsOpenFormNotification: () => void;
  dateStartDefault?: string | null;
  dateEndDefault?: string | null;
  setIsOpenAnchoringModal?: () => void;
  typePinList: typeItem[];
}

const BaseClientsFilters = ({
  handleChangeFields,
  dateStartDefault = null,
  dateEndDefault = null,
  setIsOpenAnchoringModal,
  typePinList,
  setIsOpenFormNotification,
}: BaseClientsFilters) => {
  const [dateStart, setDateStart] = useState<Date | null>(
    dateStartDefault ? new Date(dateStartDefault) : null
  );
  const [dateEnd, setDateEnd] = useState<Date | null>(
    dateEndDefault ? new Date(dateEndDefault) : null
  );
  const [selectSecond, setSelectSecond] = useState(null);

  const roles = useRoles(null);

  const [activeClient, setActiveClientFilter] = useState<ActiveClientToggler>(
    ActiveClientToggler.ACTIVE
  );

  const [optionsSecond, setOptionsSecond] = useState<selectOption[]>([]);

  const [searchSelectItem, setSearchSelectItem] = useState(null);

  useEffect(() => {
    getSelectSecondList();
  }, []);

  function getSelectSecondList() {
    // $getList().then((response) => {
    const response = [
      {
        label: "Проверка лида",
        value: FixingTypeWithSmall.LEAD_VERIFICATION,
      },
      {
        label: "Отказ в закреплении",
        value: FixingTypeWithSmall.CENCEL_FIXING,
      },
      {
        label: "Слабое закрепление",
        value: FixingTypeWithSmall.WEAK_FIXING,
      },
      {
        label: "Сильное закрепление",
        value: FixingTypeWithSmall.STRONG_FIXING,
      },
    ];

    if (!response) return;

    setOptionsSecond(response);
  }

  useEffect(() => {
    handleChangeFields(
      searchSelectItem,
      dateStart as Date,
      dateEnd as Date,
      selectSecond,
      activeClient
    );
  }, [searchSelectItem, dateStart, dateEnd, selectSecond, activeClient]);

  function handleCheck() {
    setIsOpenFormNotification();
  }

  return (
    <div className="flex flex-col ">
      <ActiveClientTogglerComp
        activeClient={activeClient}
        setActiveClientFilter={setActiveClientFilter}
        hasTitle={true}
      />
      <div className={"flex mb-5 gap-2.5 justify-between w-full"}>
        <form className={"flex gap-2.5 w-full mt-[10px]"}>
          <SearchField
            selectItem={searchSelectItem}
            onChange={setSearchSelectItem}
            isShowBtnDelete={true}
            searchQuery={`is_active=${
              activeClient === ActiveClientToggler.ACTIVE
            }`}
            className={`min-w-[175px] xl:min-w-[238px] md:rounded-[4px!important] ${
              !roles.isAgent && "flex-1"
            }`}
          />
          <Dropdown
            placeholder={"Статус закрепления"}
            value={selectSecond}
            options={optionsSecond}
            className={` w-[150px]  xl:w-[200px!important] ${
              !roles.isAgent && "flex-1"
            }`}
            onChange={setSelectSecond}
            isClearable={true}
          />
          <DatePickerField
            className={`calendar-block w-[150px] placeholder:text-[9px]  xl:w-[185px] ${
              !roles.isAgent && "flex-1"
            }`}
            startDate={dateStart}
            placeholder={"Дата доб."}
            // maxDate={dateEnd}
            isIcon
            isRightIcon
            isClearable
            onChangeDate={setDateStart}
          />

          <DatePickerField
            className={"calendar-block min-w-[200px] flex-1"}
            startDate={dateEnd}
            placeholder={"Окончания закрепления до"}
            // maxDate={dateEnd}
            isIcon
            isRightIcon
            isClearable
            onChangeDate={setDateEnd}
          />
        </form>

        <div className={`gap-[10px] flex`}>
          <ButtonForm
            text="Проверить закрепление"
            onClick={setIsOpenAnchoringModal}
            className={
              "mt-[10px] max-h-[48px] px-6 w-full justify-center bg-accent-default text-white-default text-ellipsis whitespace-nowrap"
            }
          />
          {roles.isAgent && (
            <ButtonForm
              onClick={handleCheck}
              text="Форма уведомления"
              className={
                "mt-[10px] max-h-[48px] px-6 w-full justify-center bg-accent-default text-white-default text-ellipsis whitespace-nowrap"
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default BaseClientsFilters;
