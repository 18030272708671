import React, { useEffect, useState } from "react";
import { useRoles } from "hooks/useRoles";
import EventsAnaliticsCart from "./Card/EventsAnaliticsCart";
import {
  useGetDealsExpireTimeAnalyticsMutation,
  useGetDealsPriceAnalyticsMutation,
  useGetTopEventsAnalyticsMutation,
  useGetTopManagersAnalyticsMutation,
  useGetTopNewsAnalyticsMutation,
  useGetTopTrainingsAnalyticsMutation,
} from "data/api/analytics";
import { objectToQueryString } from "utils/objectToQuery";
import { IFiltersAnalytics } from ".";
import { ManagerInfo, TopInfo } from "data/api/analytics/type";
import { PaginationType } from "modules/BaseClients";

interface IProps {
  filter: IFiltersAnalytics;
}

const NewsEventsTrainingComponent = ({ filter }: IProps) => {
  const roles = useRoles(null);

  const [topNews, setTopNews] = useState<TopInfo[] | null>(null);

  const [topEvents, setTopEvents] = useState<TopInfo[] | null>(null);

  const [topTraining, setTopTraining] = useState<TopInfo[] | null>(null);

  const [topManangers, setTopManagers] = useState<ManagerInfo[] | null>(null);

  const [dealsPrices, setDealsPrice] = useState<ManagerInfo[] | null>(null);

  const [dealsTimeExpire, setDealsTimeExpire] = useState<ManagerInfo[] | null>(
    null
  );

  const [paginationInfo, setPaginationInfo] = useState<
    Record<string, { currPage: number; totalPage: number }>
  >({
    news: { currPage: 1, totalPage: 1 },
    trainings: { currPage: 1, totalPage: 1 },
    events: { currPage: 1, totalPage: 1 },
  });

  const [pagination, setPagination] = useState({
    news: {
      page: 1,
      limit: 5,
    },
    trains: {
      page: 1,
      limit: 5,
    },
    events: {
      page: 1,
      limit: 5,
    },
  });

  const [getTopNewsMutation, { isLoading: topNewsLoading }] =
    useGetTopNewsAnalyticsMutation();

  const [getTopEventsMutation, { isLoading: topEventsLoading }] =
    useGetTopEventsAnalyticsMutation();

  const [getTopTrainingMutation, { isLoading: topTrainingLoading }] =
    useGetTopTrainingsAnalyticsMutation();

  const [getTopManagerMutation] = useGetTopManagersAnalyticsMutation();

  const [getDealsPriceMutation] = useGetDealsPriceAnalyticsMutation();

  const [getDealsTimeExpireMutation] = useGetDealsExpireTimeAnalyticsMutation();

  async function fetchTopNews() {
    try {
      const { data, error } = await getTopNewsMutation(
        objectToQueryString({ ...pagination.news, ...filter })
      );
      setPaginationInfo((prev) => ({
        ...prev,
        news: {
          currPage: data?.meta?.links.currPage || 0,
          totalPage: data?.meta?.links.totalPage || 0,
        },
      }));

      if (error) {
        console.error("API Error (News):", error);
      } else if (data) {
        setTopNews(data.data);
        // setTotalPages(data.totalPages);
      }
    } catch (err) {
      console.error("Unexpected Error (News):", err);
    }
  }

  async function fetchTopEvents() {
    try {
      const { data, error } = await getTopEventsMutation(
        objectToQueryString({ ...pagination.events, ...filter })
      );
      setPaginationInfo((prev) => ({
        ...prev,
        events: {
          currPage: data?.meta?.links.currPage || 0,
          totalPage: data?.meta?.links.totalPage || 0,
        },
      }));
      if (error) {
        console.error("API Error (Events):", error);
      } else if (data) {
        setTopEvents(data.data);
        // setTotalPages(data.totalPages);
      }
    } catch (err) {
      console.error("Unexpected Error (Events):", err);
    }
  }

  async function fetchTopTraining() {
    try {
      const { data, error } = await getTopTrainingMutation(
        objectToQueryString({ ...pagination.trains, ...filter })
      );
      setPaginationInfo((prev) => ({
        ...prev,
        trainings: {
          currPage: data?.meta?.links.currPage || 0,
          totalPage: data?.meta?.links.totalPage || 0,
        },
      }));

      if (error) {
        console.error("API Error (Training):", error);
      } else if (data) {
        setTopTraining(data.data);
        // setTotalPages(data.totalPages);
      }
    } catch (err) {
      console.error("Unexpected Error (Training):", err);
    }
  }

  async function fetchTopManagers() {
    try {
      const { data, error } = await getTopManagerMutation(
        objectToQueryString({ ...pagination.news, ...filter })
      );
      if (error) {
        console.error("API Error (Managers):", error);
      } else if (data) {
        setTopManagers(data.data);
        // setTotalPages(data.totalPages);
      }
    } catch (err) {
      console.error("Unexpected Error (Managers):", err);
    }
  }

  async function fetchDealsPrice() {
    try {
      const { data, error } = await getDealsPriceMutation(
        objectToQueryString({ ...pagination.events, ...filter })
      );
      if (error) {
        console.error("API Error (Deals Price):", error);
      } else if (data) {
        setDealsPrice(data.data);
        // setTotalPages(data.totalPages);
      }
    } catch (err) {
      console.error("Unexpected Error (Deals Price):", err);
    }
  }

  async function fetchDealsExpireTime() {
    try {
      const { data, error } = await getDealsTimeExpireMutation(
        objectToQueryString({ ...pagination.trains, ...filter })
      );
      if (error) {
        console.error("API Error (Deals Expire):", error);
      } else if (data) {
        setDealsTimeExpire(data.data);
        // setTotalPages(data.totalPages);
      }
    } catch (err) {
      console.error("Unexpected Error (Deals Expire):", err);
    }
  }

  useEffect(() => {
    if (roles.isAdmin) {
      fetchTopNews();
    } else {
      fetchTopManagers();
    }
  }, [roles.isAdmin, filter]);

  useEffect(() => {
    if (roles.isAdmin) {
      fetchTopEvents();
    } else {
      fetchDealsPrice();
    }
  }, [roles.isAdmin, filter]);

  useEffect(() => {
    if (roles.isAdmin) {
      fetchTopTraining();
    } else {
      fetchDealsExpireTime();
    }
  }, [roles.isAdmin, filter]);


  return (
    <>
      {roles.isAdmin ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[10px] order-4">
          <EventsAnaliticsCart
            title="Топ новостей"
            data={topNews}
            isLoading={topNewsLoading}
            label="Просмотры"
            paginationInfo={paginationInfo.news}
          />
          <EventsAnaliticsCart
            title="Топ обучения"
            data={topTraining}
            isLoading={topTrainingLoading}
            label="Заходы"
            paginationInfo={paginationInfo.trainings}
          />
          <EventsAnaliticsCart
            title="Топ мероприятий"
            data={topEvents}
            isLoading={topEventsLoading}
            label="Записи"
            paginationInfo={paginationInfo.events}
          />
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[10px] order-4">
          <EventsAnaliticsCart
            label="Сделок"
            title="Топ менеджеров"
            data={topManangers}
          />
          <EventsAnaliticsCart
            label="Сумма"
            title="Сумма сделок"
            data={dealsPrices}
          />
          <EventsAnaliticsCart
            label="Время"
            title="Скорость сделок"
            data={dealsTimeExpire}
          />
        </div>
      )}
    </>
  );
};

export default NewsEventsTrainingComponent;
