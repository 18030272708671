import React, { useEffect, useLayoutEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useAppDispatch } from "store";
import { getUserAction } from "store/UserReducer/actions";

function App() {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(getUserAction());
  }, [dispatch]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="pt-[67px] lg:pt-0 bg-bg-default">
      <Outlet />
    </div>
  );
}

export default App;
